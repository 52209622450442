import React, { useState, useEffect } from "react";
import NavigationBar from "../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import DynamicTableWithCustomInners from "../../../tables/DynamicTableWithCustomInners";
import DatePickerButton from "../../../button/datePickerButton";
import FilterButtonV2 from "../../../button/FilterButtonV2";
import { isMyTokenExpired } from "../../auth/tokenUtils";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { apiUrl } from "../../../../config";
import axios from "axios";
import Loader from "../../../popup/loader";

const BrandList = () => {
  const { t } = useTranslation("brandApplicationList");
  const [filteredItems, setFilteredItems] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const locationArray = [[t("brands"), "/brands"]];

  const fetchData = async () => {
    try {
      setLoaderState(true);
      const token = getCookie("access_token");
      if (!token || isMyTokenExpired(token)) {
        toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
        return navigate("/sign-in-realm");
      }

      const response = await axios.get(
        `${apiUrl}/api/v1/admin/application?page=0&size=1000`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const responseData = response.data.responseData || []; // Access responseData correctly
      console.log(responseData);
      const filteredList = responseData.filter((item) => {
        const status = item.status;
        return location.state?.requestGroupTitleText === t("pending")
          ? status === "PENDING"
          : location.state?.requestGroupTitleText === t("approved")
          ? status === "APPROVED"
          : true;
      });

      setData(filteredList);
    } catch (error) {
      console.error("HATA:", error);
    } finally {
      setLoaderState(false);
    }
  };

  const handleFilterDatesChanged = (dates) => {
    // Implement your date filter logic here
  };

  useEffect(() => {
    fetchData();
  }, []);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    return value.split(`; ${name}=`).pop()?.split(";").shift();
  }

  return (
    <div className="dashboard-container">
      <NavigationBar locationArray={locationArray} />
      {loaderState && <Loader />}
      {/* Replace with your loader component */}
      <DynamicTableWithCustomInners
        customButtonDisabled
        const
        columns={[
          { title: t("id"), field: "id", className: "column-id" }, // ID
          { title: t("brandName"), field: "brandName", className: "column-id" },
          {
            title: t("companyName"),
            field: "companyName",
            className: "column-id",
          },
          { title: t("email"), field: "email", className: "column-id" }, // Email
          { title: t("url"), field: "url", className: "column-id" }, // URL
          {
            title: t("phoneNumber"),
            field: "phoneNumber",
            className: "column-id",
          },
          {
            title: t("companyPhoneNumber"),
            field: "companyPhoneNumber",
            className: "column-id",
          }, // Company Phone Number
          { title: t("created"), field: "created", className: "column-date" }, // Created Date
        ]}
        data={data}
        itemPerPageTitle={t("brandPerPage")}
        tableName={t("brands")}
        handleInputChange={(data, setter) => setter(data)} // Adjusted this function
      >
        <FilterButtonV2
          filterItems={[]} // Populate with actual filter items
          dialogTitle={`${t("actionTypes")}:`}
          onFilteredItemsChange={setFilteredItems}
          checkedItems={filteredItems}
        />
        <DatePickerButton onDateChange={handleFilterDatesChanged} />
        <button
          className="static-button-container create-segment-button"
          onClick={() => window.location.reload()}
        >
          {t("update")}
        </button>
      </DynamicTableWithCustomInners>
    </div>
  );
};

export default BrandList;
