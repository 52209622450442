import React, { useState, useEffect } from "react";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import DynamicTableWithInnerElements from "../../../../../tables/DynamicTableWithInnerElements";
import ApprovalPopup from "../../../../../popup/approvalPopup";
import CallAPI from "../../../../../api/callApi";
import { apiUrl } from "../../../../../../config";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../popup/loader";
const SegmentDashboard = () => {
  const { t } = useTranslation("segmentation");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [loaderState, setLoaderState] = useState(false);
  const [segmentPage, setSegmentPage] = useState(0);
  const [segmentPerPage, setSegmentPerPage] = useState(20);
  const [segments, setSegments] = useState(false);
  const [segmentTotalPage, setSegmentTotalPage] = useState(0);
  const [segmentTotalCount, setSegmentTotalCount] = useState(0);
  const [popup, setPopup] = useState(false);
  const [popupSegment, setPopupSegment] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);

  const navigate = useNavigate();

  const locationArray = [[t("segments"), "/brand/srm/segment"]];

  const columns = [
    { title: t("ID"), field: "id", className: "column-id" },
    { title: t("segmentName"), field: "name", className: "column-name" },
    {
      title: t("segmentDescription"),
      field: "description",
      className: "column-name",
    },
    { title: t("rules"), field: "rules", className: "column-status" },
    {
      title: t("sotierCount"),
      field: "sotierCount",
      className: "column-status",
    },
    { title: t("lastUpdateDate"), field: "updated", className: "column-date" },
    { title: t("creationDate"), field: "created", className: "column-date" },
    { title: t("actions"), field: "unscrollable", className: "column-id" },
  ];

  const handleDeleteSegment = (segment) => {
    setPopupDelete(true);
    setSelectedSegment(segment);
    setShowPopup(true);
  };

  const handleApproveDelete = (id) => {
    setShowPopup(false);
    setSelectedSegment(null);
    deleteSegment(id);
  };

  const formatDateStringLocal = (dateString) => {
    // UTC tarihini al
    const utcDate = new Date(dateString);

    // UTC tarihine manuel olarak 3 saat ekle
    utcDate.setHours(utcDate.getHours() + 3);

    // Tarih ve saat bilgilerini istediğiniz formatta al
    const formattedDate = utcDate.toLocaleDateString("tr-TR");
    const formattedTime = utcDate.toLocaleTimeString("tr-TR");
    const formattedDateTime = formattedDate + " " + formattedTime;
    return formattedDateTime;
  };

  const deleteSegment = async (id) => {
    const deleteSegmentResponse = await CallAPI({
      method: "DELETE", // HTTP isteği türü
      endPoint: apiUrl + `/api/v1/brand-admin/brand/segment/${id}`,
      body: undefined, // İstek gövdesi
      successMessage: t("segmentDeletedSuccessfully"), // Başarılı mesaj (isteğe bağlı)
      errorMessage: t("errorMessage"), // Hata mesajı
      navigate: navigate, // Yönlendirme fonksiyonu
      t: t, // Çeviri işlevi
    });
    handleFetchSegments();
  };

  const handleCancelDelete = () => {
    setShowPopup(false);
    setSelectedSegment(null);
  };

  const handleEditSegment = (segment) => {
    navigate("/brand/srm/segment/create", {
      state: { returnedSegment: segment },
    });
  };

  const showRules = (segment) => {
    setPopupDelete(false);
    setSelectedSegment(segment);
    setShowPopup(true);
  };

  useEffect(() => {
    handleFetchSegments();
  }, [segmentPage, segmentPerPage]);

  const handleFetchSegments = async () => {
    setLoaderState(true);
    const segmentResponse = await CallAPI({
      method: "GET", // HTTP isteği türü
      endPoint:
        apiUrl +
        `/api/v1/brand-admin/brand/segment?page=${segmentPage}&size=${segmentPerPage}`, // API URL'si
      body: undefined, // İstek gövdesi
      successMessage: undefined, // Başarılı mesaj (isteğe bağlı)
      errorMessage: t("errorMessage"), // Hata mesajı
      navigate: navigate, // Yönlendirme fonksiyonu
      t: t, // Çeviri işlevi
    });
    setLoaderState(false);
    setSegmentTotalPage(segmentResponse.data.pageMetadata.totalPage);
    setSegmentPage(segmentResponse.data.pageMetadata.page);
    setSegmentTotalCount(segmentResponse.data.pageMetadata.count);
    const segmentsWithButtons = segmentResponse.data.responseData.map(
      (segment) => ({
        ...segment,
        rules: (
          <button
            className="segment-rules-button segment-button"
            onClick={() => showRules(segment)}
          >
            {t("rules")}
          </button>
        ),
        sotierCount: segment.sotierCount,
        updated: formatDateStringLocal(segment.updated),
        created: formatDateStringLocal(segment.created),

        unscrollable: (
          <div className="table-unscrollable-button-container-row">
            <button
              className="segment-edit-button segment-button"
              onClick={() => ToSotierList(segment)}
            >
              {t("details")}
            </button>
            <button
              className="segment-edit-button segment-button"
              onClick={() => handleEditSegment(segment)}
            >
              {t("edit")}
            </button>
            <button
              className="segment-delete-button segment-button"
              onClick={() => handleDeleteSegment(segment)}
            >
              {t("delete")}
            </button>
          </div>
        ),
      })
    );
    setSegments(segmentsWithButtons);
  };

  const sotierPerPageChange = (e, setter) => {
    setSegmentPage(0);
    setter(e);
  };

  const ToSotierList = (segment) => {
    // Remove non-serializable properties
    const { unscrollable, ...serializableSegment } = segment;

    navigate("/brand/srm/segment/list", {
      state: { segment: serializableSegment },
    });
  };

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      {showPopup && (
        <ApprovalPopup
          questionText={t("areYouSureToDeleteSegment")}
          cancelText={t("cancel")}
          approveText={t("delete")}
          onClose={() => {
            setShowPopup(false);
          }}
          isDeleteApprove={popupDelete}
          approvalObject={selectedSegment}
          onApproveButtonClick={handleApproveDelete}
          onCancelButtonClick={handleCancelDelete}
        />
      )}
      <NavigationBar locationArray={locationArray} />

      {segments && (
        <DynamicTableWithInnerElements
          segment={true}
          columns={columns}
          data={segments}
          segmentTotalPage={segmentTotalPage}
          segmentPerPageTitle={t("segmentPerPage")}
          setSegmentPerPage={setSegmentPerPage}
          setSegmentPage={setSegmentPage}
          handleInputChange={sotierPerPageChange}
          segmentPage={segmentPage}
        />
      )}
    </div>
  );
};

export default SegmentDashboard;
