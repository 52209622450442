// toast.js
import { toast, ToastContainer } from "react-toastify";
import "../../assets/css/toastify.css";

// Function to show a success toast
export const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
  });
};

export const showErrorToast = (message) => {
  toast.error(message, {
    osition: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

// Function to show an info toast
export const showInfoToast = (message) => {
  toast.info(message, {
    position: "top-right",
  });
};

// Function to show a warning toast
export const showWarningToast = (message) => {
  toast.warn(message, {
    position: "top-right",
  });
};
