import React, { useEffect, useState, useRef } from "react";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import basketIcon from "../../../../../../assets/media/Icon ionic-ios-cart.svg";
import sotyBasket from "../../../../../../assets/media/Icon metro-opencart.svg";
import ContentCard from "../../../../../cards/ContentCard";
import facebookIcon from "../../../../../../assets/media/facebook.svg";
import instagramIcon from "../../../../../../assets/media/instagram.svg";
import twitterIcon from "../../../../../../assets/media/x-twitter.svg";
import youtubeIcon from "../../../../../../assets/media/youtube.svg";
import tiktokIcon from "../../../../../../assets/media/tiktok.svg";
import userPng from "../../../../../../assets/media/user.png";
import { apiUrl, imagesUrl } from "../../../../../../config";
import InputContainer from "../../../../../utils/inputContainer";
import SingleTextInput from "../../../../../utils/singleTextInput";
import SingleNumberInput from "../../../../../utils/singleNumberInput";
import SingleDateInput from "../../../../../utils/singleDateInput";
import SingleTextTextarea from "../../../../../utils/singleTextTextarea";
import { toast } from "react-toastify";
import CallAPI from "../../../../../api/callApi";
import DynamicTable from "../../../../../tables/DynamicTable";
import SotierListTable from "../../../../../tables/sotierListTable";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../../../../popup/loader";
import DynamicPopupWithChildren from "../../../../../popup/dynamicPopupWithChildren";

const ContentBasket = () => {
  const { t } = useTranslation("contentList");
  const [contentCards, setContentCards] = useState([]);
  const [contentSelected, setContentSelected] = useState(false);
  const [selectedContent, setSelectedContent] = useState(false);
  const [offerHistory, setOfferHistory] = useState([]);
  const [sotyCoin, setSotyCoin] = useState();
  const [offerMessage, setOfferMessage] = useState();
  const [endDate, setEndDate] = useState();
  const navigate = useNavigate();
  const offerContainerRef = useRef(null);
  const [loaderState, setLoaderState] = useState(false);
  const location = useLocation();
  const returnedContent = location.state;
  const [historyPopup, setHistoryPopup] = useState(false);

  const [highestOfferToShow, setHighestOfferToShow] = useState();

  
  useEffect(() => {
    // JSON parse ederken hata olabilir, dolayısıyla try-catch kullanabiliriz
    let savedContentCards = [];
    try {
      savedContentCards = JSON.parse(localStorage.getItem("contentCards")) || [];
    } catch (error) {
      console.error("Error parsing contentCards from localStorage:", error);
    }
    
    // `savedContentCards`'ın bir dizi olup olmadığını kontrol edin
    if (!Array.isArray(savedContentCards)) {
      console.error("Expected savedContentCards to be an array, but got:", savedContentCards);
      savedContentCards = []; // Boş bir dizi ile devam et
    }
  
    setContentCards(savedContentCards);
  
    if (returnedContent) {
      onOffer(returnedContent.content);
    }
  
    // Fetch and display offer history for all content cards
    if(savedContentCards.length > 0) {
      fetchAndDisplayAllOfferHistories(savedContentCards);

    }
  }, [returnedContent]);
  

  useEffect(() => {
    if (contentSelected && offerContainerRef.current) {
      offerContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [contentSelected]);

  const fetchAndDisplayAllOfferHistories = async (contentCards) => {
    try {
      // En yüksek teklif değerini saklayacağımız değişkeni tanımlıyoruz
      let overallHighestOffer = 0;
  
      const updatedContentCards = await Promise.all(
        contentCards?.map(async (content) => {
          let highestOffer = 0;
          let highestOfferDetails = {};
  
          try {
            const response = await CallAPI({
              method: "GET",
              endPoint: `${apiUrl}/api/v1/brand-admin/offer/history?sotierId=${content.sotierId}&challengeId=${content.challengeId}`,
            });
  
            const offerHistoryData = response.data.responseData.history.map(
              (item) => ({
                ...item,
                offerDate: formatDateStringLocal(item.offerDate),
                offerEndDate: formatDateStringLocal(item.offerEndDate),
                offerStatus:
                  item.offerStatus === "OFFERED"
                    ? checkExpiration(item.offerEndDate)
                      ? t("expired")
                      : t(item.offerStatus)
                    : t(item.offerStatus),
              })
            );
  
            // En yüksek teklifi bul
            const maxOffer = Math.max(
              ...offerHistoryData.map((offer) => offer.offerCoin || 0)
            );
  
            if (maxOffer > highestOffer) {
              highestOffer = maxOffer;
              highestOfferDetails = offerHistoryData.find(
                (offer) => offer.offerCoin === highestOffer
              );
            }
  
            // Genel en yüksek teklifi güncelle
            if (highestOffer > overallHighestOffer) {
              overallHighestOffer = highestOffer;
            }
          } catch (error) {
            console.error(
              `Failed to fetch offer history for Content ID ${content.sotierId}:`,
              error
            );
          }
  
          return {
            ...content,
            highestOffer: highestOfferDetails.offerCoin || 0, // İçerik kartına en yüksek teklifi ekle
          };
        })
      );
  
      // Güncellenmiş içerik kartlarıyla durumu güncelle
      setContentCards(updatedContentCards);
      // Genel en yüksek teklifi göstermek için durumu güncelle
      setHighestOfferToShow(overallHighestOffer);
  
    } catch (error) {
      console.error("Failed to fetch and display offer histories:", error);
    }
  };
  

  const formatDateForCreation = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffMs = now - date;
    const diffMins = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

    if (diffMins < 60) {
      return `${diffMins} ${t("minutesAgo")}`;
    } else if (diffHours < 24) {
      return `${diffHours} ${t("hoursAgo")}`;
    } else {
      return date.toLocaleString("tr-TR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  };

  const locationArray = [
    [t("contents"), "/brand/srm/content"],
    [t("basket"), "/brand/srm/content/basket"],
  ];

  const onOffer = (content) => {
    setSelectedContent(content);
    setSotyCoin(0);
    setEndDate("");
    setOfferMessage("");
    setContentSelected(true);
    getOfferHistory({
      sotierId: content.sotierId,
      challengeId: content.challengeId,
    });
  };

  const onDelete = (content) => {
    const updatedContentCards = contentCards.filter((card) => card !== content);
    setContentCards(updatedContentCards);
    localStorage.setItem("contentCards", JSON.stringify(updatedContentCards));
    setContentSelected(false);
  };

  const onDateSelect = (value) => {
    const selectedDate = new Date(value);
    const now = new Date();

    if (selectedDate < now) {
      toast.error(t("endDateCannotBeEarlierThanNow"));
      setEndDate("");
    } else {
      setEndDate(value);
    }
  };

  const Columns = [
    {
      title: t("offerDate"),
      field: "offerDate",
      className: "sotier-list-column",
    },
    {
      title: t("offerAmount"),
      field: "offerCoin",
      className: "sotier-list-column",
    },
    {
      title: t("offerLastDate"),
      field: "offerEndDate",
      className: "sotier-list-column",
    },
    {
      title: t("status"),
      field: "offerStatus",
      className: "sotier-list-column",
    },
  ];

  const formatDateStringLocal = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);
    const formattedDate = utcDate.toLocaleDateString("tr-TR");
    const formattedTime = utcDate.toLocaleTimeString("tr-TR");
    return `${formattedDate} ${formattedTime}`;
  };

  const checkExpiration = (offerEndDate) => {
    const currentDate = new Date();
    const endDate = new Date(offerEndDate);
    endDate.setHours(endDate.getHours() + 3);
    console.log(currentDate);
    return currentDate > endDate;
  };

  const getOfferHistory = async ({ sotierId, challengeId }) => {
    try {
      const response = await CallAPI({
        method: "GET",
        endPoint: `${apiUrl}/api/v1/brand-admin/offer/history?sotierId=${
          sotierId || selectedContent.sotierId
        }&challengeId=${challengeId || selectedContent.challengeId}`,
      });
      const updatedOfferHistory = response.data.responseData.history.map(
        (item) => ({
          ...item,
          offerDate: formatDateStringLocal(item.offerDate),
          offerEndDate: formatDateStringLocal(item.offerEndDate),
          offerStatus:
            item.offerStatus === "OFFERED"
              ? checkExpiration(item.offerEndDate)
                ? t("expired")
                : t(item.offerStatus)
              : t(item.offerStatus),
        })
      );
      console.log(response);

      setOfferHistory(updatedOfferHistory);
    } catch (error) {
      console.error("Failed to fetch offer history:", error);
    }
  };

  const onOfferApprove = async () => {
    if (!sotyCoin) {
      toast.error(t("sotyCoinValueCannotBeEmpty"));
      return;
    }
    if (sotyCoin < highestOfferToShow) {
      toast.error(t("sotyCoinValueMustBeHigherThanTheHighestOffer"));
      return;
    }
    if (!endDate) {
      toast.error(t("endDateCannotBeEmpty"));
      return;
    }
    if (!offerMessage) {
      toast.error(t("offerMessageCannotBeEmpty"));
      return;
    }
  
    const iterDate = new Date(`${endDate}:00`);
    const isoDate = iterDate.toISOString();
    setLoaderState(true);
  
    let allOffersSuccessful = true; // Track overall success
    let successfulOffersCount = 0; // Track the count of successful offers
    const updatedContentCards = [...contentCards]; // Create a copy of contentCards to update
  
    try {
      for (const content of contentCards) {
        const requestBody = {
          sotierId: content.sotierId,
          challengeId: content.challengeId,
          coin: parseInt(sotyCoin, 10),
          offerEndDate: isoDate.slice(0, -5),
          offerMessage: offerMessage,
        };
  
        try {
          const response = await CallAPI({
            method: "POST",
            endPoint: `${apiUrl}/api/v1/brand-admin/offer`,
            body: requestBody,
            errorMessage: t("offerFailed"),
          });
  
          if (response.status === 200) {
            // Offer successful, increment the count and mark the content card for removal
            successfulOffersCount++;
            const index = updatedContentCards.findIndex(
              (card) =>
                card.sotierId === content.sotierId &&
                card.challengeId === content.challengeId
            );
            if (index !== -1) {
              updatedContentCards.splice(index, 1); // Remove the content card from the array
            }
          } else {
            // Offer failed, keep in basket
            allOffersSuccessful = false;
          }
        } catch (error) {
          console.error("Offer submission failed:", error);
          allOffersSuccessful = false;
        }
      }
  
      // Update localStorage and state after all offers have been processed
      localStorage.setItem("contentCards", JSON.stringify(updatedContentCards));
      setContentCards(updatedContentCards);
  
      // Fetch and display updated offer histories
      fetchAndDisplayAllOfferHistories(updatedContentCards);
  
      // Show a single notification summarizing the number of successful offers
      if (successfulOffersCount > 0) {
        toast.success(t("offerSuccess") + successfulOffersCount);
      }
  
      // Navigate to dashboard if all offers were successful
      if (allOffersSuccessful) {
        navigate("/brand/srm/content");
      }
    } catch (error) {
      console.error("An error occurred during offer processing:", error);
    } finally {
      setLoaderState(false);
    }
  };
  
  

  const handleOfferHistory = ({sotierId, challengeId}) => {
    getOfferHistory({sotierId: sotierId, challengeId : challengeId});
    setHistoryPopup(true);
  };

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} />
      {historyPopup && (
        <DynamicPopupWithChildren
          onClose={() => setHistoryPopup(false)}
          onCancel={() => setHistoryPopup(false)}
        >
          <DynamicTable
            columns={Columns}
            data={offerHistory}
            tableName={t("offerHistory")}
            offerTable={true}
          />
        </DynamicPopupWithChildren>
      )}
      <div className="content-basket-section">
        <div className="content-basket-container">
        {Array.isArray(contentCards) && contentCards.length > 0 && contentCards.map((content, index) => (
            <ContentCard
              key={index}
              content={content}
              provider={content.provider}
              link={content.link}
              providerIcon={
                content.provider === "FACEBOOK"
                  ? facebookIcon
                  : content.provider === "INSTAGRAM"
                  ? instagramIcon
                  : content.provider === "YOUTUBE"
                  ? youtubeIcon
                  : content.provider === "TIKTOK"
                  ? tiktokIcon
                  : twitterIcon
              }
              onClick={() => {}}
              disabled={true} // Disable the button in the basket view
              profilePicture={
                content.sotierProfilePhoto === undefined
                  ? userPng
                  : `${imagesUrl}/${content.sotierProfilePhoto}`
              }
              fullName={content.sotierName}
              id={content.sotierId}
              interaction={content.interaction}
              sotyCoin={content.totalEarnedCoins || 0}
              creationDate={formatDateForCreation(content.published)}
              bottomType={"offer"}
              onOfferHistory={() => {
                console.log(content);
                handleOfferHistory({sotierId: content.sotierId, challengeId: content.challengeId});
              }}
              onOffer={() => {
                onOffer(content);
              }}
              onDelete={() => {
                onDelete(content);
              }}
              OFFERED={content.OFFERED || false}
              highestOffer={content.highestOffer} // Pass highest offer as prop
            />
          ))}
        </div>

        <div
          ref={offerContainerRef}
          className="challenge-creation-content-container content-offer-column"
        >
          {/*
            <DynamicTable
              columns={Columns}
              data={offerHistory}
              tableName={t("offerHistory")}
              offerTable={true}
            />
             */}
          {(highestOfferToShow && highestOfferToShow) !== 0 && (
            <InputContainer
              title={t("highestOffer")}
              titleDescription={t("highestOfferDesc")}
            >
              <label className="content-basket-highest-offer-label">
                {t("highestOfferFirstSentence")}
                <span> {highestOfferToShow} Soty Coin </span>{" "}
                {t("highestOfferSecondSentence")}
              </label>
            </InputContainer>
          )}

          <SingleNumberInput
            title={t("sotyCoin")}
            titleDescription={t("sotyCoinDescription")}
            inputPlaceholder={t("sotyCoin")}
            isMandatory={true}
            maxLength={10000}
            minLength={1}
            onChange={(e) => {
              setSotyCoin(e.target.value);
            }}
            onBlur={(e) => {
              setSotyCoin(e.target.value);
            }}
            value={sotyCoin}
          />
          <SingleDateInput
            title={t("offerLastDate")}
            titleDescription={t("offerLastDateDescription")}
            inputPlaceholder={t("offerLastDate")}
            isMandatory={true}
            onChange={(e) => {
              onDateSelect(e.target.value);
            }}
            onBlur={(e) => {
              onDateSelect(e.target.value);
            }}
            value={endDate}
          />
          <SingleTextTextarea
            title={t("offerMessage")}
            titleDescription={t("offerMessageDescription")}
            inputPlaceholder={t("offerMessage")}
            isMandatory={true}
            maxLength={10000}
            minLength={1}
            onChange={(e) => {
              setOfferMessage(e.target.value);
            }}
            onBlur={(e) => {
              setOfferMessage(e.target.value);
            }}
            value={offerMessage}
          />
          <div
            className="offer-approve-button"
            onClick={() => {
              onOfferApprove();
            }}
          >
            {t("approve")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentBasket;
