import React from 'react';

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="image-modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <img src={imageUrl} alt="Uploaded" className="modal-image" />
      </div>
    </div>
  );
};

export default ImageModal;
