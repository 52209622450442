import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import closeIcon from "../../assets/media/close.png";
import AutoScrollToTop from "../utils/autoScrollToTop";

const DynamicPopup = ({
  title,
  isCondition,
  cancelText,
  acceptText,
  onAccept,
  isNegative,
  content,
  onCancel,
}) => {
  const { t } = useTranslation("segmentation");
  AutoScrollToTop();

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);

    const handleResize = () => {
      const backgroundElement = document.querySelector(
        ".dynamic-popup-blurred-background"
      );
      if (backgroundElement) {
        backgroundElement.style.height = `${document.documentElement.scrollHeight}px`;
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="dynamic-popup-blurred-background"></div>
      <div className="dynamic-popup-container">
        <div className="dynamic-popup-header">
          <div className="dynamic-popup-header-title">{title}</div>
          <div className="dynamic-popup-header-close-button" onClick={onCancel}>
            <img src={closeIcon} alt="Close" />
          </div>
        </div>
        <hr />
        <div className="approval-popup-content dynamic-popup-content">
          {content}
        </div>
        <div className="approval-popup-buttons">
          {isCondition ? (
            <>
              <button
                className="approval-popup-button cancel"
                onClick={onCancel}
              >
                {cancelText}
              </button>
              <button
                className={`approval-popup-button ${
                  isNegative ? "delete" : "approve-button"
                }`}
                onClick={onAccept}
              >
                {acceptText}
              </button>
            </>
          ) : (
            <button className="approval-popup-button cancel" onClick={onCancel}>
              {t("close")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default DynamicPopup;
