import React from "react";
import '../../assets/css/HorizontalWrapper.css';

const HorizontalWrapper = ({ children }) => {
    // You can add any logic here that you want to apply to the wrapped components
    return (
      <div className="HorizontalWrapper">
        {children}
      </div>
    );
  };
export default HorizontalWrapper;