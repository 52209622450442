import React, { useState, useEffect, useRef } from "react";
import '../../assets/css/SortFilterSearch.css';
import downArrow from '../../assets/media/down-arrow.png'

import FilterIcon from '../../assets/media/filter.svg';
import '../../assets/css/SortFilterSearch.css'
import FilterDialog from "../dialogs/filterDialog";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import StaticDateRangeDialog from "../dialogs/staticDateRangeDialog";



const StaticDateRangeButton = ({ options, defaultSelected, onSelect }) => {
    const [isDialogVisible, setDialogVisibility] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
  
    const { t, i18n } = useTranslation("challengeDashboard");

    const dialogRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Kullanıcı DateRangePicker dışına tıklarsa, picker'ı gizle
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setDialogVisibility(false);
            }
        };

        // Event listener'ı ekleyin
        document.addEventListener("mousedown", handleClickOutside);

        // Component unmount edildiğinde event listener'ı kaldırın
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dialogRef]);

    const toggleDialogVisibility = () => {
        setDialogVisibility(!isDialogVisible);
    };

    const onOptionSelect = (option) => {
        setSelectedOption(option)
        onSelect(option)
    }

    return (
        <div className="static-date-range-button-container" ref={dialogRef}>

            <div className="static-date-range-button-wrapper" onClick={toggleDialogVisibility}>
                <div className="static-date-range-button-title">
                {selectedOption?.label || defaultSelected?.label || t("pleaseSelectDateRange")}
                </div>
                <img className={`static-date-range-button-img ${isDialogVisible ? "deg180" : ""}`} src={downArrow} alt="V" />
            </div>
            {isDialogVisible && <StaticDateRangeDialog options={options} onSelect={(option) => { onOptionSelect(option) }} />}


        </div>
    )
}
export default StaticDateRangeButton;