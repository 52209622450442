import React from 'react';
import '../../assets/css/textPopup.css';
import { useTranslation } from "react-i18next";
import RangeSlider from '../utils/rangeSlider';

const SegmentPopup = ({ onClose, segment, questionString, onAccept }) => {
    const { t } = useTranslation("segmentation");

    // Segment içindeki kuralları ayırma fonksiyonu
    function parseRules(rule) {
        const rows = [];

        rule.subRules.forEach(subRule => {
            const conditions = {};
            let dateFilterConditions = null;

            subRule.expressions.forEach(expression => {
                const { field, operator, value } = expression;

                if (field === "DATE_FILTER") {
                    dateFilterConditions = dateFilterConditions || [];
                    dateFilterConditions.push({ [operator]: value });
                } else {
                    if (!conditions[field]) {
                        conditions[field] = [];
                    }
                    conditions[field].push({ [operator]: value });
                }
            });

            rows.push({ conditions, dateFilterConditions });
        });

        return rows;
    }

    // Segment kurallarını al
    const rows = segment?.rule ? parseRules(segment.rule) : [];

    return (
        <div className='text-popup'>
            <div className="segment-popup-content">
                <div className="segment-popup-title">{t("segmentName")}{" : "}</div>
                <div className="segment-popup-subtitle">{segment.name}</div>
                <hr />

                <div className="segment-popup-title">{t("segmentDescription")}{" : "}</div>
                <div className="segment-popup-subtitle">{segment.description}</div>
                <hr />

                <div className="segment-popup-title">{t("rules")}{" : "}</div>

                <div className="segment-popup-rules">
                    {rows.map((row, index) => (
                        <div key={index}>
                            <div className="segment-popup-rule">
                                {Object.keys(row.conditions).map((keyword, i) => (
                                    <RangeSlider
                                        key={i}
                                        minValue={row.conditions[keyword].find(cond => cond.GTE)?.GTE}
                                        maxValue={row.conditions[keyword].find(cond => cond.LTE)?.LTE}
                                        singleValue={row.conditions[keyword].find(cond => cond.EQ)?.EQ}
                                        keyword={t(keyword)}
                                        t={t}
                                        dateFilterConditions={row.dateFilterConditions}
                                    />
                                ))}
                                <hr />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {questionString &&
                <div className='segment-popup-question'>{questionString}</div>
            }
            <div className='segment-popup-button-container'>
                <button className='segment-popup-close-button' onClick={onClose}>
                    {t("close")}
                </button>
                {onAccept &&
                    <button className='segment-popup-accept-button' onClick={onAccept}>
                        {t("approve")}
                    </button>
                }
            </div>
        </div>
    );
};

export default SegmentPopup;
