import React from "react";
import DoubleSidedArrow from "../../../../../assets/media/double-horizontal-arrow.png";
import infoIconEmpty from "../../../../../assets/media/infoIconEmpty.svg"; // ikonu uygun şekilde import edin

const SpendAndEarn = ({
  xPrice,
  yCoin,
  setXPrice,
  setYCoin,
  t,
  returnedCampaign,
}) => {
  return (
    <div className="coin-folding-container">
      {/* Amount Section */}
      <div className="coin-folding-input-container campaign-option-select-container">
        <div className="challenge-creation-input-container-header-subtitle">
          {t("amount")}
        </div>
        <div className="coin-folding-input-wrapper x-price-input-container">
          <input
            type="number"
            min="0"
            className="challenge-creation-soty-coin-input"
            placeholder={t("TL")}
            value={xPrice || returnedCampaign?.responseData.spend || ""}
            onChange={(e) => setXPrice(e.target.value)}
          />
          <span className="turkish-lira-sign">{t("TL")}</span>

          <img
            className="X-Price-To-Y-Icon"
            src={DoubleSidedArrow}
            alt={"<->"}
          />

          <input
            type="number"
            min="0"
            className="challenge-creation-soty-coin-input"
            placeholder={t("sotyCoinExchange")}
            value={yCoin || returnedCampaign?.responseData.earn || ""}
            onChange={(e) => setYCoin(e.target.value)}
          />
        </div>

        {/* Info section */}
        <div className="challenge-creation-date-description-row">
          <img
            className="challenge-creation-date-description-icon"
            src={infoIconEmpty}
            alt="icon"
          />
          <div className="challenge-creation-date-description">
            {t("sotyCoinValue")}
          </div>
        </div>

        {/* If yCoin is filled, show additional info */}
        {yCoin && (
          <div className="challenge-creation-date-description-row">
            <img
              className="challenge-creation-date-description-icon"
              src={infoIconEmpty}
              alt="icon"
            />
            <div className="challenge-creation-date-description">
              {t("sotyCoinValueYouInput")}
            </div>
            <div className="challenge-creation-date-description challenge-creation-money-div">
              {yCoin / 10}
              {t("TL")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpendAndEarn;
