import React, { useState, useEffect } from "react";
import arrow from "../../../../../assets/media/arrow.png";
import downArrow from "../../../../../assets/media/down-arrow.png";
import "../../../../../assets/css/InteractionCreation.css";
import "../../../../../assets/css/ChallengeList.css";
import axios from "axios";
import { getCookie } from "../../../auth/tokenUtils";
import { useNavigate } from "react-router-dom";
import { apiUrl, imagesUrl } from "../../../../../config";
import { isMyTokenExpired } from "../../../auth/tokenUtils";
import { toast } from "react-toastify";
import DynamicTable from "../../../../tables/DynamicTable";
import Pagination from "../../../../tables/Pagination";
import PlaceholderImg from "../../../../../assets/media/noImageToShow.jpeg";
import { useTranslation } from "react-i18next";
import facebookIcon from "../../../../../assets/media/facebook.svg";
import instagramIcon from "../../../../../assets/media/instagram.svg";
import twitterIcon from "../../../../../assets/media/x-twitter.svg";
import youtubeIcon from "../../../../../assets/media/youtube.svg";
import tiktokIcon from "../../../../../assets/media/tiktok.svg";
import Loader from "../../../../popup/loader";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";

const ChallengeList = () => {
  const { t, i18n } = useTranslation("challengeList");
  const navigate = useNavigate();
  const [challenges, setChallenges] = useState([
    {
      ChallengeID: "@45784768653645",
      ChallengeName: "X 3 Soty Coin Avantajı",
      ChallengeType: "Survey",
      ChallengeSubtype: "Likert",
      CreationDate: '01/06/2023',
      StartDate: '05/06/2023',
      EndDate: '30/08/2023',
      SotyCoin: 300,
      Status: "draft",
    },
    {
      ChallengeID: "@12345678901234",
      ChallengeName: "Yarışma Zamanı",
      ChallengeType: "Contest",
      ChallengeSubtype: "Multiple Choice",
      CreationDate: '02/06/2023',
      StartDate: '10/06/2023',
      EndDate: '25/08/2023',
      SotyCoin: 500,
      Status: "active",
    },
    {
      ChallengeID: "@56789012345678",
      ChallengeName: "Anket Keyfi",
      ChallengeType: "Survey",
      ChallengeSubtype: "Open-ended",
      CreationDate: '03/06/2023',
      StartDate: '15/06/2023',
      EndDate: '20/08/2023',
      SotyCoin: 200,
      Status: "inactive",
    },
    // Diğer verileri benzer şekilde ekleyebilirsiniz.
    // ...
    {
      ChallengeID: "@09876543210987",
      ChallengeName: "Bilgi Yarışması",
      ChallengeType: "Quiz",
      ChallengeSubtype: "True/False",
      CreationDate: '10/06/2023',
      StartDate: '20/06/2023',
      EndDate: '15/08/2023',
      SotyCoin: 400,
      Status: "active",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [challengeDetailVisible, setChallengeDetailVisible] = useState(false);
  const [clickedChallenge, setClickedChallenge] = useState();
  const itemsPerPage = 100;
  const [loaderState, setLoaderState] = useState(false);

  const isRowClickable = () => {
    return true;
  }
  useEffect(() => {
    fetchData(1); // Fetch data for the initial page
  }, [])

  const fetchData = (page) => {
    setLoaderState(true);
    const token = getCookie("access_token");

    if (!token) {
      setLoaderState(false);
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      toast.error(t("error.missingAccessToken"))
      return;
    }
    if (isMyTokenExpired(token)) {
      setLoaderState(false);
      toast.error(
        t("error.sessionExpired")
      );
      navigate("/sign-in-brand");
    }

    // Fetch your data from the API
    axios
      .get(
        apiUrl + `/api/v1/brand-admin/brand/challenge?page=${page - 1}&size=${itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const responseData = response.data.responseData || [];
        const pageMetadata = response.data.pageMetadata || {};
        console.log(responseData);
        setChallenges(responseData);
        setTotalPages(pageMetadata.totalPage || 0);
        console.log(pageMetadata.totalPage);
        setCurrentPage(page);
        setLoaderState(false);
      })
      .catch((error) => {
        setLoaderState(false);
        console.error("Error fetching data:", error);
        toast.error("error.notFound")
        // Handle error, display a message, etc.
      });
  };



  const handlePageChange = (newPage) => {
    fetchData(newPage);
  };


  const formatDate = (dateString) => {
    // UTC tarihini al
    const utcDate = new Date(dateString);

    // UTC tarihine manuel olarak 3 saat ekle
    utcDate.setHours(utcDate.getHours() + 3);

    // Tarih ve saat bilgilerini istediğiniz formatta al
    const formattedDate = utcDate.toLocaleDateString("tr-TR");
    const formattedTime = utcDate.toLocaleTimeString("tr-TR");

    return {
      date: formattedDate,
      time: formattedTime,
    };
  };







  const columns = [
    { title: t("control"), field: "Control", className: "" },
    { title: t("challengeId"), field: "ChallengeID", className: "dynamic-table-link" },
    { title: t("challengeLogo"), field: "ChallengeLogo", className: "" },
    { title: t("challengeName"), field: "ChallengeName", className: "" },
   // { title: t("segment"), field: "segment", className: "" },
    { title: t("challengeDescription"), field: "ChallengeDesc", className: "" },
    { title: t("challengeType"), field: "ChallengeType", className: "" },
    { title: t("startDate"), field: "StartDate", className: "" },
    { title: t("endDate"), field: "EndDate", className: "" },
    { title: t("sotyCoin"), field: "SotyCoin", className: "" },
    { title: t("status"), field: "Status", className: "" },
  ];

  const fetchSelectedChallenge = async (challengeId, challenge) => {
    setLoaderState(true);
    const token = getCookie("access_token");
    if (!token) {
      setLoaderState(false);
      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      setLoaderState(false);
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }

    let endpoint;
    console.log(challenge);
    if (challenge.challengeType === "SURVEY") {
      endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/survey/${challengeId}/detail`;
    }
    else if (challenge.socialChallengeType === "INTERACTION") {
      endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/interaction/${challengeId}/detail`;
    }
    else if (challenge.socialChallengeType === "CONTENT_CREATION") {
      endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/content/${challengeId}/detail`;
    }


    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      console.log(data);


      if (data.responseData.socialChallengeType === "CONTENT_CREATION") {
        setLoaderState(false);
        navigate('/brand/challenges/content-creation', { state: { returnedChallenge: data } });
      }
      else if (data.responseData.socialChallengeType === "INTERACTION") {
        setLoaderState(false);
        navigate('/brand/challenges/interaction-creation', { state: { returnedChallenge: data } });
      }
      else if (data.responseData.challengeType === "SURVEY") {
        setLoaderState(false);
        navigate('/brand/challenges/survey-creation', { state: { returnedChallenge: data } });
      }
      // Do something with the data
    } catch (error) {
      setLoaderState(false);
      toast.error(t("error.fetchingData") + " (" + error.message + ")");
      console.error('Error fetching data:', error);
    }
  };

  const fetchSelectedChallengeToDetailPage = async (challengeId, challenge) => {
    setLoaderState(true);
    const token = getCookie("access_token");
    if (!token) {
      setLoaderState(false);
      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");

      return;
    }
    if (isMyTokenExpired(token)) {
      setLoaderState(false);
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");

    }

    let endpoint;
    console.log(challenge);
    if (challenge.challengeType === "SURVEY") {
      endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/survey/${challengeId}/detail`;
    }
    else if (challenge.socialChallengeType === "INTERACTION") {
      endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/interaction/${challengeId}/detail`;
    }
    else if (challenge.socialChallengeType === "CONTENT_CREATION") {
      endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/content/${challengeId}/detail`;
    }


    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      console.log(data.responseData);

      setLoaderState(false);
      navigate('/brand/challenges/challenge-detail', { state: { returnedChallenge: data.responseData } });
      // Do something with the data
    } catch (error) {
      setLoaderState(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleEditClick = (data) => {
    console.log(data);
    const modifiedData = {
      responseData: data,
    };
    console.log(modifiedData)

    if (data.challengeType === "SURVEY") {
      fetchSelectedChallenge(data.id, data);

    }
    else if (data.socialChallengeType === "INTERACTION") {
      fetchSelectedChallenge(data.id, data);

    }
    else if (data.socialChallengeType === "CONTENT_CREATION") {
      fetchSelectedChallenge(data.id, data);
    }
  }
  const challengeTypeToShow = (challengeType) => {
    let message;

    if (challengeType === "SURVEY") {
      message = t("survey");
    } else if (challengeType === "SOCIAL_MEDIA") {
      message = t("socialMedia");
    } else {
      message = t("otherChallengeTypes");
    }
    return message
  }
  const socialChallengeTypeToShow = (socialChallengeType) => {
    let message;
    if (socialChallengeType === "INTERACTION") {
      message = t("interaction");
    } else if (socialChallengeType === "CONTENT_CREATION") {
      message = t("contentCreation");
    } else {
      message = "";
    }
    return message
  }
  const formatSocialProvider = (provider) => {
    if (provider) {
      let message = provider.toLowerCase();
      return message.charAt(0).toUpperCase() + message.slice(1);
    } else {
      return ""; // veya başka bir varsayılan değer
    }
  };
  const statusToShow = (status) => {
    if (status === "DRAFTED") {
      return t("drafted")
    }
    else {
      return t("released")
    }
  }

  const getSocialIcon = (provider) => {
    switch (provider) {
      case "FACEBOOK":
        return facebookIcon;
      case "INSTAGRAM":
        return instagramIcon;
      case "TWITTER":
        return twitterIcon;
      case "YOUTUBE":
        return youtubeIcon;
      case "TIKTOK":
        return tiktokIcon;
      default:
        return null; // or a default icon if needed
    }
  };

  const data = challenges.map((challenge) => ({
    Control: (<button className="dynamic-table-button" onClick={() => handleRowClick(challenge.id)}
    >{t("detail")}</button>),
    ChallengeID: challenge.id,
    ChallengeLogo: <img src={challenge.logo ? imagesUrl + "/" + challenge.logo : PlaceholderImg} alt={`Logo for ${challenge.name}`} />,
    ChallengeName: challenge.name,
    ChallengeDesc: challenge.description,
    ChallengeType: <div>{challengeTypeToShow(challenge.challengeType)}<div>{socialChallengeTypeToShow(challenge.socialChallengeType)}<div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>{challenge.socialProvider && (<img className="challenge-list-social-media-icon" src={getSocialIcon(challenge.socialProvider)} alt="img" />)}{formatSocialProvider(challenge.socialProvider)}</div></div></div>,
    StartDate: <>{formatDate(challenge.startDate).date}<br />{formatDate(challenge.startDate).time}</>,
    EndDate: <>{formatDate(challenge.endDate).date}<br />{formatDate(challenge.endDate).time}</>,
    SotyCoin: challenge.prize + " = " + challenge.prize / 10 + t("TL"),
    Status: (
      <span className={challenge.status === "DRAFTED" ? "dynamic-table-data-red" : "dynamic-table-data-green"}>
        {statusToShow(challenge.status)}
      </span>
    ),
  }));



  const handleRowClick = (ID) => {
    // Find the clicked challenge from the challenges array
    console.log(ID)

    const clickedChallenge = challenges.find(challenge => challenge.id === ID);

    if (clickedChallenge) {
      console.log("Clicked Challenge Details:", clickedChallenge);
      // Add your logic for displaying the challenge details as needed
    } else {
      console.error("Challenge not found for ChallengeID:", ID);
    }

    setChallengeDetailVisible(true);
    setClickedChallenge(clickedChallenge);
  };


  const [profile, setProfile] = useState(null);


  const locationArray = [
    [t('challenges'), '/brand/challenges'],
    [t('challengeList'), '/brand/challenges/list']
  ];
  return (
    <div className="challenge-list-container">
      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} dividerDisabled={true}/>
      <hr />
      {challengeDetailVisible && (
        <div className="challenge-list-detail-popup">
          <div className="challenge-list-detail-top">
            <div className="challenge-list-detail-title">
              <div>
                <span>
                  {t("challengeId")} :&nbsp;
                </span>

                {clickedChallenge.id}
              </div>

              <div>
                <span>
                  {t("challengeName")} :&nbsp;
                </span>
                {clickedChallenge.name}
              </div>


            </div>
            <img src={imagesUrl + '/' + clickedChallenge.logo} />
          </div>
          <div className="challenge-list-detail-description"><span>{t("challengeDescription")}:</span> {clickedChallenge.description}</div>
          <div className="challenge-list-detail-type">
            <span>
              {t("challengeType")}:&nbsp;
            </span>
            {challengeTypeToShow(clickedChallenge.challengeType)}
          </div>
          {clickedChallenge.surveyType && (<div className="challenge-list-detail-type">
            <span>
              {t("surveyType")}:&nbsp;
            </span>
            {console.log("CLICKED CHALLENGE = ", clickedChallenge)}
          </div>)}
          {clickedChallenge.socialChallengeType && (<div className="challenge-list-detail-type">
            <span>
              {t("challengeSocialType")}:&nbsp;
            </span>
            {socialChallengeTypeToShow(clickedChallenge.socialChallengeType)}
          </div>)}
          {clickedChallenge.socialProvider && (<div className="challenge-list-detail-type">
            <span>
              {t("socialProvider")}:&nbsp;
            </span>
            {formatSocialProvider(clickedChallenge.socialProvider)}
          </div>)}
          <div className="challenge-list-detail-time">
            <span>
              {t("startDate")}:&nbsp;
            </span>
            {formatDate(clickedChallenge.startDate).date}  {formatDate(clickedChallenge.startDate).time}
          </div>
          <div className="challenge-list-detail-time">
            <span>
              {t("endDate")}:&nbsp;
            </span>
            {formatDate(clickedChallenge.endDate).date}  {formatDate(clickedChallenge.endDate).time}
          </div>
          <div className="challenge-list-detail-prize">
            <span>
              {t("sotyCoin")}:&nbsp;
            </span>
            {clickedChallenge.prize + " = " + clickedChallenge.prize / 10 + t("TL")}
          </div>
          <div className="challenge-list-detail-prize">
            <span>
              {t("status")}:&nbsp;
            </span>
            {statusToShow(clickedChallenge.status)}
          </div>
          {clickedChallenge.status === "DRAFTED" && (<button className="challenge-list-detail-edit" onClick={() => {
            setChallengeDetailVisible(!challengeDetailVisible)
            handleEditClick(clickedChallenge)
          }}>{t("edit")}</button>
          )}
          {clickedChallenge.status === "RELEASED" && (<button className="challenge-list-detail-edit" onClick={() => {
            fetchSelectedChallengeToDetailPage(clickedChallenge.id, clickedChallenge);
          }}>{t("showDetails")}</button>
          )}
          <button className="challenge-list-detail-close" onClick={() => { setChallengeDetailVisible(!challengeDetailVisible) }}>{t("close")}</button>



        </div>)}

      <div className="challenge-list-table-container">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        <DynamicTable
          columns={columns}
          data={data}
          isRowClickable={isRowClickable} // Example: Make rows clickable if the status is "Inactive"
          onRowClick={handleRowClick}
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ChallengeList;
