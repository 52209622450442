import React, { useEffect, useState } from "react";
import Loader from "../../../../popup/loader";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import DynamicTableWithCustomInners from "../../../../tables/DynamicTableWithCustomInners";
import FilterButtonV2 from "../../../../button/FilterButtonV2";
import downArrow from "../../../../../assets/media/down-arrow.png";
import "../../../../../assets/css/userList.css";
import NotAvailablePopup from "../../../../popup/notAvailablePopup";
import { userRoles } from "../../../../utils/userRoles";
import CreateChallengeButton from "../../../../button/createChallengeButton";
import AreYouSure from "../../../../popup/areYouSure";
import DynamicPopup from "../../../../popup/dynamicPopup";
import SingleTextInput from "../../../../utils/singleTextInput";
import SingleTextInputSmall from "../../../../utils/small_inputs/singleTextInputSmall";
import RadioButtonContainer from "../../../../utils/radioButtonContainer";
import { toast } from "react-toastify";
import CustomButton from "../../../../button/CustomButton";
import CallAPI from "../../../../api/callApi";
import { apiUrl } from "../../../../../config";
import FormatDate from "../../../../utils/formatDate";
import { useNavigate } from "react-router-dom";

const BrandUserList = () => {
  const [loaderState, setLoaderState] = useState(false);
  const { t } = useTranslation("userList");
  const navigate = useNavigate();
  const locationArray = [[t("users"), "/brand/settings/users"]];
  const [notAvailable, setNotAvailable] = useState(true);
  const [openIndex, setOpenIndex] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const userRoleList = userRoles();
  const [userToBeRemoved, setUserToBeRemoved] = useState(false);
  const [userToBeAddName, setUserToBeAddName] = useState("");
  const [userToBeAddSurname, setUserToBeAddSurname] = useState("");
  const [userToBeUpdate, setUserToBeUpdate] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [email, setEmail] = useState("");
  const [emailAgain, setEmailAgain] = useState("");
  const [emailsMatch, setEmailsMatch] = useState(null);
  const [userToBeAddFullName, setUserToBeAddFullName] = useState(null);
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [editUserPopup, setEditUserPopup] = useState(false);
  const [userToBeAddPhone, setUserToBeAddPhone] = useState(false);
  const [userList, setUserList] = useState([]);
  const [groups, setGroups] = useState([]);
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleEmailAgainChange = (e) => {
    const value = e.target.value;
    setEmailAgain(value);
    setEmailsMatch(email === value);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    console.log("Selected Role:", role);
  };

  useEffect(() => {
    getAllUsers();
    getAllGroups();
  }, []);

  function filterNADates(lastVisit) {
    // 2020-01-01 tarihini oluştur
    const cutoffDate = new Date("2020-01-01");

    // Kullanıcının son ziyaret tarihini oluştur
    const userDate = new Date(lastVisit);

    // Son ziyaret tarihi 2020'den eskiyse "N/A", değilse formatla
    if (userDate < cutoffDate) {
      return "N/A";
    } else {
      return FormatDate(lastVisit);
    }
  }
  const getAllGroups = async () => {
    const response = await CallAPI({
      method: "GET",
      endPoint: apiUrl + "/api/v1/brand-admin/role/group",
    });
    console.log(response);
    setGroups(response.data.responseData.roleList);
  };
  const getAllUsers = async () => {
    setLoaderState(true);
    const response = await CallAPI({
      method: "GET",
      endPoint: apiUrl + "/api/v1/brand-user",
      navigate: navigate,
    });
    console.log(response?.data.responseData);
    const formattedUsers = response.data.responseData.map((user) => ({
      ...user,
      role: user.roles[0],
      created: user.created,
      updated: user.lastVisit,
    }));

    setUserList(formattedUsers);
    setLoaderState(false);
  };

  const columns = [
    {
      title: t("user"),
      render: (rowData) => (
        <div className="brand-user-list-user-cell">
          <div className="brand-user-list-user-name">
            {rowData.firstName + " " + rowData.lastName}
          </div>
          <div className="brand-user-list-user-mail">{rowData.email}</div>
        </div>
      ),
      // Add custom sort key for the user column
      customSortKey: "user", // add this line for custom sorting
    },
    { title: t("phone"), field: "phoneNumber" },
    { title: t("role"), field: "role" },
    { title: t("last"), field: "updated" },
    { title: t("creation"), field: "created" },
    // Conditionally add the "actions" column based on user roles
    ((userRoleList && userRoleList.includes("DELETE_BRAND_USERS")) ||
      (userRoleList && userRoleList.includes("CREATE_BRAND_USERS"))) && {
      title: t("actions"),
      field: "actions",
    },
  ].filter(Boolean);

  const roleCounts = userList.reduce((acc, item) => {
    acc[item.role] = (acc[item.role] || 0) + 1;
    return acc;
  }, {});

  const rolesWithCounts = Object.keys(roleCounts).map((role) => ({
    role,
    count: roleCounts[role],
  }));

  // Filter data based on selected roles
  const filterData = () => {
    if (filteredItems.length === 0) {
      return userList; // No filter applied, show all data
    }
    return userList.filter((item) => filteredItems.includes(item.role));
  };

  // Handle filtered items change
  const handleFilteredItemsChange = (checkedItems) => {
    setFilteredItems(checkedItems);
  };

  // Handle actions button click
  const handleActionsClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Handle delete user action
  const handleDeleteUser = async (item) => {
    console.log(item);
    setLoaderState(true);
    const response = await CallAPI({
      method: "DELETE",
      endPoint: apiUrl + "/api/v1/brand-user/" + item.userId,
      successMessage: t("userDeletedSuccessfully"),
    });
    setLoaderState(false);

    if (response?.status === 200) {
      setUserToBeRemoved(false);
      getAllUsers();
      window.location.reload();
    }
  };
  const onFullNameChange = (e) => {
    setUserToBeAddFullName(e.target.value);
    const fullName = e.target.value.trim();

    // Türkçe karakterleri de dahil ederek sadece harfleri ve boşlukları tutuyoruz
    const cleanedFullName = fullName
      .replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ\s]/g, "") // Türkçe karakterleri ekliyoruz
      .replace(/\s+/g, " "); // Fazla boşlukları tek bir boşlukla değiştiriyoruz

    const [firstName, ...rest] = cleanedFullName.split(" ");
    const lastName = rest.join(" ");

    setUserToBeAddName(firstName);
    setUserToBeAddSurname(lastName);
  };

  const convertPhoneNumberFormat = (phoneNumber) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");

    // Ensure we have exactly 10 digits
    if (cleaned.length !== 10) {
      throw new Error("Invalid phone number length. Expected 10 digits.");
    }

    // Extract parts of the phone number
    const areaCode = cleaned.slice(0, 3);
    const firstPart = cleaned.slice(3, 6);
    const secondPart = cleaned.slice(6, 8);
    const thirdPart = cleaned.slice(8);

    // Format the phone number to (XXX).XXX-XXXX
    return `(${areaCode})-${firstPart}-${secondPart}${thirdPart}`;
  };

  const handleUpdateUser = async () => {
    console.log("userToBeUpdate:", {
      firstName: userToBeUpdate.firstName,
      lastName: userToBeUpdate.lastName,
      phoneNumber: userToBeUpdate.phoneNumber,
      roles: userToBeUpdate.roles[0],
    });
    console.log("userToBeAddName:", userToBeAddName);
    console.log("userToBeAddSurname:", userToBeAddSurname);
    console.log("userToBeAddPhone:", userToBeAddPhone);
    console.log("Selected Role Name:", selectedRole);
    console.log(
      "Formatted Phone Number:",
      convertPhoneNumberFormat(userToBeAddPhone)
    );

    if (
      userToBeUpdate.firstName === userToBeAddName &&
      userToBeUpdate.lastName === userToBeAddSurname &&
      userToBeUpdate.phoneNumber ===
        convertPhoneNumberFormat(userToBeAddPhone) &&
      (userToBeUpdate.roles[0] === selectedRole || selectedRole === undefined)
    ) {
      toast.error(t("userUpdateError"));
      return;
    }
    setLoaderState(true);
    const phoneToSend = convertPhoneNumberFormat(userToBeAddPhone);
    console.log(userToBeUpdate);
    console.log({
      firstName: userToBeAddName,
      lastName: userToBeAddSurname,
      phoneNumber: phoneToSend,
      id: userToBeUpdate.userId,
      groups: [selectedRole.name],
    });
    const response = await CallAPI({
      method: "PUT",
      endPoint: apiUrl + "/api/v1/brand-user/" + userToBeUpdate.userId,
      body: {
        firstName: userToBeAddName,
        lastName: userToBeAddSurname,
        phoneNumber: phoneToSend,

        groups: [selectedRole.name],
      },
      navigate: navigate,
      successMessage: t("userUpdatedSuccessfully"),
      errorMessage: t("anErrorAcurredWhileUpdatingUser"),
    });
    console.log(response);
    if (response?.status && response?.status === 200) {
      setAddUserPopup(false);
      setEditUserPopup(false);
      resetUser();
      getAllUsers();
    }
    setLoaderState(false);
  };
  const handleAddUser = async () => {
    const phoneToSend = convertPhoneNumberFormat(userToBeAddPhone);

    const emailToSend = email;

    // Telefon numarası ve e-posta adresi ile kullanıcıları kontrol edelim
    const userWithSamePhone = userList.find(
      (user) => user.phoneNumber === phoneToSend
    );
    const userWithSameEmail = userList.find(
      (user) => user.email === emailToSend
    );

    if (userWithSamePhone && userWithSameEmail) {
      toast.error(t("userPhoneAndEmailAlreadyExists")); // Hem telefon numarası hem e-posta adresi zaten var
      setLoaderState(false);
      return;
    } else if (userWithSamePhone) {
      toast.error(t("userPhoneAlreadyExists")); // Aynı telefon numarası
      setLoaderState(false);
      return;
    } else if (userWithSameEmail) {
      toast.error(t("userEmailAlreadyExists")); // Aynı e-posta adresi
      setLoaderState(false);
      return;
    }
    setLoaderState(true);

    console.log({
      firstName: userToBeAddName,
      lastName: userToBeAddSurname,
      email: email,
      phoneNumber: phoneToSend,
      groups: selectedRole,
    });
    const response = await CallAPI({
      method: "POST",
      endPoint: apiUrl + "/api/v1/brand-user",
      body: {
        firstName: userToBeAddName,
        lastName: userToBeAddSurname,
        email: email,
        phoneNumber: phoneToSend,

        groups: [selectedRole.name],
      },
      navigate: navigate,
      successMessage: t("userAddedSuccessfully"),
      errorMessage: t("anErrorAcurredWhileAddingUser"),
    });
    console.log(response);
    if (response?.status && response?.status === 200) {
      setAddUserPopup(false);
      setEditUserPopup(false);
      resetUser();
      getAllUsers();
    }
    setLoaderState(false);
  };

  const validatePhoneNumber = (number) => {
    // Telefon numarasının belirli bir formatta olup olmadığını kontrol eden düzenli ifade
    const phoneRegex = /^\(\d{3}\)-\d{3}-\d{4}$/;
    return phoneRegex.test(number);
  };

  const formatPhoneNumber = (number) => {
    // Numara içindeki tüm karakterleri temizle (sadece rakamlar kalsın)
    let inputNumber = number.replace(/\D/g, "");

    // İlk 10 haneyi al
    inputNumber = inputNumber.slice(0, 10);

    // Numarayı (555)-555-5555 formatına dönüştür
    let formattedNumber = "";
    if (inputNumber.length > 0) {
      formattedNumber += `(${inputNumber.slice(0, 3)})`;
    }
    if (inputNumber.length > 3) {
      formattedNumber += `-${inputNumber.slice(3, 6)}`;
    }
    if (inputNumber.length > 6) {
      formattedNumber += `-${inputNumber.slice(6, 10)}`;
    }
    console.log(formattedNumber)
    return formattedNumber;
  };
  const handleAddEditUser = () => {
    // Check if either name or surname is missing
    if (!userToBeAddName || !userToBeAddSurname) {
      toast.error(t("pleaseAddNameSurname"));
      return;
    }
    if (userToBeAddName.length < 2 && userToBeAddSurname.length < 2) {
      toast.error(t("nameAndSurnamesLengthsMustBeMinTwo"));
      return;
    }

    if (userToBeAddName.length < 2) {
      toast.error(t("nameLengthMustBeMinTwo"));
      return;
    }
    if (userToBeAddSurname.length < 2) {
      toast.error(t("surnameLengthMustBeMinTwo"));
      return;
    }

    if (addUserPopup) {
      if (email !== emailAgain) {
        toast.error(t("emailsDoesNotMatch"));
        return;
      } else if (!email || !emailAgain) {
        toast.error(t("pleaseEnterEmail"));
        return;
      }
    }
    // Validate email and phone number

    if (!selectedRole || selectedRole === null) {
      toast.error(t("pleaseSelectRole"));
      return;
    } else if (!validatePhoneNumber(userToBeAddPhone)) {
      if (!validatePhoneNumber(formatPhoneNumber(userToBeAddPhone))) {
        toast.error(t("pleaseEnterValidPhoneNumber"));
        return;
      } else {
        setUserToBeAddPhone(formatPhoneNumber(userToBeAddPhone));
      }
    }

    // Proceed with adding or editing the user
    try {
      if (addUserPopup) {
        handleAddUser();
      } else if (editUserPopup) {
        handleUpdateUser();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetUser = () => {
    setSelectedRole();
    setUserToBeAddName();
    setUserToBeAddSurname();
    setUserToBeUpdate();
    setUserToBeAddFullName();
    setEmail();
    setEmailAgain();
    setUserToBeAddPhone();
    setSelectedRole();
    setEditUserPopup(false);
  };
  const enhancedData = filterData().map((item, index) => ({
    ...item,
    actions: (
      <div className="brand-user-list-actions-container">
        <div
          className={`brand-user-list-actions-button ${
            openIndex === index ? "move-left" : ""
          }`}
          onClick={() => {
            handleActionsClick(index);
          }}
        >
          <label>{t("actions")}</label>
          <img
            className="brand-user-list-actions-button-img"
            src={downArrow}
            alt="Down Arrow"
          />
        </div>

        <div
          className={`brand-user-list-actions-collapsible ${
            openIndex === index ? "move-right" : ""
          }`}
        >
          <div
            className="brand-user-list-actions-collapsible-button brand-user-list-button-back"
            onClick={() => handleActionsClick(index)}
          >
            <img src={downArrow} alt="Down Arrow" />
          </div>
          <div className="brand-user-list-actions-collapsible-flex">
            {userRoleList && userRoleList.includes("CREATE_BRAND_USERS") && (
              <div
                className="brand-user-list-actions-collapsible-button brand-user-list-button-update"
                onClick={() => {
                  setSelectedRole(item.roles[0]);
                  setUserToBeAddName(item.firstName);
                  setUserToBeAddSurname(item.lastName);
                  setUserToBeUpdate(item);
                  setUserToBeAddFullName(item.firstName + " " + item.lastName);
                  setEmail(item.email);
                  setUserToBeAddPhone(item.phoneNumber);
                  setEditUserPopup(true);
                }}
              >
                {t("updateUser")}
              </div>
            )}

            {userRoleList && userRoleList.includes("DELETE_BRAND_USERS") && (
              <div
                className="brand-user-list-actions-collapsible-button brand-user-list-button-delete"
                onClick={() => {
                  setUserToBeRemoved(item);
                }}
              >
                {t("deleteUser")}
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  }));

  return (
    <div className="dashboard-container">
      {userToBeRemoved && (
        <AreYouSure
          isNegative={true}
          isCondition={true}
          questionText={t("areYouSureYouWantToDeleteThisUser")}
          acceptText={t("delete")}
          cancelText={t("cancel")}
          onCancel={() => {
            setUserToBeRemoved(false);
          }}
          onAccept={() => {
            handleDeleteUser(userToBeRemoved);
          }}
          content={
            <div className="delete-user-popup-content-container">
              <div>
                <strong>{t("firstName")}:</strong> {userToBeRemoved.firstName}
              </div>
              <div>
                <strong>{t("lastName")}:</strong> {userToBeRemoved.lastName}
              </div>
              <div>
                <strong>{t("mail")}:</strong> {userToBeRemoved.email}
              </div>

              <div>
                <strong>{t("phone")}:</strong> {userToBeRemoved.phoneNumber}
              </div>
              <div>
                <strong>{t("role")}:</strong> {userToBeRemoved.roles[0]}
              </div>
              <div>
                <strong>{t("last")}:</strong> {userToBeRemoved.updated}
              </div>
              <div>
                <strong>{t("creation")}:</strong> {userToBeRemoved.created}
              </div>
            </div>
          }
        />
      )}
      {(addUserPopup || editUserPopup) && (
        <DynamicPopup
          isNegative={false}
          isCondition={true}
          title={
            addUserPopup
              ? t("createUser")
              : editUserPopup
              ? t("editUser")
              : t("createUser")
          }
          acceptText={t("approve")}
          cancelText={t("cancel")}
          onCancel={() => {
            setUserToBeAddFullName();
            setUserToBeAddName();
            setUserToBeAddSurname();
            setUserToBeAddPhone();
            setEmail();
            setEmailAgain();
            resetUser();
            setAddUserPopup(false);
            setEditUserPopup(false);
          }}
          onAccept={() => {
            handleAddEditUser();
          }}
          content={
            <div className="delete-user-popup-content-container">
              <SingleTextInputSmall
                isMandatory={true}
                title={t("nameSurname")}
                value={userToBeAddFullName || ""}
                onBlur={(e) => {
                  onFullNameChange(e);
                }}
                onChange={(e) => {
                  onFullNameChange(e);
                }}
              />
              {!editUserPopup && (
                <SingleTextInputSmall
                  isMandatory={true}
                  title={t("phone")}
                  type="tel"
                  value={userToBeAddPhone || ""}
                  onBlur={(e) => {
                    let inputNumber = e.target.value.replace(/\D/g, ""); // Sadece rakamları al
                    inputNumber = inputNumber.slice(0, 10); // İlk 10 hane

                    // Numarayı (555)-555-5555 formatına dönüştür
                    let formattedNumber = "";
                    if (inputNumber.length > 0) {
                      formattedNumber += `(${inputNumber.slice(0, 3)})`;
                    }
                    if (inputNumber.length > 3) {
                      formattedNumber += `-${inputNumber.slice(3, 6)}`;
                    }
                    if (inputNumber.length > 6) {
                      formattedNumber += `-${inputNumber.slice(6, 10)}`;
                    }

                    setUserToBeAddPhone(formattedNumber);
                  }}
                  onChange={(e) => {
                    let inputNumber = e.target.value.replace(/\D/g, ""); // Sadece rakamları al
                    inputNumber = inputNumber.slice(0, 10); // İlk 10 hane

                    // Numarayı (555)-555-5555 formatına dönüştür
                    let formattedNumber = "";
                    if (inputNumber.length > 0) {
                      formattedNumber += `(${inputNumber.slice(0, 3)})`;
                    }
                    if (inputNumber.length > 3) {
                      formattedNumber += `-${inputNumber.slice(3, 6)}`;
                    }
                    if (inputNumber.length > 6) {
                      formattedNumber += `-${inputNumber.slice(6, 10)}`;
                    }

                    setUserToBeAddPhone(formattedNumber);
                  }}
                />
              )}

              {!editUserPopup && (
                <React.Fragment>
                  <SingleTextInputSmall
                    isMandatory={true}
                    title={t("email")}
                    type="email"
                    isCorrect={
                      email && email.includes("@") && email === emailAgain
                    }
                    value={email}
                    onBlur={(e) => {
                      handleEmailChange(e);
                      console.log(e.target.value);
                    }}
                    onChange={(e) => {
                      handleEmailChange(e);
                      console.log(e.target.value);
                    }}
                  />

                  <SingleTextInputSmall
                    isMandatory={true}
                    type="email"
                    isCorrect={
                      email && email.includes("@") && email === emailAgain
                    }
                    value={emailAgain}
                    title={t("email") + " " + t("again")}
                    onBlur={(e) => {
                      handleEmailAgainChange(e);
                      console.log(e.target.value);
                    }}
                    onChange={(e) => {
                      handleEmailAgainChange(e);
                      console.log(e.target.value);
                    }}
                  />
                </React.Fragment>
              )}

              <RadioButtonContainer
                title={t("role")}
                isMandatory={true}
                selectedOption={selectedRole}
                radioOptions={groups}
                onRoleSelect={handleRoleSelect}
              />
            </div>
          }
        />
      )}

      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} />
      <DynamicTableWithCustomInners
        columns={columns}
        data={enhancedData}
        tableName={t("userList")}
        itemPage={0}
        itemTotalPage={1}
        setItemPage={() => {}}
        handleInputChange={() => {}}
        itemPerPageTitle={t("userPerPage")}
        paginationDisabled={true}
        setItemPerPage={() => {}}
        customButtonDisabled={true}
        userList={true}
        defaultSort={{
          key: "created", // Varsayılan sıralama anahtarı
          direction: "desc", // Varsayılan sıralama yönü
        }}
        children={
          <>
            <FilterButtonV2
              filterItems={rolesWithCounts}
              dialogTitle={t("roles") + ":"}
              onFilteredItemsChange={handleFilteredItemsChange}
              checkedItems={filteredItems}
            />
            {userRoleList && userRoleList.includes("CREATE_BRAND_USERS") && (
              <CustomButton
                title={t("createUser")}
                handleClick={() => {
                  resetUser();
                  setAddUserPopup(true);
                }}
              />
            )}
          </>
        }
      />
    </div>
  );
};

export default BrandUserList;
