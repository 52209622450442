import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiUrl, imagesUrl } from "../../../../../../config";
import { toast } from "react-toastify";
import DynamicTable from "../../../../../tables/DynamicTable";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../popup/loader";
import {
  getCookie,
  getUserTypeFromToken,
  isMyTokenExpired,
} from "../../../../auth/tokenUtils";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import SearchIcon from "../../../../../../assets/media/SearchIcon.svg";
import FilterButton from "../../../../../button/filterButton";
import DatePickerButton from "../../../../../button/datePickerButton";
import Pagination from "../../../../../tables/Pagination";
import ItemsPerPageButton from "../../../../../button/itemsPerPageButton";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";

const CampaignListBrand = () => {
  const { t } = useTranslation("challengeDashboard");
  const token = getCookie("access_token");
  const [campaignFilter, setCampaignFilter] = useState(" ");
  const [campaignsList, setCampaignsList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  const [sortChallengesBy, setSortChallengesBy] = useState();
  const [loaderState, setLoaderState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchListData();
  }, [sortChallengesBy, itemPerPage]);
  useEffect(() => {
    localStorage.removeItem("checkboxStates");

    if (!token) {
      // Handle the case where the token is empty or undefined
      console.log("JWT token is empty or undefined");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      document.cookie =
        "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      toast.error(t("error401"));
      navigate("/sign-in-brand");
    }

    fetchListData();
  }, []);

  const handlePageChange = (page) => {
    console.log(page);
    fetchListData(page);
  };

  const fetchSelectedChallenge = async (campaignId) => {
    const token = getCookie("access_token");
    if (!token) {
      toast.error(t("error.missingAccessToken"));
      if (getUserTypeFromToken(getCookie("access_token"))) {
        navigate("/sign-in-brand");
      } else {
        navigate("/sign-in-realm");
      }

      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      if (getUserTypeFromToken(getCookie("access_token"))) {
        navigate("/sign-in-brand");
      } else {
        navigate("/sign-in-realm");
      }
      return;
    }

    let endpoint = apiUrl + `/api/v1/brand-admin/brand/campaign/${campaignId}`;

    try {
      setLoaderState(true);
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      setLoaderState(false);
      navigate("/brand/srm/campaign/create", {
        state: { returnedCampaign: data },
      });

      // Do something with the data
    } catch (error) {
      setLoaderState(false);
      toast.error(error.message);
      console.error("Error fetching data:", error);
    }
  };

  // Tarih dizesini "dd.mm.yyyy" formatına dönüştürme fonksiyonu
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split(".").map(Number);
    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${year}`;
  };

  const handleItemsPerPageChange = (item) => {
    setItemPerPage(item);
  };

  const whichTypeSort = () => {
    switch (sortChallengesBy) {
      case "ascForName":
        return ["NAME", "ASC"];
      case "descForName":
        return ["NAME", "DESC"];
      case "newestCreationDate":
        return ["CREATED", "DESC"];
      case "oldestCreationDate":
        return ["CREATED", "ASC"];
      case "ascForBrandName":
        return ["BRAND_NAME", "ASC"];
      case "descForBrandName":
        return ["BRAND_NAME", "DESC"];
      case "ascForType":
        return ["TYPE", "ASC"];
      case "descForType":
        return ["TYPE", "DESC"];
      case "newestStartDate":
        return ["START_DATE", "DESC"];
      case "oldestStartDate":
        return ["START_DATE", "ASC"];
      case "newestEndDate":
        return ["END_DATE", "DESC"];
      case "oldestEndDate":
        return ["END_DATE", "ASC"];
      case "ascJoinCount":
        return ["JOIN_COUNT", "ASC"];
      case "descJoinCount":
        return ["JOIN_COUNT", "DESC"];
      case "ascCampaignStatus":
        return ["CAMPAIGN_STATUS", "ASC"];
      case "descCampaignStatus":
        return ["CAMPAIGN_STATUS", "DESC"];
      default:
        return ["NAME", "ASC"];
    }
  };

  const fetchListData = async (page) => {
    setLoaderState(true);

    const filters = campaignFilter ? campaignFilter.split(",") : [];

    let filterString = "";
    if (filters[0] !== " ") {
      filterString = filters
        .map((filter) => `campaignTypes=${filter}`)
        .join("&");
    } else {
      filterString = false;
    }
    let dateAddon = false;
    if (filterStartDate && filterEndDate) {
      dateAddon = `&startDate=${filterStartDate}&endDate=${filterEndDate}`;
    }
    console.log("date Addon : " + dateAddon);

    const url =
      apiUrl +
      `/api/v1/brand-admin/brand/campaign?campaignSortBy=${
        whichTypeSort()[0]
      }&sortDirection=${whichTypeSort()[1]}&page=${
        page ? page - 1 : "0"
      }&size=${itemPerPage ? itemPerPage : 20}${
        filterString ? "&" + filterString : ""
      }${dateAddon ? dateAddon : ""}`;

    console.log("URL =" + url);

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setCurrentPage(response.data.pageMetadata.page + 1);
      setTotalPages(response.data.pageMetadata.totalPage || 0);
      const transformedData = response.data.responseData.map((item) => {
        const campaign = item;

        const controlButton = () => {
          if (
            !getUserTypeFromToken(getCookie("access_token")) &&
            campaign.status === "DRAFTED"
          ) {
            return null;
          } else if (
            !getUserTypeFromToken(getCookie("access_token")) &&
            campaign.status === "RELEASED"
          ) {
            return (
              <button
                className="dynamic-table-button dashboard-table-button"
                onClick={null}
              >
                {t("showDetails")}
              </button>
            );
          } else if (
            getUserTypeFromToken(getCookie("access_token")) &&
            campaign.status === "DRAFTED"
          ) {
            return (
              <button
                className="dynamic-table-button dashboard-table-button"
                onClick={() => {
                  fetchSelectedChallenge(campaign.id);
                }}
              >
                {t("edit")}
              </button>
            );
          } else if (
            getUserTypeFromToken(getCookie("access_token")) &&
            campaign.status === "RELEASED"
          ) {
            return (
              <button
                className="dynamic-table-button dashboard-table-button"
                onClick={null}
              >
                {t("showDetails")}
              </button>
            );
          }
        };

        const today = new Date();
        const endDate = new Date(campaign.endDate);
        const campaignEndStatus =
          campaign.publishStatus === "RELEASED" && endDate < today;
        const startDateFirst = campaign.startDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join(".");
        const startDateLast = campaign.startDate.split("T")[1];

        const endDateFirst = campaign.endDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join(".");
        const endDateLast = campaign.endDate.split("T")[1];

        return {
          control: controlButton(),
          id: campaign.id,
          campaignImg: (
            <img
              src={
                campaign.image
                  ? imagesUrl + "/" + campaign.image
                  : placeholderImg
              }
              alt={`Logo Bulunamadı`}
            />
          ),
          campaignName: campaign.name,
          campaignDescription: campaign.description,
          campaignType: t(campaign.campaignType),
          startDate: (
            <>
              {" "}
              {formatDate(startDateFirst.split("-").join("."))} <br />{" "}
              {startDateLast}
            </>
          ),
          endDate: (
            <>
              {" "}
              {formatDate(endDateFirst.split("-").join("."))} <br />{" "}
              {endDateLast}
            </>
          ),
          participationCount: campaign.participationCount,
          status: campaignEndStatus
            ? t("COMPLETED")
            : t(campaign.publishStatus),
        };
      });
      setCampaignsList(transformedData);

      setLoaderState(false);

      // Do something with the fetched data here
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoaderState(false);
    }
  };

  // Call the function to fetch the data

  const checkboxData = [
    { title: t("COIN_FOLDING"), campaignType: "COIN_FOLDING" },
    { title: t("DISCOUNT_PERCENTAGE"), campaignType: "DISCOUNT_PERCENTAGE" },
    { title: t("SPEND_AND_EARN"), campaignType: "SPEND_AND_EARN" },
    { title: t("COUPON_PERCENTAGE"), campaignType: "COUPON_PERCENTAGE" },
    { title: t("BUY_X_PAY_Y"), campaignType: "BUY_X_PAY_Y" },
    { title: t("BUY_MORE_PAY_LESS"), campaignType: "BUY_MORE_PAY_LESS" },
    { title: t("FREE_SHIPMENT"), campaignType: "FREE_SHIPMENT" },
  ];

  const campaignColumns = [
    {
      title: t("control"),
      field: "control",
      className: "",
      sortKeyAsc: null,
      sortKeyDesc: null,
      unsortable: true,
    },
    {
      title: t("id"),
      field: "id",
      className: "",
      sortKeyAsc: "ascForId",
      sortKeyDesc: "descForId",
      unsortable: true,
    },
    {
      title: t("campaignName"),
      field: "campaignName",
      className: "",
      sortKeyAsc: "ascForName",
      sortKeyDesc: "descForName",
    },
    {
      title: t("campaignType"),
      field: "campaignType",
      className: "",
      sortKeyAsc: "ascForType",
      sortKeyDesc: "descForType",
    },
    {
      title: t("campaignDescription"),
      field: "campaignDescription",
      className: "",
      sortKeyAsc: null,
      sortKeyDesc: null,
      unsortable: true,
    },
    {
      title: t("campaignImage"),
      field: "campaignImg",
      className: "",
      sortKeyAsc: null,
      sortKeyDesc: null,
      unsortable: true,
    },
    {
      title: t("startDate"),
      field: "startDate",
      className: "",
      sortKeyAsc: "newestStartDate",
      sortKeyDesc: "oldestStartDate",
    },
    {
      title: t("endDate"),
      field: "endDate",
      className: "",
      sortKeyAsc: "newestEndDate",
      sortKeyDesc: "oldestEndDate",
    },
    {
      title: t("participationCount"),
      field: "participationCount",
      className: "",
      sortKeyAsc: "ascJoinCount",
      sortKeyDesc: "descJoinCount",
    },
    {
      title: t("Status"),
      field: "status",
      className: "",
      sortKeyAsc: "ascCampaignStatus",
      sortKeyDesc: "descCampaignStatus",
    },
  ];

  const handleFilterChange = (e) => {
    if (!e || e.length === 0 || e.length === 4) {
      setCampaignFilter(" ");
    } else {
      setCampaignFilter(e.map((item) => item.campaignType).join(","));
    }
  };

  useEffect(() => {
    console.log(campaignFilter);
    fetchListData();
  }, [campaignFilter, filterEndDate, filterEndDate && filterStartDate]);

  const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {
    if (selectedEndDate) {
      const originalStartDate = new Date(selectedStartDate);
      const originalEndDate = new Date(selectedEndDate);

      originalStartDate.setHours(0, 0, 0);
      originalEndDate.setHours(23, 59, 59);

      const formattedStartDate = `${originalStartDate.getFullYear()}-${(
        originalStartDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${originalStartDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${originalStartDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${originalStartDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${originalStartDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const formattedEndDate = `${originalEndDate.getFullYear()}-${(
        originalEndDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${originalEndDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${originalEndDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${originalEndDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${originalEndDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

      setFilterStartDate(formattedStartDate);
      setFilterEndDate(formattedEndDate);
    } else {
      setFilterStartDate();
      setFilterEndDate();
    }
  };

  const locationArray = [
    [t("campaigns"), "/brand/srm/campaign"],
    [t("campaignsList"), "/brand/srm/campaign/list"],
  ];

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar dividerDisabled locationArray={locationArray} />

      <div className="dashboard-top-divider campaign-dashboard-divider" />
      <div className="dashboard-controls-container">
        <FilterButton
          checkboxData={checkboxData}
          onCheckboxSelection={handleFilterChange}
          eventType={"campaign"}
        />
        <DatePickerButton onDateChange={handleFilterDatesChanged} />
      </div>
      <div className="dashboard-divider" />
      <div className="dashboard-challenges-container ">
        <div className="dashboard-challenges-wrapper">
          <div className="dashboard-challenges-controls-container">
            <div className="dashboard-challenges-search-container">
              <img
                className="dashboard-challenges-search-icon"
                src={SearchIcon}
                alt="search"
              />
              <input
                className="dashboard-challenges-search"
                type="text"
                placeholder="search"
              />
            </div>
            <ItemsPerPageButton onSelectItem={handleItemsPerPageChange} />
          </div>
        </div>
        {campaignColumns && campaignsList && (
          <div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />

            <DynamicTable
              columns={campaignColumns}
              data={campaignsList}
              setSortDirection={(e) => {
                setSortChallengesBy(e);
              }}
              tableName={t("campaignTable")}
            />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignListBrand;