import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import RangeSlider from "../utils/rangeSlider"; // Make sure RangeSlider handles different types properly

const ApprovalPopup = ({
  questionText,
  approvalObject,
  cancelText,
  approveText,
  onApproveButtonClick,
  onCancelButtonClick,
  isDeleteApprove,
  onClose,
  isPositiveApprove,
}) => {
  const { t } = useTranslation("segmentation");
  const popupRef = useRef(null);
  const [primaryCombineOperator, setPrimaryCombineOperator] = useState(
    approvalObject?.rule?.combineOperator
  );

  const parseRules = (rule) => {
    const rows = [];

    const subRules = rule.subRules || rule.SubRules;
    console.log("RULE", rule);

    if (subRules) {
      subRules.forEach((subRule) => {
        const conditions = {};
        let dateFilterConditions = null;
        const combineOperator = subRule.combineOperator;
        subRule.expressions.forEach((expression) => {
          const { field, operator, value } = expression;

          if (field === "DATE_FILTER") {
            dateFilterConditions = dateFilterConditions || [];
            dateFilterConditions.push({ [operator]: value });
          } else {
            if (!conditions[field]) {
              conditions[field] = [];
            }
            conditions[field].push({ [operator]: value });
          }
        });

        rows.push({ conditions, dateFilterConditions, combineOperator });
      });
    } else {
      console.warn("No subRules found in the rule object:", rule);
    }

    return rows;
  };

  const rows = approvalObject.rule ? parseRules(approvalObject.rule) : [];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const getGteLteRanges = (conditions) => {
    const ranges = [];

    Object.keys(conditions).forEach((keyword) => {
      conditions[keyword].forEach((condition, index) => {
        const { GTE, LTE } = condition;

        if (GTE || LTE) {
          if (GTE) {
            ranges.push({
              keyword,
              value: Number(GTE),
              type: "GTE",
              index,
            });
          }
          if (LTE) {
            ranges.push({
              keyword,
              value: Number(LTE),
              type: "LTE",
              index,
            });
          }
        }
      });
    });

    return ranges;
  };

  const getInEqValues = (conditions, type) => {
    return Object.keys(conditions)
      .map((keyword) => {
        const values = conditions[keyword]
          .filter((condition) => Object.keys(condition)[0] === type)
          .map((condition) => condition[type]);

        if (values.length > 0) {
          return { keyword, values: values.flat() };
        }
        return null;
      })
      .filter(Boolean);
  };

  return (
    <div className="approval-popup-container" ref={popupRef}>
      <div className="approval-popup-header">
        {isDeleteApprove ? questionText : t("rules")}
      </div>
      {approvalObject?.rule?.limit && (
        <>
          <div className="popup-page-approved-title">
            TOP: {approvalObject?.rule.limit}
          </div>
          <hr />
        </>
      )}

      <div className="approval-popup-content">
        <div className="approval-popup-rules">
          {rows.map((row, index) => (
            <div key={index}>
              <div className="approval-popup-rule">
                {Object.keys(row.conditions).map((keyword, i) => {
                  const conditionValues = row.conditions[keyword];

                  // Generate individual GTE and LTE ranges
                  const ranges = getGteLteRanges(row.conditions).filter(
                    (range) => range.keyword === keyword
                  );

                  const inValues = getInEqValues(row.conditions, "IN").find(
                    (value) => value.keyword === keyword
                  );

                  const eqValues = getInEqValues(row.conditions, "EQ").find(
                    (value) => value.keyword === keyword
                  );

                  return (
                    <React.Fragment key={i}>
                      {ranges.map((range, j) => (
                        <React.Fragment key={`${keyword}-${range.type}-${j}`}>
                          <RangeSlider
                            minValue={
                              range.type === "GTE" ? range.value : undefined
                            }
                            maxValue={
                              range.type === "LTE" ? range.value : undefined
                            }
                            singleValue={undefined}
                            arrayValue={undefined}
                            keyword={range.keyword}
                            t={t}
                            type={range.type}
                            conditions={row.conditions}
                            row={row}
                            index={i}
                            rows={rows}
                            dateFilterConditions={row.dateFilterConditions}
                          />
                          {/* Insert the operator container after each RangeSlider */}
                          {(j !== ranges.length - 1 ||
                            Object.keys(row.conditions).length !== i + 1) && (
                            <div className="subrule-combine-operator-container">
                              <div className="subrule-combine-operator-part" />
                              <div className="subrule-combine-operator-title">
                                {row.combineOperator}
                              </div>
                              <div className="subrule-combine-operator-part" />
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                      {eqValues && (
                        <React.Fragment>
                          <RangeSlider
                            minValue={undefined}
                            maxValue={undefined}
                            singleValue={eqValues.values}
                            arrayValue={undefined}
                            keyword={eqValues.keyword}
                            t={t}
                            type="EQ"
                            conditions={row.conditions}
                            row={row}
                            index={i}
                            rows={rows}
                            dateFilterConditions={row.dateFilterConditions}
                          />
                          {/* Insert the operator container after eqValues if inValues exists */}
                          {eqValues &&
                            !inValues &&
                            Object.keys(row.conditions).length !== i + 1 && (
                              <div className="subrule-combine-operator-container">
                                <div className="subrule-combine-operator-part" />
                                <div className="subrule-combine-operator-title">
                                  {row.combineOperator}
                                </div>
                                <div className="subrule-combine-operator-part" />
                              </div>
                            )}
                        </React.Fragment>
                      )}
                      {inValues && (
                        <React.Fragment>
                          <RangeSlider
                            minValue={undefined}
                            maxValue={undefined}
                            singleValue={undefined}
                            arrayValue={inValues.values}
                            keyword={inValues.keyword}
                            t={t}
                            type="IN"
                            conditions={row.conditions}
                            row={row}
                            index={i}
                            rows={rows}
                            dateFilterConditions={row.dateFilterConditions}
                          />
                          {/* Insert the operator container after inValues */}
                          {!eqValues &&
                            inValues &&
                            Object.keys(row.conditions).length !== i + 1 && (
                              <div className="subrule-combine-operator-container">
                                <div className="subrule-combine-operator-part" />
                                <div className="subrule-combine-operator-title">
                                  {row.combineOperator}
                                </div>
                                <div className="subrule-combine-operator-part" />
                              </div>
                            )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })}
                {/* Insert the operator container at the end of all RangeSliders */}
              </div>
              {rows.length - 1 !== index && (
                <div className="combine-operator-divider">
                  {primaryCombineOperator}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="approval-popup-buttons">
        {isDeleteApprove ? (
          <>
            <button
              className="approval-popup-button cancel"
              onClick={onCancelButtonClick}
            >
              {cancelText}
            </button>
            <button
              className="approval-popup-button delete"
              onClick={() => onApproveButtonClick(approvalObject.id)}
            >
              {approveText}
            </button>
          </>
        ) : isPositiveApprove ? (
          <>
            <button
              className="approval-popup-button cancel"
              onClick={onCancelButtonClick}
            >
              {cancelText}
            </button>
            <button
              className="approval-popup-button create"
              onClick={() => onApproveButtonClick(approvalObject.id)}
            >
              {approveText}
            </button>
          </>
        ) : (
          <button
            className="approval-popup-button cancel"
            onClick={onCancelButtonClick}
          >
            {t("close")}
          </button>
        )}
      </div>
    </div>
  );
};

export default ApprovalPopup;
