import React, { useState } from "react";
import { imagesUrl } from "../../../../../../config";
import "../../../../../../assets/css/InteractionType.css"; // Make sure to update the CSS file
import { useTranslation } from "react-i18next";

const ContentType = ({ apiData, onPublishChallenge, onEditChallenge }) => {
  const [isChallengePublished, setIsChallengePublished] = useState(false);
  const { t, i18n } = useTranslation("challengeCreation");

  const formatDateString = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false, // 24-hour format
    };

    const inputDate = new Date(dateString);

    // Add 3 hours to the date
    const updatedDate = new Date(inputDate.getTime() + 3 * 60 * 60 * 1000);

    // Format the updated date and return the result
    return updatedDate.toLocaleDateString("tr-TR", options);
  };

  console.log("APIDATA : ", apiData);
  const publishChallenge = () => {
    const challengeId = apiData.responseData.id;
    onPublishChallenge(challengeId);
    setIsChallengePublished(true);
  };

  const editChallenge = () => {
    onEditChallenge();
  };
  return (
    <div className="interaction-type-preview-container">
      <div className="interaction-type-preview-logo-container">
        <div className="interaction-type-preview-brand-container">
          <img
            className="interaction-type-preview-brand-logo"
            src={imagesUrl + "/" + apiData.responseData.brand.logo}
            alt="image"
          />
          <div className="interaction-type-preview-challenge-Id">
            {t("challengeID")} : {apiData.responseData.id}
          </div>
        </div>
        <div className="interaction-type-preview-challenge-logo-container">
          <img
            className="interaction-type-preview-challenge-logo"
            src={imagesUrl + "/" + apiData.responseData.logo}
            alt="image"
          />
        </div>
      </div>

      <div className="interaction-type-preview-challenge-data-container">
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("challengeName")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.name}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("challengeDescription")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.description}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("challengeType")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {t("contentCreation")}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("socialMediaChannel")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.socialProvider}
          </div>
        </div>
        <hr />
        {apiData.responseData.segment && (
          <>
            <div className="interaction-type-preview-challenge-data-wrapper">
              <div className="interaction-type-preview-challenge-data-title">
                {t("segment")}:
              </div>
              <div className="interaction-type-preview-challenge-data">
                {apiData.responseData.segment}
              </div>
            </div>
            <hr />
          </>
        )}

        <div className="content-creation-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("contentTasks")}:
          </div>
          <div className="content-creation-type-preview-challenge-data-list">
            {apiData.responseData.tagAccount && (
              <>
                <div className="content-creation-type-preview-challenge-data-title">
                  Tag:
                </div>
                <div className="content-creation-type-preview-challenge-data">
                  {apiData.responseData.tagAccount}
                </div>
                {apiData.responseData.infoTagAccount && (
                  <div className="content-creation-type-preview-challenge-data-column">
                    <div className="content-creation-type-preview-challenge-data-title">
                      Tag {t("description")}:
                    </div>
                    <div className="content-creation-type-preview-challenge-data">
                      {apiData.responseData.infoTagAccount}
                    </div>
                  </div>
                )}
              </>
            )}
            {apiData.responseData.hashtag && (
              <>
                <div className="content-creation-type-preview-challenge-data-title">
                  Hashtag:
                </div>
                <div className="content-creation-type-preview-challenge-data">
                  {apiData.responseData.hashtag}
                </div>
                {apiData.responseData.infoHashtag && (
                  <>
                    <div className="content-creation-type-preview-challenge-data-title">
                      Hashtag {t("description")}:
                    </div>
                    <div className="content-creation-type-preview-challenge-data">
                      {apiData.responseData.infoHashtag}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("startDate")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {formatDateString(apiData.responseData.startDate)}
          </div>
        </div>
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("endDate")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {formatDateString(apiData.responseData.endDate)}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-data-wrapper">
          <div className="interaction-type-preview-challenge-data-title">
            {t("sotyCoin")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.prize +
              " " +
              t("sotyCoin") +
              " " +
              t("equalsTo") +
              " " +
              apiData.responseData.prize / 10 +
              " TL"}
          </div>
        </div>
        <hr />

        <div className="interaction-type-preview-challenge-release-button-wrapper">
          <button
            className="interaction-type-preview-challenge-release-button"
            onClick={() => {
              publishChallenge();
            }}
          >
            {t("publish")}
          </button>
        </div>
        <div className="interaction-type-preview-challenge-release-button-wrapper">
          <button
            className="interaction-type-preview-challenge-edit-button"
            onClick={() => {
              editChallenge();
            }}
          >
            {t("edit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentType;
