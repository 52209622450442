import React from "react";
import { useTranslation } from "react-i18next";
import tick from "../../../../../assets/media/tickInteraction.svg"; // İkonun doğru yolunu kontrol edin

const CampaignGenericOption = ({ options, onClick, selectedOption }) => {
  const { t } = useTranslation("challengeCreation");
  return (
    <div className="coin-folding-container">
      <div className="coin-folding-input-container campaign-option-select-container">
        <div className="coin-folding-input-wrapper">
          {options.map((option) => (
            <div
              key={option}
              className={`percentage-coupon-input ${
                selectedOption === option && "percentage-coupon-input-active"
              }`}
              onClick={() => onClick(option)}
            >
              {t(option)}
              {selectedOption === option && <img src={tick} alt="tick" />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignGenericOption;
