import React, { useEffect, useState } from "react";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import InputContainer from "../../../../utils/inputContainer";
import CustomSelect from "../../../../collapsible/customSelect";
import "../../../../../assets/css/inbox.css";
import { useNavigate, useLocation } from "react-router-dom";
import SegmentList from "../../../../utils/segmentList";
import SegmentPopup from "../../../../popup/segmentPopup";
import SingleTextInput from "../../../../utils/singleTextInput";
import DynamicInputList from "../../../../utils/dynamicInputList";
import DateRangeInput from "../../../../utils/dateRangeInput";
import SingleNumberInput from "../../../../utils/singleNumberInput";
import PhoneViewNotification from "../../../../phone/phoneViewNotification";
import Pagination from "../../../../tables/Pagination";
import ItemsPerPageButton from "../../../../button/itemsPerPageButton";
import { toast } from "react-toastify";
import CallAPI from "../../../../api/callApi";
import { apiUrl } from "../../../../../config";
import SingleTextTextarea from "../../../../utils/singleTextTextarea";
import Loader from "../../../../popup/loader";

const PushNotificationCreation = () => {
  const navigate = useNavigate();
  const location = useLocation(); // location hook'unu ekledik

  const { t } = useTranslation("inbox");
  const [loaderState, setLoaderState] = useState(false);
  const [defaultSelectText, setDefaultSelectText] = useState(t("select"));
  const [selectedTarget, setSelectedTarget] = useState("");
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [popup, setPopup] = useState(false);
  const [popupSegment, setPopupSegment] = useState(null);
  const [url, setUrl] = useState("https://"); // State for URLs from DynamicInputList
  const [urlValid, setUrlValid] = useState(true);
  const [urlCorrect, setUrlCorrect] = useState(false);
  const [images, setImages] = useState([]); // State for images from DynamicInputList
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sotyCoin, setSotyCoin] = useState();
  const locationArray = [
    [t("inbox"), "/brand/inbox"],
    [t("pushNotification"), "/brand/inbox/push"],
    [t("createPushNotification"), "/brand/inbox/push/create"],
  ];
  const [usernames, setUsernames] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const notification = location.state?.notification;
  useEffect(() => {
    // Check if there is a state object in the location
    if (location.state && location.state.notification) {
      const notification = location.state.notification;
      console.log(notification); // Log the notification object to the console
      if (notification.url) {
        setUrl(notification.url);
        UrlCheck(notification.url);
      }
      setHeader(notification.heading);
      setDescription(notification.contentText);
      if (notification.segmentList.length == 0) {
        setSelectedTarget(t("sotier"));
        setUsernames(notification.userList);
      } else {
        setSelectedTarget(t("segment"));
        setSelectedSegment(notification.segmentList);
      }
    }
  }, [location.state]);

  const handleSegmentPopup = (segment) => {
    setPopupSegment(segment);
    setPopup(true);
  };

  const targetOptions = [t("sotier"), t("segments")];

  // Handler to update URLs state

  // Handler to update Images state
  const handleImagesChange = (newImages) => {
    setImages(newImages);
  };

  useEffect(() => {
    console.log(selectedSegment);
  }, [selectedSegment]);

  const handleAddUsername = () => {
    // Split input by commas and trim whitespace from each part
    const newEntries = inputValue.split(",").map((entry) => entry.trim());

    // Add each valid username to the list
    newEntries.forEach((username) => {
      if (username && !usernames.includes(username)) {
        setUsernames((prevUsernames) => [...prevUsernames, username]);
      }
    });

    setInputValue("");
  };

  const handleDeleteUsername = (index) => {
    setUsernames(usernames.filter((_, i) => i !== index));
  };

  const handleItemsPerPageChange = (value) => {
    setPerPage(value);
    setPage(0); // Reset to first page when items per page changes
  };

  const paginatedUsernames =
    usernames && usernames.slice(page * perPage, (page + 1) * perPage);

  const publishNotifications = async (drafted) => {
    let urlToSend;
    if (url !== "https://" || url !== "") {
      if (!urlValid) {
        toast.error(t("pleaseEnterAValidUrl"));
        return;
      }
      if (url === "https://" || url === "") {
        urlToSend = undefined;
      } else {
        urlToSend = url;
      }
    }
    if (selectedTarget === "") {
      toast.error(t("pleaseSelectATarget"));
      return;
    }
    if (selectedTarget === t("sotier")) {
      console.log(usernames);
      if (!usernames || usernames.length === 0) {
        toast.error(t("pleaseEnterAtLeastOneSotier"));
        return;
      }

      setSelectedSegment([]);
    }
    if (selectedTarget === t("segment")) {
      console.log(selectedSegment);
      if (
        selectedSegment == null ||
        selectedSegment === undefined ||
        selectedSegment === "" ||
        selectedSegment.length === 0
      ) {
        toast.error(t("pleaseSelectAtLeastOneSegment"));

        return;
      }
      setUsernames([]);
    }
    if (header === "" || !header) {
      toast.error(t("pleaseEnterAHeaderForNotification"));
      return;
    }
    if (description === "" || !description) {
      toast.error(t("pleaseEnterADescriptionForNotification"));
      return;
    }

    const body = {
      userNames: selectedTarget === t("sotier") ? usernames : undefined,
      segments: selectedTarget === t("segment") ? selectedSegment : undefined,
      heading: header,
      contend: description,
      saveAsDraft: drafted,
      url: urlToSend,
    };
    setLoaderState(true);
    const response = await CallAPI({
      method: "POST",
      endPoint: apiUrl + "/api/v1/brand-admin/brand/notification",
      t: t,
      body: body,
      successMessage: t("notificationCreatedSuccessFully"),
      errorMessage: t("anErrorAcurredWhenCreatingNotification"),
    });
    setLoaderState(false);
    if (response.status === 200) {
      navigate("/brand/inbox/push");
    }
    console.log(response);
    console.log(body);
  };

  const UrlCheck = (value) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const isValid = urlRegex.test(value);
    if (value === "" || value === null) {
      setUrlCorrect(false);
      setUrlValid(true);
    } else if (isValid) {
      setUrlValid(true);
      setUrlCorrect(true);
    } else {
      setUrlValid(false);
      setUrlCorrect(false);
    }
  };
  const isValidUUID = (username) => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(username);
  };

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} />
      {popup && (
        <SegmentPopup
          segment={popupSegment}
          onClose={() => {
            setPopup(false);
          }}
        />
      )}
      <div className="dashboard-row">
        <div className="challenge-creation-content-container">
          <InputContainer
            title={t("target")}
            isMandatory={true}
            titleDescription={t("pushTargetDesc")}
            children={
              <div className="survey-type-checkbox-title-wrapper push-notification-select-option">
                <CustomSelect
                  id="multiple-choice-select"
                  defaultText={
                    !notification
                      ? t("select")
                      : notification.segmentList.length == 0
                      ? t("sotier")
                      : t("segment")
                  }
                  optionsList={targetOptions}
                  onChange={(e) => {
                    if (e === 0) setSelectedTarget(t("sotier"));
                    else if (e === 1) setSelectedTarget(t("segment"));
                  }}
                />
              </div>
            }
          />

          {selectedTarget === t("sotier") && (
            <InputContainer
              title={t("sotier")}
              isMandatory={true}
              titleDescription={t("pushSotierDesc")}
              children={
                <>
                  <div className="push-notification-select-option push-notification-user-list-container">
                    <div className="push-notification-user-list-input-container">
                      <input
                        className="push-notification-user-list-input"
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(e) =>
                          e.key === "Enter" && handleAddUsername()
                        }
                        placeholder={t("enterUsername")}
                      />
                      <div
                        className="discount-to-x-product-button-plus  push-notification-user-list-input-add-button"
                        onClick={() => {
                          handleAddUsername();
                        }}
                      >
                        +
                      </div>
                    </div>

                    <div
                      className={`push-notification-username-list ${
                        usernames.length < 1 && "hidden"
                      }`}
                    >
                      <div className="push-notification-username-list-top-container">
                        <label className="push-notification-total-user">
                          {t("totalUsers")} : {usernames.length}
                        </label>
                        <div className="push-notification-delete-all">
                          {t("deleteAll")}
                          <div
                            className="push-notification-delete-all-button"
                            onClick={() => {
                              setUsernames([]);
                            }}
                          >
                            -
                          </div>
                        </div>
                      </div>

                      {paginatedUsernames.map((username, index) => (
                        <div
                          key={index + page * perPage}
                          className="push-notification-username-row"
                        >
                          <span className="push-notification-username-counter">
                            {index + 1 + page * perPage}
                          </span>
                          <span
                            className={`push-notification-username ${isValidUUID(username) ? "clickable-username" : ""}`}
                            onClick={() => {
                              if (isValidUUID(username)) {
                                navigate(
                                  `/brand/srm/sotier/profile/${username}`
                                );
                              }
                            }}
                          >
                            {username}
                          </span>
                          <div
                            className="discount-to-x-product-button-minus push-notification-user-list-input-delete-button"
                            onClick={() =>
                              handleDeleteUsername(index + page * perPage)
                            }
                          >
                            -
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className={`segment-list-footer ${
                      usernames.length < 1 && "hidden"
                    }`}
                  >
                    {usernames.length > perPage ? (
                      <Pagination
                        currentPage={page + 1 || null}
                        totalPages={Math.ceil(usernames.length / perPage)}
                        onPageChange={(newPage) => setPage(newPage - 1)}
                      />
                    ) : (
                      <div className="visibility-hidden">
                        <Pagination
                          currentPage={page + 1 || null}
                          totalPages={Math.ceil(usernames.length / perPage)}
                          onPageChange={(newPage) => setPage(newPage - 1)}
                        />
                      </div>
                    )}
                    <ItemsPerPageButton
                      onSelectItem={handleItemsPerPageChange}
                      title={t("sotierPerPage")}
                    />
                  </div>
                </>
              }
            />
          )}
          {selectedTarget === t("segment") && (
            <InputContainer
              title={t("segment")}
              isMandatory={true}
              titleDescription={t("selectSotier")}
              children={
                <SegmentList
                  t={t}
                  handleSegmentPopup={handleSegmentPopup}
                  selectedSegment={selectedSegment}
                  setSelectedSegment={setSelectedSegment}
                  navigate={navigate}
                />
              }
            />
          )}
          <SingleTextInput
            title={t("header")}
            isMandatory={true}
            titleDescription={t("pushHeaderDesc")}
            inputPlaceholder={t("headerPlaceholder")}
            onChange={(e) => {
              setHeader(e.target.value);
            }}
            onBlur={(e) => {
              setHeader(e.target.value);
            }}
            maxLength={64}
            minLength={2}
            value={header}
            infoText={[
              t("minLength") + " 2 " + t("character"),
              t("maxLength") + " 64 " + t("character"),
            ]}
          />
          <SingleTextTextarea
            title={t("description")}
            isMandatory={true}
            titleDescription={t("pushDescriptionDesc")}
            inputPlaceholder={t("descriptionPlaceholder")}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            onBlur={(e) => {
              setDescription(e.target.value);
            }}
            maxLength={512}
            minLength={2}
            value={description}
            infoText={[
              t("minLength") + " 2 " + t("character"),
              t("maxLength") + " 512 " + t("character"),
            ]}
          />
          <SingleTextInput
            title={t("URL")}
            isMandatory={false}
            titleDescription={t("pushUrlDesc")}
            inputPlaceholder={"https://"}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            onBlur={(e) => {
              UrlCheck(e.target.value);
            }}
            maxLength={256}
            minLength={2}
            value={url}
            infoText={[
              t("minLength") + " 2 " + t("character"),
              t("maxLength") + " 256 " + t("character"),
            ]}
            isValid={urlValid}
            invalidMessage={t("urlIsInvalid")}
            isCorrect={urlCorrect}
          />

          <div className="display-none">
            <DynamicInputList
              title={t("notificationImage")}
              titleDescription={t("pushImageDesc")}
              t={t}
              inputType="file"
              maxInputs={3}
              onImagesChange={handleImagesChange} // Pass handler to capture image uploads
            />

            <SingleNumberInput
              title={t("sotyCoin")}
              titleDescription={t("pushSotyCoinDesc")}
              value={sotyCoin}
              onChange={(e) => {
                setSotyCoin(e.target.value);
              }}
              onBlur={(e) => {
                setSotyCoin(e.target.value);
              }}
            />
            <DateRangeInput
              dateRangeTitle={t("date")}
              dateRangeTitleDescription={t("pushDateDesc")}
              startDateTitle={t("startDate")}
              endDateTitle={t("endDate")}
              onStartDateBlur={(e) => {
                setStartDate(e.target.value);
              }}
              onStartDateChange={(e) => {
                setStartDate(e.target.value);
              }}
              onEndDateBlur={(e) => {
                setEndDate(e.target.value);
              }}
              onEndDateChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
          {!(notification && notification.status === "SENT") && (
            <div className="notification-creation-button-container">
              <div
                className="notification-creation-button notification-creation-save-as-draft-button"
                onClick={() => {
                  publishNotifications(true);
                }}
              >
                {t("saveAsDraft")}
              </div>
              <div
                className="notification-creation-button notification-creation-send-button"
                onClick={() => {
                  publishNotifications(false);
                }}
              >
                {t("send")}
              </div>
            </div>
          )}
        </div>

        <div className="challenge-creation-phone-container notification-phone-margin-adjust display-none">
          <PhoneViewNotification />
        </div>
      </div>
    </div>
  );
};

export default PushNotificationCreation;
