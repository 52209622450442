import React from "react";
import chart from "../../assets/media/Icon metro-chart-dots.svg";
import '../../assets/css/ViewInSotyAppButton.css';
import { useTranslation } from "react-i18next";

const ChallengeAnalysisButton = ({ onClick, campaign }) => {
    const { t, i18n } = useTranslation("challengeCreation");
    return (
        campaign ?

            <button className="static-button-container challenge-analysis-button campaign-analysis-button" onClick={onClick}>
                <img src={chart} alt="icn" />
                {t("campaignAnalysis")}
            </button>
            :
            <button className="static-button-container challenge-analysis-button" onClick={onClick}>
                <img src={chart} alt="icn" />
                {t("challengeAnalysis")}
            </button>

    );
}
export default ChallengeAnalysisButton;