import React from "react";
import plus from '../../assets/media/plus.svg';
const CustomButton = ({ handleClick, title }) => {
  return (
    <div
      className="static-button-container create-segment-button"
      onClick={() => 
        handleClick()
      }
    >
      <img className="create-challenge-button-img" src={plus} alt="+" />
      {title && title}
    </div>
  );
};

export default CustomButton;
