const FormatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  // Parse the date string and create a new Date object
  let date = new Date(dateString);
  if (date.getFullYear() < 2020) {
    return "N/A";
  }

  // Add 3 hours to the date
  date.setHours(date.getHours() + 3);

  // Get the language from localStorage, default to 'en-US' if not set
  const language = localStorage.getItem("language") || "en-US";

  // Format the date according to the retrieved language
  return date.toLocaleDateString(language, options);
};

export default FormatDate;
