import React, { useEffect, useState } from "react";
import checkIcon from '../../../assets/media/Checklist.svg';
import infoIcon from '../../../assets/media/infoIconEmpty.svg';
import timeIcon from '../../../assets/media/time.svg';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const ContentCardBottomApproved = ({ offerCoin, offerAcceptDate }) => {
    const { t, i18n } = useTranslation("contentList");
  

    return (
        <div className="content-card-bottom-approved-container">
            <div className="content-card-bottom-approved-row-container">
                <div className="content-card-bottom-approved-row">
                    <div className="content-card-bottom-approved-row-left">
                        <div className="content-card-bottom-approved-row-left-indicator" />
                        <label>{t("approved")}</label>
                    </div>
                    <div className="content-card-bottom-approved-row-right-img-container">
                        <img src={checkIcon} alt="tick" />
                    </div>
                </div>

                <div className="content-card-bottom-approved-row">
                    <div className="content-card-bottom-approved-row-left">
                        <div className="content-card-bottom-approved-row-left-indicator" />
                        <label>{t("approveDate")}:</label>
                        <span>{offerAcceptDate}</span>
                    </div>
                </div>

                <div className="content-card-bottom-approved-row">
                    <div className="content-card-bottom-approved-row-left">
                        <div className="content-card-bottom-approved-row-left-indicator" />
                        <label>{t("offerCoin")}:</label>
                    </div>
                    <div className="content-card-bottom-approved-row-right">
                        {offerCoin}
                    </div>
                </div>

            </div>

            <div className="content-card-bottom-approved-container-info-container">
                <div className="content-card-bottom-approved-container-info-row">
                    <img src={infoIcon} alt="info" />
                    <label>{t("contentApproved")}</label>
                </div>
            </div>
        </div>
    );
};

export default ContentCardBottomApproved;
