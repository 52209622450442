import React from "react";
import { useTranslation } from "react-i18next"; // Assuming you have i18next setup
import undraw_authentication_re_svpt from "../../../assets/media/main/undraw_authentication_re_svpt.svg";
import undraw_product_explainer_8jbm from "../../../assets/media/main/undraw_product_explainer_8jbm.svg";
import undraw_blooming_re_2kc4 from "../../../assets/media/main/undraw_blooming_re_2kc4.svg";
import undraw_digital_nomad_re_w8uy from "../../../assets/media/main/undraw_digital_nomad_re_w8uy.svg";
import undraw_segmentation_re_gduq from "../../../assets/media/main/undraw_segmentation_re_gduq.svg";
import undraw_engineering_team_a7n2 from "../../../assets/media/main/undraw_engineering_team_a7n2.svg";
import undraw_social_dashboard_re_ocbd from "../../../assets/media/main/undraw_social_dashboard_re_ocbd.svg";
import undraw_gifts_0ceh from "../../../assets/media/main/undraw_gifts_0ceh.svg";
import undraw_social_strategy_re_mk87 from "../../../assets/media/main/undraw_social_strategy_re_mk87.svg";
import undraw_halloween_re_2kq1 from "../../../assets/media/main/undraw_halloween_re_2kq1.svg";
import undraw_statistic_chart_re_w0pk from "../../../assets/media/main/undraw_statistic_chart_re_w0pk.svg";
import undraw_mobile_app_re_catg from "../../../assets/media/main/undraw_mobile_app_re_catg.svg";
import undraw_successful_purchase_re_mpig from "../../../assets/media/main/undraw_successful_purchase_re_mpig.svg";
import undraw_mobile_content_xvgr from "../../../assets/media/main/undraw_mobile_content_xvgr.svg";
import undraw_super_thank_you_re_f8bo from "../../../assets/media/main/undraw_super_thank_you_re_f8bo.svg";
import undraw_notifications_12nk from "../../../assets/media/main/undraw_notifications_12nk.svg";
import undraw_transfer_money_re_6o1h from "../../../assets/media/main/undraw_transfer_money_re_6o1h.svg";
import undraw_popular_re_mlfe from "../../../assets/media/main/undraw_popular_re_mlfe.svg";
import undraw_working_late_re_0c3y from "../../../assets/media/main/undraw_working_late_re_0c3y.svg";
import undraw_post_re_mtr4 from "../../../assets/media/main/undraw_post_re_mtr4.svg";
import undraw_working_re_ddwy from "../../../assets/media/main/undraw_working_re_ddwy.svg";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utils/userRoles";
const MainPage = () => {
  const { t } = useTranslation("landing");
  const navigate = useNavigate();
  const today = new Date();
  const hour = today.getHours();
  console.log(userRoles());
  // Image, title, and subtitle objects compatible with the JSON structure
  // Mapping roles to URLs
  const roleUrlMapping = {
    LIST_BAN: "/brand/reports",
    LIST_BRAND_CHALLENGES: "/brand/challenges/list",
    LIST_BRAND_ROLE: "/brand/settings/roles",
    LIST_BRAND_USERS: "/brand/settings/users",
    LIST_CAMPAIGNS: "/brand/srm/campaign/list",
    LIST_CHALLENGES: "/brand/challenges/list",
    LIST_NOTIFICATION: "/brand/inbox/push",
    LIST_POSTS: "/brand/srm/content/library",
    LIST_SEGMENT: "/brand/srm/segment",
    VIEW_BRAND: "/brand/settings/profile",
  };

  const images = [
    {
      svg: undraw_authentication_re_svpt,
      title: t("authentication_title"),
      subtitle: t("authentication_subtitle"),
    },
    {
      svg: undraw_product_explainer_8jbm,
      title: t("product_explainer_title"),
      subtitle: t("product_explainer_subtitle"),
    },
    {
      svg: undraw_blooming_re_2kc4,
      title: t("blooming_title"),
      subtitle: t("blooming_subtitle"),
    },
    {
      svg: undraw_digital_nomad_re_w8uy,
      title: t("digital_nomad_title"),
      subtitle: t("digital_nomad_subtitle"),
    },
    {
      svg: undraw_segmentation_re_gduq,
      title: t("segmentation_title"),
      subtitle: t("segmentation_subtitle"),
    },
    {
      svg: undraw_engineering_team_a7n2,
      title: t("engineering_team_title"),
      subtitle: t("engineering_team_subtitle"),
    },
    {
      svg: undraw_social_dashboard_re_ocbd,
      title: t("social_dashboard_title"),
      subtitle: t("social_dashboard_subtitle"),
    },
    {
      svg: undraw_gifts_0ceh,
      title: t("gifts_title"),
      subtitle: t("gifts_subtitle"),
    },
    {
      svg: undraw_social_strategy_re_mk87,
      title: t("social_strategy_title"),
      subtitle: t("social_strategy_subtitle"),
    },
    {
      svg: undraw_statistic_chart_re_w0pk,
      title: t("statistics_title"),
      subtitle: t("statistics_subtitle"),
    },
    {
      svg: undraw_mobile_app_re_catg,
      title: t("mobile_app_title"),
      subtitle: t("mobile_app_subtitle"),
    },
    {
      svg: undraw_successful_purchase_re_mpig,
      title: t("successful_purchase_title"),
      subtitle: t("successful_purchase_subtitle"),
    },
    {
      svg: undraw_super_thank_you_re_f8bo,
      title: t("thank_you_title"),
      subtitle: t("thank_you_subtitle"),
    },
    {
      svg: undraw_notifications_12nk,
      title: t("notifications_title"),
      subtitle: t("notifications_subtitle"),
    },
    {
      svg: undraw_popular_re_mlfe,
      title: t("popular_title"),
      subtitle: t("popular_subtitle"),
    },
    {
      svg: undraw_working_late_re_0c3y,
      title: t("working_late_title"),
      subtitle: t("working_late_subtitle"),
    },
    {
      svg: undraw_post_re_mtr4,
      title: t("post_title"),
      subtitle: t("post_subtitle"),
    },
    {
      svg: undraw_working_re_ddwy,
      title: t("working_title"),
      subtitle: t("working_subtitle"),
    },
  ];

  // Function to get the appropriate image, title, and subtitle based on conditions
  const getRandomImage = () => {
    // If today is Halloween, show the Halloween image
    if (today.getMonth() === 9 && today.getDate() === 31) {
      return {
        svg: undraw_halloween_re_2kq1,
        title: t("halloween_title"),
        subtitle: t("halloween_subtitle"),
      };
    }

    // If it's before 10 AM
    if (hour < 10) {
      return {
        svg: undraw_working_re_ddwy,
        title: t("good_morning_title"),
        subtitle: t("good_morning_subtitle"),
      };
    }

    // If it's after 7 PM
    if (hour >= 19) {
      return {
        svg: undraw_working_late_re_0c3y,
        title: t("working_title"),
        subtitle: t("working_subtitle"),
      };
    }

    // Otherwise, return a random object
    return images[Math.floor(Math.random() * images.length)];
  };

  const targetRoles = [
    "LIST_BAN",
    "LIST_BRAND_CHALLENGES",
    "LIST_BRAND_ROLE",
    "LIST_BRAND_USERS",
    "LIST_CAMPAIGNS",
    "LIST_CHALLENGES",
    "LIST_NOTIFICATION",
    "LIST_POSTS",
    "LIST_SEGMENT",
    "VIEW_BRAND",
  ];

  const matchingRoles = userRoles().filter((role) =>
    targetRoles.includes(role)
  );
  const roleToDisplay =
    matchingRoles.length > 0
      ? matchingRoles[Math.floor(Math.random() * matchingRoles.length)]
      : null;

  const { svg, title, subtitle } = getRandomImage();
  const handleRedirect = () => {
    if (roleToDisplay && roleUrlMapping[roleToDisplay]) {
      navigate(roleUrlMapping[roleToDisplay]);
    }
  };

  return (
    <div className="dashboard-container main-container">
      <main className="main-content">
        <div className="image-container">
          <img className="main-image" src={svg} alt="Illustration" />
        </div>
        <div className="text-container">
          <h2 className="main-title">{title}</h2>
          <p className="main-subtitle">{subtitle}</p>
          <button className="rounded-button" onClick={() => handleRedirect()}>
            {t(roleToDisplay)}
          </button>
        </div>
      </main>
    </div>
  );
};

export default MainPage;
