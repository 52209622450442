import React from "react";
import arrow from "../../../assets/media/arrow.png";
import downArrow from "../../../assets/media/down-arrow.png";

const NavigationBar = ({ locationArray, dividerDisabled=false }) => {
  return (
    <React.Fragment>
      <div className="content-location-container">
        <a className="content-location-container-href">
          <img src={arrow} alt="arrow" />
        </a>
        {locationArray && locationArray.map((item, index) => (
          <React.Fragment key={index}>
            <a className="content-location-container-link-text" href={item[1]}>
              <div className="older">{item[0]}</div>
            </a>
            {index < locationArray.length - 1 && (
              <React.Fragment>
                <img
                  src={downArrow}
                  className="backArrow"
                  alt="down-arrow"
                />
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </div>
      {!dividerDisabled && <hr />}
    </React.Fragment>
  );
};

export default NavigationBar;
