// DynamicTableWithInnerElements.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../assets/css/DynamicTable.css";
import downArrow from '../../assets/media/down-arrow.png';
import FilterButton from '../button/filterButton';
import CreateChallengeButton from "../button/createChallengeButton";
import Pagination from "../tables/Pagination";
import ItemsPerPageButton from "../button/itemsPerPageButton";

const DynamicTableWithInnerElements = ({ columns, data, tableName, segment, isRowClickable, onRowClick, corporateCampaign, segmentPage, segmentTotalPage, setSegmentPage, handleInputChange, segmentPerPageTitle, setSegmentPerPage }) => {
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });


    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };


    const parseDate = (dateString) => {
        if (!dateString) {
            return 0; // or any other default value you prefer
        }

        if (dateString.props && dateString.props.children) {
            const children = dateString.props.children;
            let date = '';
            let time = '';

            for (const child of children) {
                if (typeof child === 'string' && child.trim() !== '') {
                    if (child.includes('.')) {
                        date = child.trim().split('.').reverse().join('-');
                    } else if (child.includes(':')) {
                        time = child.trim();
                    }
                }
            }

            const dateTimeString = date + (time ? ' ' + time : '');

            return Date.parse(dateTimeString) || 0;
        } else if (typeof dateString === 'string') {
            const dateParts = dateString.split(' ');
            const date = dateParts[0].split('.').map(Number);
            const time = dateParts[1] ? dateParts[1].split(':').map(Number) : [0, 0, 0];
            return new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]).getTime();
        } else {
            return 0;
        }
    };



    const parseCorporateDate = (dateString) => {
        if (!dateString) {
            return 0; // veya tercih ettiğiniz herhangi bir varsayılan değer
        }

        const [datePart, timePart] = dateString.split(', ');
        const [day, month, year] = datePart.split('.').map(Number);
        const [hour, minute, second] = timePart.split(':').map(Number);

        return new Date(year, month - 1, day, hour, minute, second).getTime();
    };





    const sortedData = [...data].sort((a, b) => {
        if (sortConfig.key === "creationDate" || sortConfig.key === "startDate" || sortConfig.key === "endDate") {
            let dateA;
            let dateB;
            if (corporateCampaign) {
                dateA = parseCorporateDate(a[sortConfig.key]);
                dateB = parseCorporateDate(b[sortConfig.key]);
            }
            else {
                dateA = parseDate(a[sortConfig.key]);
                dateB = parseDate(b[sortConfig.key]);
            }

            return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
        } else if (sortConfig.key === "id") { // Eğer sıralama anahtarı "id" ise
            const valueA = parseInt(a[sortConfig.key]);
            const valueB = parseInt(b[sortConfig.key]);
            return sortConfig.direction === "asc" ? valueA - valueB : valueB - valueA;
        } else {
            const valueA = sortConfig.key === "Status" && a[sortConfig.key].props ? a[sortConfig.key].props.children : a[sortConfig.key];
            const valueB = sortConfig.key === "Status" && b[sortConfig.key].props ? b[sortConfig.key].props.children : b[sortConfig.key];

            if (typeof valueA === "string" && typeof valueB === "string") {
                return sortConfig.direction === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else {
                return sortConfig.direction === "asc" ? valueA - valueB : valueB - valueA;
            }
        }
    });






    return (
        <div className="dynamic-table-container">
            <div className="dynamic-table-inner-element-container">
                <Pagination
                    currentPage={segmentPage + 1 || null}
                    totalPages={segmentTotalPage}
                    onPageChange={(page) => { setSegmentPage(page - 1) }}
                />
                <ItemsPerPageButton onSelectItem={(e) => { handleInputChange(e, setSegmentPerPage) }} title={segmentPerPageTitle} />

                {segment && <CreateChallengeButton Segment={true} />}
            </div>
            <h2 className="dynamic-table-name">{tableName}</h2>
            <table className="dynamic-table">
                <thead>
                    <tr className="complaint-row">
                        {columns.map((column, index) => (
                            <th key={index} className={`dynamic-th ${column.className}`}>
                                <div className="dynamic-th-wrapper">
                                    {column.title}
                                    {!(column.field === "unscrollable") && (
                                        <div className="sort-buttons">
                                            <button onClick={() => handleSort(column.field)}>
                                                <img className="dyanmic-sort-asc" src={downArrow} alt="Sort ascending" />
                                            </button>
                                            <button onClick={() => handleSort(column.field)}>
                                                <img className="dyanmic-sort-desc" src={downArrow} alt="Sort descending" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((item, index) => (
                        <React.Fragment key={index}>
                            <tr
                                className={`dynamic-table-row ${typeof isRowClickable === "function" && isRowClickable(item) ? "clickable-row" : ""}`}
                                onClick={isRowClickable && onRowClick(item)}
                            >
                                {columns.map((column, index) => (
                                    <td key={index} className={`dynamic-td ${column.className}`}>
                                        {column.render ?
                                            column.render(item) :
                                            (typeof item[column.field] === 'string' && item[column.field].includes(" link ")) ?
                                                item[column.field].split(" link ")[1].startsWith("http") ?
                                                    <span>{item[column.field].split(" link ")[0]} <a href={"https://" + item[column.field].split(" link ")[1]}>{"(" + item[column.field].split(" link ")[1].substring(8) + ")"}</a></span> :
                                                    item[column.field] :
                                                item[column.field]}
                                    </td>
                                ))}


                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>

        </div >
    );
};

DynamicTableWithInnerElements.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    tableName: PropTypes.string.isRequired,
    isRowClickable: PropTypes.func,
    onRowClick: PropTypes.func,
};

export default DynamicTableWithInnerElements;
