import React, { useState, useRef, useEffect } from 'react';
import downArrow from "../../assets/media/down-arrow.png";
import axios from 'axios';
import { toast } from "react-toastify";
import leftArrow from "../../assets/media/Left.svg";
import notificationIcon from "../../assets/media/Notifications.png";
import MedalIcon from "../../assets/media/Medal.svg";
import InstagramIcon from "../../assets/media/instagramMobile.svg";
import FacebookIcon from "../../assets/media/facebook.svg";
import TwitterIcon from "../../assets/media/x-twitter.svg";
import YoutubeIcon from "../../assets/media/youtube.svg";
import TiktokIcon from "../../assets/media/tiktok.svg";
import InfoIcon from "../../assets/media/Info.svg";
import arrow from "../../assets/media/arrow.png";
import placeholderImg from "../../assets/media/noImageToShow.jpeg";
import "../../assets/css/ChallengePreview.css";
import "../../assets/css/PhoneView.css";
import SurveyType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/SurveyType.js';
import InteractionType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/InteractionType.js';
import tagIcon from "../../assets/media/tagIcon.svg";
import hashtagIcon from "../../assets/media/hashtagIcon.svg";
import ContentType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/ContentType.js';
import Approved from '../pages/brand_admin/popup_page/PopupPage.js';
import Slider from 'react-slick';
import { useLocation, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { imagesUrl } from '../../config.js';
import { getCookie } from '../pages/auth/tokenUtils.js';
import { isMyTokenExpired } from '../pages/auth/tokenUtils.js';
import { apiUrl } from "../../config.js";
import paperIcon from "../../assets/media/paper.svg";



const PhoneViewInteraction = ({ returnedApiData, imagesUrl, formatDateString }) => {
    const [hoveredRuleInfo, setHoveredRuleInfo] = useState(null);

    const [challengeTypeImg, setChallengeTypeImg] = useState();
    const [challengeTypeText, setChallengeTypeText] = useState();
    const [challengeTypeDescText, setChallengeTypeDescText] = useState();
    const [challengeTypeToView, setChallengeTypeToView] = useState();

    function formatSocialProvider(socialProvider) {
        // Büyük harfleri küçük harflere çevirme ve ilk harfi büyük yapma
        const formattedSocialProvider = socialProvider.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
        return formattedSocialProvider;
    }

    const challengeTypeFinder = () => {
        if (returnedApiData.responseData.socialProvider === "FACEBOOK") {
            setChallengeTypeImg(FacebookIcon);

        }
        else if (returnedApiData.responseData.socialProvider === "INSTAGRAM") {
            setChallengeTypeImg(InstagramIcon);
        }
        else if (returnedApiData.responseData.socialProvider === "TWITTER") {
            setChallengeTypeImg(TwitterIcon);
        }
        else if (returnedApiData.responseData.socialProvider === "TIKTOK") {
            setChallengeTypeImg(TiktokIcon);
        }
        else if (returnedApiData.responseData.socialProvider === "YOUTUBE") {
            setChallengeTypeImg(YoutubeIcon);
        }
        setChallengeTypeDescText("Marka " + formatSocialProvider(returnedApiData.responseData.socialProvider) + " Gönderisi Etkileşimleri")
        setChallengeTypeText("Etkileşim Yap")


    }



    useEffect(() => {
        challengeTypeFinder();
    }, [])


    return (<div className='phone-view-interaction-container'>
        <div className='phone-view-header'>
            <img src={leftArrow} alt='<' />

            {returnedApiData.responseData.name}

            <img src={notificationIcon} alt='notify' />
        </div>

        <div
            className='phone-view-interaction-content-container'
        >
            <div className='phone-view-interaction-content-container-header'
                style={{ backgroundImage: `url(${imagesUrl + '/' + returnedApiData.responseData.logo})` }}>
                <div className='phone-view-interaction-content-container-header-column-left'>
                    <div className='phone-view-interaction-content-container-header-title-container'>
                        <label>Etkileşim Alma</label>
                        <img src={MedalIcon} alt='medal' />
                    </div>
                    <div className='phone-view-interaction-content-container-header-brand-logo-wrapper'>
                        <img className='phone-view-interaction-content-container-header-brand-logo' src={imagesUrl + '/' + returnedApiData.responseData.brand.logo} />
                    </div>
                </div>
                <div className='phone-view-interaction-content-container-header-column-right'>
                    <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                        {returnedApiData.responseData.prize}<span>Soty Coin</span>
                    </div>

                </div>
            </div>
            <div className='phone-view-interaction-content-description'>
                {returnedApiData.responseData.description}
            </div>
            <div className='phone-view-interaction-definition-container'>
                <img src={challengeTypeImg} alt='icon' />
                <div className='phone-view-interaction-definition-wrapper'>
                    <div className='phone-view-interaction-definition-title'>
                        {challengeTypeDescText}
                    </div>
                    <div className='phone-view-interaction-definition-end-date'>
                        Bitis Tarihi:
                        <span>
                            {" " + formatDateString(returnedApiData.responseData.endDate)}
                        </span>
                    </div>
                </div>
            </div>





            <div className='phone-view-interaction-tasks-container'>
                <div className='phone-view-interaction-task-title'>
                    Görevler
                </div>
                <div className='phone-view-interaction-tasks-wrapper'>
                    <div className='phone-view-interaction-task-Description'>
                        URL'de yer alan gönderi için;
                    </div>
                    <div className='phone-view-interaction-task-list'>
                    {returnedApiData.responseData.interactionChallengeRules && (
                            returnedApiData.responseData.interactionChallengeRules.map((rule, index) => (
                                <div key={index} className='phone-view-interaction-task-row'>
                                    <div className='phone-view-interaction-task'>
                                        <span>{index + 1 + " "}</span>
                                        {rule.instruction}
                                    </div>
                                    <img
                                        className='phone-view-interaction-task-check'
                                        src={InfoIcon}
                                        alt='info'
                                        onMouseEnter={() => setHoveredRuleInfo({ index, info: rule.info })}
                                        onMouseLeave={() => setHoveredRuleInfo(null)}
                                    />

                                    {hoveredRuleInfo && hoveredRuleInfo.index === index && (
                                        <div className="phone-view-hovered-rule-info">{hoveredRuleInfo.info}</div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>




            <div className='phone-view-url-list-container'>
                <div className='phone-view-url-list-title'>
                    Linkler
                </div>

                <div className='phone-view-url-list-button'>
                    GÖNDERİYİ GÖR
                </div>
            </div>

            <div className='phone-view-approve-button'>Onayla</div>
            {/* Your content goes here */}
        </div>

    </div>)
}
export default PhoneViewInteraction;