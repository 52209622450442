import React from "react";
import cancelIcon from "../../assets/media/clear.png";

const ReportOperationsCard = ({
  Title,
  Description,
  onClick,
  buttonText,
  cardImg,
  positive,
  warning,
}) => {
  return (
    <div
      className={`${
        warning
          ? "report-operations-card-warning"
          : positive
          ? "report-operations-card-positive"
          : "report-operations-card"
      }`}
    >
      <div className="report-operations-card-header">
        <div
          className={`${
            warning
              ? "report-operations-card-top-warning"
              : positive
              ? "report-operations-card-top-positive"
              : "report-operations-card-top"
          }`}
        >
          <img src={cardImg || cancelIcon} alt="block" />
          {Title}
        </div>
        <div className="report-operations-card-body">{Description}</div>
      </div>
      <div className="report-operations-card-bottom">
        <div
          className={`${
            warning
              ? "report-operations-card-button-warning"
              : positive
              ? "report-operations-card-button-positive"
              : "report-operations-card-button"
          }`}
          onClick={onClick}
        >
          {buttonText}
        </div>
        <div className="report-operations-card-footer" />
      </div>
    </div>
  );
};

export default ReportOperationsCard;
