import React from "react";
import '../../assets/css/SortFilterSearch.css';
import downArrow from '../../assets/media/down-arrow.png'

import FilterIcon from '../../assets/media/filter.svg';
import '../../assets/css/SortFilterSearch.css'
import FilterDialog from "../dialogs/filterDialog";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const StaticDateRangeDialog = ({ options, onSelect }) => {
    const handleClick = (value) => {
        onSelect(value);
    };

    return (
        <div className="static-date-range-dialog-container">
            {options.map(option => (
                <div key={option.value} className="static-date-range-dialog-item" onClick={() => handleClick(option)}>
                    {option.label}
                </div>
            ))}
        </div>
    );
};

export default StaticDateRangeDialog;
