import React from "react";
import infoIconEmpty from "../../assets/media/infoIconEmpty.svg";

const SingleDateInput = ({ title,infoText, isMandatory, titleDescription, defaultValue, value, onBlur, isValid = true, onChange }) => {
    return (
        <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                    {title && title}{isMandatory && <span>*</span>}:
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                    {titleDescription && titleDescription}
                </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
                <div className="challenge-creation-date-column-container">
                    <div className="challenge-creation-date-column">
                        
                        <input
                            type="datetime-local"
                            className="challenge-creation-date-picker"
                            defaultValue={defaultValue}
                            value={value}
                            onBlur={(e) => { onBlur && onBlur(e) }}
                            onChange={(e) => { onChange && onChange(e) }}
                            style={{ border: isValid ? "" : "2px solid red" }}
                        />
                    </div>
                </div>
                {
                        infoText &&
                        infoText.map((text, index) => (
                            <div key={index} className="challenge-title-info-container">
                                <img
                                    className="challenge-title-info-icon"
                                    src={infoIconEmpty}
                                    alt="info:"
                                />
                                <div className="challenge-title-info-text">
                                    {text}
                                </div>
                            </div>
                        ))
                    }

            </div>

        </div>
    )
}

export default SingleDateInput;
