import React from "react";

// Helper function to format timestamps and numeric values
const formatValue = (value, t, keyword) => {
  // Handle arrays

  if (Array.isArray(value)) {
    return value.map((item) => formatValue(item, t, keyword)).join(", ");
  }

  const numberValue = parseInt(value, 10);

  // Check if the value is a timestamp
  if (
    keyword === "MEMBERSHIP_DATE" ||
    keyword === "LAST_VISIT_DATE" ||
    keyword === "LAST_VISIT_TO_BRAND_DATE"
  ) {
    return t(value);
  }

  // Check if the value is numeric
  if (!isNaN(numberValue)) {
    return numberValue;
  }

  // Replace apostrophes in non-numeric values
  if (typeof value === "string" && value.includes("'")) {
    return value.replace(/'/g, "");
  }
  if (typeof value === "boolean") {
    return;
  }

  return t(value);
};

const RangeSlider = ({
  minValue,
  maxValue,
  singleValue,
  arrayValue,
  keyword,
  t,
  index,
  dateFilterConditions,
  conditions,
  row,
  rows,
}) => {
  console.log("AARRAY VALUE", row);
  const renderDateFilterInfo = () => {
    console.log("ROW", row);
    if (dateFilterConditions) {
      return (
        <div className="date-filter-info">
          {dateFilterConditions.map((cond, index) => (
            <>
              <div key={index}>
                {Object.keys(cond).map((op) => (
                  <div key={op}>
                    {t("last")} {t(cond[op])}
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      );
    }
    return null;
  };

  // Check if row.conditions is an array before filtering
  const calculateMinValue = () => {
    if (Array.isArray(row.conditions)) {
      const gteConditions = row.conditions.filter(
        (cond) => cond.operator === "GTE"
      );

      if (gteConditions.length > 0) {
        if (row.combineOperator === "AND") {
          return Math.max(...gteConditions.map((cond) => cond.value)); // Largest GTE value
        } else if (row.combineOperator === "OR") {
          return Math.min(...gteConditions.map((cond) => cond.value)); // Smallest GTE value
        }
      }
    }
    return minValue; // Fallback to original minValue
  };

  // Check if row.conditions is an array before filtering
  const calculateMaxValue = () => {
    if (Array.isArray(row.conditions)) {
      const lteConditions = row.conditions.filter(
        (cond) => cond.operator === "LTE"
      );

      if (lteConditions.length > 0) {
        if (row.combineOperator === "AND") {
          return Math.min(...lteConditions.map((cond) => cond.value)); // Smallest LTE value
        } else if (row.combineOperator === "OR") {
          return Math.max(...lteConditions.map((cond) => cond.value)); // Largest LTE value
        }
      }
    }
    return maxValue; // Fallback to original maxValue
  };

  return (
    <div className="range-slider-container flex-1">
      {/* Range Slider */}
      <div className="range-slider-wrapper">
        <div className="range-slider-container-range">
          {/* Background line */}
          {!singleValue && (minValue || maxValue) && (
            <div className="range-slider-absolute-background-thin" />
          )}
          {!singleValue && (minValue || maxValue) && (
            <div className="range-slider-absolute-background-thin-longer-background">
              <div
                className={`range-slider-absolute-background-thin-longer-left ${
                  minValue && "range-slider-gray"
                }`}
              />
              <div
                className={`range-slider-absolute-background-thin-longer-right ${
                  maxValue && "range-slider-gray"
                }`}
              />
            </div>
          )}
          {/* Min value circle */}
          <div
            className={`range-slider-container-range-circle ${
              !minValue && "visibility-hidden"
            }`}
          />
          {/* Single value circle */}
          <div
            className={`range-slider-container-range-circle ${
              !arrayValue && "display-none"
            }`}
          />
          <div
            className={`range-slider-container-range-circle ${
              !singleValue && "display-none"
            }`}
          />
          {/* Max value circle */}
          <div
            className={`range-slider-container-range-circle ${
              !maxValue && "visibility-hidden"
            }`}
          />
        </div>
      </div>
      {/* Values */}
      <div className="range-slider-wrapper no-padding">
        <div className="range-slider-container-range">
          {/* Min value */}
          <div
            className={`range-slider-value ${
              !minValue ? "visibility-hidden" : ""
            }`}
          >
            {formatValue(calculateMinValue(minValue), t, keyword)}
          </div>
            {console.log("SINGLE", singleValue)}
          {/* Single value (if present) */}
          {singleValue && (
            <div className="range-slider-value">
              {Array.isArray(singleValue) &&
              keyword !== "SMS_ENABLED" &&
              keyword !== "EMAIL_ENABLED"
                ? singleValue.map((value, index) => (
                    <span key={index}>
                      {formatValue(value, t, keyword)}
                      {index < singleValue.length - 1 && ", "}
                    </span>
                  ))
                : formatValue(
                    keyword === "SMS_ENABLED" || keyword === "EMAIL_ENABLED"
                      ? ""
                      : singleValue,
                    t,
                    keyword
                  )}
            </div>
          )}

          {/* Array value (for IN condition) */}
          {arrayValue && Array.isArray(arrayValue) && (
            <div className="range-slider-value">
              {arrayValue.map((val, index) => (
                <span key={index}>
                  {t(val)}

                  {index < arrayValue.length - 1 && ", "}
                </span>
              ))}
            </div>
          )}

          {/* Max value */}
          <div
            className={`range-slider-value ${
              !maxValue ? "visibility-hidden" : ""
            }`}
          >
            {formatValue(calculateMaxValue(maxValue), t, keyword)}
          </div>
        </div>
      </div>
      {/* Keyword (if present) */}
      {keyword && (
        <div className="range-slider-wrapper no-padding range-slider-wrapper-keyword">
          <div className="range-slider-container-range-title">
            <div className="range-slider-value display-none">
              {formatValue(keyword, t, keyword)}
            </div>

            <div className="range-slider-value range-slider-value-title">
              {formatValue(keyword, t, keyword)}
            </div>
            <div className="range-slider-value display-none">
              {formatValue(keyword, t, keyword)}
            </div>
          </div>
        </div>
      )}
      {renderDateFilterInfo()}
    </div>
  );
};

export default RangeSlider;
