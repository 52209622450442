import React, { useEffect, useState } from "react";
import shoppingCardIcon from '../../../assets/media/Icon ionic-ios-cart.svg';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const ContentCardBottomDefault = ({ onClick, link, content }) => {
    const { t } = useTranslation("contentList");
    const navigate = useNavigate();

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        // Check if the content card link exists in localStorage
        const savedContentCards = JSON.parse(localStorage.getItem('contentCards')) || [];
        const isLinkInBasket = savedContentCards.some(content => content.link === link);
        setDisabled(isLinkInBasket);


    }, [link]);

    const handleClick = () => {
        if (link && disabled) {
            navigate('/brand/srm/content/basket'); // Navigate to basket page
        } else {
            setDisabled(true)
            onClick(link);
        }
    };

    const expireDate = new Date(content.offerEndDate);
    const now = new Date();
    if(content.offerStatus === "OFFERED"){

        
    }
    function toTimestamp(strDate){
        var datum = Date.parse(strDate);
        return datum/1000;
     }
    return (
        <div className="content-card-bottom-container">
            {link ? (
                disabled ? (
                    <div className="content-card-bottom-default-card-button-disabled" onClick={() => {handleClick()}}>
                        <div className="content-card-bottom-default-card-button-title">
                            {t("goToBasket")}
                        </div>
                    </div>
                ) : (
                    content.offerStatus === "ACCEPTED" ?
                        <div className="content-card-bottom-default-card-button" onClick={() => { }}>
                            <div className="content-card-bottom-default-card-button-title">
                                {t("alreadyOwned")}
                            </div>
                        </div>
                        :
                        content.offerStatus === "OFFERED" && toTimestamp(expireDate) > toTimestamp(now) ?
                            <div className="content-card-bottom-default-card-button">
                                <img className="content-card-default-card--button-img" src={shoppingCardIcon} alt="icn" />
                                <div className="content-card-bottom-default-card-button-title">
                                    {t("OFFERED")}
                                </div>
                            </div>
                            :
                            <div className="content-card-bottom-default-card-button" onClick={() => { handleClick(); }}>
                                <img className="content-card-default-card--button-img" src={shoppingCardIcon} alt="icn" />
                                <div className="content-card-bottom-default-card-button-title">
                                    {t("addToBasket")}
                                </div>
                            </div>
                )
            ) : (
                <div className="content-card-bottom-default-card-button-error">
                    <div className="content-card-bottom-default-card-button-title">
                        {t("contentNotFound")}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContentCardBottomDefault;
