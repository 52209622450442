import React from "react";

const SingleSmallInput = ({
  title,
  titleDescription,
  isMandatory,
  inputPlaceholder,
  maxLength,
  minLength,
  infoText,
  type='text',
  value,
  isValid = true,
  onBlur,
  onChange,
  readOnly = false,
  invalidMessage,
  isCorrect,
}) => {
  return (
    <div className="small-input-wrapper">
      <div className="challenge-creation-input-container-header-title">
        {title && title}
        {isMandatory && <span>*</span>}
        {title && ":"}
      </div>
      <input
        className="single-text-input-small-input"
        placeholder={inputPlaceholder && inputPlaceholder}
        minLength={minLength && minLength}
        maxLength={maxLength && maxLength}
        type={type}
        required={true}
        readOnly={readOnly}
        value={value}
        onBlur={(e) => {
          onBlur(e);
        }}
        onChange={(e) => {
          onChange(e);
        }}
        style={{
          border:
            isValid && isCorrect
              ? "2px solid #119650"
              : isValid
              ? ""
              : "2px solid red",
        }}
      />
    </div>
  );
};

export default SingleSmallInput;
