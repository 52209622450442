import React, { useEffect, useState } from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { QRCodeSVG } from 'qrcode.react';
import "../../assets/css/QrOption.css";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import infoIconEmpty from "../../assets/media/infoIconEmpty.svg";
import tick from "../../assets/media/tickInteraction.svg";
import jpgIcon from "../../assets/media/jpg.png";
import pngIcon from "../../assets/media/png.png";
import pdfIcon from "../../assets/media/pdf.png";
import svgIcon from "../../assets/media/svg.png";
import Loader from "../popup/loader";
import { getCookie } from "../pages/auth/tokenUtils";
import axios from "axios";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import logoSVG from '../../assets/media/Logo.svg';


const QrOption = ({ qrString, qrChecked }) => {
    const { t, i18n } = useTranslation("challengeCreation");
    const [checked, setChecked] = useState(false);
    const [loaderState, setLoaderState] = useState(false);

    const [qrCode, setQrCode] = useState();
    const toggleCheckbox = () => {
        const newChecked = !checked;
        setChecked(newChecked);
        qrChecked(newChecked); // checked durumunu parent bileşene iletiyoruz

    };

    useEffect(() => {

        
        if (checked) fetchQR();
        
    }, [checked])


    const fetchQR = async () => {
        setLoaderState(true);

        const authToken = getCookie("access_token");
        await axios
            .get(
                apiUrl + "/api/v1/brand-admin/brand/challenge/qr",
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    setQrCode(response.data.responseData);
                    setLoaderState(false);
                    qrString(response.data.responseData);

                }
            })
            .catch((error) => {
                setLoaderState(false);

                console.log(error);
                toast.error(t('error.fetchQR') + "(" + error.message + ")");
                toggleCheckbox();
            });
    };


    const downloadSVG = () => {
        const qrSVG = document.getElementById('qr-svg');
        if (qrSVG) {
            const svgData = new XMLSerializer().serializeToString(qrSVG);
            const blob = new Blob([svgData], { type: 'image/svg+xml' });
            saveAs(blob, 'qrcode.svg'); // SVG olarak indir
        }
    };

    const downloadPDF = () => {
        const qrContainer = document.getElementById('qr-container');

        html2canvas(qrContainer, { allowTaint: true }).then(canvas => {
            // jsPDF ile yeni bir PDF belgesi oluşturuyoruz
            const pdf = new jsPDF('p', 'mm', 'a4');

            // PDF sayfasının genişliğini ve yüksekliğini alın
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // Canvas'ı PDF sayfasının merkezine yerleştirme koordinatlarını hesaplayın
            const imgWidth = 50; // Görüntünün genişliği
            const imgHeight = 50; // Görüntünün yüksekliği
            const x = (pdfWidth - imgWidth) / 2;
            const y = (pdfHeight - imgHeight) / 2;

            // Canvas'ı PDF'e ekliyoruz
            pdf.addImage(canvas, 'SVG', x, y, imgWidth, imgHeight);
            // PDF'i indirme işlemi

            pdf.save("Soty_Qr.pdf");

        });
    };




    const downloadImage = (format) => {
        html2canvas(document.getElementById('qr-container')).then(canvas => {
            const link = document.createElement('a');
            if (format === 'png') {
                link.download = 'qrcode.png';
                link.href = canvas.toDataURL('image/png');
            } else if (format === 'jpg') {
                link.download = 'qrcode.jpg';
                link.href = canvas.toDataURL('image/jpeg', 3.0);
            }
            link.click();
        });
    };

    return (
        <div className="qr-generator-container challenge-creation-input-container">
            {loaderState && <Loader />}

            <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                    {t("QRCode")}:
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                    {t("QRCodeSubtitle")}
                </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
                <div className="qr-checkbox-container">
                    <div className={checked ? 'qr-checkbox checked' : 'qr-checkbox'} onClick={toggleCheckbox}>
                        {checked && <img src={tick} alt="Tick" />}
                    </div>
                    <div>{t("generateQR")}</div>
                </div>
                {checked && qrCode &&
                    <div className="qr-code-showcase-container">
                        <div className="qr-code-showcase">
                            <div className="qr-code" id="qr-container">

                                <QRCodeSVG id="qr-svg" value={qrCode} size={12} height={256} width={256} />
                            </div>


                        </div>
                        <div className="download-buttons">


                            <div className="download-qr download-jpg" onClick={() => downloadImage('jpg')}>
                                <img src={jpgIcon} alt="svg" />
                            </div>
                            <div className="download-qr download-png" onClick={() => downloadImage('png')}>
                                <img src={pngIcon} alt="svg" />
                            </div>
                            <div className="download-qr download-pdf" onClick={() => { downloadPDF() }}>
                                <img src={pdfIcon} alt="svg" />
                            </div>
                            <div className="download-qr download-svg" onClick={() => { downloadSVG() }}>
                                <img src={svgIcon} alt="svg" />
                            </div>
                        </div>
                    </div>
                }
                <div className="challenge-starting-instructions-store-info-row">
                    <img
                        className="challenge-starting-instructions-info-icon"
                        src={infoIconEmpty}
                        alt="Icon"
                    />
                    <div className="challenge-starting-instructions-info">
                        {t("qrDescription")}
                    </div>
                </div>
            </div>

        </div>
    );
};




export default QrOption;
