import React from "react";

const TabButton = ({ id, title, active, onClick }) => {
    return (
        <div className={`${active ? 'tab-button-active' : 'tab-button'}`} onClick={onClick}>
            {title}
        </div>
    );
};

export default TabButton;
