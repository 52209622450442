import React, { useEffect, useState } from "react";
import downArrow from "../../../../../../assets/media/down-arrow.png";
import axios from "axios";
import { toast } from "react-toastify";
import arrow from "../../../../../../assets/media/arrow.png";
import "../../../../../../assets/css/ChallengePreview.css";
import "../../../../../../assets/css/PhoneView.css";
import Approved from "../../../../brand_admin/popup_page/PopupPage.js";
import { useLocation, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { imagesUrl } from "../../../../../../config";
import { getCookie } from "../../../../auth/tokenUtils.js";
import { isMyTokenExpired } from "../../../../auth/tokenUtils";
import { apiUrl } from "../../../../../../config";
import PhoneViewJoin from "../../../../../phone/phoneViewJoin.js";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../popup/loader.js";
import checkImage from "../../../../../../assets/media/check.svg";
import tick from "../../../../../../assets/media/tickInteraction.svg";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar.js";

const CampaignPreview = ({ challengeType }) => {
  const { t, i18n } = useTranslation("challengeCreation");
  const navigate = useNavigate();
  const location = useLocation();
  const [loaderState, setLoaderState] = useState(false);

  const { returnedApiData } = location.state || {};

  const [isChallengePublished, setIsChallengePublished] = useState(false);

  {
    /**
    if (!returnedApiData) {
        return (
            <div className="dashboard-container">
                <p>Data not available. Please check your API response.</p>
            </div>
        );
    }
 */
  }

  useEffect(() => {
    console.log(returnedApiData);
  }, [returnedApiData]);

  const returnToEdit = async () => {
    console.log(returnedApiData);
    if (returnedApiData.responseData.campaignSpecialDay) {
      navigate("/brand/srm/campaign/special-campaign/create", {
        state: { returnedCampaign: returnedApiData },
      });
    } else if (returnedApiData.responseData.corporateName) {
      navigate("/brand/srm/corporate-campaign/create", {
        state: { returnedCampaign: returnedApiData },
      });
    } else {
      navigate("/brand/srm/campaign/create", {
        state: { returnedCampaign: returnedApiData },
      });
    }
  };

  const handlePublishChallenge = async () => {
    setLoaderState(true);
    const endPoint =
      apiUrl +
      `/api/v1/brand-admin/brand/campaign/${returnedApiData.responseData.id}/publish`;
    const authToken = getCookie("access_token");
    if (!authToken) {
      console.error("Access token is missing.");
      setLoaderState(false);

      navigate("/sign-in-brand");
      toast.error(t("error.missingAccessToken"));
      return;
    }
    // Send the PUT request
    console.log(authToken);
    axios
      .put(
        endPoint,
        {}, // Empty object as the second argument
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        setLoaderState(false);
        console.log("Response:", response.data);
        toast.success(t("success.CampaignPublish"));

        if (returnedApiData.responseData.corporateName) {
          navigate("/brand/srm/corporate-campaign");
        } else {
          navigate("/brand/srm/campaign");
        }
      })
      .catch((error) => {
        setLoaderState(false);

        console.error("Request Details:", error.config);
        // Handle the response status codes
        console.error(
          `Error: ${
            error.response.data || error.response?.data?.metadata?.message
          }`
        );
        toast.error(t("error.CampaignCreation") + " (" + error.message + ")");
      });
  };

  const formatDateString = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const locale = localStorage.getItem("language") || "tr-TR"; // Varsayılan olarak 'en-US' kullanılacak
    return new Date(dateString).toLocaleDateString(locale, options);
  };

  const publishChallenge = () => {
    const challengeId = returnedApiData.responseData.id;
    setIsChallengePublished(true);
  };

  const displayEmailName = (email) => {
    const atIndex = email.indexOf("@");
    if (atIndex === -1) return ""; // Eğer "@" işareti yoksa boş string döndür
    const name = email.substring(0, atIndex);

    let [firstName, lastName] = name.split(".");

    if (!lastName || (lastName && /^[A-Z]+$/.test(lastName))) {
      // Soyisim tanımsızsa veya sadece büyük harflerle yazılmışsa
      // Split işlemi tekrar yapılır
      [firstName, lastName] = name.split(/(?=[A-Z])/);
    }

    if (!lastName)
      return `${firstName.charAt(0).toUpperCase() + firstName.slice(1)}` || ""; // Eğer lastName tanımsızsa, sadece firstName'ı döndür

    return `${firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${
      lastName.charAt(0).toUpperCase() + lastName.slice(1)
    }`;
  };

  const locationArray = [
    [t("corporateCampaigns"), "/brand/srm/corporate-campaign"],
    [
      t("createCampaignForCorporations"),
      "/brand/srm/corporate-campaign/create",
    ],
  ];

  return (
    <>
      {loaderState && <Loader />}
      <div className="dashboard-container">
        <NavigationBar locationArray={locationArray} />

        <div className="dashboard-row">
          <div className="campaign-preview-container">
            <div className="campaign-preview-brand-container">
              <img
                className="brand-logo"
                src={imagesUrl + "/" + localStorage.getItem("brandLogo")}
                alt="brand"
              />
              <div className="campaign-id">
                {t("CampaignID")}: {returnedApiData.responseData.id}
              </div>
            </div>
            <div className="campaign-preview-detail-container">
              <div className="campaign-preview-detail-column">
                <div className="campaign-preview-detail-column-title">
                  {t("CampaignName")}
                </div>
                <div className="campaign-preview-detail-column-content">
                  {returnedApiData.responseData.name}
                </div>
              </div>

              {returnedApiData.responseData.segment && (
                <div className="campaign-preview-detail-column">
                  <div className="campaign-preview-detail-column-title">
                    {t("segment")} ID :
                  </div>
                  <div className="campaign-preview-detail-column-content campaign-preview-detail-column-content-gray-background">
                    {returnedApiData.responseData.segment}
                  </div>
                </div>
              )}
              {returnedApiData.responseData.corporateName && (
                <div className="campaign-preview-detail-column">
                  <div className="campaign-preview-detail-column-title">
                    {t("corporation")}
                  </div>
                  <div className="campaign-preview-detail-column-content campaign-preview-detail-column-content-gray-background green-domain-color">
                    {returnedApiData.responseData.corporateDomain}
                  </div>
                  <div className="campaign-preview-detail-column-content">
                    {returnedApiData.responseData.corporateName}
                  </div>
                </div>
              )}

              <div className="campaign-preview-detail-column">
                <div className="campaign-preview-detail-column-title">
                  {t("ChannelType")}
                </div>
                <div className="campaign-preview-detail-column-content">
                  {returnedApiData.responseData.shoppingChannel === "ALL" &&
                    t("all")}
                  {returnedApiData.responseData.shoppingChannel === "STORE" &&
                    t("store")}
                  {returnedApiData.responseData.shoppingChannel === "ONLINE" &&
                    t("online")}
                </div>
              </div>
              <div className="campaign-preview-detail-column">
                <div className="campaign-preview-detail-column-title">
                  {t("campaignDetails")}
                </div>
                <div className="campaign-preview-detail-column-content">
                  {returnedApiData.responseData.campaignSpecialDay &&
                    returnedApiData.responseData.campaignSpecialDay ===
                      "BIRTHDAY" && <div>{t("birthday")}</div>}
                  {returnedApiData.responseData.campaignSpecialDay &&
                    returnedApiData.responseData.campaignSpecialDay ===
                      "MEMBERSHIP_ANNIVERSARY" && <div>{t("sotySubDate")}</div>}
                  {returnedApiData.responseData.campaignSpecialDay &&
                    returnedApiData.responseData.campaignSpecialDay ===
                      "BRAND_MEMBERSHIP_ANNIVERSARY" && (
                      <div>{t("brandSubDate")}</div>
                    )}

                  {returnedApiData.responseData.specialDayRange && (
                    <div>
                      <div>{t("rangeOfCampaign")}</div>
                      <div>{returnedApiData.responseData.specialDayRange}</div>
                    </div>
                  )}

                  {returnedApiData.responseData.campaignType ===
                    "COIN_FOLDING" && t("sotyCoinFolding")}
                  {returnedApiData.responseData.campaignType ===
                    "DISCOUNT_PERCENTAGE" && t("discountPercentage")}
                  {returnedApiData.responseData.campaignType ===
                    "COUPON_PERCENTAGE" && t("percentageCoupon")}
                  {returnedApiData.responseData.campaignType ===
                    "SPEND_AND_EARN" && (
                    <div>{t("ySotyCoinForEveryXTlPurchase")}</div>
                  )}
                  {returnedApiData.responseData.campaignType ===
                    "BUY_X_PAY_Y" && <div>{t("buyXPayNow")}</div>}
                  {returnedApiData.responseData.campaignType ===
                    "BUY_MORE_PAY_LESS" && (
                    <div>{t("percentageDiscountOnXItems")}</div>
                  )}

                  {returnedApiData.responseData.campaignType ===
                    "BUY_MORE_PAY_LESS" &&
                    returnedApiData.responseData.discounts &&
                    returnedApiData.responseData.discounts.map(
                      (discount, index) => (
                        <span key={index}>
                          {localStorage.getItem("language") === "tr-TR"
                            ? discount !== "0"
                              ? index + 2 + ". Ürüne %" + discount + " İndirim"
                              : null
                            : discount !== "0"
                            ? discount +
                              "% Discount on the " +
                              (index + 2) +
                              ". Product"
                            : null}
                        </span>
                      )
                    )}
                  {returnedApiData.responseData.campaignType ===
                    "FREE_SHIPMENT" && <div>{t("freeShipping")}</div>}
                  {returnedApiData.responseData.singleParticipatable ? (
                    <div>{t("single")}</div>
                  ) : (
                    <div>{t("multiple")}</div>
                  )}
                  {returnedApiData.responseData.earn && (
                    <div>
                      {" "}
                      {t("sotyCoinToEarn") +
                        " : " +
                        returnedApiData.responseData.earn}
                    </div>
                  )}
                  <div className="campaign-preview-detail-column-content-green-background">
                    {returnedApiData?.responseData?.minimumSpending?.toLocaleString()}{" "}
                    {/* minimumSpending */}
                    {returnedApiData.responseData.minimumSpending &&
                      returnedApiData?.responseData?.spendingUpperLimit &&
                      " - "}
                    {returnedApiData?.responseData?.spendingUpperLimit &&
                      returnedApiData?.responseData?.spendingUpperLimit}{" "}
                    {/* spendingUpperLimit */}
                  </div>

                  {returnedApiData.responseData.couponRatio && (
                    <div>{"%" + returnedApiData.responseData.couponRatio}</div>
                  )}
                  {returnedApiData.responseData.foldingFactor && (
                    <div>
                      {returnedApiData.responseData.foldingFactor + "x"}
                    </div>
                  )}
                  {returnedApiData.responseData.discountPercentage && (
                    <div>
                      {"%" + returnedApiData.responseData.discountPercentage}
                    </div>
                  )}
                  {returnedApiData.responseData.payCount && (
                    <div>
                      {localStorage.getItem("language") === "tr-TR"
                        ? returnedApiData.responseData.buyCount +
                          " " +
                          t("buy") +
                          " " +
                          returnedApiData.responseData.payCount +
                          " " +
                          t("pay")
                        : t("buy") +
                          " " +
                          returnedApiData.responseData.buyCount +
                          " " +
                          t("pay") +
                          " " +
                          returnedApiData.responseData.payCount}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="campaign-preview-detail-container">
              <div className="campaign-preview-detail-column">
                <div className="campaign-preview-detail-column-title">
                  {t("startDate")}
                </div>
                <div className="campaign-preview-detail-column-content">
                  {formatDateString(returnedApiData.responseData.startDate)}
                </div>
              </div>
              <div className="campaign-preview-detail-column">
                <div className="campaign-preview-detail-column-title">
                  {t("endDate")}
                </div>
                <div className="campaign-preview-detail-column-content">
                  {formatDateString(returnedApiData.responseData.endDate)}
                </div>
              </div>
              <div className="campaign-preview-detail-column">
                <div className="campaign-preview-detail-column-title">
                  {t("campaignMessageTypeTitle")}
                </div>
                <div className="campaign-preview-detail-column-content">
                  {returnedApiData.responseData.smsNotificationEnabled ===
                    true && (
                    <div className="flex row align-center justify-start gap-20 width-100">
                      <div className={"qr-checkbox checked"}>
                        <img src={tick} alt="Tick" />
                      </div>
                      <div className="challenge-creation-segment-title">
                        {t("sms")}
                      </div>
                    </div>
                  )}
                  {returnedApiData.responseData.emailNotificationEnabled ===
                    true && (
                    <div className="flex row align-center justify-start gap-20 width-100">
                      <div className={"qr-checkbox checked"}>
                        <img src={tick} alt="Tick" />
                      </div>
                      <div className="challenge-creation-segment-title">
                        {t("mail")}
                      </div>
                    </div>
                  )}
                  {returnedApiData.responseData.inAppNotificationEnabled ===
                    true && (
                    <div className="flex row align-center justify-start gap-20 width-100">
                      <div className={"qr-checkbox checked"}>
                        <img src={tick} alt="Tick" />
                      </div>
                      <div className="challenge-creation-segment-title">
                        {t("inAppNotification")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {returnedApiData.responseData.emails && (
              <div className="campaign-preview-detail-container campaign-preview-column-detail-container">
                <div className="campaign-preview-detail-column align-flex-start">
                  <div className="campaign-preview-detail-column-title">
                    {t("CorporateCampaignEmailDispatch")}:
                  </div>
                </div>

                <div className="corporation-campaign-creation-member-list corporation-campaign-creation-member-list-preview ">
                  {returnedApiData.responseData.emails?.length > 0 &&
                    returnedApiData.responseData.emails
                      ?.slice()
                      .reverse()
                      .map((corpMember, index) => (
                        <div key={index} className="corporation-member-row">
                          <img
                            src={checkImage}
                            alt="ico"
                            className="brand-admin-info-container-title-img"
                          />
                          <div className="corporation-member-wrapper">
                            <div className="corporation-member">
                              {displayEmailName(corpMember)}
                            </div>
                            <div className="corporation-member-email">
                              {corpMember}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            )}
            <div className="interaction-type-preview-challenge-release-button-wrapper">
              <button
                className="interaction-type-preview-challenge-release-button"
                onClick={() => {
                  handlePublishChallenge();
                }}
              >
                {t("publish")}
              </button>
            </div>
            <div className="interaction-type-preview-challenge-release-button-wrapper">
              <button
                className="interaction-type-preview-challenge-edit-button"
                onClick={() => {
                  returnToEdit();
                }}
              >
                {t("edit")}
              </button>
            </div>
          </div>
          <PhoneViewJoin
            name={returnedApiData.responseData.name}
            description={returnedApiData.responseData.description}
            endDate={returnedApiData.responseData.endDate || ""}
            returnedApiData={returnedApiData}
            imagesUrl={imagesUrl}
            challengeType={t("Campaign")}
            foldingString={
              returnedApiData.responseData.foldingFactor &&
              "x" + returnedApiData.responseData.foldingFactor + " Soty Coin"
            }
            percentString={
              returnedApiData.responseData.discountPercentage &&
              "%" + returnedApiData.responseData.discountPercentage
            }
            startDate={
              returnedApiData.responseData.startDate &&
              returnedApiData.responseData.startDate
            }
            spendToEarnEarn={
              returnedApiData.responseData.earn &&
              returnedApiData.responseData.earn
            }
            percentCouponString={
              returnedApiData.responseData.couponRatio &&
              "%" + returnedApiData.responseData.couponRatio
            }
            freeShipping={returnedApiData.responseData.freeShipping}
          />
        </div>
      </div>
    </>
  );
};

export default CampaignPreview;
