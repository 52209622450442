import React, { useState, useEffect } from "react";
import arrow from "../../../../assets/media/arrow.png";
import downArrow from "../../../../assets/media/down-arrow.png";
import "../../../../assets/css/InteractionCreation.css";
import "../../../../assets/css/ChallengeList.css";
import axios from "axios";
import { getCookie } from "../../auth/tokenUtils";
import { useNavigate } from "react-router-dom";
import { apiUrl, imagesUrl } from "../../../../config";
import { isMyTokenExpired } from "../../auth/tokenUtils";
import { toast } from "react-toastify";
import DynamicTable from "../../../tables/DynamicTable";
import Pagination from "../../../tables/Pagination";
import { useTranslation } from "react-i18next";
import Loader from "../../../popup/loader";

const ChallengeList = () => {
  const { t, i18n } = useTranslation("challengeList");
  const navigate = useNavigate();
  const [challenges, setChallenges] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loaderState, setLoaderState] = useState(false);

  const [challengeDetailVisible, setChallengeDetailVisible] = useState(false);
  const [clickedChallenge, setClickedChallenge] = useState();
  const itemsPerPage = 100;

  const isRowClickable = (item) => {
    return true;
  }
  useEffect(() => {
    fetchData(1); // Fetch data for the initial page
  }, [])



  const fetchData = (page) => {
    setLoaderState(true);

    const token = getCookie("access_token");


    if (!token) {
      setLoaderState(false);

      console.error("Access token is missing.");
      navigate("/sign-in-realm");
      return;
    }
    if (isMyTokenExpired(token)) {
      setLoaderState(false);

      toast.error(
        "Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız."
      );
      navigate("/sign-in-realm");
    }

    // Fetch your data from the API
    axios
      .get(
        apiUrl + `/api/v1/admin/challenge?page=${page - 1}&size=${itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const responseData = response.data.responseData || [];
        const pageMetadata = response.data.pageMetadata || {};

        setChallenges(responseData);
        console.log(responseData);
        setTotalPages(pageMetadata.totalPage || 0);
        console.log(pageMetadata.totalPage);
        setLoaderState(false);

        setCurrentPage(page);
      })
      .catch((error) => {
        setLoaderState(false);

        console.error("Error fetching data:", error);
        // Handle error, display a message, etc.
      });
  };

  const handlePageChange = (newPage) => {
    fetchData(newPage);
  };

  const formatDate = (dateString) => {
    // UTC tarihini al
    const utcDate = new Date(dateString);

    // UTC tarihine manuel olarak 3 saat ekle
    utcDate.setHours(utcDate.getHours() + 3);

    // Tarih ve saat bilgilerini istediğiniz formatta al
    const formattedDate = utcDate.toLocaleDateString("tr-TR");
    const formattedTime = utcDate.toLocaleTimeString("tr-TR");

    return {
      date: formattedDate,
      time: formattedTime,
    };
  };



  const challengeTypeToShow = (challengeType) => {
    let message;

    if (challengeType === "SURVEY") {
      message = t("survey");
    } else if (challengeType === "SOCIAL_MEDIA") {
      message = t("socialMedia");
    } else {
      message = t("otherChallengeTypes");
    }
    return message
  }
  const socialChallengeTypeToShow = (socialChallengeType) => {
    let message;
    if (socialChallengeType === "INTERACTION") {
      message = t("interaction");
    } else if (socialChallengeType === "CONTENT_CREATION") {
      message = t("contentCreation");
    } else {
      message = "";
    }
    return message
  }
  const formatSocialProvider = (provider) => {
    if (provider) {
      let message = provider.toLowerCase();
      return message.charAt(0).toUpperCase() + message.slice(1);
    } else {
      return ""; // veya başka bir varsayılan değer
    }
  };
  const statusToShow = (status) => {
    if (status === "DRAFTED") {
      return t("drafted")
    }
    else {
      return t("released")
    }
  }

  const columns = [
    { title: t("control"), field: "Control", className: "" },
    { title: t("challengeId"), field: "ChallengeID", className: "dynamic-table-link" },
    { title: t("challengeName"), field: "ChallengeName", className: "" },
    { title: t("challengeDescription"), field: "ChallengeType", className: "" },
    { title: t("brandName"), field: "BrandName", className: "" },
    { title: t("startDate"), field: "StartDate", className: "" },
    { title: t("endDate"), field: "EndDate", className: "" },
    { title: t("sotyCoin"), field: "SotyCoin", className: "" },
    { title: t("status"), field: "Status", className: "" },
  ];

  const data = challenges.map((challenge) => ({
    Control: (<button className="dynamic-table-button" onClick={() => handleRowClick(challenge.id)}
    >{t("detail")}</button>),
    ChallengeID: challenge.id,
    ChallengeName: challenge.name,
    ChallengeType: challengeTypeToShow(challenge.challengeType),
    BrandName: challenge.brand.brandName,
    StartDate: <>{formatDate(challenge.startDate).date}<br />{formatDate(challenge.startDate).time}</>,
    EndDate: <>{formatDate(challenge.endDate).date}<br />{formatDate(challenge.endDate).time}</>,
    SotyCoin: challenge.prize + " = " + challenge.prize / 10 + " TL",
    Status: (
      <span className={challenge.status === "DRAFTED" ? "dynamic-table-data-red" : "dynamic-table-data-green"}>
        {statusToShow(challenge.status)}
      </span>
    ),
  }));



  const handleRowClick = (ID) => {
    // Find the clicked challenge from the challenges array
    console.log(ID)
    
    const clickedChallenge = challenges.find(challenge => challenge.id === ID);

    if (clickedChallenge) {
      console.log("Clicked Challenge Details:", clickedChallenge);
      // Add your logic for displaying the challenge details as needed
    } else {
      console.error("Challenge not found for ChallengeID:", ID);
    }
    
    setChallengeDetailVisible(true);
    setClickedChallenge(clickedChallenge);
  };


  const fetchSelectedChallengeToDetailPage = async (challengeId, challenge) => {
    const token = getCookie("access_token");
    if (!token) {
      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-realm");

      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-realm");

    }

    let endpoint;
    console.log(challenge);
    if (challenge.challengeType === "SURVEY") {
      endpoint = `${apiUrl}/api/v1/admin/challenge/survey/${challengeId}/detail`;
    }
    else if (challenge.socialChallengeType === "INTERACTION") {
      endpoint = `${apiUrl}/api/v1/admin/challenge/social/interaction/${challengeId}/detail`;
    }
    else if (challenge.socialChallengeType === "CONTENT_CREATION") {
      endpoint = `${apiUrl}/api/v1/admin/challenge/social/content/${challengeId}/detail`;
    }


    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      console.log(data.responseData);


      navigate('/challenges/detail', { state: { returnedChallenge: data.responseData } });
      // Do something with the data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [profile, setProfile] = useState(null);
  return (
    <div className="challenge-list-container">
      {loaderState && <Loader/>} 

      <div className="content-location-container">
                <a className="content-location-container-href" href="#">
                    <img src={arrow} alt="arrow" />
                </a>
                <a className="content-location-container-link-text" href="#">
                    <div className="older">{t("challenges")}</div>
                </a>
            </div>
      <hr />
      {challengeDetailVisible && (
        <div className="challenge-list-detail-popup">
          <div className="challenge-list-detail-top">
            <div className="challenge-list-detail-title">
              <div>
                <span>
                {t("challengeId")}:&nbsp;
                </span>

                {clickedChallenge.id}
              </div>

              <div>
                <span>
                {t("challengeName")}:&nbsp;
                </span>
                {clickedChallenge.name}
              </div>


            </div>
            <img src={imagesUrl + '/' + clickedChallenge.logo} />
          </div>
          <div className="challenge-list-detail-description"><span>{t("challengeDescription")}:</span> {clickedChallenge.description}</div>
          <div className="challenge-list-detail-type">
            <span>
            {t("challengeType")}:&nbsp;
            </span>
            {clickedChallenge.challengeType}
          </div>
          {clickedChallenge.socialChallengeType && (<div className="challenge-list-detail-type">
            <span>
            {t("challengeSocialType")}:&nbsp;
            </span>
            {clickedChallenge.socialChallengeType}
          </div>)}
          {clickedChallenge.socialProvider && (<div className="challenge-list-detail-type">
            <span>
            {t("socialProvider")}:&nbsp;
            </span>
            {clickedChallenge.socialProvider}
          </div>)}
          <div className="challenge-list-detail-time">
            <span>
            {t("startDate")}:&nbsp;
            </span>
            {formatDate(clickedChallenge.startDate).date}  {formatDate(clickedChallenge.startDate).time}
          </div>
          <div className="challenge-list-detail-time">
            <span>
            {t("endDate")}:&nbsp;
            </span>
            {formatDate(clickedChallenge.endDate).date}  {formatDate(clickedChallenge.endDate).time}
          </div>
          <div className="challenge-list-detail-prize">
            <span>
            {t("sotyCoin")}:&nbsp;
            </span>
            {clickedChallenge.prize + " = " + clickedChallenge.prize / 10 + " TL"}

          </div>
          <div className="challenge-list-detail-prize">
            <span>
            {t("status")}:&nbsp;
            </span>
            {clickedChallenge.status}
          </div>
          {clickedChallenge.status === "RELEASED" && (<button className="challenge-list-detail-edit" onClick={() => {
            fetchSelectedChallengeToDetailPage(clickedChallenge.id, clickedChallenge);
          }}>{t("showDetails")}</button>
          )}
          <button className="challenge-list-detail-close" onClick={() => { setChallengeDetailVisible(!challengeDetailVisible) }}>{t("close")}</button>



        </div>)}
      <div className="challenge-list-table-container">
        <div className="challenge-list-table-controls-container">
          <div className="challenge-list-table-search-container">
            <div className="challenge-list-table-search-wrapper">
              <svg viewBox="0 0 24 24" aria-hidden="true" className="icon">
                <g>
                  <path
                    d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                  ></path>
                </g>
              </svg>
              <input className="input" type="search" placeholder={t("search")} />
            </div>
          </div>
          <div className="challenge-list-table-button-container">

          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        <DynamicTable
          columns={columns}
          data={data}
          tableName={t("challenges")}
          isRowClickable={isRowClickable} // Example: Make rows clickable if the status is "Inactive"
          onRowClick={handleRowClick}
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />



      </div>








    </div>
  );
};

export default ChallengeList;
