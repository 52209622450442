import React from "react";
import '../../assets/css/ViewInSotyAppButton.css';
import LibraryIcon from '../../assets/media/Icon metro-library.svg';
import { useTranslation } from "react-i18next";
const ContentLibraryButton = ({ onClick }) => {
    const { t, i18n } = useTranslation("challengeCreation");
    return (
        <button className="static-button-container content-library-button" onClick={onClick}>
            <img src={LibraryIcon} alt="icn" />
            {t("contentLibrary")}
        </button>
    );
}

export default ContentLibraryButton;
