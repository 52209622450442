import React from 'react';
import '../../assets/css/textPopup.css';
import sotyLogo from '../../assets/media/Logo.svg';

const TextPopup = ({ title, content, onClose, logo }) => {
    // [Date] ifadesini bugünkü tarihle değiştir
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('tr-TR'); // Sadece gün/ay/yıl

    // Metni paragraflara ayırmak için satırları paragraflara böl
    const paragraphs = content.split('\n').map((paragraph, index) => (
        <p key={index}>
            {index === 0 ? paragraph.replace('[Date]', formattedDate) : paragraph}
        </p>
    ));

    return (
        <div className='text-popup'>
            <div className='text-popup-logo'>
                {logo ? (<img src={logo} alt='icon' />) : (<img src={sotyLogo} alt='icon' />)}
            </div>
            <div className='text-popup-content'>
                <div className='text-popup-scrollable-content'>
                    {paragraphs}
                    <div className='text-popup-logo'>
                        {logo ? (<img src={logo} alt='icon' />) : (<img src={sotyLogo} alt='icon' />)}
                    </div>
                </div>
            </div>
            <button className='text-popup-close-button' onClick={onClose}>
                Kapat
            </button>
        </div>
    );
};

export default TextPopup;
