import React, { useState, useEffect, useRef } from "react";
import plusImg from "../../assets/media/plus.svg";
import SegmentAddFilterSelect from "../collapsible/SegmentAddFilterSelect";
import { useTranslation } from "react-i18next";
import equalsOrLessImg from "../../assets/media/equalsOrLess.png";

const SegmentAddFilterButton = ({
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
  categoryTitle,
}) => {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const { t } = useTranslation("segmentation");
  const buttonRef = useRef(null);
  const selectorRef = useRef(null);
  useEffect(() => {
    
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        selectorRef.current &&
        !selectorRef.current.contains(event.target)
      ) {
        setSelectorVisible(false);
      }
    };

    // Event listener'ı ekleyin
    document.addEventListener("mousedown", handleClickOutside);

    // Event listener'ı temizleyin
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="segment-add-filter-button-container">
      <div
        className={`segment-add-filter-button button ${
          minValue || maxValue ? "filled" : ""
        }`}
        ref={buttonRef}
        onClick={() => setSelectorVisible(!selectorVisible)}
      >
        {!(minValue || maxValue) && (
          <img
            src={plusImg}
            alt="+"
            className="segment-add-filter-button-img"
          />
        )}
        <div className="segment-add-filter-button-title">
          {categoryTitle &&
            (minValue && maxValue && minValue === maxValue ? (
              `${t(categoryTitle)} = ${minValue}`
            ) : (
              <>
                {minValue &&  (
                  <>
                    <span className="segment-rule-saved-value-title">
                      {minValue}
                    </span>
                    <img
                      src={equalsOrLessImg}
                      alt="plus"
                      style={{
                        width: "16px",
                        height: "16px",
                        verticalAlign: "middle",
                      }}
                    />
                  </>
                )}
                {minValue || maxValue ? t(categoryTitle) : t("addFilter")}
                {maxValue && (
                  <>
                    <img
                      src={equalsOrLessImg}
                      alt="plus"
                      style={{
                        width: "16px",
                        height: "16px",
                        verticalAlign: "middle",
                      }}
                    />
                    <span className="segment-rule-saved-value-title">
                      {maxValue}
                    </span>
                  </>
                )}
              </>
            ))}
        </div>
      </div>
      {selectorVisible && (
        <div ref={selectorRef}>
          <SegmentAddFilterSelect
            minValue={minValue}
            maxValue={maxValue}
            setMinValue={setMinValue}
            setMaxValue={setMaxValue}
            categoryTitle={categoryTitle}
          />
        </div>
      )}
    </div>
  );
};

export default SegmentAddFilterButton;
