import React from "react";
import plus from '../../assets/media/plus.svg';
import { useNavigate } from "react-router-dom";
import '../../assets/css/ViewInSotyAppButton.css';
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const CreateChallengeButton = ({ Segment, pushNotification, customText }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation("challengeDashboard");


    return (
        Segment === true ?
            <div className="static-button-container create-segment-button" onClick={() => { navigate('/brand/srm/segment/create') }}>
                <img className="create-challenge-button-img" src={plus} alt="+" />
                {t("createSegment")}
            </div>
            :
            pushNotification === true ?

                <div className="static-button-container create-segment-button" onClick={() => { navigate('/brand/inbox/push/create') }}>
                    <img className="create-challenge-button-img" src={plus} alt="+" />
                    {customText}
                </div>

                :
                <div className="static-button-container create-challenge-button" onClick={() => { navigate('/brand/challenges/challenge-creation') }}>
                    <img className="create-challenge-button-img" src={plus} alt="+" />
                    {t("createChallenge")}
                </div>

    )
}
export default CreateChallengeButton;