import React, { useState, useEffect, useRef } from "react";
import FilterIcon from '../../assets/media/filter.svg';
import '../../assets/css/SortFilterSearch.css'
import FilterDialog from "../dialogs/filterDialog";
import { useTranslation } from "react-i18next";
import FilterDialogCampaign from "../dialogs/filterDialogCampaign";
import FilterDialogContent from "../dialogs/filterDialogContent";

const FilterButton = ({
    checkboxData,
    checkboxSocialProviderData,
    onCheckboxSelection,
    eventType,
    checkboxData2,
    listCheckbox,
    onSocialMediaTypeSelect,
    followerCountMax,
    followerCountMin,
    setFollowerCountMax,
    setFollowerCountMin,
    viewCountMax,
    viewCountMin,
    setViewCountMax,
    setViewCountMin,
    interactionMax,
    interactionMin,
    setInteractionMax,
    setInteractionMin,
    onInputBlur,
    onInputChange,
    levelRange,
    setLevelRange,
    setDeleteAll
}) => {
    const [isDialogVisible, setDialogVisibility] = useState(false);
    const dialogRef = useRef(null);
    const { t, i18n } = useTranslation("challengeDashboard");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setDialogVisibility(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dialogRef]);

    const toggleDialogVisibility = () => {
        setDialogVisibility(!isDialogVisible);
    };

    return (
        <div className="filter-button-container" ref={dialogRef}>
            <div className="filter-button-clickable" onClick={toggleDialogVisibility}>
                <img className="filter-button-img" src={FilterIcon} alt="filter" />
                <div className="filter-button-title">
                    {t("filter")}
                </div>
            </div>
            {isDialogVisible && eventType !== "campaign" && <FilterDialog checkboxData={checkboxData} checkboxSocialProviderData={checkboxSocialProviderData} onCheckboxSelection={onCheckboxSelection} eventType={eventType} />}
            {isDialogVisible && eventType === "campaign" && <FilterDialogCampaign checkboxData={checkboxData} onCheckboxSelection={onCheckboxSelection} eventType={eventType} checkboxData2={checkboxData2} listCheckbox={listCheckbox} onItemsSelect={onCheckboxSelection}/>}
            {isDialogVisible && eventType === "content" && (
                 <FilterDialogContent
                 checkboxData={checkboxData}
                 onCheckboxSelection={onCheckboxSelection}
                 eventType={eventType}
                 checkboxData2={checkboxData2}
                 onSocialMediaTypeSelect={onSocialMediaTypeSelect}
                 followerCountMax={followerCountMax}
                 followerCountMin={followerCountMin}
                 setFollowerCountMax={setFollowerCountMax}
                 setFollowerCountMin={setFollowerCountMin}
                 viewCountMax={viewCountMax}
                 viewCountMin={viewCountMin}
                 setViewCountMax={setViewCountMax}
                 setViewCountMin={setViewCountMin}
                 interactionMax={interactionMax}
                 interactionMin={interactionMin}
                 setInteractionMax={setInteractionMax}
                 setInteractionMin={setInteractionMin}
                 onInputBlur={onInputBlur}
                 onItemsSelect={onCheckboxSelection}
                 onInputChange={onInputChange}
                 levelRange={levelRange}
                 setLevelRange={setLevelRange}
                 setDeleteAll={setDeleteAll}
             />
            )}
        </div>
    );
};

export default FilterButton;
