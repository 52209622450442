import React from "react";
import Collapsible from "../collapsible/collapsible";
export function BrandAdminProfileRightContainer({
  t,
  checkImage,
  isForm1Filled,
  firstName,
  lastName,
  phoneNumber,
  email,
  isForm2Filled,
  url,
  brandName,
  imagesUrl,
  logo,
  defaultSotyLogo,
  isForm3Filled,
  companyName,
  brandCategories,
  taxOffice,
  taxNumber,
  isForm4Filled,
  formatGiro,
  giro,
  formatStoreCount,
  storeCount,
  failedIcon,
  isForm5Filled,
  ulke,
  sehir,
  ilce,
  mahalle,
  cadde,
  sokak,
  no,
  postaKodu,
  isForm6Filled,
  instagramIcon,
  instagramUrl,
  youtubeIcon,
  youtubeUrl,
  facebookIcon,
  facebookUrl,
  xIcon,
  twitterUrl,
  tiktokIcon,
  tiktokUrl,
}) {
  return (
    <>
      <Collapsible
        title={t("personalInfo")}
        checkImage={checkImage}
        showCheckIcon={isForm1Filled}
      >
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("name")}:</div>
          <div id="ad2" className="collapsible-content-data">
            {firstName}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("surname")}:</div>
          <div id="soyad2" className="collapsible-content-data">
            {lastName}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("phoneNumber")}:</div>
          <div id="tel2" className="collapsible-content-data">
            {phoneNumber}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("email")}:</div>
          <div id="mail2" className="collapsible-content-data">
            {email}
          </div>
        </div>
      </Collapsible>
      <Collapsible
        title={t("brandInfo")}
        checkImage={checkImage}
        showCheckIcon={isForm2Filled}
      >
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("domainName")}:</div>
          <div id="alanAdi2" className="collapsible-content-data">
            {url}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("brandName")}:</div>
          <div id="markaAdi2" className="collapsible-content-data">
            {brandName}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("brandLogo")}:</div>
          <img
            className="collapsible-brand-logo"
            src={`${imagesUrl}/${logo}`}
            onError={(e) => {
              e.target.src = defaultSotyLogo; // Set default image on error
            }}
            alt={defaultSotyLogo}
          />
        </div>
      </Collapsible>
      <Collapsible
        title={t("companyInfo")}
        checkImage={checkImage}
        showCheckIcon={isForm3Filled}
      >
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("companyName")}:</div>
          <div id="sirketAdi2" className="collapsible-content-data">
            {companyName}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("taxOffice")}:</div>
          <div id="vergiDairesi2" className="collapsible-content-data">
            {taxOffice}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("taxNumber")}:</div>
          <div id="vergiNumarasi2" className="collapsible-content-data">
            {taxNumber}
          </div>
        </div>
      </Collapsible>
      <Collapsible
        title={t("additionalCompanyInfo")}
        checkImage={checkImage}
        showCheckIcon={isForm4Filled}
      >
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("revenue")}:</div>
          <div id="eTicaretCiro2" className="collapsible-content-data">
            {formatGiro(giro)}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("storeCount")}:</div>
          <div id="magazaSayisi2" className="collapsible-content-data">
            {formatStoreCount(storeCount)}
          </div>
        </div>
      </Collapsible>
      <Collapsible
        title={t("brandCategories")}
        checkImage={brandCategories.length > 0 ? checkImage : failedIcon}
        showCheckIcon={isForm4Filled}
      >
        {brandCategories.map((category) => (
          <div className="collapsible-content-row">
            <div className="collapsible-content-title">{t("category")}:</div>
            <div id="eTicaretCiro2" className="collapsible-content-data">
              {navigator.language.startsWith("en")
                ? category.categoryEn
                : category.category}
            </div>
          </div>
        ))}
      </Collapsible>
      <Collapsible
        title={t("invoiceInfo")}
        checkImage={checkImage}
        showCheckIcon={isForm5Filled}
      >
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("country")}</div>
          <div id="ulke2" className="collapsible-content-data">
            {ulke}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("city")}</div>
          <div id="sehir2" className="collapsible-content-data">
            {sehir}
          </div>
        </div>

        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("district")}</div>
          <div id="ilce2" className="collapsible-content-data">
            {ilce}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("neighborhood")}</div>
          <div id="mahalle2" className="collapsible-content-data">
            {mahalle}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("street")}</div>
          <div id="cadde2" className="collapsible-content-data">
            {cadde}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("avenue")}</div>
          <div id="sokak2" className="collapsible-content-data">
            {sokak}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("no")}</div>
          <div id="no2" className="collapsible-content-data">
            {no}
          </div>
        </div>
        <div className="collapsible-content-row">
          <div className="collapsible-content-title">{t("postalCode")}</div>
          <div id="postaKodu2" className="collapsible-content-data">
            {postaKodu}
          </div>
        </div>
      </Collapsible>
      <Collapsible
        title={t("socialMedia")}
        checkImage={checkImage}
        showCheckIcon={isForm6Filled}
      >
        <div className="collapsible-content-row">
          <div className="collapsible-content-social-row">
            <div className="collapsible-content-social-left">
              <img
                className="collapsible-social-icon"
                src={instagramIcon}
                alt="inst"
              />
              <a
                href="https://www.instagram.com"
                className="collapsible-social-content-link"
              >
                {instagramUrl}
              </a>
            </div>
            <div className="collapsible-content-social-right">
              {/*  <img src={check} alt="check" /> */}
            </div>
          </div>
        </div>

        <div className="collapsible-content-row">
          <div className="collapsible-content-social-row">
            <div className="collapsible-content-social-left">
              <img
                className="collapsible-social-icon"
                src={youtubeIcon}
                alt="youtube"
              />
              <a
                href="https://www.instagram.com"
                className="collapsible-social-content-link"
              >
                {youtubeUrl}
              </a>
            </div>
            <div className="collapsible-content-social-right">
              {/*  <img src={check} alt="check" /> */}
            </div>
          </div>
        </div>

        <div className="collapsible-content-row">
          <div className="collapsible-content-social-row">
            <div className="collapsible-content-social-left">
              <img
                className="collapsible-social-icon"
                src={facebookIcon}
                alt="facebook"
              />
              <a
                href="https://www.instagram.com"
                className="collapsible-social-content-link"
              >
                {facebookUrl}
              </a>
            </div>
            <div className="collapsible-content-social-right">
              {/*  <img src={check} alt="check" /> */}
            </div>
          </div>
        </div>

        <div className="collapsible-content-row">
          <div className="collapsible-content-social-row">
            <div className="collapsible-content-social-left">
              <img className="collapsible-social-icon" src={xIcon} alt="X" />
              <a
                href="https://www.instagram.com"
                className="collapsible-social-content-link"
              >
                {twitterUrl}
              </a>
            </div>
            <div className="collapsible-content-social-right">
              {/*  <img src={check} alt="check" /> */}
            </div>
          </div>
        </div>

        <div className="collapsible-content-row">
          <div className="collapsible-content-social-row">
            <div className="collapsible-content-social-left">
              <img
                className="collapsible-social-icon"
                src={tiktokIcon}
                alt="tiktok"
              />
              <a
                href="https://www.instagram.com"
                className="collapsible-social-content-link"
              >
                {tiktokUrl}
              </a>
            </div>
            <div className="collapsible-content-social-right">
              {/*  <img src={check} alt="check" /> */}
            </div>
          </div>
        </div>
      </Collapsible>
    </>
  );
}
