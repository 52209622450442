import React, { useState, useEffect } from "react";
import arrow from "../../../../../../assets/media/arrow.png";
import downArrow from "../../../../../../assets/media/down-arrow.png";
import "../../../../../../assets/css/InteractionCreation.css";
import "../../../../../../assets/css/ChallengeList.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiUrl, imagesUrl } from "../../../../../../config";
import { toast } from "react-toastify";
import DynamicTable from "../../../../../tables/DynamicTable";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../popup/loader";
import { getCookie, getUserTypeFromToken, isMyTokenExpired } from "../../../../auth/tokenUtils";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import '../../../../../../assets/css/ChallengeDashboard.css';
import SearchIcon from '../../../../../../assets/media/SearchIcon.svg';
import FilterButton from "../../../../../button/filterButton";
import DatePickerButton from "../../../../../button/datePickerButton";
import TotalCards from "../../../../../cards/TotalCards";
import Pagination from "../../../../../tables/Pagination";
import SortByButton from "../../../../../button/sortByButton";
import ItemsPerPageButton from "../../../../../button/itemsPerPageButton";


const CampaignList = () => {
 
    const [viewType, setViewType] = useState('GRID');
    const { t, i18n } = useTranslation("challengeDashboard");
    const token = getCookie("access_token");
    const [challenges, setChallenges] = useState();
    const [campaigns, setCampaigns] = useState();
    const [uuid, setUuid] = useState();
    const [campaignFilter, setCampaignFilter] = useState(" ");
    const [campaignsList, setCampaignsList] = useState();
    const [pageMetaData, setPageMetaData] = useState();
    const [clickedPage, setClickedPage] = useState('0');
    const [activeTab, setActiveTab] = useState(0);
    const [activeTabKey, setActiveTabKey] = useState("ALL");
    const [responseData, setResponseData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [challengesForList, setChallengesForList] = useState([]);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [challengeStartDatesWithProvider, setChallengeStartDatesWithProvider] = useState([]);
    const [prevClickedPage, setPrevClickedPage] = useState('0');
    const [filterChallengeType, setFilterChallengeType] = useState([]);
    const [filterSocialChallengeType, setFilterSocialChallengeType] = useState([]);
    const [filterSocialProvider, setFilterSocialProvider] = useState([]);
    const [filterStartDate, setFilterStartDate] = useState();
    const [filterEndDate, setFilterEndDate] = useState();
    const [sortChallengesBy, setSortChallengesBy] = useState();

    //templer, diger social providerlar gelene kadar gecici sureligine endpoint icin editlenmis degiskenler.
    const [tempSocialChallengeType, setTempSocialChallengeType] = useState();
    const [tempChallengeType, setTempChallengeType] = useState();
    const [tempSocialProvider, setTempSocialProvider] = useState();
    const [tempStartDate, setTempStartDate] = useState();
    const [tempEndDate, setTempEndDate] = useState();



    const [endpoint, setEndpoint] = useState();
    const [loaderState, setLoaderState] = useState(false);

    const [brandId, setBrandID] = useState('');

    const navigate = useNavigate();


    const [headerValues, setHeaderValues] = useState(false);


    const sortItems = [
        { id: 1, text: t("ascForName"), string: "ascForName" },
        { id: 2, text: t("descForName"), string: "descForName" },
        { id: 3, text: t("newestCreationDate"), string: "newestCreationDate" },
        { id: 4, text: t("oldestCreationDate"), string: "oldestCreationDate" },
        { id: 5, text: t("ascForType"), string: "ascForType" },
        { id: 6, text: t("descForType"), string: "descForType" },
        { id: 7, text: t("newestStartDate"), string: "newestStartDate" },
        { id: 8, text: t("oldestStartDate"), string: "oldestStartDate" },
        { id: 9, text: t("newestEndDate"), string: "newestEndDate" },
        { id: 10, text: t("oldestEndDate"), string: "oldestEndDate" },
        { id: 11, text: t("ascJoinCount"), string: "ascJoinCount" },
        { id: 12, text: t("descJoinCount"), string: "descJoinCount" },
        { id: 13, text: t("ascCampaignStatus"), string: "ascCampaignStatus" },
        { id: 14, text: t("descCampaignStatus"), string: "descCampaignStatus" },
    ];

    useEffect(() => {
        fetchListData();
    }, [sortChallengesBy, itemPerPage])
    useEffect(() => {

        localStorage.removeItem('checkboxStates');

        if (!token) {
            // Handle the case where the token is empty or undefined
            console.log("JWT token is empty or undefined");
            navigate('/sign-in-brand')
            return;
        }
        if (isMyTokenExpired(token)) {
            document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            toast.error(t("error401"));
            navigate("/sign-in-brand");
        }

    
        fetchListData();

    }, []);
    const addSpaceBeforeCapital = (str) => {
        return str.replace(/([A-Z])/g, ' $1').trim();
    };
    const getInitials = (str) => {
        const words = str.split(/(?=[A-Z])/);
        return words.map(word => word[0]).join('');
    };

    const handleSortClick = (itemId, itemText, itemString) => {
        console.log(itemString)
        setSortChallengesBy(itemString)
    };

  

    const handlePageChange = (page) => {
        console.log(page)
        fetchListData(page);
    };

    const fetchSelectedChallenge = async (campaignId) => {

        const token = getCookie("access_token");
        if (!token) {
            toast.error(t("error.missingAccessToken"));
            if (getUserTypeFromToken(getCookie("access_token"))) {
                navigate("/sign-in-brand");
            }
            else {
                navigate("/sign-in-realm");
            }


            return;
        }
        if (isMyTokenExpired(token)) {
            toast.error(t("error.sessionExpired"));
            if (getUserTypeFromToken(getCookie("access_token"))) {
                navigate("/sign-in-brand");
            }
            else {
                navigate("/sign-in-realm");
            }
            return;

        }

        let endpoint = apiUrl + `/api/v1/brand-admin/brand/campaign/${campaignId}`






        try {
            setLoaderState(true);
            const response = await axios.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = response.data;
            setLoaderState(false);
            navigate('/brand/srm/campaign/create', { state: { returnedCampaign: data } });


            // Do something with the data
        } catch (error) {
            setLoaderState(false);
            toast.error(error.message);
            console.error('Error fetching data:', error);
        }
    };

    // Tarih dizesini "dd.mm.yyyy" formatına dönüştürme fonksiyonu
    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('.').map(Number);
        return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
    };

    const handleItemsPerPageChange = (item) => {
        setClickedPage('0')
        setItemPerPage(item);
    }



    const whichTypeSort = () => {
        switch (sortChallengesBy) {
            case "ascForName":
                return ["NAME", "ASC"];
            case "descForName":
                return ["NAME", "DESC"];
            case "newestCreationDate":
                return ["CREATED", "DESC"];
            case "oldestCreationDate":
                return ["CREATED", "ASC"];
            case "ascForBrandName":
                return ["BRAND_NAME", "ASC"];
            case "descForBrandName":
                return ["BRAND_NAME", "DESC"];
            case "ascForType":
                return ["TYPE", "ASC"];
            case "descForType":
                return ["TYPE", "DESC"];
            case "newestStartDate":
                return ["START_DATE", "DESC"];
            case "oldestStartDate":
                return ["START_DATE", "ASC"];
            case "newestEndDate":
                return ["END_DATE", "DESC"];
            case "oldestEndDate":
                return ["END_DATE", "ASC"];
            case "ascJoinCount":
                return ["JOIN_COUNT", "ASC"];
            case "descJoinCount":
                return ["JOIN_COUNT", "DESC"];
            case "ascCampaignStatus":
                return ["CAMPAIGN_STATUS", "ASC"];
            case "descCampaignStatus":
                return ["CAMPAIGN_STATUS", "DESC"];
            default:
                return ["NAME", "ASC"];
        }
    };


    const fetchListData = async (page) => {
        setLoaderState(true);
        
        const filters = campaignFilter ? campaignFilter.split(',') : [];

        let filterString = "";
        if (filters[0] !== ' ') {
          filterString = filters.map(filter => `campaignTypes=${filter}`).join('&');
        }
        else{
            filterString = false;
        }
        let dateAddon = false;
        if (filterStartDate && filterEndDate) {
            dateAddon = `&startDate=${filterStartDate}&endDate=${filterEndDate}`;
        }
        console.log("date Addon : " + dateAddon)

        const url = apiUrl + `/api/v1/brand-admin/brand/campaign?campaignSortBy=${whichTypeSort()[0]}&sortDirection=${whichTypeSort()[1]}&page=${page ? page - 1 : "0"}&size=${itemPerPage ? itemPerPage : 20}${filterString ? "&" + filterString : ""}${dateAddon ? dateAddon : "" }`;

        console.log("URL =" + url)

        try {
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log(response)
            setCampaigns(response.data.responseData);
            setCurrentPage(response.data.pageMetadata.page + 1);
            setTotalPages(response.data.pageMetadata.totalPage || 0);
            const transformedData = response.data.responseData.map(item => {
                const campaign = item;

                const controlButton = () => {
                    if (!getUserTypeFromToken(getCookie("access_token")) && campaign.status === "DRAFTED") {
                        return (
                            null
                        )
                    }
                    else if (!getUserTypeFromToken(getCookie("access_token")) && campaign.status === "RELEASED") {
                        return (<button className="dynamic-table-button dashboard-table-button" onClick={null}
                        >{t("showDetails")}</button>)
                    }
                    else if (getUserTypeFromToken(getCookie("access_token")) && campaign.status === "DRAFTED") {
                        return (<button className="dynamic-table-button dashboard-table-button" onClick={() => { fetchSelectedChallenge(campaign.id) }}
                        >{t("edit")}</button>)
                    }
                    else if (getUserTypeFromToken(getCookie("access_token")) && campaign.status === "RELEASED") {
                        return (<button className="dynamic-table-button dashboard-table-button" onClick={null}
                        >{t("showDetails")}</button>)
                    }
                }

                const today = new Date();
                const endDate = new Date(campaign.endDate);
                console.log("endDate = " + endDate)
                console.log("TODAY = " + today)
                const campaignEndStatus = campaign.publishStatus === "RELEASED" && endDate < today;
                const startDateFirst = campaign.startDate.split("T")[0].split("-").reverse().join(".");
                const startDateLast = campaign.startDate.split("T")[1];

                const endDateFirst = campaign.endDate.split("T")[0].split("-").reverse().join(".");
                const endDateLast = campaign.endDate.split("T")[1];

                return {
                    control: controlButton(),
                    id: campaign.id,
                    brandName: campaign.brandName,
                    campaignImg: <img src={campaign.image ? imagesUrl + "/" + campaign.image : placeholderImg} alt={`Logo Bulunamadı`} />,
                    campaignName: campaign.name,
                    campaignDescription: campaign.description,
                    campaignType: t(campaign.campaignType),
                    startDate: <> {formatDate(startDateFirst.split("-").join("."))}  <br />  {startDateLast}</>,
                    endDate: <> {formatDate(endDateFirst.split("-").join("."))}  <br />  {endDateLast}</>,
                    participationCount: campaign.participationCount,
                    status: campaignEndStatus ? t("COMPLETED") : t(campaign.publishStatus)
                };
            });
            setCampaignsList(transformedData);

            setLoaderState(false);

            // Do something with the fetched data here
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoaderState(false);

        }




    };

    // Call the function to fetch the data






    const checkboxData = [
        { title: t("sotyCoinFolding"), campaignType: 'COIN_FOLDING' },
        { title: t("discountPercentage"), campaignType: 'DISCOUNT_PERCENTAGE' },
        { title: t("spendAndEarn"), campaignType: 'SPEND_AND_EARN' },
        { title: t("couponPercentage"), campaignType: 'COUPON_PERCENTAGE' },
        { title: t("BUY_X_PAY_Y"), campaignType: 'BUY_X_PAY_Y' },
        { title: t("BUY_MORE_PAY_LESS"), campaignType: 'BUY_MORE_PAY_LESS' },
        { title: t("FREE_SHIPMENT"), campaignType: 'FREE_SHIPMENT' },
    ];














    function formatDateStringLocal(inputDate) {
        if (inputDate) {
            const date = new Date(inputDate);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
            return formattedDate;
        }
        else {
            return ""
        }

    }



    const campaignColumns = [
        { title: t('control'), field: "control", className: "" },
        { title: t('id'), field: "id", className: "" },
        { title: t('brandName'), field: "brandName", className: "" },
        { title: t('campaignName'), field: "campaignName", className: "" },
        { title: t('campaignType'), field: "campaignType", className: "" },
        { title: t('campaignDescription'), field: "campaignDescription", className: "" },
        { title: t('campaignImage'), field: "campaignImg", className: "" },
        { title: t('startDate'), field: "startDate", className: "" },
        { title: t('endDate'), field: "endDate", className: "" },
        { title: t('participationCount'), field: "participationCount", className: "" },
        { title: t('Status'), field: "status", className: "" },
    ];















    const handleFilterChange = (e) => {

        if (!e || e.length === 0 || e.length === 4) {
            setCampaignFilter(" ");

        }
        else {
            setCampaignFilter(e.map(item => item.campaignType).join(','));

        }


    }

    useEffect(() => {

        fetchListData();
    }, [campaignFilter, filterEndDate, filterEndDate && filterStartDate])




    const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {

        if (selectedEndDate) {
            const originalStartDate = new Date(selectedStartDate);
            const originalEndDate = new Date(selectedEndDate);

            originalStartDate.setHours(0, 0, 0);
            originalEndDate.setHours(23, 59, 59);

            const formattedStartDate = `${originalStartDate.getFullYear()}-${(originalStartDate.getMonth() + 1).toString().padStart(2, '0')}-${originalStartDate.getDate().toString().padStart(2, '0')}T${originalStartDate.getHours().toString().padStart(2, '0')}:${originalStartDate.getMinutes().toString().padStart(2, '0')}:${originalStartDate.getSeconds().toString().padStart(2, '0')}`;
            const formattedEndDate = `${originalEndDate.getFullYear()}-${(originalEndDate.getMonth() + 1).toString().padStart(2, '0')}-${originalEndDate.getDate().toString().padStart(2, '0')}T${originalEndDate.getHours().toString().padStart(2, '0')}:${originalEndDate.getMinutes().toString().padStart(2, '0')}:${originalEndDate.getSeconds().toString().padStart(2, '0')}`;

            setFilterStartDate(formattedStartDate);
            setFilterEndDate(formattedEndDate);
        }
        else {
            setFilterStartDate();
            setFilterEndDate();
        }


    }



    return (
        <div className="dashboard-container">
            {loaderState && <Loader />}
            <div className="content-location-container">
                <a className="content-location-container-href" href="#">
                    <img src={arrow} alt="arrow" />
                </a>
                <div className="current">{t('campaigns')}</div>
                <img src={downArrow} className="backArrow" alt="down-arrow" />
        <a className="content-location-container-link-text" href="#">
          <div className="older">{t("campaignsList")}</div>
        </a>
            </div>
            <div className='dashboard-top-divider campaign-dashboard-divider' />
            <div className="dashboard-controls-container">
                 <FilterButton checkboxData={checkboxData} onCheckboxSelection={handleFilterChange} eventType={"campaign"} />
                <SortByButton items={sortItems} onItemSelect={handleSortClick} />
                <DatePickerButton onDateChange={handleFilterDatesChanged} />

            </div>
            <div className="dashboard-divider" />
            <div className="dashboard-challenges-container ">
                
                <div className="dashboard-challenges-wrapper">
                    <div className="dashboard-challenges-controls-container">
                        <div className="dashboard-challenges-search-container">
                            <img className="dashboard-challenges-search-icon" src={SearchIcon} alt="search" />
                            <input className="dashboard-challenges-search" type="text" placeholder="search" />
                        </div>
                        <ItemsPerPageButton onSelectItem={handleItemsPerPageChange} />

                    </div>
                </div>
                {campaignColumns && campaignsList && (
                    <div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />

                        <DynamicTable
                            columns={campaignColumns}
                            data={campaignsList}
                            tableName={t('campaignTable')}
                        />
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />

                    </div>

                )}
            </div>

        </div>
    )
};

export default CampaignList;
