import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { loadConfig } from './config';

const root = ReactDOM.createRoot(document.getElementById('root'));

loadConfig().then(() => {
  root.render(<App />);
});

// Performans ölçümleri için istediğiniz fonksiyonu geçebilirsiniz
reportWebVitals(console.log);
