import React, { useState } from "react";
import "../../../assets/css/login.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showSuccessToast, showErrorToast } from "../../toast/toast";
import privacyPolicyText from "../../text_content/Privacy.json";
import { apiUrl, authUrl } from "../../../config";
import sotyIcon from "../../../assets/media/sotylogo.svg";
import "../../../assets/i18n/i18n";
import TextPopup from "../../popup/textPopup";
import Loader from "../../popup/loader";
import CallAPI from "../../api/callApi";
import VerificationCodeInput from "./2fa/VerificationCodeInput";

const LoginForm = () => {
  const { t } = useTranslation("brandLoginForm");
  const navigate = useNavigate();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [popupTitle, setPopupTitle] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loaderState, setLoaderState] = useState(false);
  const [UUID, setUUID] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderState(true);

    try {
      const response = await CallAPI({
        method: "PUT",
        endPoint: apiUrl + "/api/v1/admin/otp",
        body: { email: email },
        noAuth: true,
      });

      console.log(response);
      setUUID(response.data);
      setLoaderState(false);
    } catch (error) {
      setLoaderState(false);
      console.error("Error during OTP request:", error);
      showErrorToast(t("errorMessages.genericError"));
    }
  };

  const adminAuthorization = async (code) => {
    setLoaderState(true);
    try {
      const adminUrlencoded = new URLSearchParams();
      adminUrlencoded.append("client_id", "admin-client");
      adminUrlencoded.append("username", email);
      adminUrlencoded.append("password", code);
      adminUrlencoded.append("grant_type", "password");
      adminUrlencoded.append(
        "client_secret",
        "Vkkjk2yPSI1c5C631wa9mWdO5cqUdF8p"
      );

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: adminUrlencoded,
        redirect: "follow",
      };

      const response = await fetch(
        authUrl + "/realms/admin/protocol/openid-connect/token",
        requestOptions
      );
      const unnecessary = await CallAPI({
        method: "PUT",
        endPoint: apiUrl + "/api/v1/admin/config",
        dontShowError: true,
      });
      const result = await response.text();
      console.log(result);
      const jsonResponse = JSON.parse(result);
      const token = jsonResponse.access_token;

      if (token) {
        document.cookie = `access_token=${token}; path=/; expires=${new Date(
          new Date().getTime() + 7 * 24 * 60 * 60 * 1000
        ).toUTCString()}`;
        setLoaderState(false);
        showSuccessToast(t("successMessages.loginSuccessful"));
        navigate("/brand-application-list");
      } else {
        setLoaderState(false);
        showErrorToast(t("errorMessages.invalidCredentials"));
      }
    } catch (error) {
      setLoaderState(false);
      console.error("Error during admin authorization:", error);
      showErrorToast(t("errorMessages.genericError"));
    }
  };

  const handlePopupClose = () => {
    setPopupVisibility(false);
  };

  const handlePopupOpen = (content) => {
    setPopupContent(content);
    setPopupTitle("soty");
    setPopupVisibility(true);
  };

  return (
    <div className="login-container">
      {loaderState && <Loader />}

      <div className="login-wrapper">
        <img src={sotyIcon} alt="soty" />
        {UUID ? (
          <form>
            <div className="go-back-button" onClick={() => setUUID("")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                viewBox="0 -960 960 960"
                width="16px"
                fill="#e8eaed"
              >
                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
              </svg>
              {t("goBack")}
            </div>
            <VerificationCodeInput
              onComplete={(code) => adminAuthorization(code)}
            />
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>{t("form.label")}</label>
            <input
              className="login-input"
              placeholder={t("form.placeholders.username")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              className="login-input display-none"
              placeholder={t("form.placeholders.password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">{t("form.buttonText")}</button>
          </form>
        )}
        <label
          className="login-form-policy-label"
          onClick={() => handlePopupOpen(privacyPolicyText.privacy_policy)}
        >
          {t("readOurPrivacyPolicy")}
        </label>
      </div>

      {isPopupVisible && (
        <TextPopup
          title={popupTitle}
          content={popupContent}
          onClose={handlePopupClose}
        />
      )}
    </div>
  );
};

export default LoginForm;
