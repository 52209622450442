import React, { useState, useEffect, useRef } from "react";
import SegmentTopSelect from "../collapsible/SegmentTopSelect";
import downArrow from "../../assets/media/down-arrow.png";

const SegmentTopButton = ({ setTopValue, topValue }) => {
  const [selectorVisible, setSelectorVisible] = useState(false);

  const buttonRef = useRef(null);
  const selectorRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        selectorRef.current &&
        !selectorRef.current.contains(event.target)
      ) {
        setSelectorVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  return (
    <div className="segment-top-button-container">
      <div
        className={`segment-top-button button ${topValue ? 'filled' : ''}`}
        ref={buttonRef}
        onClick={() => setSelectorVisible(!selectorVisible)}
      >
        <div className="segment-top-button-title">
          TOP {topValue ? `: ${topValue}` : ""}
        </div>
        <img src={downArrow} alt="V" />
      </div>
      {selectorVisible && (
        <div ref={selectorRef}>
          <SegmentTopSelect
            topValue={topValue}
            setTopValue={setTopValue}
          />
        </div>
      )}
    </div>
  );
};

export default SegmentTopButton;
