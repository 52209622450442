import React, { useEffect, useState } from "react";
import SegmentDateRangeButton from "../../button/SegmentDateRangeButton";
import SegmentAddDateFilterButton from "../../button/SegmentAddDateFilterButton";
import SegmentAddFilterButton from "../../button/SegmentAddFilterButton";
import SegmentRuleCreateButton from "../../button/SegmentRuleCreateButton";

const SegmentExpression = ({
  deleteExpression,
  items,
  category,
  localdeYapilanlariSubRuleACevir,
  minValue: initialMinValue,
  maxValue: initialMaxValue,
  handleMinValueChange,
  handleMaxValueChange,
}) => {
  // State to manage minValue and maxValue
  const [minValue, setMinValue] = useState(initialMinValue);
  const [maxValue, setMaxValue] = useState(initialMaxValue);

  // Effect to reset minValue and maxValue when category changes
  useEffect(() => {
    if(category){
      setMinValue(undefined);
      setMaxValue(undefined);
    }

  }, [category]);

  // Update minValue and maxValue when the props change
  useEffect(() => {
    setMinValue(initialMinValue);
    setMaxValue(initialMaxValue);
  }, [initialMinValue, initialMaxValue]);

  return (
    <div className="segment-rule-row">
      <div
        className="segment-row-remove-button button"
        onClick={deleteExpression} // Directly use the deleteExpression function
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="18px"
          viewBox="0 -960 960 960"
          width="18px"
          fill="#c33e5d"
        >
          <path d="M200-440v-80h560v80H200Z" />
        </svg>
      </div>

      <SegmentRuleCreateButton
        items={items}
        category={category}
        setCategory={localdeYapilanlariSubRuleACevir}
      />

      {category?.valueType === "DOUBLE" && (
        <SegmentAddFilterButton
          categoryTitle={category.title}
          minValue={minValue}
          maxValue={maxValue}
          setMinValue={(value) => {
            setMinValue(value);
            handleMinValueChange(value);
          }}
          setMaxValue={(value) => {
            setMaxValue(value);
            handleMaxValueChange(value);
          }}
        />
      )}

      {category?.valueType === "DATE" && (
        <SegmentAddDateFilterButton
          categoryTitle={category.title}
          startDate={minValue}
          endDate={maxValue}
          setStartDate={(date) => {
            setMinValue(date);
            handleMinValueChange(date);
          }}
          setEndDate={(date) => {
            setMaxValue(date);
            handleMaxValueChange(date);
          }}
        />
      )}
    </div>
  );
};

export default SegmentExpression;
