import React, { useState } from 'react';
import '../../assets/css/ViewInSotyAppButton.css';
import gridIcon from '../../assets/media/gridIcon.svg';
import listIcon from '../../assets/media/listIcon.svg';

const GridListSwitchButton = ({ onSelectView }) => {
    const [isChecked, setIsChecked] = useState('GRID');

    const handleToggle = (viewType) => {
        setIsChecked(viewType);
        onSelectView(viewType);
    };

    return (
        <div className={`grid-list-switch-container`}>
            <div className={`grid-list-switch-container-side ${isChecked === 'GRID' ? 'grid-active' : 'grid-deactive'}`} onClick={() => handleToggle('GRID')}>
                <img src={gridIcon} alt="grid" />
            </div>
            <div className={`grid-list-switch-container-side ${isChecked === 'LIST' ? 'list-active' : 'list-deactive'}`} onClick={() => handleToggle('LIST')}>
                <img src={listIcon} alt="list" />
            </div>
        </div>
    );
};

export default GridListSwitchButton;
