import React, { useState, useEffect, useRef } from "react";
import plusImg from "../../assets/media/plus.svg";
import SegmentAddDateFilterSelect from "../collapsible/SegmentAddDateFilterSelect";
import { useTranslation } from "react-i18next";
import equalsOrLessImg from "../../assets/media/equalsOrLess.png";
import DatePicker from "react-datepicker"; // Ensure you have this library installed
import "react-datepicker/dist/react-datepicker.css"; // Ensure you have this CSS

const SegmentAddDateFilterButton = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  categoryTitle,
}) => {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const { t } = useTranslation("segmentation");
  const buttonRef = useRef(null);
  const selectorRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        selectorRef.current &&
        !selectorRef.current.contains(event.target)
      ) {
        setSelectorVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  return (
    <div className="segment-add-filter-button-container">
      <div
        className={`segment-add-filter-button button ${
          startDate || endDate ? "filled" : ""
        }`}
        ref={buttonRef}
        onClick={() => setSelectorVisible(!selectorVisible)}
      >
        {!(startDate || endDate) && (
          <img
            src={plusImg}
            alt="+"
            className="segment-add-filter-button-img"
          />
        )}
        <div className="segment-add-filter-button-title">
          {categoryTitle && (
            <>
              {startDate && endDate ? (
                <>
                  {t(startDate)}
                  <img
                    src={equalsOrLessImg}
                    alt="+"
                    className="segment-add-filter-button-img"
                  />
                  {t(categoryTitle)}
                  <img
                    src={equalsOrLessImg}
                    alt="+"
                    className="segment-add-filter-button-img"
                  />
                  {t(endDate)}
                </>
              ) : (
                <>
                  {startDate && (
                    <>
                      <span className="segment-add-filter-saved-value-title">
                        {t(startDate)}
                      </span>
                      <img
                        src={equalsOrLessImg}
                        alt="+"
                        className="segment-add-filter-button-img"
                      />
                    </>
                  )}

                  {startDate || endDate ? t(categoryTitle) : t("addFilter")}

                  {endDate && (
                    <>
                      <img
                        src={equalsOrLessImg}
                        alt="+"
                        className="segment-add-filter-button-img"
                      />
                      <span className="segment-add-filter-saved-value-title">
                        {t(endDate)}
                      </span>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {selectorVisible && (
        <div ref={selectorRef}>
          <SegmentAddDateFilterSelect
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            categoryTitle={categoryTitle}
          />
        </div>
      )}
    </div>
  );
};

export default SegmentAddDateFilterButton;
