// src/utils/permissionUtils.js
import Cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";
export const userRoles = () => {
  // Extract the JWT token from cookies
  const token = Cookie.get("access_token"); // Adjust the cookie name if necessary

  if (token) {
    try {
      // Decode the JWT token to get the payload
      const decodedToken = jwtDecode(token);
      const permissions = decodedToken.realm_access.roles || []; // Adjust according to your token structure

      return permissions;
    } catch (error) {
      console.error("Failed to decode JWT token:", error);
      return false;
    }
  } else {
    console.log("No JWT token found");
    return false;
  
  }
};
