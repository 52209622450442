// tiktokApi.js
import axios from 'axios';

const TikTokApi = {
  getAccessToken: async (authorizationCode) => {
    try {
      const tokenEndpoint = 'https://open-api.tiktok.com/oauth/token';

      const response = await axios.post(tokenEndpoint, {
        app_id: '7309488478214375429',
        secret: 'nXXgKmBQaVfIh1SkSVDLZBP2l4cBJ8l6',
        code: authorizationCode,
        grant_type: 'authorization_code',
      });

      return response.data.access_token;
    } catch (error) {
      console.error('Error getting TikTok access token:', error);
      throw error;
    }
  },
};

export default TikTokApi;
