import React from "react";
import tick from "../../../../../assets/media/tickInteraction.svg"; // İkonun doğru yolunu kontrol edin

const CampaignType = ({ campaignOptions, campaignType }) => {
  return (
    <div className="challenge-creation-input-container-content-wrapper">
      {campaignOptions.map((option, index) => (
        <div
          key={index}
          className={`challenge-creation-segment-row ${
            option.disabled ? "disabled" : ""
          }`}
        >
          <div
            className={`qr-checkbox ${
              campaignType === option.type ? "checked" : ""
            }`}
            onClick={option.onClick}
          >
            <img src={tick} alt="Tick" />
          </div>

          <div className="challenge-creation-segment-content-container">
            <div className="challenge-creation-segment-title">
              {option.title}
            </div>
            <div className="challenge-creation-segment-description">
              {option.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CampaignType;
