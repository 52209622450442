import React from "react";
import GreenCheckbox from "./GreenCheckbox";
import { useTranslation } from "react-i18next";
const CampaignChannelType = ({ online, setOnline, shop, setShop }) => {
  const { t } = useTranslation("challengeCreation");
  return (
    <div className="challenge-creation-input-container-content-wrapper">
      <div className="challenge-creation-segment-row">
        <GreenCheckbox
          value={online}
          onClick={() => setOnline(!online)}
          title={t("online")}
        />

        <GreenCheckbox
          value={shop}
          onClick={() => setShop(!shop)}
          title={t("store")}
        />
      </div>
    </div>
  );
};

export default CampaignChannelType;
