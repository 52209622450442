import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CallAPI from "../../../api/callApi";
import { apiUrl } from "../../../../config";
import { useNavigate } from "react-router-dom";
const FaqForm = ({ editingFaq, setEditingFaq, onClose, onSuccess }) => {
  const { t } = useTranslation("faq");
  const [questionEn, setQuestionEn] = useState("");
  const navigate = useNavigate();
  const [questionTr, setQuestionTr] = useState("");
  const [answerEn, setAnswerEn] = useState("");
  const [answerTr, setAnswerTr] = useState("");
  const [english, setEnglish] = useState(false);
  const [turkish, setTurkish] = useState(true);

  useEffect(() => {
    if (editingFaq) {
      setQuestionEn(
        editingFaq.questionEn === "null" ? "" : editingFaq.questionEn
      );
      setQuestionTr(
        editingFaq.questionTr === "null" ? "" : editingFaq.questionTr
      );
      setAnswerEn(editingFaq.answerEn === "null" ? "" : editingFaq.answerEn);
      setAnswerTr(editingFaq.answerTr === "null" ? "" : editingFaq.answerTr);
    } else {
      setQuestionEn("");
      setQuestionTr("");
      setAnswerEn("");
      setAnswerTr("");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (turkish) {
      if (questionTr === "") {
        toast.error(t("pleaseFillTurkishQuestion"));
        return;
      }
      if (answerTr === "") {
        toast.error(t("pleaseFillTurkishAnswer"));
        return;
      }
    }
    if (english) {
      if (questionEn === "") {
        toast.error(t("pleaseFillEnglishQuestion"));
        return;
      }
      if (answerEn === "") {
        toast.error(t("pleaseFillEnglishAnswer"));
        return;
      }
    }

    const newFaq = {
      questionEn: questionEn || "null",
      questionTr: questionTr || "null",
      answerEn: answerEn || "null",
      answerTr: answerTr || "null",
    };

    console.log(editingFaq);
    console.log(newFaq);

    try {
      let response;
      if (editingFaq) {
        response = await CallAPI({
          method: "PUT",
          endPoint: `${apiUrl}/api/v1/admin/faq/${editingFaq.id}`,
          body: newFaq,
          successMessage: t("faqUpdatedSuccessfully"),
          errorMessage: t("faqUpdationError"),
          t: t,
        });
      } else {
        response = await CallAPI({
          method: "POST",
          endPoint: `${apiUrl}/api/v1/admin/faq`,
          body: newFaq,
          successMessage: t("faqCreatedSuccessfully"),
          errorMessage: t("faqCreationError"),
        });
      }

      if (response?.status === 200) {
        setQuestionEn("");
        setQuestionTr("");
        setAnswerEn("");
        setAnswerTr("");
        setEditingFaq(null);
        onSuccess();
        if (onClose) onClose(); // Popup'ı kapat
      }
    } catch (error) {
      // Handle unexpected errors if needed
      console.error(error);
    }
  };
  return (
    <form className="faq-form-container" onSubmit={handleSubmit}>
      {false && (
        <div className="faq-label-container">
          <div
            className={`custom-checkbox ${
              turkish ? "custom-checkbox-active" : ""
            }`}
            onClick={() => setTurkish(!turkish)}
          />
          <label
            onClick={() => setTurkish(!turkish)}
            className="faq-column-label"
          >
            {t("tr")}
          </label>
        </div>
      )}

      {turkish && (
        <>
          <div className="faq-form__field">
            <label className="faq-form__label">{t("question")}</label>
            <input
              type="text"
              className="faq-form__input"
              value={questionTr}
              onChange={(e) => setQuestionTr(e.target.value)}
              maxLength={255} // Limit input to 255 characters
            />
          </div>
          <div className="faq-form__field">
            <label className="faq-form__label">{t("answer")}</label>
            <textarea
              className="faq-form__textarea"
              value={answerTr}
              onChange={(e) => setAnswerTr(e.target.value)}
              maxLength={1024} // Limit input to 1024 characters
            />
          </div>
        </>
      )}
      {false && (
        <div className="faq-label-container">
          <div
            className={`custom-checkbox ${
              english ? "custom-checkbox-active" : ""
            }`}
            onClick={() => setEnglish(!english)}
          />
          <label
            onClick={() => setEnglish(!english)}
            className="faq-column-label"
          >
            {t("en")}
          </label>
        </div>
      )}

      {false && english && (
        <>
          <div className="faq-form__field">
            <label className="faq-form__label">{t("questionEn")}</label>
            <input
              type="text"
              className="faq-form__input"
              value={questionEn}
              onChange={(e) => setQuestionEn(e.target.value)}
            />
          </div>
          <div className="faq-form__field">
            <label className="faq-form__label">{t("answerEn")}</label>
            <textarea
              className="faq-form__textarea"
              value={answerEn}
              onChange={(e) => setAnswerEn(e.target.value)}
            />
          </div>
        </>
      )}
      {(turkish || english) && (
        <div className="approval-popup-buttons">
          <button
            className="approval-popup-button cancel"
            type="button"
            onClick={() => {
              setQuestionEn("");
              setQuestionTr("");
              setAnswerEn("");
              setAnswerTr("");
              setEditingFaq(null);
              if (onClose) onClose(); // Popup'ı kapat
            }}
          >
            {t("cancel")}
          </button>
          <button
            className="approval-popup-button approve-button"
            type="submit"
          >
            {editingFaq ? t("edit") : t("createFaq")}
          </button>
        </div>
      )}
    </form>
  );
};

export default FaqForm;
