import React, { useEffect, useState } from "react";
import SegmentRuleCreateButton from "../../button/SegmentRuleCreateButton";
import SegmentAddFilterButton from "../../button/SegmentAddFilterButton";
import SegmentDateRangeButton from "../../button/SegmentDateRangeButton";
import SegmentAddDateFilterButton from "../../button/SegmentAddDateFilterButton";
import deleteIcon from "../../../assets/media/trash.svg";
import { useTranslation } from "react-i18next";
import Logic from "./Logic";
import SegmentExpression from "./SegmentExpression";
import { v4 as uuidv4 } from "uuid";
const SegmentCondition = ({
  items = [],
  subRule,
  handleSetSubRule,
  returnedSegment,
  internalSelectedDateRange,
  deleteRow,
}) => {
  const [selectedLogic, setSelectedLogic] = useState("AND");

  const { t } = useTranslation("segmentation");
  
  useEffect(() => {
    if (subRule.expressions) {
      const gteExpressions = [];
      const lteExpressions = [];
      const eqExpressions = [];
      const inExpressions = [];
  
      // Classify expressions into different categories
      subRule.expressions.forEach((expression) => {
        const category = findMatchingFieldAndKeyword(
          items,
          expression.field,
          expression.value
        );
  
        if (expression.operator === "GTE") {
          gteExpressions.push({ ...expression, category });
        } else if (expression.operator === "LTE") {
          lteExpressions.push({ ...expression, category });
        } else if (expression.operator === "EQ") {
          eqExpressions.push({ ...expression, category });
        } else if (expression.operator === "IN") {
          inExpressions.push({ ...expression, category });
        }
      });
  
      // Process GTE and LTE expressions separately
      gteExpressions.forEach((expression) => {
        const minValue = expression.value[0];
        setSegmentExpressionComponents((prevComponents) => [
          ...prevComponents,
          {
            id: uuidv4(),
            category: expression.category,
            minValue,
            maxValue: undefined, // No maxValue for GTE expressions
          },
        ]);
      });
  
      lteExpressions.forEach((expression) => {
        const maxValue = expression.value[0];
        setSegmentExpressionComponents((prevComponents) => [
          ...prevComponents,
          {
            id: uuidv4(),
            category: expression.category,
            minValue: undefined, // No minValue for LTE expressions
            maxValue,
          },
        ]);
      });
  
      // Process EQ and IN expressions separately
      eqExpressions.forEach((expression) => {
        const minValue = expression.value[0];
        setSegmentExpressionComponents((prevComponents) => [
          ...prevComponents,
          {
            id: uuidv4(),
            category: expression.category,
            minValue,
            maxValue: minValue, // EQ expressions use the same value for min and max
          },
        ]);
      });
  
      inExpressions.forEach((expression) => {
        // Handle IN expressions with multiple values
        if (expression.value.length > 1) {
          const category = expression.value.map((val) => ({
            title: val,
            valueType: "TEXT",
            multiselect: true,
            keyword: val,
            field: expression.field,
            requiresDateRange: true,
          }));
          setSegmentExpressionComponents((prevComponents) => [
            ...prevComponents,
            {
              id: uuidv4(),
              category,
              minValue: undefined,
              maxValue: undefined,
            },
          ]);
        } else {
          setSegmentExpressionComponents((prevComponents) => [
            ...prevComponents,
            {
              id: uuidv4(),
              category: expression.category,
              minValue: undefined,
              maxValue: undefined,
            },
          ]);
        }
      });
  
      // Set the selected logic operator
      setSelectedLogic(subRule.combineOperator);
    }
  }, [returnedSegment]);
  
  
  

  function createGroups(expressions) {
    // GTE ve LTE operatörlerini ilgili field'lar bazında filtrele
    const lteExpressions = expressions.filter((e) => e.operator === "LTE");
    const gteExpressions = expressions.filter((e) => e.operator === "GTE");
    const remainingExpressions = expressions.filter(
      (e) => e.operator !== "LTE" && e.operator !== "GTE"
    );
  
    // Min ve Max değerlerini gruplamak için çiftleri sıralayın
    const pairedGroups = [];
    
    gteExpressions.sort((a, b) => parseFloat(a.value[0]) - parseFloat(b.value[0]));
    lteExpressions.sort((a, b) => parseFloat(a.value[0]) - parseFloat(b.value[0]));
  
    while (gteExpressions.length > 0 && lteExpressions.length > 0) {
      const gte = gteExpressions.shift();
      const lte = lteExpressions.find((lte) => parseFloat(lte.value[0]) > parseFloat(gte.value[0]));
      
      if (lte) {
        // Eğer uygun bir LTE varsa, çifti gruba ekle
        pairedGroups.push([gte, lte]);
        lteExpressions.splice(lteExpressions.indexOf(lte), 1); // LTE'yi kullandıktan sonra listeden çıkar
      } else {
        // Eğer uygun LTE yoksa, GTE tek başına kalır
        pairedGroups.push([gte]);
      }
    }
  
    // Kalan GTE ve LTE ifadelerini ayrı gruplar olarak ekle
    if (lteExpressions.length > 0) {
      lteExpressions.forEach((e) => pairedGroups.push([e]));
    }
    if (gteExpressions.length > 0) {
      gteExpressions.forEach((e) => pairedGroups.push([e]));
    }
  
    // Diğer ifadeleri ayrı ayrı ekleyin (EQ, IN, vb.)
    for (let i = 0; i < remainingExpressions.length; i++) {
      pairedGroups.push([remainingExpressions[i]]);
    }
  
    return pairedGroups;
  }
  

  function findMatchingFieldAndKeyword(items, field, value) {
    let matchedField = null;
    let matchedFieldAndKeyword = null;

    function search(itemList) {
      for (const item of itemList) {
        // Check if item matches the field
        if (item.field === field) {
          matchedField = item;
          // Check if value matches the keyword for the matched field
          if (item.keyword && value.includes(item.keyword)) {
            matchedFieldAndKeyword = item;
          }
        }

        // Recursively search in details if available
        if (item.details) {
          search(item.details);
        }
      }
    }

    search(items);

    // Return the matched item if both field and keyword match, otherwise just the field match
    return matchedFieldAndKeyword || matchedField;
  }

  /*
  
      setSegmentExpressionComponents((prevComponents) => [
          ...prevComponents,
          {
            id: uuidv4(),
            category: category,
            minValue: expression.value,
            maxValue: undefined,
          },
        ]);
      
  */

  const localdeYapilanlariSubRuleACevir = (localExpressions) => {
    let EnhancedExpressionForRequest = [];

    localExpressions.forEach((expression) => {
      if (expression.category) {
        let expressionId = expression.id || uuidv4(); // Her expression'a benzersiz bir id ver
        if (expression.minValue || expression.maxValue) {
          if (expression.minValue && expression.maxValue) {
            
          } else if (expression.minValue) {
            EnhancedExpressionForRequest.push({
              id: expressionId,
              field: expression.category.field,
              operator: "GTE",
              value: [expression.minValue],
            });
          } else if (expression.maxValue) {
            EnhancedExpressionForRequest.push({
              id: expressionId,
              field: expression.category.field,
              operator: "LTE",
              value: [expression.maxValue],
            });
          }
        } else {
          if (
            expression.category &&
            !Array.isArray(expression.category) &&
            expression.category.valueType === "TEXT"
          ) {
            EnhancedExpressionForRequest.push({
              id: expressionId,
              field: expression.category.field,
              operator: "EQ",
              value: [expression.category.keyword],
            });
          }

          if (Array.isArray(expression.category)) {
            let keywords = expression.category.map((cat) => cat.keyword);

            if (keywords.includes("true")) {
              expression.category.forEach((cat) => {
                if (cat.keyword === "true") {
                  EnhancedExpressionForRequest.push({
                    id: expressionId,
                    field: cat.field,
                    operator: "EQ",
                    value: [String(cat.valueType)],
                  });
                }
              });
            } else {
              EnhancedExpressionForRequest.push({
                id: expressionId,
                field: expression.category[0]?.field,
                operator: "IN",
                value: [keywords],
              });
            }
          }
        }
      }
    });

    // Yeni localSubRule'u oluştur
    const localSubRule = {
      id: subRule?.id || uuidv4(), // Eğer subRule varsa id'sini kullan, yoksa yeni bir id oluştur
      combineOperator: selectedLogic, // Seçilen logic AND/OR
      expressions: EnhancedExpressionForRequest,
    };

    // Parent'a localSubRule'u gönder
    handleSetSubRule(localSubRule, subRule?.id);
  };

  const [segmentExpressionComponents, setSegmentExpressionComponents] =
    useState([]);

  useEffect(() => {
    localdeYapilanlariSubRuleACevir(segmentExpressionComponents);
  }, [segmentExpressionComponents]);

  useEffect(() => {
    if (subRule) {
      // Güncellenmiş combineOperator'u içeren bir localSubRule oluştur
      const updatedSubRule = {
        ...subRule,
        combineOperator: selectedLogic,
      };
      handleSetSubRule(updatedSubRule, subRule.id);
    }
  }, [selectedLogic]);

  const addEmptyComponent = () => {
    setSegmentExpressionComponents((prevComponents) => [
      ...prevComponents,
      {
        id: uuidv4(),
        category: undefined,
        minValue: undefined,
        maxValue: undefined,
      },
    ]);
  };

  const deleteExpression = (index) => {
    setSegmentExpressionComponents((prevComponents) =>
      prevComponents.filter((_, i) => i !== index)
    );
  };

  // Setter ve metotlar
  const setCategory = (index, newCategory) => {
    setSegmentExpressionComponents((prevComponents) =>
      prevComponents.map((component, i) => 
        i === index 
          ? { ...component, category: newCategory, minValue: undefined, maxValue: undefined }
          : component
      )
    );
  };
  

  const handleMinValueChange = (index, newValue) => {
    setSegmentExpressionComponents((prevComponents) =>
      prevComponents.map((component, i) =>
        i === index ? { ...component, minValue: newValue } : component
      )
    );
  };

  const handleMaxValueChange = (index, newValue) => {
    setSegmentExpressionComponents((prevComponents) =>
      prevComponents.map((component, i) =>
        i === index ? { ...component, maxValue: newValue } : component
      )
    );
  };

  const handleDateRangeChange = (index, newRange) => {
    setSegmentExpressionComponents((prevComponents) =>
      prevComponents.map((component, i) =>
        i === index ? { ...component, selectedDateRange: newRange } : component
      )
    );
  };

  return (
    <>
      <div className="segment-condition-container">
        {/* <div className="segment-rule-row-count">{index}</div> */}
        <div className="segment-condition">
          <Logic
            hideTopLine
            hideBottomLine
            setSelectedLogic={setSelectedLogic}
            selectedLogic={selectedLogic}
          />
          <div className="segment-expression-container">
            {segmentExpressionComponents.map((component, index) => (
              <SegmentExpression
                key={component.id} // Benzersiz bir anahtar
                subRule={component.subRule}
                deleteExpression={() => {
                  deleteExpression(index);
                }}
                deleteRow={() => deleteRow(index)} // İlgili row'u silmek için indeks kullanımı
                items={items}
                category={component.category}
                localdeYapilanlariSubRuleACevir={(newCategory) =>
                  setCategory(index, newCategory)
                }
                minValue={component.minValue}
                maxValue={component.maxValue}
                handleMinValueChange={(newValue) =>
                  handleMinValueChange(index, newValue)
                }
                handleMaxValueChange={(newValue) =>
                  handleMaxValueChange(index, newValue)
                }
                selectedDateRange={component.selectedDateRange}
                handleDateRangeChange={(newRange) =>
                  handleDateRangeChange(index, newRange)
                }
              />
            ))}

            <div className="segment-rule-row">
              <svg
                className="segment-add-new-expression-svg visibility-hidden"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#c33e5d"
              >
                <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
              </svg>
              <button
                className="segment-add-new-expression button"
                onClick={() => {
                  addEmptyComponent();
                }}
              >
                {t("addNewExpression")}
              </button>
            </div>
          </div>
          <div className="margin-left-auto">
            <div
              className="segment-row-delete-button button"
              onClick={() => deleteRow(subRule.id)}
            >
              <img src={deleteIcon} alt="delete" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SegmentCondition;
