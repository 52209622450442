import React, { useState, useEffect } from "react";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import DynamicTableWithCustomInners from "../../../../tables/DynamicTableWithCustomInners";
import DatePickerButton from "../../../../button/datePickerButton";
import FilterButtonV2 from "../../../../button/FilterButtonV2";

const Logs = () => {
  const { t } = useTranslation("logs");
  const [filteredItems, setFilteredItems] = useState([]);

  const locationArray = [
    [t("settings"), "/brand/settings/"],
    [t("logs"), "/brand/settings/logs"],
  ];

  const handleInputChange = (data, setter) => {
    setter(data);
  };

  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
  };

  const [filterStartDate, setFilterStartDate] = useState(
    formatDate(oneMonthAgo)
  );
  const [filterEndDate, setFilterEndDate] = useState(formatDate(today));

  const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {
    const originalStartDate = new Date(selectedStartDate || oneMonthAgo);
    const originalEndDate = new Date(selectedEndDate || today);

    originalStartDate.setHours(0, 0, 0);
    originalEndDate.setHours(23, 59, 59);

    setFilterStartDate(formatDate(originalStartDate));
    setFilterEndDate(formatDate(originalEndDate));
  };

  const handleFilteredItemsChange = (newFilteredItems) => {
    if (JSON.stringify(newFilteredItems) !== JSON.stringify(filteredItems)) {
      setFilteredItems(newFilteredItems);
    }
  };

  const allData = [
    {
      requestDate: "2024-09-01",
      subject: "REQ12345",
      executor: "John Doe",
      actionType: "Email",
      actionArea: "Marketing",
      transactionDate: "2024-09-02",
      description: "Review campaign performance",
    },
    {
      requestDate: "2024-08-30",
      subject: "REQ67890",
      executor: "Jane Smith",
      actionType: "SMS",
      actionArea: "Sales",
      transactionDate: "2024-09-01",
      description: "Review SMS results",
    },
    {
      requestDate: "2024-08-25",
      subject: "REQ54321",
      executor: "Alex Johnson",
      actionType: "Push Notification",
      actionArea: "Customer Support",
      transactionDate: "2024-08-26",
      description: "Check support case updates",
    },
  ];

  const [actionTypes, setActionTypes] = useState([]);

  useEffect(() => {
    const uniqueActionTypes = [
      ...new Set(allData.map((item) => item.actionType)),
    ].map((type) => ({
      role: type,
      name: type,
    }));

    setActionTypes(uniqueActionTypes);
  }, [allData]);

  const filteredData =
    filteredItems.length > 0
      ? allData.filter((item) => filteredItems.includes(item.actionType))
      : allData;

  const columns = [
    { title: t("requestDate"), field: "requestDate", className: "column-name" },
    { title: t("subject"), field: "subject", className: "column-id" },
    { title: t("executor"), field: "executor", className: "column-id" },
    { title: t("actionType"), field: "actionType", className: "column-id" },
    { title: t("actionArea"), field: "actionArea", className: "column-date" },
    {
      title: t("transactionDate"),
      field: "transactionDate",
      className: "column-status",
    },
    {
      title: t("description"),
      field: "description",
      className: "column-button",
    },
  ];

  return (
    <div className="dashboard-container">
      <NavigationBar locationArray={locationArray} />
      <DynamicTableWithCustomInners
        customButtonDisabled
        columns={columns}
        data={filteredData}
        itemPerPageTitle={t("logPerPage")}
        tableName={t("logs")}
        handleInputChange={handleInputChange}
      >
        <FilterButtonV2
          filterItems={actionTypes}
          dialogTitle={t("actionTypes") + ":"}
          onFilteredItemsChange={handleFilteredItemsChange}
          checkedItems={filteredItems}
        />
        <DatePickerButton onDateChange={handleFilterDatesChanged} />
        <button
          className="static-button-container create-segment-button"
          onClick={() => window.location.reload()}
        >
          {t("update")}
        </button>
      </DynamicTableWithCustomInners>
    </div>
  );
};

export default Logs;
