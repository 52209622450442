import React, { useState, useEffect } from "react";
import equalsOrLessImg from "../../assets/media/equalsOrLess.png";
import { useTranslation } from "react-i18next";

const SegmentAddFilterSelect = ({
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
  categoryTitle,
  disableMinInput,
  disableMaxInput,
}) => {
  const [error, setError] = useState("");
  const { t } = useTranslation("segmentation");

  useEffect(() => {
    // Clear error if input values are valid
    if (minValue && maxValue && minValue > maxValue) {
      setError("Min value cannot be greater than max value");
    } else {
      setError("");
    }
  }, [minValue, maxValue]);

  const handleMinValueChange = (e) => {
    const value = e.target.value;
    if (value) {
      setMaxValue(null); // Clear maxValue when minValue is set
    }
    setMinValue(value);
  };

  const handleMaxValueChange = (e) => {
    const value = e.target.value;
    if (value) {
      setMinValue(null); // Clear minValue when maxValue is set
    }
    setMaxValue(value);
  };

  return (
    <div className="segment-add-filter-select">
      <div className="segment-add-filter-select-input-container">
        <input
       
          type="number"
          placeholder="-"
          value={minValue || ""}
          onChange={handleMinValueChange}
          onBlur={handleMinValueChange}
          disabled={disableMinInput}
          className={maxValue ?  "display-none" : ""}
        />
        <img
          src={equalsOrLessImg}
          alt="<="
          className={`segment-add-filter-equation-icon ${maxValue ? "display-none" : ""}`}

        />
        <div className="segment-add-filter-title">{t(categoryTitle) || ""}</div>
        <img
          src={equalsOrLessImg}
          alt="<="
          className={`segment-add-filter-equation-icon ${minValue ? "display-none" : ""}`}
        />
        <input
          type="number"
          placeholder="-"
          value={maxValue || ""}
          onChange={handleMaxValueChange}
          onBlur={handleMaxValueChange}
          disabled={disableMaxInput}
          className={minValue ?  "display-none" : ""}
        />
      </div>
    </div>
  );
};

export default SegmentAddFilterSelect;
