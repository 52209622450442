import React, { useState, useRef, useEffect } from 'react';
import downArrow from "../../assets/media/down-arrow.png";
import axios from 'axios';
import { toast } from "react-toastify";
import leftArrow from "../../assets/media/Left.svg";
import notificationIcon from "../../assets/media/Notifications.png";
import MedalIcon from "../../assets/media/Medal.svg";
import InstagramIcon from "../../assets/media/instagramMobile.svg";
import FacebookIcon from "../../assets/media/facebook.svg";
import TwitterIcon from "../../assets/media/x-twitter.svg";
import YoutubeIcon from "../../assets/media/youtube.svg";
import TiktokIcon from "../../assets/media/tiktok.svg";
import InfoIcon from "../../assets/media/Info.svg";
import arrow from "../../assets/media/arrow.png";
import placeholderImg from "../../assets/media/placeholder.png";

import "../../assets/css/ChallengePreview.css";
import "../../assets/css/PhoneView.css";
import SurveyType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/SurveyType.js';
import InteractionType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/InteractionType.js';
import tagIcon from "../../assets/media/tagIcon.svg";
import hashtagIcon from "../../assets/media/hashtagIcon.svg";
import ContentType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/ContentType.js';
import Approved from '../pages/brand_admin/popup_page/PopupPage.js';
import Slider from 'react-slick';
import { useLocation, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { imagesUrl } from '../../config.js';
import { getCookie } from '../pages/auth/tokenUtils.js';
import { isMyTokenExpired } from '../pages/auth/tokenUtils.js';
import { apiUrl } from "../../config.js";
import { useTranslation } from "react-i18next";
import paperIcon from "../../assets/media/paper.svg";


const PhoneViewJoin = ({ returnedApiData, imagesUrl, formatDateString, payCount, buyCount, freeShipping, discounts, name, logo, prize, description, startDate, endDate, challengeType, socialProvider, socialProviderContentCreationType, foldingString, percentString, percentCouponString, spendToEarnSpend, spendToEarnEarn }) => {
    const { t, i18n } = useTranslation("challengeCreation");
    const [challengeTypeImg, setChallengeTypeImg] = useState();
    const [challengeTypeText, setChallengeTypeText] = useState();
    const [challengeTypeDescText, setChallengeTypeDescText] = useState();
    const [challengeTypeToView, setChallengeTypeToView] = useState();
    const [defaultBrandLogo, setDefaultBrandLogo] = useState();
    const brandLogoFromLocalStorage = localStorage.getItem('brandLogo');
    const navigate = useNavigate;
    function formatSocialProvider(socialProvider) {
        // Büyük harfleri küçük harflere çevirme ve ilk harfi büyük yapma
        const formattedSocialProvider = socialProvider.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
        return formattedSocialProvider;
    }

    useEffect(() => {
        if (socialProvider) {
            let initialChallengeTypeImg;

            switch (socialProvider) {
                case "INSTAGRAM":
                    initialChallengeTypeImg = InstagramIcon;
                    break;
                case "FACEBOOK":
                    initialChallengeTypeImg = FacebookIcon;
                    break;
                case "TWITTER":
                    initialChallengeTypeImg = TwitterIcon;
                    break;
                case "YOUTUBE":
                    initialChallengeTypeImg = YoutubeIcon;
                    break;
                case "TIKTOK":
                    initialChallengeTypeImg = TiktokIcon;
                    break;
                default:
                    initialChallengeTypeImg = paperIcon;
            }
            setChallengeTypeImg(initialChallengeTypeImg);
        }
        else {
            setChallengeTypeImg(paperIcon);
        }


    }, [socialProvider])

    const challengeTypeFinder = () => {
        if (returnedApiData) {
            const { responseData } = returnedApiData;

            const socialProviderIcons = {
                FACEBOOK: FacebookIcon,
                INSTAGRAM: InstagramIcon,
                TWITTER: TwitterIcon,
                TIKTOK: TiktokIcon,
                YOUTUBE: YoutubeIcon,
            };

            if (responseData.challengeType === "SURVEY") {
                setChallengeTypeToView("SURVEY");
                setChallengeTypeText("Anket Doldur");
                setChallengeTypeDescText("Anket Doldur");
                setChallengeTypeImg(paperIcon);
            } else if (responseData.socialChallengeType === "INTERACTION" || responseData.socialChallengeType === "CONTENT_CREATION") {
                const socialProviderIcon = socialProviderIcons[responseData.socialProvider];
                setChallengeTypeImg(socialProviderIcon);

                if (responseData.socialChallengeType === "INTERACTION") {
                    setChallengeTypeDescText(`Marka ${formatSocialProvider(responseData.socialProvider)} Gönderisi Etkileşimleri`);
                    setChallengeTypeText("Etkileşim Alma");
                } else if (responseData.socialChallengeType === "CONTENT_CREATION") {
                    setChallengeTypeDescText(`${formatSocialProvider(responseData.socialProvider)} ${formatSocialProvider(responseData.socialProviderContentCreationType.contentCreationType)} Paylaşımı`);
                    setChallengeTypeText("İçerik Oluşturma");
                }


            } else {

            }
        }


    };

    useEffect(() => {
        challengeTypeFinder();
        if (!brandLogoFromLocalStorage) {
            fetchData();
        }


    }, []);

    const fetchData = async () => {
        console.log("logo eklendi");
        const token = getCookie("access_token");

        if (!token) {
            console.error("Access token is missing.");
            navigate("/sign-in-brand");
            toast.error(t("error.missingAccessToken"));
            return;
        }
        if (isMyTokenExpired(token)) {
            toast.error(
                t("error.sessionExpired")
            );
            navigate("/sign-in-brand");
        }



        const endpoint = apiUrl + `/api/v1/brand-admin/brand`
        try {
            const response = await axios.get(endpoint, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json', // İsteğin content type'ını belirtmek iyi bir uygulama pratigidir
                },
                // Diğer istek yapılandırmalarını buraya ekleyebilirsiniz
            });

            console.log(response)
            setDefaultBrandLogo(response.data.responseData.logo)
            localStorage.setItem('brandLogo', response.data.responseData.logo);
        } catch (error) {
            // Hata durumunda hata mesajını yakalayabilirsiniz
            toast.error(t('error.fetchingData') + "(" + error.response.data.metadata.message + ")");
        }
    };

    function formatDateStringLocal(inputDate) {
        if (challengeType === t("Campaign")) {
            const locale = localStorage.getItem('language') || 'tr-TR'; // Use 'en-US' if localStorage.getItem('language') returns null or undefined
            const options = { day: '2-digit', month: 'long' };

            if (inputDate) {
                console.log(inputDate);
                const date = new Date(inputDate);
                const formattedDate = date.toLocaleDateString(locale, options);
                return formattedDate;
            } else {
                return "";
            }
        }
        else if (inputDate) {
            const date = new Date(inputDate);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
            return formattedDate;
        }
        else {
            return ""
        }
    }

    return (
        <div className="phone-view">
            <div className='phone-view-interaction-container'>
                <div className='phone-view-header'>
                    <img src={leftArrow} alt='<' />

                    {challengeType === t("Campaign") ? (
                        <span>
                            {(returnedApiData?.responseData.name || name || t("enterCampaignName")).slice(0, 20)}
                            {((returnedApiData?.responseData.name || name || t("enterCampaignName")).length > 20 ? '...' : '')}
                        </span>
                    ) : (
                        <span>
                            {(returnedApiData?.responseData.name || name || t("enterChallengeName")).slice(0, 20)}
                            {((returnedApiData?.responseData.name || name || t("enterChallengeName")).length > 20 ? '...' : '')}
                        </span>
                    )}

                    <img src={notificationIcon} alt='notify' />
                </div>


                <div
                    className='phone-view-interaction-content-container'
                >
                    <div className='phone-view-interaction-content-container-header'

                        style={{
                            backgroundImage: `url(${returnedApiData ? imagesUrl + '/' +
                                (returnedApiData.responseData.logo ? returnedApiData.responseData.logo :
                                    (returnedApiData.responseData.image ? returnedApiData.responseData.image : logo)) : placeholderImg})`
                        }}

                    >
                        <div className='phone-view-interaction-content-container-header-column-left'>
                            {challengeType === t("Campaign") ?

                                <div className='phone-view-interaction-content-container-header-title-container green-background'>
                                    <label>{t("startDate")} : {startDate && formatDateStringLocal(startDate)}</label>
                                </div>


                                :
                                <div className='phone-view-interaction-content-container-header-title-container'>
                                    <label>{challengeTypeText || challengeType}</label>
                                    <img src={MedalIcon} alt='medal' />
                                </div>
                            }

                            <div className='phone-view-interaction-content-container-header-brand-logo-wrapper'>
                                <img className='phone-view-interaction-content-container-header-brand-logo' src={imagesUrl + '/' + brandLogoFromLocalStorage}

                                    alt='brand-logo' />
                            </div>
                        </div>
                        {(prize || returnedApiData?.responseData.prize) &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    {returnedApiData?.responseData.prize || prize}<span>{t("sotyCoin")}</span>
                                </div>

                            </div>
                        }
                        {challengeType === t("Campaign") && returnedApiData && returnedApiData.responseData.foldingFactor &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{"X" + returnedApiData.responseData.foldingFactor + " Coin"}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && returnedApiData && returnedApiData.responseData.campaignType === "FREE_SHIPMENT" &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{t("freeShipping")}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && returnedApiData && returnedApiData.responseData.discounts && discounts?.findIndex(discount => discount !== "0") !== -1 &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>

                                    <span>
                                        {
                                            (localStorage.getItem('language') === "tr-TR" ?
                                                (returnedApiData.responseData.discounts.findIndex(discount => discount !== "0") !== -1 ?
                                                    ((returnedApiData.responseData.discounts.findIndex(discount => discount !== "0") + 2) + ". Ürüne %" + returnedApiData.responseData.discounts.find(discount => discount !== "0") + " İndirim") :
                                                    null
                                                ) :
                                                (returnedApiData.responseData.discounts.find(discount => discount !== "0") !== undefined ?
                                                    (returnedApiData.responseData.discounts.find(discount => discount !== "0") + "% Discount on the " + (returnedApiData.responseData.discounts.findIndex(discount => discount !== "0") + 2) + ". Product") :
                                                    null
                                                )
                                            )
                                        }
                                    </span>

                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && returnedApiData && returnedApiData.responseData.payCount &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{localStorage.getItem('language') === "tr-TR" ?
                                        returnedApiData.responseData.buyCount + " " + t("buy") + " " + returnedApiData.responseData.payCount + " " + t("pay")
                                        :
                                        t("buy") + " " + returnedApiData.responseData.buyCount + " " + t("pay") + " " + returnedApiData.responseData.payCount
                                    }</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && returnedApiData && returnedApiData.responseData.discountPercentage &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{"%" + returnedApiData.responseData.discountPercentage + " " + t("discount")}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && returnedApiData && returnedApiData.responseData.couponRatio &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{"%" + returnedApiData.responseData.couponRatio + " " + t("coupon")}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && returnedApiData && returnedApiData.responseData.earn &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{returnedApiData.responseData.earn + " " + t("SotyCoinEarn")}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && !returnedApiData && foldingString &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{foldingString}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && !returnedApiData && percentString &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{percentString + " " + t("discount")}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && !returnedApiData && discounts && discounts?.findIndex(discount => discount !== "0") !== -1 &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>

                                    <span>
                                        {
                                            (localStorage.getItem('language') === "tr-TR" ?
                                                (discounts.findIndex(discount => discount !== "0") !== -1 ?
                                                    ((discounts.findIndex(discount => discount !== "0") + 2) + ". Ürüne %" + discounts.find(discount => discount !== "0") + " İndirim") :
                                                    null
                                                ) :
                                                (discounts.find(discount => discount !== "0") !== undefined ?
                                                    (discounts.find(discount => discount !== "0") + "% Discount on the " + (discounts.findIndex(discount => discount !== "0") + 2) + ". Product") :
                                                    null
                                                )
                                            )
                                        }
                                    </span>

                                </div>
                            </div>
                        }

                        {challengeType === t("Campaign") && !returnedApiData && freeShipping &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{t("freeShipping")}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && !returnedApiData && percentCouponString &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{percentCouponString + " " + t("coupon")}</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && !returnedApiData && (buyCount && payCount) &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{localStorage.getItem('language') === "tr-TR" ?
                                        buyCount + " " + t("buy") + " " + payCount + " " + t("pay")
                                        :
                                        t("buy") + " " + buyCount + " " + t("pay") + " " + payCount
                                    }</span>
                                </div>
                            </div>
                        }
                        {challengeType === t("Campaign") && !returnedApiData && (spendToEarnEarn && spendToEarnSpend) &&
                            <div className='phone-view-interaction-content-container-header-column-right'>
                                <div className='phone-view-interaction-content-container-header-soty-coin-wrapper'>
                                    <span>{spendToEarnEarn && spendToEarnEarn + " " + t("SotyCoinEarn")}</span>
                                </div>
                            </div>
                        }

                    </div>

                    {challengeType === t("Campaign") ?
                        <>
                            <div className='phone-view-campaign-title'>
                                {foldingString && foldingString + ":"}
                                {percentString && percentString + " " + t("discount") + ":"}
                                {percentCouponString && percentCouponString + " " + t("coupon") + ":"}
                                {spendToEarnEarn && spendToEarnEarn + " " + t("SotyCoinEarn") + ":"}
                                {freeShipping && " " + t("freeShipping") + ":"}
                                {returnedApiData?.responseData?.campaignType === "FREE_SHIPMENT" && " " + t("freeShipping") + ":"}
                                {(buyCount && payCount) && buyCount + " " + t("buy") + " " + payCount + " " + t("pay") + ":"}
                                {(returnedApiData?.responseData?.buyCount && returnedApiData?.responseData?.payCount) && returnedApiData?.responseData?.buyCount + " " + t("buy") + " " + returnedApiData?.responseData?.payCount + " " + t("pay") + ":"}
                                {challengeType === t("Campaign") && !returnedApiData && discounts && discounts?.findIndex(discount => discount !== "0") !== -1 &&


                                    (localStorage.getItem('language') === "tr-TR" ?
                                        (discounts.findIndex(discount => discount !== "0") !== -1 ?
                                            ((discounts.findIndex(discount => discount !== "0") + 2) + ". Ürüne %" + discounts.find(discount => discount !== "0") + " İndirim") :
                                            null
                                        ) :
                                        (discounts.find(discount => discount !== "0") !== undefined ?
                                            (discounts.find(discount => discount !== "0") + "% Discount on the " + (discounts.findIndex(discount => discount !== "0") + 2) + ". Product") :
                                            null
                                        )
                                    )

                                }
                                {challengeType === t("Campaign") && returnedApiData && returnedApiData.responseData.discounts && discounts?.findIndex(discount => discount !== "0") !== -1 &&


                                    (localStorage.getItem('language') === "tr-TR" ?
                                        (returnedApiData.responseData.discounts.findIndex(discount => discount !== "0") !== -1 ?
                                            ((returnedApiData.responseData.discounts.findIndex(discount => discount !== "0") + 2) + ". Ürüne %" + returnedApiData.responseData.discounts.find(discount => discount !== "0") + " İndirim") :
                                            null
                                        ) :
                                        (returnedApiData.responseData.discounts.find(discount => discount !== "0") !== undefined ?
                                            (returnedApiData.responseData.discounts.find(discount => discount !== "0") + "% Discount on the " + (returnedApiData.responseData.discounts.findIndex(discount => discount !== "0") + 2) + ". Product") :
                                            null
                                        )
                                    )

                                }

                            </div>
                            <div className='phone-view-campaign-description'>
                                {returnedApiData?.responseData.description || description || t("enterCampaignDescription")}
                            </div>
                        </>

                        :
                        <div className='phone-view-interaction-content-description'>
                            {returnedApiData?.responseData.description || description || t("enterChallengeDescription")}
                        </div>

                    }
                    {!challengeType === t("Campaign") &&
                        <div className='phone-view-interaction-definition-container'>
                            <img src={challengeTypeImg || challengeTypeImg} alt='socialMedia' />
                            <div className='phone-view-interaction-definition-wrapper'>
                                <div className='phone-view-interaction-definition-title'>
                                    {challengeTypeDescText || challengeType}
                                </div>
                                <div className='phone-view-interaction-definition-end-date'>
                                    {t("endDate")}:
                                    <span>
                                        {" " + (returnedApiData ? formatDateString(returnedApiData.responseData.endDate) : formatDateStringLocal(endDate))}

                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {!(challengeType === t("Campaign")) &&
                        <div className='phone-view-interaction-qr-container'>
                            <div className='phone-view-interaction-qr-title-container'>
                                <img src={InfoIcon} alt='info' />
                                <div className='phone-view-interaction-qr-title'>
                                    {t("qrCodeRequired")}
                                </div>
                            </div>
                            <div className='phone-view-interaction-qr-description'>
                                {t("qrCodeDescription")}
                            </div>
                        </div>
                    }

                    {returnedApiData?.responseData.socialChallengeType === "INTERACTION" || challengeType === t("interaction") && (
                        <div className='phone-view-url-list-container'>
                            <div className='phone-view-url-list-title'>
                                {t("links")}
                            </div>

                            <div className='phone-view-url-list-button'>
                                {t("viewThePost")}
                            </div>
                        </div>
                    )}

                    <div className='phone-view-join-button'>{t("join")}</div>
                    {/* Your content goes here */}
                </div>

            </div>


        </div>
    );
}

export default PhoneViewJoin;
