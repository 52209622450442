import React from "react";
import "../../../assets/css/Segment.css";
import deleteIcon from "../../../assets/media/Icon material-delete.svg";
import SegmentTopButton from "../../button/SegmentTopButton";
import { useTranslation } from "react-i18next";
const SegmentRuleContainer = ({ children, topValue, setTopValue }) => {
  const { t } = useTranslation("segmentation");

  return (
    <div className="segment-rule-container">
      <div className="segment-rule-container-header">
      
        <SegmentTopButton
          topValue={topValue}
          setTopValue={setTopValue}
        />
      </div>
      {children && children}

    </div>
  );
};

export default SegmentRuleContainer;
