import React from "react";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import "../../../../../assets/css/Packages.css";
import tickIcon from "../../../../../assets/media/correct.png";
const Packages = () => {
  const { t } = useTranslation("packages");
  const locationArray = [[t("packages"), "/brand/settings/packages"]];

  // Example pricing plans
  const pricingPlans = [
    {
      title: t("basicPlan"),
      price: "$19/mo",
      features: [t("feature1"), t("feature2"), t("feature3")],
      buttonText: t("select"),
      isHighlighted: false,
    },
    {
      title: t("standardPlan"),
      price: "$39/mo",
      features: [t("feature1"), t("feature2"), t("feature3"), t("feature4")],
      buttonText: t("select"),
      isHighlighted: true, // Highlighted plan
    },
    {
      title: t("premiumPlan"),
      price: "$59/mo",
      features: [
        t("feature1"),
        t("feature2"),
        t("feature3"),
        t("feature4"),
        t("feature5"),
      ],
      buttonText: t("select"),
      isHighlighted: false,
    },
  ];

  return (
    <div className="dashboard-container">
      <NavigationBar locationArray={locationArray} />
      <h1 className="pricing-title">
        {t("We've got a plan")}
        <br />
        {t("that's perfect for you")}
      </h1>

      <div className="pricing-container">
        {pricingPlans.map((plan, index) => (
          <div
            className={`pricing-card ${
              plan.isHighlighted ? "highlighted" : ""
            }`}
            key={index}
          >
            <h2 className="card-title">{plan.title}</h2>
            <p className="price">{plan.price}</p>
            <div className="plan-features">
              {plan.features.map((feature, index) => (
                <div className="plan-feature" key={index}>
                  <img src={tickIcon} />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
            <button className="select-button">{plan.buttonText}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Packages;
