import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const SegmentDateRangeSelect = ({ selectedValue, setSelectedValue }) => {
  const { t } = useTranslation("segmentation");
  const values = [
    "1",
    "7",
    "30",
    "60",
    "90",
    "180",
    "365",
    "730"
  ];

  const handleValueClick = (value) => {
    setSelectedValue(value);
  };

  return (
    <div className="segment-top-select-container">
      <div className="segment-top-value-container">
        {values.map((value) => (
          <div
            key={value}
            className={`segment-top-value ${
              value === selectedValue ? "segment-top-value-selected" : ""
            }`}
            onClick={() => handleValueClick(value)}
          >
            {t(value)}
          </div>
        ))}
        <button
          className="segment-top-value-delete-button button"
          onClick={() => {setSelectedValue()}}
        >
          {t("delete")}
        </button>
      </div>
    </div>
  );
};

export default SegmentDateRangeSelect;
