import React from "react";

export const CampaignNotificationTimeSelector = ({
  selectedTimeOption,
  setSelectedTimeOption,
  t,
  tickIcon,
  infoIconEmpty,
  campaignParticipationCountDesc,
  timeOptions = [
    "BEFORE_1_HOUR",
    "BEFORE_2_HOUR",
    "BEFORE_3_HOUR",
    "BEFORE_6_HOUR",
    "BEFORE_12_HOUR",
    "BEFORE_24_HOUR",
    "NOT_NOTIFY",
  ],
}) => {
  return (
    <div className="coin-folding-container">
      <div className="coin-folding-input-container">
        <div className="coin-folding-input-wrapper">
          {timeOptions.map((timeOption) => (
            <div
              key={timeOption}
              className={`coin-folding-input ${
                selectedTimeOption === timeOption && "coin-folding-input-active"
              }`}
              onClick={() => {
                setSelectedTimeOption(timeOption);
              }}
            >
              {t(timeOption)}
              {selectedTimeOption === timeOption && (
                <img src={tickIcon} alt="tick" />
              )}
            </div>
          ))}
        </div>
        {campaignParticipationCountDesc && (
          <div className="challenge-creation-date-description-row">
            <img
              className="challenge-creation-date-description-icon"
              src={infoIconEmpty}
              alt="icon"
            />
            <div className="challenge-creation-date-description">
              {t(campaignParticipationCountDesc)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
