import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";
import "../../../../../assets/css/BrandApplicationDetail.css";
import arrow from "../../../../../assets/media/arrow.png";
import downArrow from "../../../../../assets/media/down-arrow.png";
import DetailListTable from "../../../../list/DetailListTable";
import { apiUrl } from "../../../../../config.js";
import { toast } from "react-toastify";
import { isMyTokenExpired } from "../../../auth/tokenUtils";
import { useTranslation } from "react-i18next";
import Loader from "../../../../popup/loader.js";

const BrandApplicationDetail = () => {
  const { t, i18n } = useTranslation("brandApplicationList");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const initialCurrentPage = state ? state.currentPage : 1;
  const totalPages = state ? state.totalPages : 1;
  console.log(state);

  const [newList, setNewList] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const [loaderState, setLoaderState] = useState(false);

  const staticDataForReadyToApproved = [
    {
      id: 1,
      companyName: "Company1",
      brandName: "Brand1",
      url: "https://example1.com",
      requestId: "123",
      created: "01-03-2023",
      status: "PENDING",
    },
    {
      id: 2,
      companyName: "Company2",
      brandName: "Brand2",
      url: "https://example2.com",
      requestId: "124",
      created: "01-01-2023",
      status: "PENDING",
    },
    {
      id: 3,
      companyName: "Company3",
      brandName: "Brand3",
      url: "https://example3.com",
      requestId: "125",
      created: "1-02-2021",
      status: "PENDING",
    },
  ];
  const fetchData = async () => {
    try {
      setLoaderState(true);
      const token = getCookie("access_token");
      if (!token) {
        console.error("Access token is missing.");
        setLoaderState(false);

        navigate("/sign-in-realm");
        return;
      }
      if (isMyTokenExpired(token)) {
        toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
        setLoaderState(false);

        navigate("/sign-in-realm");
      }

      setLoaderState(true);
      const response = await fetch(

        `${apiUrl}/api/v1/admin/application?page=${currentPage - 1}&size=1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {

        console.error("Error fetching data. Status: ", response.status);
        return;
      }

      const responseData = await response.json();

      // Check if responseData.responseData is an array
      if (!Array.isArray(responseData.responseData)) {
        console.error("Response data is not an array.");
        return;
      }
      console.log("response", responseData)
      // Initialize the lists
      const newresponsedList = responseData.responseData
      let filteredList;
      newresponsedList.map((item) => ({
        id: item.id,
        companyName: item.companyName,
        brandName: item.brandName,
        url: item.url,
        requestId: responseData.metadata.requestId,
        created: item.created,
        phoneNumber: item.phoneNumber,
        status: item.status,
      }))
        .filter(
          (item) =>
            item.status === location.state.requestGroupTitleText.toUpperCase()
        );
      if (location.state.requestGroupTitleText === t("pending")) {
        // If the title is "Ready To Approved," use static data
        setNewList(staticDataForReadyToApproved);

      } else if (location.state.requestGroupTitleText === t("newRequest")) {
        // If the title is "newRequest," filter items with status "PENDING"
        filteredList = responseData.responseData
          .map((item) => ({
            id: item.id,
            companyName: item.companyName,
            brandName: item.brandName,
            url: item.url,
            requestId: responseData.metadata.requestId,
            created: item.created,
            phoneNumber: item.phoneNumber,
            status: item.status,
          }))
          .filter((item) => item.status === "PENDING");
        setNewList(filteredList);

      } else if (location.state.requestGroupTitleText === t("approved")) {
        // If the title is "approved," filter items with status "APPROVED"
        filteredList = responseData.responseData
          .map((item) => ({
            id: item.id,
            companyName: item.companyName,
            brandName: item.brandName,
            url: item.url,
            requestId: responseData.metadata.requestId,
            created: item.created,
            phoneNumber: item.phoneNumber,
            status: item.status,
          }))
          .filter((item) => item.status === "APPROVED");
        setNewList(filteredList);

      }
      else {
        // Aksi halde fetchData() ile verileri çek
        setNewList(newresponsedList);

      }
      console.log(responseData.responseData);
      setLoaderState(false);


      // Update the state with the lists

    } catch (error) {
      setLoaderState(false);

      console.error("HATA:", error);
    }
  };

  useEffect(() => {
    setLoaderState(true);
    fetchData();

  }, [currentPage]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const countListItems = (list) => {
    return list.length;
  };





  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };


  return (<>
    {loaderState && <Loader />}
    <div className="dashboard-container">


      <div className="content-location-container">
        <Link
          to="../brand-application-list"
          className="content-location-container-href"
        >
          <img src={arrow} />
        </Link>
        <Link
          to="../brand-application-list"
          className="content-location-container-link-text"
        >
          <div className="older">{t("brandApplications")}</div>
        </Link>
        <img src={downArrow} className="backArrow" />
        <div className="current">{t("dashboard")}</div>
      </div>
      <hr />
      <div className="detail-column-container">
        <div className="detail-column">
          <div className="main-container">
            <div className="main-container-header">
              <div className="main-container-header-column">
                <div className="request-group">
                  <div
                    className={`request-group-title ${location.state.requestGroupTitleText ===
                      "Ready To Approved"
                      ? "approved-group"
                      : ""
                      }`}
                  >
                    {location.state.requestGroupTitleText || "Error"}
                  </div>
                  <p>{countListItems(newList) || "0"}</p>
                </div>
                <div className="request-link">
                  {location.state.requestLinkTextContent || "Error"}
                </div>
              </div>
              <div className="request-icon">
                <img
                  src={location.state.requestIconSrcValue || "Error"}
                  className={`request-icon-img ${location.state.requestGroupTitleText === "approved"
                    ? "approved-icon"
                    : ""
                    }`}
                  alt="Request Icon"
                />
              </div>
            </div>
            <hr />
            <div className="tables">
              <div className="table-border">
                <DetailListTable List={newList} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img className="pagination-prev-icon" src={downArrow} alt="prev" />
        </button>
        <span>{t("page")} {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img className="pagination-next-icon" src={downArrow} alt="next" />
        </button>
      </div>
    </div>
  </>

  );
};

export default BrandApplicationDetail;
