import React from "react";
import plus from '../../assets/media/plus.svg';
import campaignIcon from '../../assets/media/Icon simple-campaignmonitor.svg';
import corporateCampaignIcon from '../../assets/media/corporateCampaign.svg';
import { useNavigate } from "react-router-dom";
import '../../assets/css/ViewInSotyAppButton.css';
import { useTranslation } from "react-i18next";

const CreateCampaignButton = ({ corporateCampaign }) => {
    const navigate = useNavigate();
    const { t } = useTranslation("challengeCreation");

    return (
        corporateCampaign ? (
            <div className="static-button-container create-campaign-button create-corporate-campaign-button" onClick={() => { navigate('/brand/srm/corporate-campaign/create') }}>
                <img className="create-campaign-button-img" src={corporateCampaignIcon} alt="+" />
                {t("createCampaignForCorporations")}
            </div>
        ) : (
            <div className="static-button-container create-campaign-button" onClick={() => { navigate('/brand/srm/campaign/create') }}>
                <img className="create-campaign-button-img" src={campaignIcon} alt="+" />
                {t("createCampaign")}
            </div>
        )
    );
};

export default CreateCampaignButton;
