import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import closeIcon from "../../assets/media/close.png";

const DynamicPopupWithChildren = ({
  title,
  isCondition,
  cancelText,
  acceptText,
  onAccept,
  isNegative,
  children,
  onCancel,
  buttons,
  maxWidth,
  Width,
}) => {
  const { t } = useTranslation("segmentation");
  const popupRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const backgroundElement = document.querySelector(
        ".dynamic-popup-blurred-background"
      );
      backgroundElement.style.height = `${document.documentElement.scrollHeight}px`;
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCancel]);

  useEffect(() => {
    // Scroll to popup position when it opens
    const scrollToPopup = () => {
      if (popupRef.current) {
        const { top } = popupRef.current.getBoundingClientRect();
        const viewportTop = window.scrollY; // current scroll position of the viewport
        window.scrollTo({
          top: viewportTop + top, // scroll to the top of the popup
          behavior: "smooth", // smooth scrolling
        });
      }
    };

    scrollToPopup();
  }, [popupRef.current]);

  return (
    <>
      <div className="dynamic-popup-blurred-background"></div>
      <div
        className="dynamic-popup-container"
        style={{
          maxWidth: maxWidth || "600px",
          width: Width || "auto",
        }}
        ref={popupRef}
      >
        <div className="dynamic-popup-header">
          <div className="dynamic-popup-header-title">{title}</div>
          <div className="dynamic-popup-header-close-button" onClick={onCancel}>
            <img src={closeIcon} alt="Close" />
          </div>
        </div>
        <hr />
        <div className="approval-popup-content dynamic-popup-content">
          {children}
        </div>
        {buttons && (
          <div className="approval-popup-buttons">
            {isCondition ? (
              <>
                <button
                  className="approval-popup-button cancel"
                  onClick={onCancel}
                >
                  {cancelText}
                </button>
                <button
                  className={`approval-popup-button ${
                    isNegative ? "delete" : "approve-button"
                  }`}
                  onClick={onAccept}
                >
                  {acceptText}
                </button>
              </>
            ) : (
              <button
                className="approval-popup-button cancel"
                onClick={onCancel}
              >
                {t("close")}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DynamicPopupWithChildren;
