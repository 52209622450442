import React, { useState } from 'react';

const CustomImageDrop = ({ onImageDrop, removable }) => {
    const [dragging, setDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0]; // Only handle the first dropped file
        if (file && file.type.startsWith('image/')) {
            // Pass the dropped image file to the parent component
            onImageDrop(file);
        } else {
            alert('Please drop an image file.');
        }
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            onImageDrop(file);
        } else {
            alert('Please select an image file.');
        }
    };

    return (
        <div
            className={`custom-image-drop ${dragging ? 'dragging' : ''}`}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById('fileInput').click()}
        >
            <input
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileInput}
            />
            {dragging ? (
                <p>Drop the image here</p>
            ) : (
                <p>Drag and drop image here, or click to select</p>
            )}
        </div>
    );
};

export default CustomImageDrop;
