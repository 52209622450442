import React, { useState, useRef, useEffect } from "react";
import downArrow from "../../assets/media/down-arrow.png";
import SegmentRoleTypeSelect from "../collapsible/SegmentRoleTypeSelect";
import { useTranslation } from "react-i18next";

const SegmentRuleCreateButton = ({ items, category, setCategory }) => {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const { t } = useTranslation("segmentation");
  const buttonRef = useRef(null);
  const selectorRef = useRef(null);

  // Seçilen kategoriyi ebeveyne ileten fonksiyon
  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
  };

  // Dış tıklama olayını işleme
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        selectorRef.current &&
        !selectorRef.current.contains(event.target)
      ) {
        setSelectorVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Kategori başlığını belirleme
  const getCategoryTitle = () => {
    if (Array.isArray(category)) {
      if (category.length === 0) {
        return t("addNewRule");
      }
      return category.map((item, index) => (
        <div key={index}>
          {t(item.title)}
          {index < category.length - 1 ? ", " : ""}
        </div>
      ));
    } else if (category && category.title) {
      return <div>{t(category.title)}</div>;
    } else {
      return t("addNewRule");
    }
  };

  return (
    <div className="segment-rule-create-button-wrapper">
      <div
        ref={buttonRef}
        className={`segment-rule-create-button  ${category ? "filled" : ""}`}
        onClick={() => setSelectorVisible(!selectorVisible)}
      >
        <div className="segment-rule-create-button-title">
          {getCategoryTitle()}
        </div>
        <img src={downArrow} alt="V" />
      </div>
      {selectorVisible && (
        <div ref={selectorRef}>
          <SegmentRoleTypeSelect
            items={items}
            updatedCategoriesForSavedCheckeds={category}
            onCategorySelect={handleCategorySelect}
          />
        </div>
      )}
    </div>
  );
};

export default SegmentRuleCreateButton;
