import React, { useRef, useEffect, useState } from 'react';

const VerificationCodeInput = ({ onComplete }) => {
  const inputs = useRef([]);
  const [code, setCode] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false); // Flag to prevent multiple calls

  useEffect(() => {
    if (code.length === 6 && !isAuthorized) {
      setIsAuthorized(true); // Set flag to prevent further calls
      onComplete(code);
    }
  }, [code, onComplete, isAuthorized]);

  useEffect(() => {
    // İlk input alanını odakla
    if (inputs.current[0]) {
      inputs.current[0].focus();
    }
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1) {
      setCode(prevCode => {
        const newCode = prevCode.slice(0, index) + value + prevCode.slice(index + 1);
        if (index < 5) {
          inputs.current[index + 1].focus();
        }
        return newCode;
      });
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && e.target.value === '') {
      inputs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('Text');
    if (/^\d{6}$/.test(pasteData)) {
      setCode(pasteData);
      pasteData.split('').forEach((char, index) => {
        inputs.current[index].value = char;
        if (index < 5) inputs.current[index + 1].focus();
      });
    }
    e.preventDefault();
  };

  return (
    <div
      className='verification-input-container'
      onPaste={handlePaste}
    >
      {[...Array(6)].map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          ref={(el) => (inputs.current[index] = el)}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
    </div>
  );
};

export default VerificationCodeInput;
