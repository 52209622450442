import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const SegmentTopSelect = ({
  topValue,
  setTopValue,
}) => {
  const { t } = useTranslation("segmentation");
  const values = Array.from({ length: 10 }, (_, i) => (i + 1) * 10);
  const [customValue, setCustomValue] = useState("");

  const handleValueClick = (value) => {
    setTopValue(value);
    setCustomValue(""); // Seçim yapıldığında input'u temizle
  };

  const handleCustomValueChange = (e) => {
    setTopValue(e.target.value);
  };

  const handleCustomValueSubmit = () => {
    const customNumber = parseInt(customValue, 10);
    if (!isNaN(customNumber)) {
      setTopValue(customNumber);
    }
  };

  const handleDeleteClick = () => {
    setTopValue(null);
    setCustomValue("");
  };

  return (
    <div className="segment-top-select-container">
      <div className="segment-top-value-container">
        {values.map((value) => (
          <div
            key={value}
            className={`segment-top-value ${
              value === topValue ? "segment-top-value-selected" : ""
            }`}
            onClick={() => handleValueClick(value)}
          >
            {value}
          </div>
        ))}
      </div>
      <div className="segment-top-value-custom-container">
        <div className="segment-top-value-custom-title">{t("custom")}</div>
        <input
          className="segment-top-value-custom-input"
          type="number"
          placeholder="-"
          value={topValue || ""}
          onChange={handleCustomValueChange}
          onBlur={handleCustomValueSubmit}
        />
      </div>
      <button
        className="segment-top-value-delete-button button"
        onClick={handleDeleteClick}
      >
        {t("delete")}
      </button>
    </div>
  );
};

export default SegmentTopSelect;
