import React, { useState, useEffect } from "react";
import "../../../assets/css/register.css";
import TextPopup from "../../popup/textPopup";
import privacyPolicyText from "../../text_content/Privacy.json";
import cookieStatement from "../../text_content/CookieStatement.json";
import termsOfUse from "../../text_content/TermsOfUse.json";
import logo from "../../../assets/media/Logo.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { apiUrl } from "../../../config";
import Loader from "../../popup/loader";
import CallAPI from "../../api/callApi";
import { useTranslation } from "react-i18next";
import downArrow from "../../../assets/media/down-arrow.png";
const SignUpForm = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [brandName, setBrandName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taxOffice, setTaxOffice] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const { t } = useTranslation("inbox");

  const [store, setStore] = useState(false);
  const [selectedStoreCount, setSelectedStoreCount] = useState(`RANGE_0`);
  const [selectedGiroCount, setSelectedGiroCount] = useState(false);
  const [response, setResponse] = useState([]);
  const [popupTitle, setPopupTitle] = useState();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // New state for checkbox
  const [loaderState, setLoaderState] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const browserLanguage = navigator.language || navigator.userLanguage; // 'navigator.language' tarayıcı dilini alır
  let text;
  if (browserLanguage.startsWith("tr")) {
    text = "tr-TR";
  } else if (browserLanguage.startsWith("en")) {
    text = "en-US";
  } else {
    text = "tr-TR"; // Desteklenmeyen diller için varsayılan bir metin
  }

  useEffect(() => {
    console.log("Browser language:", navigator.language);

    fetchCategories();
  }, []);
  const validateEmail = (value) => {
    // Simple email validation example
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const validateWebsite = (value) => {
    // Simple website URL validation example
    const urlRegex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,6}([\/\w.-]*)*\/?$/;
    return urlRegex.test(value);
  };

  const validatePhoneNumber = (value) => {
    // Simple phone number validation example
    const phoneRegex = /^\+?[0-9]*$/;
    return phoneRegex.test(value);
  };
  const fetchCategories = async () => {
    const response = await CallAPI({
      method: "GET",
      endPoint: apiUrl + "/api/v1/brand/brand-category",
      t: t,
      noAuth: true,
    });
    setResponse(response);
    console.log(response);
  };

  const handlePopupOpen = (content) => {
    setPopupContent(content);
    setPopupTitle("soty");
    setPopupVisibility(true);
  };

  const handlePopupClose = () => {
    setPopupVisibility(false);
    setPopupContent("");
  };

  const handleNameChange = (event) => {
    const newName = event.target.value.replace(/[^a-zA-ZğüşıöçĞÜŞİÖÇ]/g, "");
    setName(newName);
  };

  const handleSurnameChange = (event) => {
    const newSurname = event.target.value.replace(/[^a-zA-ZğüşıöçĞÜŞİÖÇ]/g, "");
    setSurname(newSurname);
  };

  const handlePhoneNumberChange = (event) => {
    let inputNumber = event.target.value.replace(/\D/g, ""); // Sadece rakamları al
    inputNumber = inputNumber.slice(0, 10); // İlk 10 hane

    // Numarayı (555)-555-55-55 formatına dönüştür
    let formattedNumber = "";
    for (let i = 0; i < inputNumber.length; i++) {
      if (i === 0) {
        formattedNumber += `(${inputNumber[i]}`;
      } else if (i === 3) {
        formattedNumber += `)-${inputNumber[i]}`;
      } else if (i === 6 || i === 8) {
        formattedNumber += `-${inputNumber[i]}`;
      } else {
        formattedNumber += inputNumber[i];
      }
    }

    setPhoneNumber(formattedNumber);

    if (!validatePhoneNumber(formattedNumber)) {
      // Handle invalid phone number, e.g., show an error message
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      toast.error("lutfen gecerli bir mail adresi giriniz.");
    }
  };

  const handleWebsiteChange = (event) => {
    const newWebsite = event.target.value;
    setWebsite(newWebsite);

    if (!validateWebsite(newWebsite)) {
      toast.error("lutfen gecerli bir website giriniz.");
    }
  };

  const handleBrandNameChange = (event) => {
    setBrandName(event.target.value);
    // You can add additional validation for brand name if needed
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handleTaxOfficeChange = (event) => {
    setTaxOffice(event.target.value);
  };
  const handleTaxNumberChange = (event) => {
    const inputTaxNumber = event.target.value;

    // Vergi numarasının on haneli bir sayı olup olmadığını kontrol et
    const taxNumberRegex = /^\d{10}$/;
    const isValidTaxNumber = taxNumberRegex.test(inputTaxNumber);

    if (isValidTaxNumber) {
      // Geçerli bir vergi numarasıysa değeri ayarla
      setTaxNumber(inputTaxNumber);
    } else {
      // Geçerli bir vergi numarası değilse kullanıcıyı uyar
      console.error("Yanlış formatta vergi numarası");
      toast.error(
        "Lütfen geçerli bir vergi numarası girin. Vergi numarası 10 haneli bir sayı olmalıdır."
      );
      // veya istediğiniz bir hata mesajını kullanıcıya gösterebilirsiniz
    }
  };

  const handleStoreCountChange = (event) => {
    if (store === false) {
      setSelectedStoreCount(`RANGE_0`);
      return;
    }
    const selectedValue = event.target.value;
    console.log(selectedValue);
    if (selectedValue === "on") {
      setSelectedStoreCount(`RANGE_0`);
    } else {
      setSelectedStoreCount(`RANGE_${selectedValue}`);
    }

    console.log(`RANGE_${selectedValue}`);
  };

  const handleGiroCountChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedGiroCount(`RANGE_${selectedValue}`);
    console.log(`RANGE_${selectedValue}`);
  };

  const options = [
    <option value="" disabled selected>
      Seçiniz
    </option>,
    <option key="0" value="0">
      0
    </option>,
    <option key="1-10" value="1_10">
      1-10
    </option>,
    <option key="10-20" value="10_20">
      10-20
    </option>,
    <option key="20-50" value="20_50">
      20-50
    </option>,
    <option key="50-100" value="50_100">
      50-100
    </option>,
  ];

  const optionsGiro = [
    <option value="" disabled selected>
      Seçiniz
    </option>,
    <option key="0-30" value="0_30M">
      0 - 30 milyon TL
    </option>,
    <option key="30-60" value="30M_100M">
      30 - 100 milyon TL
    </option>,
    <option key="60-90" value="100M_1000M">
      60 - 1000 milyon TL
    </option>,
  ];
  const categoryOptions = [
    <option value="" disabled selected>
      Seçiniz
    </option>,
    response?.data?.responseData?.map((item) => (
      <option value="" selected>
        {item.category}
      </option>
    )),
  ];

  function formatPhoneNumber(input) {
    // Temizleme işlemi: Sadece sayıları al
    const cleaned = ("" + input).replace(/\D/g, "");

    // Uygun formata dönüştürme
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ")-" + match[2] + "-" + match[3];
    }

    // Eğer uygun formata dönüştürülemezse, orijinal değeri döndür
    return input;
  }

  const handleFormSend = () => {
    const idsArray = selectedCategoryOptions.map((item) => item.id);
    console.log(idsArray);
    if (store === false) {
      setSelectedStoreCount(`RANGE_0`);
    }
    if (idsArray.length < 1) {
      toast.error("Lütfen marka kategorinizi belirtin.");
      return;
    }
    if (store && selectedStoreCount === false) {
      toast.error(`Lütfen Mağaza sayınızı belirtin.`);
      return;
    }

    // Check if required fields are filled
    const requiredFields = [
      { label: "Ad", value: name },
      { label: "Soyad", value: surname },
      { label: "Telefon Numarası", value: formatPhoneNumber(phoneNumber) },
      { label: "E-Posta", value: email },
      { label: "Alan Adı", value: website },
      { label: "Marka Adı", value: brandName },
      { label: "Şirket Adı", value: companyName },
      { label: "Vergi Dairesi", value: taxOffice },
      { label: "Vergi Numarası", value: taxNumber },
      { label: "Aylık Ortalama Ciro", value: selectedGiroCount },
      { label: "Marka Kategorisi", value: idsArray },
    ];

    const missingFields = requiredFields
      .filter((field) => !field.value)
      .map((field) => field.label);

    if (missingFields.length > 0) {
      const errorMessage = `Lütfen aşağıdaki zorunlu alanları doldurun: ${missingFields.join(
        ", "
      )}`;

      toast.error(errorMessage);
      return;
    }

    // Log user data to the console
    console.log(phoneNumber);
    console.log(formatPhoneNumber(phoneNumber));
    // Adjust the format of selectedGiroCount and selectedStoreCount

    console.log(
      JSON.stringify(
        {
          firstName: name,
          lastName: surname,
          phoneNumber: formatPhoneNumber(phoneNumber),
          email: email,
          url: website,
          brandName: brandName,
          companyName: companyName,
          taxOffice: taxOffice,
          taxNumber: taxNumber,
          giro: selectedGiroCount,
          storeCount: selectedStoreCount,
        },
        null,
        2
      )
    );

    setLoaderState(true);

    // Example of sending data to an endpoint using Axios
    axios
      .post(
        apiUrl + "/api/v1/application",
        {
          firstName: name,
          lastName: surname,
          phoneNumber: formatPhoneNumber(phoneNumber),
          email: email,
          url: website,
          brandName: brandName,
          companyName: companyName,
          taxOffice: taxOffice,
          taxNumber: taxNumber,
          giro: selectedGiroCount,
          storeCount: selectedStoreCount,
          categories: idsArray,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle success, e.g., show a success message
        console.log("Form data sent successfully:", response.data);
        setLoaderState(false);

        toast.success("Başvurunuz başarıyla gönderildi!");
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        const errorMessage = error.response.data.metadata.message;
        console.error(
          "Error sending form data:",
          error.response.data.metadata.message
        );
        setLoaderState(false);

        toast.error(
          `Form verileri gönderilirken bir hata oluştu. ` + errorMessage
        );
      });
  };

  // Helper function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (selectedCategoryOptions.includes(option)) {
      setSelectedCategoryOptions(
        selectedCategoryOptions.filter((o) => o !== option)
      );
    } else {
      setSelectedCategoryOptions([...selectedCategoryOptions, option]);
    }
    console.log(selectedCategoryOptions);
  };
  return (
    <div className="dashboard-container sign-up-background">
      {loaderState && <Loader />}

      <div className="sign-up-container">
        <div className="sign-up-header">
          <img src={logo} alt="soty" />
          <div className="sign-up-title">ÜYELİK BAŞVURUSU</div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Ad<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <input
              type="text"
              className="sign-up-input"
              onBlur={handleNameChange}
            />
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Soyad<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <input
              type="text"
              className="sign-up-input"
              onBlur={handleSurnameChange}
            />
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Cep Telefon Numarası<span>*</span>
          </div>
          <div className="sign-up-phone">
            <input
              type="text"
              className="sign-up-input-phone"
              placeholder="+90"
              readOnly={true}
            />
            <input
              type="tel"
              className="sign-up-input"
              placeholder="(555)-555-55-55"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            {/* ... (other phone input JSX code) */}
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            E-Posta<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <input
              type="text"
              className="sign-up-input"
              placeholder="example@soty.com"
              onBlur={handleEmailChange}
            />
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Alan Adı<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <input
              type="text"
              className="sign-up-input"
              placeholder="https://www.secilstore.com/"
              onBlur={handleWebsiteChange}
            />
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Marka Adı<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <input
              type="text"
              className="sign-up-input"
              placeholder="Soty"
              onBlur={handleBrandNameChange}
            />
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Şirket Adı:<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <input
              type="text"
              className="sign-up-input"
              onBlur={handleCompanyNameChange}
            />
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Vergi Dairesi:<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <input
              type="text"
              className="sign-up-input"
              onBlur={handleTaxOfficeChange}
            />
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Vergi Numarası:<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <input
              type="text"
              className="sign-up-input"
              onBlur={handleTaxNumberChange}
            />
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Aylık Ortalama Cironuz?<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <select
              className="sign-up-input-select"
              onBlur={handleGiroCountChange}
            >
              {optionsGiro}
            </select>
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Marka Kategorisi<span>*</span>
          </div>
          <div className="sign-up-input-wrapper">
            <div
              className="brand-category-dropdown-selected flex align-center"
              onClick={toggleDropdown}
            >
              <div>
                {selectedCategoryOptions.length > 0
                  ? selectedCategoryOptions
                      .map((option) =>
                        text === "en-US" ? option.categoryEn : option.category
                      )
                      .join(", ")
                  : "Seçiniz"}
              </div>
              <div className="dropdown-arrow">
                <img src={downArrow} />
              </div>
            </div>
            {isOpen && (
              <div className="brand-category-dropdown-options">
                {response?.data?.responseData?.map((item, index) => (
                  <div
                    key={index}
                    className={`category-dropdown-option ${
                      selectedCategoryOptions.includes(item)
                        ? "selected-category"
                        : ""
                    }`}
                    onClick={() => handleOptionClick(item)}
                  >
                    {text === "en-US" ? item.categoryEn : item.category}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="sign-up-input-container">
          <div className="sign-up-input-title">
            Mağazanız Var Mı?<span>*</span>
          </div>
          <div className="sign-up-input-wrapper border-none flex flex-row align-center gap">
            <input
              type="radio"
              className="sign-up-radio"
              name="storeRadio"
              checked={store}
              onChange={() => setStore(true)}
            />
            <div>Evet</div>
            <input
              type="radio"
              className="sign-up-radio"
              name="storeRadio"
              checked={!store}
              onChange={(e) => {
                setStore(false);
                handleStoreCountChange(e);
              }}
            />
            <div>Hayır</div>
          </div>
        </div>

        {store && (
          <>
            <div className="sign-up-input-container">
              <div className="sign-up-input-title">
                Mağaza Sayısı<span>*</span>
              </div>
              <div className="sign-up-input-wrapper">
                <select
                  className="sign-up-input-select"
                  onChange={handleStoreCountChange}
                >
                  {options}
                </select>
              </div>
            </div>
          </>
        )}

        <div className="sign-up-agree-privacy-cookie">
          Devam ederek,
          <span onClick={() => handlePopupOpen(termsOfUse.terms_of_use)}>
            {" "}
            Kullanım Koşulları{" "}
          </span>
          ,
          <span
            onClick={() => handlePopupOpen(privacyPolicyText.privacy_policy)}
          >
            {" "}
            Gizlilik{" "}
          </span>{" "}
          ve
          <span
            onClick={() => handlePopupOpen(cookieStatement.cookie_statement)}
          >
            {" "}
            Çerez Bildirimi'ni{" "}
          </span>
          okuduğunuzu ve onayladığınızı gösterir.
        </div>

        <div className="sign-up-approve-checkbox-container">
          <input
            type="checkbox"
            checked={isCheckboxChecked}
            onChange={() => handleCheckboxChange()}
          />
          <div>Okudum Onaylıyorum.</div>
        </div>

        <div className="sign-up-button-container">
          <button
            className={`sign-up-approve-button ${
              !isCheckboxChecked ? "disabled" : ""
            }`}
            disabled={!isCheckboxChecked}
            onClick={() => {
              handleFormSend();
            }}
          >
            ONAYLA VE GÖNDER
          </button>
        </div>

        {isPopupVisible && (
          <TextPopup
            title={popupTitle}
            content={popupContent}
            onClose={handlePopupClose}
          />
        )}
      </div>
    </div>
  );
};

export default SignUpForm;
