import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../assets/media/upload.png";
import "../../assets/css/ImageDropzone.css"; // You can create a CSS file for styling
import { useTranslation } from "react-i18next";

const ImageDropzone = ({ onImageUpload }) => {
  const { t, i18n } = useTranslation("challengeCreation");
  const acceptedFileTypes = [
    "image/jpeg",
    "image/png"
  ];
  

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Check if each file is a valid image file (JPG or PNG)
      const validFiles = acceptedFiles.filter((file) =>
        acceptedFileTypes.includes(file.type)
      );

      if (validFiles.length > 0) {
        // Handle the valid image files (e.g., upload, display, etc.)
        onImageUpload(validFiles);
      } else {
        // Handle invalid file types (e.g., show an error message)
        console.error("Invalid file types. Please upload JPG or PNG images.");
      }
    },
    [onImageUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.join(","), // Accept only JPG and PNG
  });

  return (
    <div className="image-dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      <img src={upload} alt="uploadico" />
      <p>{t("uploadImage")}</p>
    </div>
  );
};

export default ImageDropzone;
