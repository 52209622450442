import React, { useState } from "react";
import { imagesUrl } from "../../../../../../config";
import "../../../../../../assets/css/SurveyType.css";
import { useTranslation } from "react-i18next";

const SurveyType = ({ apiData, onPublishChallenge, onEditChallenge }) => {
  const [isChallengePublished, setIsChallengePublished] = useState(false);
  const { t, i18n } = useTranslation("challengeCreation");

  const formatDateString = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false, // 24-hour format
    };

    const inputDate = new Date(dateString);

    // Add 3 hours to the date
    const updatedDate = new Date(inputDate.getTime() + 3 * 60 * 60 * 1000);

    // Format the updated date and return the result
    return updatedDate.toLocaleDateString("tr-TR", options);
  };

  const publishChallenge = () => {
    // Assume you want to pass apiData.responseData.id and true to the parent
    const challengeId = apiData.responseData.id;
    onPublishChallenge(challengeId);
    setIsChallengePublished(true);
  };
  const editChallenge = () => {
    onEditChallenge();
  };
  const mapSurveyTypeToTurkish = (surveyType) => {
    const typeMap = {
      SINGLE_CHOICE: "Tek Seçenekli",
      MULTIPLE_CHOICE: "Çoklu Seçenekli",
      IMAGE: "Resimli",
      SCORE: "Doğrusal Sayısal Ölçek",
      LIKERT_FREQUENCY: "Likert (Frekans)",
      LIKERT_AGREEMENT: "Likert (Anlaşma)",
      LIKERT_IMPORTANCE: "Likert (Önem)",
      LIKERT_INTEREST: "Likert (İlgi)",
    };

    return typeMap[surveyType] || surveyType; // If not found, return the original value
  };

  return (
    <div className="challenge-type-preview-container">
      <div className="challenge-type-preview-logo-container">
        <div className="challenge-type-preview-brand-container">
          <img
            className="challenge-type-preview-brand-logo"
            src={imagesUrl + "/" + apiData.responseData.brand.logo}
            alt="image"
          />
          <div className="challenge-type-preview-challenge-Id">
            Challenge ID: {apiData.responseData.id}
          </div>
        </div>
        <div className="challenge-type-preview-challenge-logo-container">
          <img
            className="challenge-type-preview-challenge-logo"
            src={imagesUrl + "/" + apiData.responseData.logo}
            alt="image"
          />
        </div>
      </div>

      <div className="challenge-type-preview-challenge-data-container">
        <div className="challenge-type-preview-challenge-data-wrapper">
          <div className="challenge-type-preview-challenge-data-title">
            {t("challengeName")}:
          </div>
          <div className="challenge-type-preview-challenge-data">
            {apiData.responseData.name}
          </div>
        </div>
        <hr />
        <div className="challenge-type-preview-challenge-data-wrapper">
          <div className="challenge-type-preview-challenge-data-title">
            {t("challengeDescription")}:
          </div>
          <div className="challenge-type-preview-challenge-data">
            {apiData.responseData.description}
          </div>
        </div>
        <hr />
        <div className="challenge-type-preview-challenge-data-wrapper">
          <div className="challenge-type-preview-challenge-data-title">
            {t("surveyType")}:
          </div>
          <div className="challenge-type-preview-challenge-data">
            {mapSurveyTypeToTurkish(apiData.responseData.surveyType)}
          </div>
        </div>
        <hr />
        <div className="challenge-type-preview-challenge-data-wrapper">
          <div className="challenge-type-preview-challenge-data-title">
            {t("text")} / {t("image")}:
          </div>
          <div className="challenge-type-preview-challenge-data">
            {t("text")}
          </div>
        </div>
        <hr />
        {apiData.responseData.segment && (
          <>
            <div className="interaction-type-preview-challenge-data-wrapper">
              <div className="interaction-type-preview-challenge-data-title">
                {t("segment")}:
              </div>
              <div className="interaction-type-preview-challenge-data">
                {apiData.responseData.segment}
              </div>
            </div>
            <hr />
          </>
        )}
        <div className="challenge-type-preview-challenge-data-wrapper challenge-type-preview-challenge-tasks">
          <div className="challenge-type-preview-challenge-data-title">
            {t("surveyQuestions")}:
          </div>
          <div className="challenge-type-preview-challenge-data">
            {apiData.responseData.questions.map((question, index) => (
              <div
                className="challenge-type-preview-question-container"
                key={index}
              >
                <div className="challenge-type-preview-question-wrapper">
                  <div>Soru {index + 1} : </div>
                  <div>{question.text}</div>
                </div>

                {/* Eğer question içinde surveyChoices varsa onları da render et */}
                {question.surveyChoices && (
                  <div className="challenge-type-preview-survey-choices">
                    <ul>
                      {question.surveyChoices.map((choice, choiceIndex) => (
                        <li key={choiceIndex}>
                          <input
                            type="radio"
                            id={`choice_${index}_${choiceIndex}`}
                            name={`question_${index}`}
                          />
                          <label htmlFor={`choice_${index}_${choiceIndex}`}>
                            {choice.text}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>

          <hr />
        </div>
        <div className="challenge-type-preview-challenge-data-wrapper">
          <div className="challenge-type-preview-challenge-data-title">
            {t("challengeDate")}:
          </div>
        </div>
        <div className="challenge-type-preview-challenge-data-wrapper">
          <div className="challenge-type-preview-challenge-data-title">
            {t("startDate")}:
          </div>

          <div className="challenge-type-preview-challenge-data">
            {formatDateString(apiData.responseData.startDate)}
          </div>
        </div>
        <div className="challenge-type-preview-challenge-data-wrapper">
          <div className="challenge-type-preview-challenge-data-title">
            {t("endDate")}:
          </div>

          <div className="challenge-type-preview-challenge-data">
            {formatDateString(apiData.responseData.endDate)}
          </div>
        </div>
        <hr />
        {/**
                 * <div className="challenge-type-preview-challenge-data-wrapper">
                    <div className="challenge-type-preview-challenge-data-title">
                        Challenge Başlama Zamanı:
                    </div>

                </div>
                <div className="challenge-type-preview-challenge-data-wrapper">
                    <div className="challenge-type-preview-challenge-data-title">
                        Alışveriş Mecrası:
                    </div>

                    <div className="challenge-type-preview-challenge-data">
                        ONLINE
                    </div>
                </div>
                <div className="challenge-type-preview-challenge-data-wrapper">
                    <div className="challenge-type-preview-challenge-data-title">
                        Süreç Başlatma Zamanı:
                    </div>

                    <div className="challenge-type-preview-challenge-data">
                        Alışveriş Yapıldığı An İtibariyle Süreç Başlasın
                    </div>
                </div>
                <hr />
                 */}

        <div className="challenge-type-preview-challenge-data-wrapper">
          <div className="challenge-type-preview-challenge-data-title">
            {t("sotyCoin")}:
          </div>
          <div className="interaction-type-preview-challenge-data">
            {apiData.responseData.prize +
              " " +
              t("sotyCoin") +
              " " +
              t("equalsTo") +
              " " +
              apiData.responseData.prize / 10 +
              " TL"}
          </div>
        </div>
        <hr />
        <div className="interaction-type-preview-challenge-release-button-wrapper">
          <button
            className="interaction-type-preview-challenge-release-button"
            onClick={() => {
              publishChallenge();
            }}
          >
            {t("publish")}
          </button>
        </div>
        <div className="interaction-type-preview-challenge-release-button-wrapper">
          <button
            className="interaction-type-preview-challenge-edit-button"
            onClick={() => {
              editChallenge();
            }}
          >
            {t("edit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyType;
