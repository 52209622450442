import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NavigationBar from "../../../utils/navigation_bar/navigationBar";
import FaqForm from "./FaqForm";
import FaqItem from "./FaqItem";
import DynamicPopupWithChildren from "../../../popup/dynamicPopupWithChildren";
import CallAPI from "../../../api/callApi";
import { apiUrl } from "../../../../config";

const FaqList = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingFaq, setEditingFaq] = useState(null);
  const { t } = useTranslation("faq");
  const [FAQS, setFAQS] = useState([]);

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    const response = await CallAPI({
      method: "GET",
      endPoint: apiUrl + "/api/v1/admin/faq",
    });

    setFAQS(response.data.responseData);
  };

  const handleDelete = async (id) => {
    const response = await CallAPI({
      method: "DELETE",
      endPoint: apiUrl + "/api/v1/admin/faq/" + id,
      successMessage: t("faqDeletedSuccessfully"),
      errorMessage: t("faqDeletionError")
    });
    console.log(response);
    fetchFaqs();
  };

  const openCreatePopup = () => {
    setIsEditing(false);
    setEditingFaq(null);
    setPopupOpen(true);
  };

  const openEditPopup = (faq) => {
    setEditingFaq(faq);
    setIsEditing(true);
    setPopupOpen(true);
  };

  const locationArray = [[t("FAQ"), "/faq"]];

  return (
    <div className="dashboard-container">
      {popupOpen && (
        <DynamicPopupWithChildren
          title={isEditing ? t("edit") : t("createNewFaq")}
          isCondition={true}
          acceptText={t("save")}
          cancelText={t("cancel")}
          maxWidth={"1000px !important"}
          onAccept={() => setPopupOpen(false)}
          onCancel={() => setPopupOpen(false)}
        >
          <FaqForm
            setEditingFaq={setEditingFaq}
            editingFaq={editingFaq}
            onSuccess={() => {fetchFaqs()}}
            onClose={() => setPopupOpen(false)} // onClose prop'u doğru şekilde geçilmelidir
          />
        </DynamicPopupWithChildren>
      )}

      <NavigationBar locationArray={locationArray} />

      <div className="faq-section">
        <div className="faq-header">
          <h1 className="faq-header-part faq-section__title">{t("FAQ")}</h1>
          <div
            className="static-button-container create-segment-button"
            onClick={openCreatePopup}
          >
            {t("createNewFaq")}
          </div>
        </div>

        <div className="faq-list">
          {FAQS.map((faq) => (
            <FaqItem
              key={faq.id}
              faq={faq}
              onEdit={() => openEditPopup(faq)}
              onDelete={() => handleDelete(faq.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqList;
