import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CallAPI from '../../../../../api/callApi';
import { apiUrl, imagesUrl } from "../../../../../../config";
import { useTranslation } from "react-i18next";
import DynamicTableWithInnerElements from '../../../../../tables/DynamicTableWithInnerElements';
import Loader from '../../../../../popup/loader';
import NavigationBar from '../../../../../utils/navigation_bar/navigationBar';
import BlankCanvasIcon from '../../../../../../assets/media/blank_canvas.svg';
const SegmentUserList = () => {
    const { t } = useTranslation("segmentation");
    const [loaderState, setLoaderState] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [sotierPerPage, setSotierPerPage] = useState(20);
    const [sotierTotalPage, setSotierTotalPage] = useState(0)
    const [userList, setUserList] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { segment } = location.state || "";


    const locationArray = [
        [t("segments"), "/brand/srm/segment/"],
        [t("segmentDashboard"), "/brand/srm/segment"],
        [t("segmentUserList"), "/brand/srm/segment/list"],
    ];
    const fetchUserList = async () => {
        setLoaderState(true);
        const response = await CallAPI({
            method: "GET",
            endPoint: apiUrl + `/api/v1/brand-admin/brand/segment/${segment.id}/sotier`,
            endpointParams: `page=${currentPage || 0}&size=${sotierPerPage || 20}`
        })
        const transformedData = response.data.responseData.map(item => {
            const user = item;




            return {
                sotierId: user.userId,
                fullName: t(user.firstName) + " " + user.lastName,
                username: user.userName,
                level: user.level,
                creationDate: formatDateStringLocal(user.created),
            };
        });
        setUserList(transformedData);
        setSotierTotalPage(response.data.pageMetadata.totalPage);
        setLoaderState(false);
    }
    useEffect(() => {
        fetchUserList();
    }, [])

    useEffect(() => {
        fetchUserList();
    }, [sotierPerPage, currentPage])

    const formatDateStringLocal = (dateString) => {
        // UTC tarihini al
        const utcDate = new Date(dateString);

        // UTC tarihine manuel olarak 3 saat ekle
        utcDate.setHours(utcDate.getHours() + 3);

        // Tarih ve saat bilgilerini istediğiniz formatta al
        const formattedDate = utcDate.toLocaleDateString("tr-TR");
        const formattedTime = utcDate.toLocaleTimeString("tr-TR");
        const formattedDateTime = formattedDate + " " + formattedTime;
        return formattedDateTime;
    };

    const handleSotierPerPage = (e) => {
        setCurrentPage(0);
        setSotierPerPage(e)
    }

    const columns = [
        { title: t("ID"), field: "sotierId", className: "column-id" },
        { title: t("username"), field: "username", className: "column-name" },
        { title: t("nameSurname"), field: "fullName", className: "column-name" },
        { title: t("level"), field: "level", className: "column-status" },
        { title: t("creationDate"), field: "creationDate", className: "column-date" },
    ];
    return (
        <div className='dashboard-container'>
            {loaderState && <Loader />}
            <NavigationBar
                locationArray={locationArray}
            />
            {userList.length === 0 ?
                <div className="segment-user-list-no-user-container">
                    <img className="segment-user-list-no-user-icon" src={BlankCanvasIcon} alt="No data" />
                    <div className='segment-user-list-no-user-wrapper'>
                        <h1>{t("noSotierFound")}</h1>
                        <p>{t("noSotierFoundDescription")}</p>
                        <div className="table-unscrollable-button-container-row">
                            <div className="no-user-found-edit-button" onClick={() => { navigate('/brand/srm/segment/create', { state: { returnedSegment: segment } }) }}>{t("editSegment")}</div>

                        </div>
                    </div>

                </div>
                :
                <DynamicTableWithInnerElements
                    columns={columns}
                    data={userList}
                    segment={true}
                    tableName={t("sotierListForSegment")}
                    corporateuser={false}
                    segmentPage={currentPage}
                    segmentTotalPage={sotierTotalPage} // Adjust according to actual response structure
                    setSegmentPage={setCurrentPage}
                    handleInputChange={(e, setPageSize) => setPageSize(e)}
                    segmentPerPageTitle={t("sotierPerPage")}
                    setSegmentPerPage={handleSotierPerPage}
                    
                />
            }

        </div>
    )
}

export default SegmentUserList