let apiUrl, authUrl, imagesUrl, metabaseUrl, authenticationGrantType, adminClientId, adminClientSecret, brandClientId, brandClientSecret, brandTiktokClientKey, brandTiktokRedirectUri;

// Çevresel değişkenlerden ayarları yükleme
apiUrl = process.env.REACT_APP_API_URL;
authUrl = process.env.REACT_APP_AUTH_URL;
imagesUrl = process.env.REACT_APP_IMAGES_URL;
metabaseUrl = process.env.REACT_APP_METABASE_URL;
authenticationGrantType = process.env.REACT_APP_AUTHENTICATION_GRANT_TYPE;
adminClientId = process.env.REACT_APP_ADMIN_CLIENT_ID;
adminClientSecret = process.env.REACT_APP_ADMIN_CLIENT_SECRET;
brandClientId = process.env.REACT_APP_BRAND_CLIENT_ID;
brandClientSecret = process.env.REACT_APP_BRAND_CLIENT_SECRET;
brandTiktokClientKey = 'sbaw5pl25vvg8nveg7';
brandTiktokRedirectUri = '/brand/settings/profile';

// config.json'dan ayarları yükleme
const loadConfig = async () => {
  const response = await fetch('/config.json');
  if (response.ok) {
    const data = await response.json();
    // Eğer config.json'da aynı isimli ayarlar varsa, onları güncelle
    apiUrl = data.apiUrl || apiUrl;
    authUrl = data.authUrl || authUrl;
    imagesUrl = data.imagesUrl || imagesUrl;
    metabaseUrl = data.metabaseUrl || metabaseUrl;
    authenticationGrantType = data.authenticationGrantType || authenticationGrantType;
    adminClientId = data.adminClientId || adminClientId;
    adminClientSecret = data.adminClientSecret || adminClientSecret;
    brandClientId = data.brandClientId || brandClientId;
    brandClientSecret = data.brandClientSecret || brandClientSecret;
  }
};

export {
  apiUrl,
  authUrl,
  imagesUrl,
  metabaseUrl,
  authenticationGrantType,
  adminClientId,
  adminClientSecret,
  brandClientId,
  brandClientSecret,
  brandTiktokClientKey,
  brandTiktokRedirectUri,
  loadConfig
};
