import React from "react";

const BuyMorePayLess = ({
  discounts,
  handleDiscountChange,
  handleDeleteDiscount,
  handleAddDiscount,
  t,
}) => {
  return (
    <div className="discount-to-x-product-container">
      {discounts.map((discount, index) => (
        <div key={index} className="discount-to-x-product-row-wrapper">
          <div className="discount-to-x-product-row">
            <div className="discount-to-x-product-counter-circle">
              {index + 2}
            </div>
            <input
              type="number"
              min="0"
              max="100" // En fazla 100 girilebilir
              className="challenge-creation-soty-coin-input"
              placeholder={t("pleaseEnterPercentage")}
              value={discount} // Use value instead of defaultValue
              onChange={(e) => {
                // Girilen değeri kontrol et
                let newValue = e.target.value;
                // Eğer girilen değer 100'den büyükse 100 yap

                if (newValue > 100) {
                  newValue = "100";
                }

                // State'i güncelle
                handleDiscountChange(index, newValue);
              }} // Use onChange to trigger handler function
            />

            <div className="challenge-creation-input-container-header-subtitle">
              {localStorage.getItem("language") === "tr-TR"
                ? index + 2 + ". " + t("theProductsDiscount")
                : index + 2 + ". " + t("theProductsDiscount")}
            </div>
          </div>

          {/* Delete button: Only shows when there is more than 1 discount */}
          {discounts.length > 1 && (
            <div className="discount-to-x-product-row">
              <div
                className="discount-to-x-product-button"
                onClick={() => handleDeleteDiscount(index)}
              >
                <div className="discount-to-x-product-button-minus">-</div>
                <span>{t("delete")}</span>
              </div>
            </div>
          )}

          {/* Add button, shows only when the length of discounts is less than 4 */}
          {discounts.length < 4 && index === discounts.length - 1 && (
            <div className="discount-to-x-product-row">
              <div
                className="discount-to-x-product-button"
                onClick={handleAddDiscount}
              >
                <div className="discount-to-x-product-button-plus">+</div>
                <span>{t("addProduct")}</span>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default BuyMorePayLess;
