import React, { useEffect, useState } from "react";
import DynamicTable from "../../../tables/DynamicTable";
import { useTranslation } from "react-i18next";
import NavigationBar from "../../../utils/navigation_bar/navigationBar";
import CallAPI from "../../../api/callApi";
import { apiUrl, imagesUrl } from "../../../../config";
import Loader from "../../../popup/loader";
import DynamicTableWithInnerElements from "../../../tables/DynamicTableWithInnerElements";
import placeholderImg from "../../../../assets/media/user.png";
import { useNavigate } from "react-router-dom";
import InstagramIcon from "../../../../assets/media/instagram-flat.png";
import FacebookIcon from "../../../../assets/media/facebook-flat.png";

const PostReports = () => {
  const { t } = useTranslation("reports");
  const locationArray = [[t("reports"), "/brand/reports"]];
  const [data, setData] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(20);
  const navigate = useNavigate();

  const columns = [
    { title: t("id"), field: "id" },
    { title: t("reported"), field: "reported", className: "sotier" },
    { title: t("reporter"), field: "reporter", className: "sotier" },
    { title: t("provider"), field: "socialProvider" },
    { title: t("creationDate"), field: "creationDate" },
    { title: t("reason"), field: "reason" },
    { title: t("status"), field: "status" },
    { title: t("investigate"), field: "unscrollable", className: "" },
  ];

  const itemPerPageChange = (e, setter) => {
    setCurrentPage(0);
    setter(e);
  };

  useEffect(() => {
    fetchReports();
  }, [currentPage, itemPerPage]);

  const formatDateStringLocal = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);
    const formattedDate = utcDate.toLocaleDateString("tr-TR");
    const formattedTime = utcDate.toLocaleTimeString("tr-TR");
    return `${formattedDate} ${formattedTime}`;
  };

  const handleInvestigateClick = (item) => {
    console.log(item);
    navigate(`/brand/reports/detail/${item.reported.userId}`, { state: { report: item } });
  };

  const fetchReports = async () => {
    setLoaderState(true);
    try {
      const response = await CallAPI({
        method: "GET",
        endPoint: `${apiUrl}/api/v1/brand-admin/report`,
        endpointParams: `page=${currentPage || 0}&size=${itemPerPage || 20}`,
        errorMessage: t("fetchReportsError"),
      });
      setLoaderState(false);
      console.log(response);
      const updatedReports = response.data.responseData.map((item) => ({
        ...item,
        reported: (
          <div
            className="sotier-list-sotier-container"
            onClick={() => {
              navigate(`/brand/srm/sotier/profile/${item.reported.userId}`);
            }}
          >
            <img
              className="sotier-list-sotier-image"
              src={
                item.reported.logo
                  ? `${imagesUrl}/${item.reported.logo}`
                  : placeholderImg
              }
              alt="logo"
            />
            <div className="sotier-list-sotier-details-container">
              <div className="sotier-list-sotier-details-full-name">
                {item.reported.firstName} {item.reported.lastName}
              </div>
              <div className="sotier-list-sotier-details-id">
                ID: {item.reported.userId}
              </div>
              <div className="sotier-list-sotier-details-report">
                {t("reportedReportCount")}: {item.reportCountToSamePost}
              </div>
            </div>
          </div>
        ),
        reporter:
          (item.reporter && (
            <div
              className="sotier-list-sotier-container"
              onClick={() => {
                navigate(`/brand/srm/sotier/profile/${item.reporter.userId}`);
              }}
            >
              <img
                className="sotier-list-sotier-image"
                src={
                  item.reporter.logo
                    ? `${imagesUrl}/${item.reporter.logo}`
                    : placeholderImg
                }
                alt="logo"
              />
              <div className="sotier-list-sotier-details-container">
                <div className="sotier-list-sotier-details-full-name">
                  {item.reporter.firstName} {item.reporter.lastName}
                </div>
                <div className="sotier-list-sotier-details-id">
                  ID: {item.reporter.userId}
                </div>
                <div className="sotier-list-sotier-details-report">
                  {t("reporterReportCount")}: {item.reportCountFromReporter}
                </div>
              </div>
            </div>
          )) ||
          "",
        creationDate: formatDateStringLocal(item.created),
        socialProvider: item.socialProvider,
        reason: t(item.type),
        status: t(item.status),
        unscrollable: (
          <div className="table-unscrollable-button-container-row">
            <div
              className="investigate-button"
              onClick={() => handleInvestigateClick(item)}
            >
              {t("investigate")}
            </div>
          </div>
        ),
      }));
      setData(updatedReports);
      setTotalPage(response.data.pageMetadata.totalPage);
    } catch (error) {
      setLoaderState(false);
    }
  };

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} />
      <DynamicTableWithInnerElements
        columns={columns}
        data={data}
        tableName={t("reports")}
        segmentPerPageTitle={t("reportPerPage")}
        handleInputChange={itemPerPageChange}
        segmentPage={currentPage}
        segmentTotalPage={totalPage}
        setSegmentPage={setCurrentPage}
        setSegmentPerPage={setItemPerPage}
      />
    </div>
  );
};

export default PostReports;
