import React, { useState } from "react";
import "../../assets/css/radioButtonContainer.css";
const RadioButtonContainer = ({
  title,
  headerChild,
  radioOptions = [],
  selectedOption,
  onRoleSelect,
  isMandatory,
}) => {
  const [selectedRole, setSelectedRole] = useState( null);
  const handleRadioChange = (role) => {
    console.log(role.name)
    setSelectedRole(role);
    onRoleSelect(role);
  };



  return (
    <div className="radio-button-container">
      <div className="radio-button-container-header">
        {title && (
          <div className="radio-button-container-title">
            {title}
            {isMandatory && <span className="mandatory-star">*</span>}
          </div>
        )}
        {headerChild && headerChild}
      </div>

      <div className="radio-button-container-list">
        {radioOptions.map((option, index) => (
          <>
            <div className="radio-button-row" key={index}>
              <label className="radio-label">
                <input
                  className="display-none"
                  type="checkbox"
                  name="option"
                  checked={selectedOption === option.name || selectedRole === option}
                  onChange={() => {handleRadioChange(option);}}
                  value={option.name}
                />
                <div className={`custom-radio ${ selectedOption === option.name ? "custom-radio-selected" : ""}`} ></div>
              </label>
              <div className="radio-button-title-container">
                <div className="radio-button-title">{option.name && option.name}</div>
                <div className="radio-button-description">
                  {option.key && option.key}
                </div>
              </div>
            </div>
            <hr />
          </>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonContainer;
