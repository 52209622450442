import React from "react";
import tick from "../../../../../assets/media/tickInteraction.svg"; // Tick iconunun yolu
import Pagination from "../../../../tables/Pagination";
import ItemsPerPageButton from "../../../../button/itemsPerPageButton";
import { Tooltip } from "react-tippy";
import { useTranslation } from "react-i18next";

const SegmentList = ({
  segments,
  selectedSegment,
  setSelectedSegment,
  segmentPage,
  setSegmentPage,
  segmentTotalPage,
  setSegmentPerPage,
  handleSegmentPopup,
  handleInputChange,
}) => {
  const { t } = useTranslation("ChallengeCreation");
  return (
    <div className="challenge-creation-input-container-content-wrapper">
      <div className="segment-list-footer">
        <Pagination
          currentPage={segmentPage + 1 || null}
          totalPages={segmentTotalPage}
          onPageChange={(page) => setSegmentPage(page - 1)}
        />
        <ItemsPerPageButton
          onSelectItem={(e) => handleInputChange(e, setSegmentPerPage)}
          title={t("segmentPerPage")}
        />
      </div>
      <div className="segment-list-container">
        <div className="segment-list-inner">
          {segments &&
            segments?.map((segment) => (
              <div key={segment.id} className="challenge-creation-segment-row">
                <div
                  className={
                    selectedSegment === segment.id
                      ? "qr-checkbox checked"
                      : "qr-checkbox"
                  }
                  onClick={() => setSelectedSegment(segment.id)}
                >
                  <img src={tick} alt="Tick" />
                </div>

                {/* Segment Content */}
                <div className="challenge-creation-segment-content-container">
                  <Tooltip
                    title={t("showDetails")}
                    position="bottom-start"
                    theme="light"
                    size="small"
                    delay="1000"
                  >
                    <div
                      className="challenge-creation-segment-title segment-list-segment-title outline"
                      onClick={() => handleSegmentPopup(segment)}
                    >
                      {segment.name}
                    </div>
                  </Tooltip>
                  <div className="challenge-creation-segment-description">
                    {segment.description}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SegmentList;
