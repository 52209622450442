import React from "react";
import tick from "../../../../../assets/media/tickInteraction.svg"; // İcon dosyasını uygun şekilde import edin

const GreenCheckbox = ({ value, onClick, title, description }) => {
  return (
    <div className="challenge-creation-channel-container">
      <div
        className={value ? "qr-checkbox checked" : "qr-checkbox"}
        onClick={onClick}
      >
        <img src={tick} alt="Tick" />
      </div>

      {(title || description) && (
        <div className="challenge-creation-segment-content-container">
          {title && (
            <div className="challenge-creation-segment-title">{title}</div>
          )}

          {description && (
            <div className="challenge-creation-segment-description">
              {description}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GreenCheckbox;
