import React from "react";
import "../../assets/css/ListTable.css";
import arrow from "../../assets/media/arrow.png";
import { useTranslation } from "react-i18next";
const ListTable = ({ List, onItemClick }) => {
  const { t, i18n } = useTranslation("brandApplicationList");
  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay indeksi 0 ile başlar, bu yüzden 1 ekliyoruz.
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year}`;
  }
  function calculateDaysDifference(dateTimeString) {
    const currentDate = new Date(); // Bugünkü tarih
    const inputDate = new Date(dateTimeString);

    // Tarihler arasındaki farkı hesapla ve gün cinsine çevir
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference;
  }



  console.log(List);
  return (
    <div>
      {List.map((app) => (
        <div className="table-border">

          <div className="application-brand-card-header-container">


            <div className="application-brand-card-header-container-left">
              <div className="application-brand-card-header-brand-name">
                {app.brandName}
              </div>
              <div className="application-brand-card-header-url">
                <a
                  href={app.url.startsWith("https://") ? app.url : "https://" + app.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {app.url.startsWith("https://") ? app.url : "https://" + app.url}
                </a>

              </div>
            </div>
            <div className="application-brand-card-header-container-right">
              {app.status === "PENDING" && (
                <button className="application-brand-card-header-container-button" onClick={() => onItemClick(app.id)}>{t("review")}</button>
              )}
                
            </div>

          </div>

          <div className="application-brand-card-detail-container">
            <div className="application-brand-card-detail-row">
              <div className="application-brand-card-detail-row-left">
                <span></span>
                <div className="">
                  {t("companyName")}:
                </div>
              </div>
              <div className="application-brand-card-detail-row-right">
                <div className="">
                  {app.companyName}
                </div>
              </div>
            </div>
            <div className="application-brand-card-detail-row">
              <div className="application-brand-card-detail-row-left">
                <span></span>
                <div className="">
                  {t("brandName")}:
                </div>
              </div>
              <div className="application-brand-card-detail-row-right">
                <div className="">
                  {app.brandName}
                </div>
              </div>
            </div>
            <div className="application-brand-card-detail-row">
              <div className="application-brand-card-detail-row-left">
                <span></span>
                <div className="">
                  {t("applicationDate")}:
                </div>
              </div>
              <div className="application-brand-card-detail-row-right">
                <div className="">
                  {formatDateTime(app.created)}
                </div>
              </div>
            </div>
            <div className="application-brand-card-detail-row">
              <div className="application-brand-card-detail-row-left">
                <span></span>
                <div className="">
                  {t("daysSinceApplication")}:
                </div>
              </div>
              <div className="application-brand-card-detail-row-right">
                <div className="">
                  {calculateDaysDifference(app.created)}
                </div>
              </div>
            </div>
            <div className="application-brand-card-detail-row">
              <div className="application-brand-card-detail-row-left">
                <span></span>
                <div className="">
                  {t("requestId")}:
                </div>
              </div>
              <div className="application-brand-card-detail-row-right">
                <div className="">
                  {app.id}
                </div>
              </div>
            </div>
            <div className="application-brand-card-detail-row application-brand-card-detail-row-progress-container">
              <div className="application-brand-card-detail-row-progress-bar-wrapper">
                <div className="list-table-progress-bar">
                  <div
                    className={`progress-fill ${app.status === "PENDING" ? "progress-25" : ""
                      }${app.status === "In Progress" ? "progress-50" : ""}${app.status === "APPROVED" ? "progress-100" : ""
                      }${!app.status ? "progress-75" : ""}`}
                  ></div>
                </div>
                {t("process")}
              </div>
              {app.status === "APPROVED" && (
                <div>100%</div>
              )}
              {app.status === "PENDING" && (
                <div>25%</div>
              )}
              {!app.status && (
                <div>75%</div>
              )}

            </div>
          </div>
        </div>

      ))}
    </div>
  )

  /*
    return (
      <table id="kt_datatable_zero_configuration" className="table">
        <thead>
          <tr className="list-table-row">
            <th className="id">Id</th>
            <th>Sirket Adi</th>
            <th>Marka Adi</th>
            <th>Basvuru Tarihi</th>
          </tr>
        </thead>
        <tbody>
          {List.map((app) => (
            <tr className="list-table-row" key={app.id} onClick={() => onItemClick(app.id)}>
              <td className="id">{app.id}</td>
              <td>{app.companyName}</td>
              <td>{app.brandName}</td>
              <td>{formatDateTime(app.created)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
    */
};

export default ListTable;
