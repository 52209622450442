import React from "react";
import ContentCardBottomDefault from "./content_card_types/ContentCardBottomDefault";
import "../../assets/css/ContentCard.css";
import ContentCardTop from "./content_card_types/ContentCardTop";
import ContentCardBottomApproved from "./content_card_types/ContentCardBottomApproved";
import ContentCardBottomRejected from "./content_card_types/ContentCardBottomRejected";
import ContentCardBottomPending from "./content_card_types/ContentCardBottomPending";
import ContentCardBottomOffer from "./content_card_types/ContentCardBottomOffer";

const ContentCard = ({
    bottomType,
    highestOffer,
    provider,
    link,
    content,
    providerIcon,
    profilePicture,
    fullName,
    onOfferHistory,
    id,
    interaction,
    sotyCoin,
    creationDate,
    onClick,
    disabled,
    onOffer,
    onDelete,
    OFFERED,
    offerDate,
    offerCoin,
    offerDeclineDate,
    offerAcceptDate,
    offerEndDate,
    formatDateStringLocal
}) => {

  

    return (
        <div className={`content-card-container`} >
             <ContentCardTop
                provider={provider}
                link={link}
                content={content}
                providerIcon={providerIcon}
                profilePicture={profilePicture}
                fullName={fullName}
                id={id}
                interaction={interaction}
                sotyCoin={sotyCoin}
                creationDate={creationDate}
                highestOffer={highestOffer}
                bottomType={bottomType}
            />
           
            
            <hr />
            {bottomType === "default" && (
                <ContentCardBottomDefault onClick={onClick} disabled={disabled} link={link} content={content} />
            )}
            {bottomType === "OFFERED" && (
                <ContentCardBottomPending
                    offerDate={offerDate}
                    offerCoin={offerCoin}
                    offerEndDate={offerEndDate}
                    formatDateStringLocal={formatDateStringLocal} />
            )}
            {bottomType === "DECLINED" && (
                <ContentCardBottomRejected
                    offerDeclineDate={offerDeclineDate}
                    offerCoin={offerCoin}
                    content={content}
                    offerEndDate={offerEndDate}
                     />
            )}
            {bottomType === "ACCEPTED" && (
                <ContentCardBottomApproved
                    content={content}
                    offerCoin={offerCoin} 
                    offerAcceptDate={offerAcceptDate} />
            )}
            {bottomType === "offer" && (
                <ContentCardBottomOffer
                    onOffer={onOffer}
                    onDelete={onDelete}
                    OFFERED={OFFERED}
                    onOfferHistory={onOfferHistory}
                />
            )}
            
        </div>
    );
}

export default ContentCard;
