import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const CategoryDetails = ({
  details,
  onCategoryClick,
  setSelectedCategory,
  multiSelect,
  selectedItem,
  setSelectedItems,
  updatedCategoriesForSavedCheckeds,
}) => {
  // Internal state to manage the details with updated selected values
  const [localDetails, setLocalDetails] = useState(details);
  const { t } = useTranslation("segmentation");

  useEffect(() => {
    // Update localDetails when details prop changes
    setLocalDetails(details);
  }, [details]);

  const handleCheckboxClick = (item, e) => {
    if (e) {
      e.stopPropagation();
    }

    // Find the index of the item to update
    const index = localDetails.findIndex(
      (detail) => detail.title === item.title
    );
    if (index === -1) return; // Item not found

    // Create a new array with the updated item
    const updatedDetails = [...localDetails];
    updatedDetails[index] = {
      ...item,
      selected: !item.selected,
    };

    // Update the local state
    setLocalDetails(updatedDetails);

    // Update the selected items state
    if (multiSelect) {
      const updatedSelectedItems = updatedDetails
        .filter((detail) => detail.selected)
        .map((detail) => ({ ...detail }));
      setSelectedItems(updatedSelectedItems);
      onCategoryClick(updatedSelectedItems);
    }
  };

  const handleItemClick = (item) => {
    if (!multiSelect) {
      setSelectedCategory(item);
      onCategoryClick(item);
    }
  };

  return (
    <div className="segment-role-type-select-category-wrapper">
      {localDetails && Array.isArray(localDetails) ? (
        localDetails.map((detail, index) => {
          const isChecked =
            detail.selected ||
            (updatedCategoriesForSavedCheckeds &&
              Array.isArray(updatedCategoriesForSavedCheckeds) &&
              updatedCategoriesForSavedCheckeds?.some(
                (cat) =>
                  cat.title === detail.title &&
                  cat.valueType === detail.valueType &&
                  cat.requiresDateRange === detail.requiresDateRange &&
                  cat.multiselect === detail.multiselect
              ));

          return (
            <div
              key={index}
              className={`segment-role-type-select-category-item ${
                isChecked
                  ? "segment-role-type-select-category-item-selected"
                  : ""
              }`}
              onClick={() =>
                multiSelect
                  ? handleCheckboxClick(detail)
                  : handleItemClick(detail)
              }
            >
              {multiSelect && (
                <div
                  className={`segment-custom-checkbox ${
                    isChecked
                      ? "segment-custom-checkbox-checked"
                      : "segment-custom-checkbox-unchecked"
                  }`}
                  onClick={(e) => handleCheckboxClick(detail, e)}
                />
              )}
              {t(detail.title)}
            </div>
          );
        })
      ) : (
        <div className="segment-role-type-select-category-empty-title">
          {selectedItem && t(selectedItem.title)}
        </div>
      )}
    </div>
  );
};

export default CategoryDetails;
