import React, { useState, useEffect, useRef } from "react";
import "../../../../../../../../assets/css/TextTypeSurvey.css";
import { toast } from "react-toastify";
import orderImg from "../../../../../../../../assets/media/change-order.png";
import ChallengeImageDropzone from "../../../../../../../dropzone/ChallengeImageDropzone";
import { apiUrl, imagesUrl } from "../../../../../../../../config";
import axios from "axios";
import {
  getCookie,
  getUserRolesFromToken,
} from "../../../../../../auth/tokenUtils";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../../../popup/loader";


const TextTypeSurvey = ({
  question,
  onIsSavedChange,
  onQuestionsDataReady,
  returnedQuestions,
  infoIconEmpty,
  currentQuestionImageResponseString,
  onCurrentQuestionImageChange,
  onResetQuestionImage,
  setClearImage,
  clearImage,
}) => {
  const [loaderState, setLoaderState] = useState(false);
  const [answerCounter, setAnswerCounter] = useState(1);
  const [answerRows, setAnswerRows] = useState([
    {
      id: answerCounter,
      text: "", // Kullanıcının gireceği metin
    },
  ]);
  const [tempImageResponseString, setTempImageResponseString] = useState();
  const [savedQuestions, setSavedQuestions] = useState([]); // Kaydedilen soruların listesi
  const { t, i18n } = useTranslation("challengeCreation");
  const [currentQuestion, setCurrentQuestion] = useState(question); // Şu anki soru
  const [currentAnswers, setCurrentAnswers] = useState([]); // Şu anki sorunun cevapları
  const [isPopupsOpen, setIsPopupsOpen] = useState(
    Array(savedQuestions.length).fill(false)
  ); // Array to track popup states for each question
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1); // Initially, no question is selected
  const [selectedQuestionImage, setSelectedQuestionImage] = useState();
  const [
    selectedQuestionImageResponseString,
    setSelectedQuestionImageResponseString,
  ] = useState();


  const [popupContent, setPopupContent] = useState({
    question: "",
    answers: [],
  });


  const hasEffectRun = useRef(false);

  useEffect(() => {
    if (!hasEffectRun.current) {
  
      fetchData();
      hasEffectRun.current = true;
    }
  }, []);
  
  const fetchData = (counter) => {
    if (returnedQuestions) {
      const updatedSavedQuestions = returnedQuestions.map((question) => ({
        question: question.text,
        answers: question.surveyChoices.map((choice) => choice.text),
        image: question.image,
      }));
      console.log("textType a gelen sorular : ", updatedSavedQuestions);

      // Set the questions to the state

      // Do additional asynchronous operations here if needed
      // ...
      counter++;
      // Call handleSave for each updatedSavedQuestion
      
      for (const updatedSavedQuestion of updatedSavedQuestions) {
        console.log("foreach", updatedSavedQuestion);
         handleSave(updatedSavedQuestion);
      }
    }
  };

  useEffect(() => {
    
    console.log("Saved questions updated:", savedQuestions);
    createQuestionsDataAndSendToParent();
  }, [savedQuestions]);

  const deleteQuestionImage = (index) => {
    const updatedQuestion = savedQuestions[index];
    setTempImageResponseString(null);
    setSelectedQuestionImageResponseString(null);
    updatedQuestion.image = null;

    const updatedQuestions = [...savedQuestions];
    updatedQuestions[index] = updatedQuestion;
    setSavedQuestions(updatedQuestions);

  };
  const handleImageUpload = async (file, isChallengeImage) => {
    try {
      setLoaderState(true)
      // Get the access token from the cookie
      const authToken = getCookie("access_token");

      // Create a FormData object to send the file
      const formData = new FormData();

      // Convert Blob URL to Blob data
      const response = await fetch(file);
      const blobData = await response.blob();

      // Append Blob data to FormData
      formData.append("file", blobData);

      // Determine the URL based on whether it's a challenge or a question image
      const uploadUrl = isChallengeImage
        ? apiUrl + "/api/v1/brand-admin/brand/challenge/logo"
        : apiUrl + "/api/v1/brand-admin/brand/challenge/logo"; // Replace with the actual URL

      // Use Axios to send the POST request to upload the file
      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // Güncelleme yapmak için doğrudan state'i kullanın
      if (isChallengeImage) {
        // Challenge image
      } else {
        // Question image
        const updatedQuestions = [...savedQuestions];
        // Gelen resmi, ilgili sorunun index'ine göre güncelleyin
        console.log(
          "ENDPOINTTEN GELEN VERI:" + uploadResponse.data.responseData
        );
        setSelectedQuestionImageResponseString(
          uploadResponse.data.responseData
        );
        setSavedQuestions(updatedQuestions);
        setLoaderState(false)

      }

      toast.success(
        isChallengeImage
          ? "Challenge image uploaded"
          : "Question image uploaded"
      );
    } catch (error) {
      setLoaderState(false)
      console.error("Dosya yüklenirken hata oluştu:", error);
      toast.error("Dosya yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const createQuestionsDataAndSendToParent = () => {
    // 'questions' verisini oluşturun
    const questionsData = savedQuestions.map((savedQuestion, index) => {
      return {
        order: index,
        text: savedQuestion.question,
        image: savedQuestion.image, // İmage bilgisi eklemelisiniz.
        choices: savedQuestion.answers.map((answer, answerIndex) => {
          return {
            order: answerIndex,
            text: answer,
            image: "string", // İmage bilgisi eklemelisiniz.
            score: 10,
          };
        }),
      };
    });

    // JSON verisini parent bileşene iletmek için bir prop fonksiyonunu kullanın
    onQuestionsDataReady(questionsData);
  };

  const moveQuestionUp = (index) => {
    if (index > 0) {
      const updatedQuestions = [...savedQuestions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index - 1];
      updatedQuestions[index - 1] = temp;
      setSavedQuestions(updatedQuestions);
    }
  };

  const moveQuestionDown = (index) => {
    if (index < savedQuestions.length - 1) {
      const updatedQuestions = [...savedQuestions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index + 1];
      updatedQuestions[index + 1] = temp;

      // Check if the current question and the next question are both saved
      if (
        index < savedQuestions.length - 1 &&
        updatedQuestions[index].question &&
        updatedQuestions[index].answers.length > 0 &&
        updatedQuestions[index + 1].question &&
        updatedQuestions[index + 1].answers.length > 0
      ) {
        setSavedQuestions(updatedQuestions);
      }
    }
  };

  const openPopup = (question, answers, index, image) => {
    setPopupContent({ question, answers });
    const updatedPopupsOpen = [...isPopupsOpen];
    updatedPopupsOpen[index] = true; // Set the selected question's popup to open
    setTempImageResponseString(image);
    setIsPopupsOpen(updatedPopupsOpen);
  };

  const closePopup = (index) => {
    const updatedPopupsOpen = [...isPopupsOpen];
    updatedPopupsOpen[index] = false; // Set the selected question's popup to closed
    setIsPopupsOpen(updatedPopupsOpen);
    createQuestionsDataAndSendToParent();
  };

  const deleteQuestion = (index) => {
    closePopup(index);
    const updatedQuestions = [...savedQuestions];
    const updatedPopupsOpen = [...isPopupsOpen];
    updatedPopupsOpen[index] = false;
    updatedQuestions.splice(index, 1);

    setSavedQuestions(updatedQuestions);
  };

  const editQuestion = (index) => {
    closePopup(index);
    const updatedQuestion = savedQuestions[index];
    const updatedPopupsOpen = [...isPopupsOpen];
    if (!selectedQuestionImageResponseString)
      setSelectedQuestionImageResponseString(tempImageResponseString);
    const updatedQuestionImageString =
      selectedQuestionImageResponseString || tempImageResponseString;
    const updatedPopupQuestion = popupContent.question;
    if (popupContent.question.length < 3) {
      toast.error("Soru en az 3 karakter uzunluğunda olmalıdır.");
      return;
    }

    console.log("popup answers : " + popupContent.answers);

    console.log("UZUNLUK" + popupContent.answers.length);
    const updatedPopupAnswers = popupContent.answers.filter(
      (answer) => answer.trim() !== ""
    );
    console.log("updated popup answers: " + updatedPopupAnswers);
    if (updatedPopupAnswers.length < 2) {
      toast.error("Lütfen en az iki cevap giriniz.");
      return;
    }

    updatedQuestion.question = updatedPopupQuestion;
    updatedQuestion.answers = updatedPopupAnswers;
    updatedQuestion.image = updatedQuestionImageString;

    const updatedQuestions = [...savedQuestions];
    updatedQuestions[index] = updatedQuestion;
    setSavedQuestions(updatedQuestions);
    updatedPopupsOpen[index] = false;
    setIsPopupsOpen(updatedPopupsOpen);
    setSelectedQuestionImageResponseString(undefined);
  };

  const handleAnswerChange = (id, text) => {
    const updatedText = text.charAt(0).toUpperCase() + text.slice(1);
    const updatedAnswerRows = answerRows.map((row) =>
      row.id === id ? { ...row, text: updatedText } : row
    );
    setAnswerRows(updatedAnswerRows);
  };

  const addAnswerRow = () => {
    if (answerCounter < 10) {
      setAnswerCounter(answerCounter + 1);

      const newRow = {
        id: answerCounter + 1,
        text: "", // Kullanıcının gireceği metin
      };

      setAnswerRows([...answerRows, newRow]);
    }
  };

  const questionControl = () => {
    if (currentQuestion === "") {
      toast.error(t("noQuestionError"));
      return;
    }
    if (currentQuestion.length < 3) {
      toast.error(t("questionLengthError"));
      return;
    }
    let imageToUse =
      selectedQuestionImageResponseString || currentQuestionImageResponseString;
    const savedQuestion = {
      question: currentQuestion,
      answers: answerRows
        .map((row) => row.text.trim())
        .filter((answer) => answer !== ""),
      image: imageToUse,
    };

    if (!savedQuestion.answers.every((answer) => answer.length >= 3)) {
      toast.error(t("answerLengthError"));
      return;
    }
    if (savedQuestion.answers.length < 2) {
      toast.error(t("minAnswerCount"));
      return;
    }
    if (new Set(savedQuestion.answers).size !== savedQuestion.answers.length) {
      toast.error(t("duplicateAnswerError"));
      return;
    }

    // Her cevabın minimum 3 karakter uzunluğunda olup olmadığını kontrol ediyoruz.
    else {
      handleSave(savedQuestion);
    }
  };

  const handleSave = (savedQuestion) => {
    // Check if the question is already present in savedQuestions
    const isQuestionAlreadySaved = savedQuestions.some(
      (question) => question.question === savedQuestion.question
    );
  
    if (!isQuestionAlreadySaved) {
      // If the question is not already present, add it to savedQuestions
      setSavedQuestions((prevSavedQuestions) => [...prevSavedQuestions, savedQuestion]);
    } else {
      // If the question is already present, remove the duplicate
      const updatedSavedQuestions = savedQuestions.filter(
        (question) => question.question !== savedQuestion.question
      );
      // Add the updated question list to savedQuestions
      setSavedQuestions(updatedSavedQuestions);
    }
  
    // Clear the current question-related state
    setCurrentQuestion("");
    setCurrentAnswers([]);
    setAnswerCounter(1);
    setAnswerRows([{ id: 1, text: "" }]);
  
    // Trigger necessary callbacks and resets
    onIsSavedChange(true);
    onCurrentQuestionImageChange("");
    setClearImage((prevClearImage) => (prevClearImage + 1) % 2);
    onResetQuestionImage();
  
    // Clear the input field
    const questionInput = document.querySelector(".challenge-creation-survey-question-input");
    if (questionInput) {
      questionInput.value = "";
    }
  
    // Update the parent component with the latest questions data
    createQuestionsDataAndSendToParent();
  };
  
  
  


  useEffect(() => {
    setCurrentQuestion(question);
  }, [question]);

  return (
    <div className="text-type-survey-answer-container">
      {loaderState && <Loader/>} 
      <div className="text-type-survey-answer-container-title">
        <div className="text-type-survey-answer-container-title-text">
        {t("answers")}<span style={{ color: "red" }}>*</span>
        </div>
        <span className="challenge-creation-input-container-header-subtitle">
          <img
            className="challenge-creation-date-description-icon"
            src={infoIconEmpty}
            alt="icon"
          />{" "}
          {t("min")} 3, {t("max")} 60 {t("character")}
        </span>
      </div>

      {answerRows.map((row) => (
        <div className="text-type-survey-answer-row" key={row.id}>
          <div className="text-type-survey-answer-icon">{row.id}</div>
          <input
            type="text"
            className="text-type-survey-answer-input"
            placeholder={t("enterAnswer")}
            value={row.text}
            onChange={(e) => handleAnswerChange(row.id, e.target.value)}
            maxLength={60}
            minLength={3}
          />
        </div>
      ))}

      {answerCounter < 10 && (
        <div className="text-type-survey-answer-row">
          <div className="text-type-survey-answer-icon">+</div>
          <button
            className="text-type-survey-answer-add-button"
            onClick={addAnswerRow}
          >
            {t("addAnswer")}
          </button>
        </div>
      )}

      <button
        className="text-type-survey-question-add-save-button"
        onClick={() => {
          questionControl();
        }}
      >
        {t("save")}
      </button>

      <hr />

      {savedQuestions.map((savedQuestion, index) => (
        <div key={index}>
          <div
            className={`text-type-survey-question-demo-container ${!isPopupsOpen[index]
                ? "text-type-survey-question-demo-container-clickable"
                : ""
              }`}
            onClick={() => {
              if (!isPopupsOpen[index]) {
                openPopup(
                  savedQuestion.question,
                  savedQuestion.answers,
                  index,
                  savedQuestion.image
                );
              }
            }}
          >
            {!isPopupsOpen[index] && (
              <div className="text-type-survey-question-demo-container">
                {savedQuestion.image && (
                  <img
                    src={`${imagesUrl}/${savedQuestion.image}`}
                    alt={`Soru Resmi ${index + 1}`}
                    className="text-type-survey-question-demo-image"
                  />
                )}
                <div className="text-type-survey-question-demo">
                  <div className="text-type-survey-question-demo-title">
                    {index + 1}
                  </div>
                  <div className="text-type-survey-question-demo-question">
                    {savedQuestion.question}
                  </div>
                </div>

                <div className="text-type-survey-question-answer-demo">
                  {savedQuestion.answers.map((answer, answerIndex) => (
                    <div
                      key={answerIndex}
                      className={`text-type-survey-question-answer-demo-answer-${answerIndex + 1
                        } text-type-survey-question-answer-demo-answer`}
                    >
                      <div className="text-type-survey-question-answer-demo-check-area"></div>
                      <span>{answer}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {isPopupsOpen[index] && (
              <div className="text-type-survey-popup-container">
                <div className="text-type-survey-popup">
                  <div className="text-type-survey-popup-content">
                    <div className="challenge-creation-survey-question-img-container">
                      <div className="challenge-creation-survey-question-img-title">
                      {t("QuestionImage")}
                      </div>
                      {savedQuestion.image && (
                        <>


                          <ChallengeImageDropzone
                            onImageUpload={(file) =>
                              handleImageUpload(file, false)
                            }
                            currentImg={savedQuestion.image}
                          />
                          <button className="challenge-creation-image-question-image-delete-button" onClick={() => deleteQuestionImage(index)}>
                          {t("deleteImage")}
                          </button>
                        </>
                      )}
                      {!savedQuestion.image && (
                        <ChallengeImageDropzone
                          onImageUpload={(file) =>
                            handleImageUpload(file, false)
                          }
                          currentImg={savedQuestion.image}
                        />
                      )}
                      <label>{t("allowedFileTypes")} png, jpg</label>
                    </div>
                    <h3>{t("question")}</h3>
                    <input
                      type="text"
                      maxLength={140}
                      minLength={3}
                      value={popupContent.question}
                      onChange={(e) =>
                        setPopupContent({
                          ...popupContent,
                          question: e.target.value,
                        })
                      }
                    />
                    <h3>{t("answers")}</h3>
                    <div className="text-type-survey-popup-answers-container">
                      {popupContent.answers.map((answer, answerIndex) => (
                        <div
                          key={answerIndex}
                          className="text-type-survey-popup-answer-row"
                        >
                          <input
                            type="text"
                            minLength={3}
                            maxLength={60}
                            value={answer}
                            onChange={(e) => {
                              const updatedAnswers = [...popupContent.answers];
                              updatedAnswers[answerIndex] = e.target.value;
                              setPopupContent({
                                ...popupContent,
                                answers: updatedAnswers,
                              });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="text-type-survey-popup-button-container">
                      <button
                        onClick={() => closePopup(index)}
                        className="text-type-survey-popup-button"
                      >
                       {t("cancel")}
                      </button>
                      <button
                        onClick={() => deleteQuestion(index)}
                        className="text-type-survey-popup-button delete"
                      >
                        {t("delete")}
                      </button>
                      <button
                        onClick={() => editQuestion(index)}
                        className="text-type-survey-popup-button save"
                      >
                        {t("save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <hr />

          {index !== savedQuestions.length - 1 && (
            <div>
              <button
                className="text-type-survey-move-button-down"
                onClick={() => moveQuestionDown(index)}
              >
                <img src={orderImg} alt="Sirayi Degistir" />
              </button>
              <hr />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TextTypeSurvey;
