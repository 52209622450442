import React, { useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";

const FilterDialogCustom = forwardRef(
  (
    { onFilteredItemsChange, filterItems, title = "filter", checkedItems = [] },
    ref
  ) => {
    const { t } = useTranslation("challengeDashboard");
    const [localCheckedItems, setLocalCheckedItems] = useState({});

    useEffect(() => {
      const checkedItemsObject = filterItems.reduce((acc, item) => {
        acc[item.role] = checkedItems.includes(item.role);
        return acc;
      }, {});
      setLocalCheckedItems(checkedItemsObject);
      console.log(filterItems);
    }, [checkedItems, filterItems]);

    const handleCheckboxChange = (role) => {
      setLocalCheckedItems((prev) => {
        const newCheckedItems = {
          ...prev,
          [role]: !prev[role],
        };
        const selectedItems = Object.keys(newCheckedItems).filter(
          (key) => newCheckedItems[key]
        );
        if (onFilteredItemsChange) {
          onFilteredItemsChange(selectedItems);
        }
        return newCheckedItems;
      });
    };

    const resetCheckboxes = () => {
      setLocalCheckedItems({});
      if (onFilteredItemsChange) {
        onFilteredItemsChange([]);
      }
    };

    return (
      <div className="filter-dialog" ref={ref}>
        <div className="filter-dialog-part filter-dialog-part-type  padding-left-disabled padding-right-disabled">
          <div className="filter-dialog-title filter-dialog-title-v2">{title}</div>
          <div className="filter-dialog-row-container-vol-2">
            {filterItems.map((item) => (
              <div
                key={item.role}
                className={`filter-dialog-item-v2 ${
                  localCheckedItems[item.role] ? "filter-v2-checked" : ""
                }`}
                onClick={() => handleCheckboxChange(item.role)}
              >
               {item.role}

              </div>
            ))}
          </div>
        </div>

        <div className="filter-dialog-button-container">
          <div className="filter-dialog-reset-button" onClick={resetCheckboxes}>
            {t("reset")}
          </div>
        </div>
      </div>
    );
  }
);

export default FilterDialogCustom;
