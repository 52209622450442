import React from "react";
import "../../../assets/css/ContentCard.css";
import noImageToShow from "../../../assets/media/placeholder.png";
import facebookIcon from "../../../assets/media/facebook.svg";
import shareIcon from "../../../assets/media/share.png";
import likeIcon from "../../../assets/media/like.png";
import ThreeDotsIcon from "../../../assets/media/threeDots.svg";
import {
  InstagramEmbed,
  PlaceholderEmbed,
  TikTokEmbed,
  XEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";
import { FacebookEmbed } from "react-social-media-embed";
import { useTranslation } from "react-i18next";
import userPng from "../../../assets/media/user.png";
import FacebookEmbedPost from "../../embed/facebookEmbedPost";
import InstagramEmbedPost from "../../embed/instagramEmbedPost";
import { imagesUrl } from "../../../config";
const ContentCardTop = ({
  provider,
  link,
  providerIcon,
  profilePicture,
  fullName,
  id,
  interaction,
  sotyCoin,
  creationDate,
  content,
  highestOffer,
  bottomType,
}) => {
  const { t } = useTranslation("contentList");

  console.log(content);
  return (
    <div className="content-card-top-container">
      <div>
        <div className="content-card-top-header">
          <div className="content-card-top-header-left-container">
            <img
              className="content-card-top-header-profile-picture"
              src={profilePicture || userPng}
            />
            <div className="content-card-top-header-profile-info">
              <div className="content-card-top-header-profile-username">
                {fullName}
              </div>
              <div className="content-card-top-header-sotier-id">
                Soty ID: <span>{id}</span>
              </div>
            </div>
          </div>
          <div className="content-card-top-header-right-container">
            <img
              className="content-card-top-options-button"
              src={ThreeDotsIcon}
              alt="..."
            />
          </div>
        </div>

        {(!provider || !link) && (
          <img
            className="content-card-top-content"
            src={noImageToShow}
            alt="img"
          />
        )}

        <img
          className="content-image"
          src={
            content.thumbnail && content.thumbnail.startsWith("https")
              ? noImageToShow
              : imagesUrl + "/" + content.thumbnail
          }
          alt="img"
          onClick={() => {
            if (content.link) {
              window.open(content.link, "_blank");
            }
          }}
        />
      </div>
      <div>
        <div className="content-card-top-description-container">
          <div className="content-card-top-description-container-left">
            <div className="content-card-top-description-earned-coin-container">
              {t("earnedSotyCoin")} : <span>{sotyCoin}</span>
            </div>
            <div className="content-card-top-description-creation-date">
              {t("creationDate")}: <span>{creationDate}</span>
            </div>
            {bottomType === "offer" ? (
              <div className="content-card-top-description-creation-date">
                {t("highestOfferDesc")}:{" "}
                <span className="soty-color">
                  {highestOffer === 0 ? t("none") : highestOffer}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
          <img
            className="content-card-top-description-container-right"
            src={providerIcon !== undefined ? providerIcon : noImageToShow}
            alt="icn"
          />
        </div>
        <div className="content-card-top-interaction-container">
          {content.hashTags?.length > 0 ? (
            content.hashTags.map((hashtag, index) => (
              <div className="content-card-top-hashtag" key={index}>
                #{hashtag}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="content-card-top-interaction-container">
          {content.tags?.length > 0 ? (
            content.tags.map((tag, index) => (
              <div className="content-card-top-hashtag" key={index}>
                @{tag}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="content-card-top-interaction-container">
          {false && (
            <>
              {" "}
              <div className="content-card-top-interaction-type">
                <img src={shareIcon} alt="int" />
                <div className="content-card-top-interaction-type-count">
                  20
                </div>
              </div>
              <div className="content-card-top-interaction-type">
                <img src={shareIcon} alt="int" />
                <div className="content-card-top-interaction-type-count">
                  20
                </div>
              </div>
              <div className="content-card-top-interaction-type">
                <img src={shareIcon} alt="int" />
                <div className="content-card-top-interaction-type-count">
                  20
                </div>
              </div>
              <div className="content-card-top-interaction-type">
                <img src={shareIcon} alt="int" />
                <div className="content-card-top-interaction-type-count">
                  20
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContentCardTop;
