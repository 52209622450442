import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import arrow from "../../../../../../assets/media/Icon feather-arrow-right-circle.svg";
import infoIcon from "../../../../../../assets/media/Action Panel.svg";

const ChallengeDetailTotalCard = ({ headerTitle, title, subtitle, data, buttonText }) => {
    const showData = data ? data : "0";
    const { t, i18n } = useTranslation("challengeCreation");
    const formatNumber = (number) => {
        
        if (!isNaN(number)) {
            return new Intl.NumberFormat().format(number);
        }
        return number;
    };

    return (
        <div className="challenge-detail-total-card">
            <div className="challenge-detail-total-card-top">
                <div className="challenge-detail-total-card-header">
                    <div className="challenge-detail-total-card-header-title">
                        {headerTitle || t('error.notFound')}
                    </div>
                    <img className="challenge-detail-total-card-header-info" src={infoIcon} alt="info" />
                </div>
                <div className="challenge-detail-total-card-title">
                    {title || t('error.notFound')}<div className="challenge-detail-total-card-subtitle">({subtitle || t('error.notFound')})</div>
                </div>
            </div>
            <div className="challenge-detail-total-card-bottom">
                <div className="challenge-detail-total-card-data">
                    {formatNumber(showData)}
                    
                </div>
                <div className="challenge-detail-total-card-separator" />
                <div className={`challenge-detail-total-card-button`}>
                    {t("viewInAnalysisPage")}
                    
                    <img src={arrow} alt={"icon"} />
                </div>
            </div>
        </div>
    );
};

ChallengeDetailTotalCard.propTypes = {
    headerTitle: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default ChallengeDetailTotalCard;
