import React from "react";
import PropTypes from "prop-types";
import "../../assets/css/DynamicPagination.css";
import downArrow from '../../assets/media/down-arrow.png';
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const visiblePages = 2; // Number of visible pages on each side of the current page

        // Calculate the range of pages to display
        let startPage = Math.max(1, currentPage - visiblePages);
        let endPage = Math.min(totalPages, currentPage + visiblePages);

        // Adjust startPage and endPage to ensure there are at least 5 pages visible
        if (endPage - startPage < visiblePages * 2) {
            if (startPage === 1) {
                endPage = Math.min(totalPages, startPage + visiblePages * 2);
            } else {
                startPage = Math.max(1, endPage - visiblePages * 2);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li
                    key={i}
                    className={i === currentPage ? "dynamic-pagination-li active" : "dynamic-pagination-li"}
                >
                    <button
                        className="dynamic-pagination-button"
                        style={{ width: "50px" }} // Set the desired width, adjust as needed
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <div className="dynamic-pagination">
            <ul className="dynamic-pagination-ul">
                {currentPage !== 1 && (
                    <li className="dynamic-pagination-li">
                        <button
                            className="dynamic-pagination-button dynamic-pagination-button-previous"
                            onClick={() => onPageChange(currentPage - 1)}
                        >
                            <img src={downArrow} />
                        </button>
                    </li>
                )}

                {currentPage === 1 && (
                    <li className="dynamic-pagination-li">
                        <button
                            className="dynamic-pagination-button dynamic-pagination-button-previous dynamic-pagination-button-disabled"
                            disabled
                        >
                            <img src={downArrow} />
                        </button>
                    </li>
                )}

                {renderPageNumbers()}


                {currentPage !== totalPages && (
                    <li className="dynamic-pagination-li">
                        <button
                            className="dynamic-pagination-button dynamic-pagination-button-next"
                            onClick={() => onPageChange(currentPage + 1)}
                        >
                            <img src={downArrow} />
                        </button>
                    </li>
                )}

                {currentPage === totalPages && (
                    <li className="dynamic-pagination-li">
                        <button
                            className="dynamic-pagination-button dynamic-pagination-button-next dynamic-pagination-button-disabled"
                            disabled
                        >
                            <img src={downArrow} />
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
