import React, { useEffect, useState } from "react";
import shoppingCardIcon from "../../../assets/media/Icon ionic-ios-cart.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ContentCardBottomOffer = ({ onOffer, link, onDelete, OFFERED, onOfferHistory }) => {
  const { t } = useTranslation("contentList");
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);

  return (
    <div className="content-card-bottom-container">
      {OFFERED && (
        <div
          className="content-card-bottom-default-card-button"
          onClick={onOffer}
        >
          <div className="content-card-bottom-default-card-button-title">
            {t("OFFERED")}
          </div>
        </div>
      )}
      <div
        className="content-card-bottom-default-card-button"
        onClick={onOfferHistory}
      >
        <div className="content-card-bottom-default-card-button-title">
          {t("offerHistory")}
        </div>
      </div>
      <div
        className="content-card-bottom-default-card-button-delete"
        onClick={onDelete}
      >
        <div className="content-card-bottom-default-card-button-title">
          {t("delete")}
        </div>
      </div>
    </div>
  );
};

export default ContentCardBottomOffer;
