import React, { useState } from 'react';
import CustomImageDrop from './imageDrop';
import ImageModal from './imageModal';

const DynamicInputList = ({ t, placeholder, inputType = 'text', title, isMandatory, titleDescription, addButtonText, maxInputs, onInputsChange, onImagesChange }) => {
  const [inputs, setInputs] = useState(['']);
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');


  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
    if (onInputsChange) {
      onInputsChange(newInputs);
    }
  };

  const handleAddInput = () => {
    setInputs([...inputs, '']);
    if (onInputsChange) {
      onInputsChange([...inputs, '']);
    }
  };

  const handleRemoveInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    if (onInputsChange) {
      onInputsChange(newInputs);
    }
  };

  const handleImageInputChange = (event, index) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = reader.result;
          return newImages;
        });
        if (onImagesChange) {
          onImagesChange(images);
        }
      };
    }
  };

  const removeImage = (index) => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = null;
      return newImages;
    });
    if (onImagesChange) {
      onImagesChange(images);
    }
  };

  const handleImageDrop = (file, index) => {
    // Handle the dropped image file here
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = reader.result;
          return newImages;
        });
        if (onImagesChange) {
          onImagesChange(images);
        }
      };
    } else {
      alert('Please drop an image file.');
    }
  };



  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <div className="challenge-creation-input-container">
      <div className="challenge-creation-input-container-header">
        <div className="challenge-creation-input-container-header-title">
          {title && title}{isMandatory && <span>*</span>}{title && ":"}
        </div>
        <div className="challenge-creation-input-container-header-subtitle">
          {titleDescription && titleDescription}
        </div>
      </div>
      <hr />
      <div className="challenge-creation-input-container-content-wrapper">
      {showModal && <ImageModal imageUrl={selectedImageUrl} onClose={closeModal} />}

        <div className="dynamic-input-list-container">
          {inputs.map((input, index) => (
            <div key={index} className="dynamic-input-list input-row">
              <span className="dynamic-input-list-input-counter">{index + 1}.</span>

              {inputType === 'file' ? (
                <>

                  {images[index] ? (
                    <div className="dynamic-input-list-uploaded-image-container">
                      <div className='dynamic-input-list-uploaded-image-wrapper'>
                        <img
                          src={images[index]}
                          alt="Uploaded"
                          className="dynamic-input-list-uploaded-image"
                          onClick={() => openModal(images[index])}
                        />
                      </div>

                      {inputs.length > 1 && (
                        <div className="discount-to-x-product-button segment-criteria-delete-button" onClick={() => removeImage(index)}>
                          <div className="discount-to-x-product-button-minus">
                            -
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='dynamic-input-list-image-upload-row'>
                      <CustomImageDrop
                        onImageDrop={(file) => handleImageDrop(file, index)}
                        image={images[index]}
                        handleImageInputChange={(e) => handleImageInputChange(e, index)}
                        handleRemoveImage={() => removeImage(index)}
                      />
                      {inputs.length > 1 && (
                        <div className="discount-to-x-product-button segment-criteria-delete-button" onClick={() => handleRemoveInput(index)}>
                          <div className="discount-to-x-product-button-minus">
                            -
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                <input
                  type={inputType}
                  value={input}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder={placeholder}
                  className="dynamic-input-list-dynamic-input"
                />
                {inputs.length > 1 && (
                  <div
                    className="discount-to-x-product-button segment-criteria-delete-button"
                    onClick={() => handleRemoveInput(index)}
                  >
                    <div className="discount-to-x-product-button-minus">-</div>
                  </div>
                )}
                </>
              )}
            </div>
          ))}

          {(!maxInputs || inputs.length < maxInputs) && (
            <div className="segment-criteria-add-button-container">
              <div className="segment-criteria-add-button" onClick={handleAddInput}>
                <div className="discount-to-x-product-button-plus">+</div>
              </div>
              <span>{addButtonText || t("add")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicInputList;
