import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import arrow from "../../../../../../assets/media/arrow.png";
import downArrow from "../../../../../../assets/media/down-arrow.png";
import "../../../../../../assets/css/ChallengeDetail.css";
import "../../../../../../assets/css/ContentList.css";
import facebookIcon from "../../../../../../assets/media/facebook.svg";
import instagramIcon from "../../../../../../assets/media/instagram.svg";
import twitterIcon from "../../../../../../assets/media/x-twitter.svg";
import youtubeIcon from "../../../../../../assets/media/youtube.svg";
import tiktokIcon from "../../../../../../assets/media/tiktok.svg";
import TotalCards from "../../../../../cards/TotalCards";
import ViewInSotyAppButton from "../../../../../button/viewInSotyAppButton";
import EndChallengeButton from "../../../../../button/endChallengeButton";
import ChallengeAnalysisButton from "../../../../../button/challengeAnalysisButton";
import { getCookie } from "../../../../auth/tokenUtils";
import { apiUrl, imagesUrl } from "../../../../../../config";
import { isMyTokenExpired } from "../../../../auth/tokenUtils";
import axios from "axios";
import { toast } from "react-toastify";
import ContentCard from "../../../../../cards/ContentCard";
import ContentLibraryButton from "../../../../../button/contentLibraryButton";
import ContentCartButton from "../../../../../button/contentCartButton";
import { InstagramEmbed } from 'react-social-media-embed';
import { FacebookEmbed } from 'react-social-media-embed';
import CallAPI from "../../../../../api/callApi";
import userPng from '../../../../../../assets/media/user.png';
import Pagination from "../../../../../tables/Pagination";
import ItemsPerPageButton from "../../../../../button/itemsPerPageButton";
import Loader from "../../../../../popup/loader";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import FilterButton from "../../../../../button/filterButton";
import SortByButton from "../../../../../button/sortByButton";
import DatePickerButton from "../../../../../button/datePickerButton";
import InstagramIcon from '../../../../../../assets/media/instagram-flat.png';
const ContentList = () => {
    const { t } = useTranslation("contentList");
    const navigate = useNavigate();
    const [theChallenge, setTheChallenge] = useState();
    const [disabledCards, setDisabledCards] = useState([]);
    const [contentCards, setContentCards] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [currentPerPage, setCurrentPerPage] = useState(20);
    const [loaderState, setLoaderState] = useState(false);
    const [deleteAll, setDeleteAll] = useState(false);
    const [topData, setTopData] = useState({
        postCount: 0,
        offerCount: 0,
        acceptedOfferCount: 0,
        declinedOfferCount: 0,
        pendingOfferCount: 0,
        totalEarnedCoins: 0,
    });

    const [checkboxStates, setCheckboxStates] = useState([]);
    const [followerCountMax, setFollowerCountMax] = useState();
    const [followerCountMin, setFollowerCountMin] = useState();
    const [viewCountMax, setViewCountMax] = useState();
    const [viewCountMin, setViewCountMin] = useState();
    const [interactionMax, setInteractionMax] = useState();
    const [interactionMin, setInteractionMin] = useState();


    const [provider, setProvider] = useState({ name: 'INSTAGRAM', icon: instagramIcon });
    const [itemPerPage, setItemPerPage] = useState(20);
    const [filterStartDate, setFilterStartDate] = useState();
    const [filterEndDate, setFilterEndDate] = useState();
    const [sortChallengesBy, setSortChallengesBy] = useState();
    const [campaignFilter, setCampaignFilter] = useState(" ");
    const [levelRange, setLevelRange] = useState([1, 5]);
    const [basketContents, setBasketContents] = useState(JSON.parse(localStorage.getItem('contentCards')));

    //PARAMETERS FOR ENDPOINT

    const [isVideo, setIsVideo] = useState(false);
    const [socialProvider, setSocialProvider] = useState(provider.name);
    const [minSotierLevel, setMinSotierLevel] = useState();
    const [maxSotierLevel, setMaxSotierLevel] = useState();
    const [minFollowerCount, setMinFollowerCount] = useState();
    const [maxFollowerCount, setMaxFollowerCount] = useState();
    const [minInteractionRate, setMinInteractionRate] = useState();
    const [maxInteractionRate, setMaxInteractionRate] = useState();



    const handleCheckboxSelection = (selectedCheckboxes) => {
        setCheckboxStates(selectedCheckboxes);
    };

    const handleSocialMediaTypeSelect = (selectedSocialMedia) => {
        setProvider(selectedSocialMedia)
    };

    const locationArray = [
        [t("contents"), "/brand/srm/content"],
    ];



    const fetchPosts = async ({
        isVideo,
        minFollowerCount,
        maxFollowerCount,
        minInteractionRate,
        maxInteractionRate,
        minSotierLevel,
        maxSotierLevel,
        provider,
        filterStartDate,
        filterEndDate,
        sortDirection
    }) => {
        const endPoint = `${apiUrl}/api/v1/brand-admin/posts?page=${currentPage || 0}&size=${currentPerPage || 20}`;
        let maxChallengeCount;
        let minChallengeCount;
        if(provider.name === "INSTAGRAM"){

            switch (parseInt(maxSotierLevel)) {
                case 1:
                  maxChallengeCount = 1;
                  break;
                case 2:
                  maxChallengeCount = 5;
                  break;
                case 3:
                  maxChallengeCount = 10;
                  break;
                case 4:
                  maxChallengeCount = 20;
                  break;
                case 5:
                  maxChallengeCount = undefined; // veya isteğe bağlı olarak başka bir değer atanabilir
                  break;
                default:
                  maxChallengeCount = undefined;
                  break;
              }
            switch (parseInt(minSotierLevel)) {
                case 1:
                  minChallengeCount = 1;
                  break;
                case 2:
                  minChallengeCount = 5;
                  break;
                case 3:
                  minChallengeCount = 10;
                  break;
                case 4:
                  minChallengeCount = 20;
                  break;
                case 5:
                  minChallengeCount = 20;
                  break;
                default:
                  minChallengeCount = undefined;
                  break;
              }
        }
        if(provider.name === "FACEBOOK"){

            switch (parseInt(maxSotierLevel)) {
                case 1:
                  maxChallengeCount = 1;
                  break;
                case 2:
                  maxChallengeCount = 5;
                  break;
                case 3:
                  maxChallengeCount = undefined; // veya isteğe bağlı olarak başka bir değer atanabilir
                  break;
                default:
                  maxChallengeCount = undefined;
                  break;
              }
            switch (parseInt(minSotierLevel)) {
                case 1:
                  minChallengeCount = 1;
                  break;
                case 2:
                  minChallengeCount = 5;
                  break;
                case 3:
                  minChallengeCount = 5;
                  break;
                default:
                  minChallengeCount = undefined;
                  break;
              }
        }
        
        
      
        
        

        const optionalParams = [
            provider ? `socialProvider=${provider.name}` : '',
            isVideo ? `isVideo=${isVideo}` : '',
            minChallengeCount && minChallengeCount !== 0 ? `minSotierLevel=${minChallengeCount}` : '',
            maxChallengeCount && maxChallengeCount !== 0 ? `maxSotierLevel=${maxChallengeCount}` : '',
            minFollowerCount && minFollowerCount !== "0" ? `minFollowerCount=${minFollowerCount}` : '',
            maxFollowerCount && maxFollowerCount !== "0" ? `maxFollowerCount=${maxFollowerCount}` : '',
            minInteractionRate && minInteractionRate !== "0" ? `minInteractionRate=${minInteractionRate / 100}` : '',
            maxInteractionRate && maxInteractionRate !== "0" ? `maxInteractionRate=${maxInteractionRate / 100}` : '',

            filterStartDate ? `from=${filterStartDate}` : '',
            filterEndDate ? `to=${filterEndDate}` : '',
            sortDirection ? `sortDirection=${sortDirection}` : ''
        ].filter(param => param !== '').join('&');

        const finalEndPoint = optionalParams ? `${endPoint}&${optionalParams}` : endPoint;
        const splitEndPoint = finalEndPoint.split('&').join('\n');
  

        const newUrl = `${window.location.pathname}?${optionalParams}`;
        
        try {
            setLoaderState(true);
            const response = await CallAPI({
                method: "GET",
                endPoint: finalEndPoint
            });
            setContentCards(response.data.responseData);

            setTotalPages(response.data.pageMetadata.totalPage);

            setLoaderState(false);
        } catch (error) {
            setLoaderState(false); // Ensure to stop loader in case of error as well
        }
    };

    const fetchTopData = async ({
        isVideo,
        minFollowerCount,
        maxFollowerCount,
        minInteractionRate,
        maxInteractionRate,
        minSotierLevel,
        maxSotierLevel,
        provider,
        filterStartDate,
        filterEndDate,
        sortDirection
    }) => {
        const endPoint = `${apiUrl}/api/v1/brand-admin/posts/info?`;
        let maxChallengeCount;
        let minChallengeCount;
        if(provider.name === "INSTAGRAM"){

            switch (parseInt(maxSotierLevel)) {
                case 1:
                  maxChallengeCount = 1;
                  break;
                case 2:
                  maxChallengeCount = 5;
                  break;
                case 3:
                  maxChallengeCount = 10;
                  break;
                case 4:
                  maxChallengeCount = 20;
                  break;
                case 5:
                  maxChallengeCount = undefined; // veya isteğe bağlı olarak başka bir değer atanabilir
                  break;
                default:
                  maxChallengeCount = undefined;
                  break;
              }
            switch (parseInt(minSotierLevel)) {
                case 1:
                  minChallengeCount = 1;
                  break;
                case 2:
                  minChallengeCount = 5;
                  break;
                case 3:
                  minChallengeCount = 10;
                  break;
                case 4:
                  minChallengeCount = 20;
                  break;
                case 5:
                  minChallengeCount = 20;
                  break;
                default:
                  minChallengeCount = undefined;
                  break;
              }
        }
        if(provider.name === "FACEBOOK"){

            switch (parseInt(maxSotierLevel)) {
                case 1:
                  maxChallengeCount = 1;
                  break;
                case 2:
                  maxChallengeCount = 5;
                  break;
                case 3:
                  maxChallengeCount = undefined; // veya isteğe bağlı olarak başka bir değer atanabilir
                  break;
                default:
                  maxChallengeCount = undefined;
                  break;
              }
            switch (parseInt(minSotierLevel)) {
                case 1:
                  minChallengeCount = 1;
                  break;
                case 2:
                  minChallengeCount = 5;
                  break;
                case 3:
                  minChallengeCount = 5;
                  break;
                default:
                  minChallengeCount = undefined;
                  break;
              }
        }
        
        
      
        
        

        const optionalParams = [
            provider ? `socialProvider=${provider.name}` : '',
            isVideo ? `isVideo=${isVideo}` : '',
            minChallengeCount && minChallengeCount !== 0 ? `minSotierLevel=${minChallengeCount}` : '',
            maxChallengeCount && maxChallengeCount !== 0 ? `maxSotierLevel=${maxChallengeCount}` : '',
            minFollowerCount && minFollowerCount !== "0" ? `minFollowerCount=${minFollowerCount}` : '',
            maxFollowerCount && maxFollowerCount !== "0" ? `maxFollowerCount=${maxFollowerCount}` : '',
            minInteractionRate && minInteractionRate !== "0" ? `minInteractionRate=${minInteractionRate / 100}` : '',
            maxInteractionRate && maxInteractionRate !== "0" ? `maxInteractionRate=${maxInteractionRate / 100}` : '',

            filterStartDate ? `from=${filterStartDate}` : '',
            filterEndDate ? `to=${filterEndDate}` : '',
            sortDirection ? `sortDirection=${sortDirection}` : ''
        ].filter(param => param !== '').join('&');

        const finalEndPoint = optionalParams ? `${endPoint}&${optionalParams}` : endPoint;
        setLoaderState(true);
        const response = await CallAPI({
            method: "GET",
            endPoint: finalEndPoint
        });

        setTopData(response.data.responseData);
        setLoaderState(false);
    };

    useEffect(() => {

        localStorage.getItem('contentCards');
        fetchTopData({
            provider,
            filterStartDate,
            filterEndDate,
            sortDirection: 'DESC'
        });
        fetchPosts({

            provider,
            filterStartDate,
            filterEndDate,
            sortDirection: 'DESC'
        });
    }, []);


    const currentPerPageChange = (e, setter) => {
        setCurrentPage(0)
        setter(e);


    }


    const dataSets = [
        {
            headerTitle: t("total"),
            title: t("contentCount"),
            subtitle: t("SCE"),
            data: topData.postCount,
        },
        {
            headerTitle: t("total"),
            title: t("requestedContents"),
            subtitle: t("SSC"),
            data: topData.offerCount,
        },
        {
            headerTitle: t("total"),
            title: t("approvedRequests"),
            subtitle: t("CVC"),
            data: topData.acceptedOfferCount,
        },
        {
            headerTitle: t("total"),
            title: t("rejectedRequests"),
            subtitle: t("CCC"),
            data: topData.declinedOfferCount,
        },
        {
            headerTitle: t("total"),
            title: t("pendingRequests"),
            subtitle: t("CRC"),
            data: topData.pendingOfferCount,
        },
        {
            headerTitle: t("total"),
            title: t("sharedSotyCoin"),
            subtitle: t("CSR"),
            data: topData.totalEarnedCoins,
        }
    ];



    const getSocialIcon = (provider) => {
        switch (provider) {
            case "FACEBOOK":
                return facebookIcon;
            case "INSTAGRAM":
                return instagramIcon;
            case "TWITTER":
                return twitterIcon;
            case "YOUTUBE":
                return youtubeIcon;
            case "TIKTOK":
                return tiktokIcon;
            default:
                return null;
        }
    };





    const handleContentLibraryButton = () => {
        navigate('/brand/srm/content/library');
    };
    const handleContentBasketButton = () => {
        navigate('/brand/srm/content/basket');
    }
    const onBasketClick = (index) => {
        setDisabledCards((prevDisabledCards) => {
            const newDisabledCards = [...prevDisabledCards];
            newDisabledCards[index] = true;
            return newDisabledCards;
        });
        toast.success(t("addedToBasket"))
        const contentToSave = contentCards[index];
        let savedContentCards = JSON.parse(localStorage.getItem('contentCards')) || [];
        savedContentCards.push(contentToSave);
        localStorage.setItem('contentCards', JSON.stringify(savedContentCards));
        setBasketContents(savedContentCards);
    };




    useEffect(() => {
        setDisabledCards(new Array(contentCards.length).fill(false));
    }, [contentCards]);


    const formatDateForCreation = (dateString) => {
        const now = new Date();
        const date = new Date(dateString);
        const diffMs = now - date;
        const diffMins = Math.floor(diffMs / (1000 * 60));
        const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

        if (diffMins < 60) {
            return `${diffMins} ${t("minutesAgo")}`;
        } else if (diffHours < 24) {
            return `${diffHours} ${t("hoursAgo")}`;
        } else {
            return date.toLocaleString("tr-TR", {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        }
    };



    const sortItems = [
        { id: 3, text: t("newestCreationDate"), string: "newestCreationDate" },
        { id: 4, text: t("oldestCreationDate"), string: "oldestCreationDate" },
    ];
    const handleFilterChange = (e) => {

        if (!e || e.length === 0 || e.length === 4) {
            setCampaignFilter(" ");

        }
        else {
            setCampaignFilter(e.map(item => item.campaignType).join(','));

        }


    }
    const handleSortClick = (itemId, itemText, itemString) => {

        setSortChallengesBy(itemString)

    };

    const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {

        if (selectedEndDate) {
            const originalStartDate = new Date(selectedStartDate);
            const originalEndDate = new Date(selectedEndDate);

            originalStartDate.setHours(0, 0, 0);
            originalEndDate.setHours(23, 59, 59);

            const formattedStartDate = `${originalStartDate.getFullYear()}-${(originalStartDate.getMonth() + 1).toString().padStart(2, '0')}-${originalStartDate.getDate().toString().padStart(2, '0')}T${originalStartDate.getHours().toString().padStart(2, '0')}:${originalStartDate.getMinutes().toString().padStart(2, '0')}:${originalStartDate.getSeconds().toString().padStart(2, '0')}`;
            const formattedEndDate = `${originalEndDate.getFullYear()}-${(originalEndDate.getMonth() + 1).toString().padStart(2, '0')}-${originalEndDate.getDate().toString().padStart(2, '0')}T${originalEndDate.getHours().toString().padStart(2, '0')}:${originalEndDate.getMinutes().toString().padStart(2, '0')}:${originalEndDate.getSeconds().toString().padStart(2, '0')}`;

            setFilterStartDate(formattedStartDate);
            setFilterEndDate(formattedEndDate);
        }
        else {
            setFilterStartDate();
            setFilterEndDate();
        }


    }

    const checkboxData = [
        { title: t("video"), contentType: 'video' },

    ];




    const onSocialMediaTypeSelect = (socialMedia) => {
        setProvider(socialMedia)
    }
    const onInputBlur = (e, setter, maxValue) => {
        if (!e || !e.target || typeof e.target.value === 'undefined') {
            return;
        }

        const numberValue = Number(e.target.value);
        if (numberValue <= 0) {
            setter('');
        } else if (maxValue && numberValue > maxValue) {
            setter(maxValue);
        } else {
            setter(numberValue);
        }
    };


    useEffect(() => {
        let sortDirection;
        if (sortChallengesBy === "oldestCreationDate") {
            sortDirection = "ASC"
        }
        else {
            sortDirection = "DESC"
        }
        const isVideo = checkboxStates.some(item => item.contentType === 'video')
        fetchTopData({
            isVideo: isVideo,
            minFollowerCount: followerCountMin,
            maxFollowerCount: followerCountMax,
            minInteractionRate: interactionMin,
            maxInteractionRate: interactionMax,
            minSotierLevel: levelRange[0],
            maxSotierLevel: levelRange[1],
            provider,
            filterStartDate,
            filterEndDate,
            sortDirection: sortDirection
        });
        fetchPosts({
            isVideo: isVideo,
            minFollowerCount: followerCountMin,
            maxFollowerCount: followerCountMax,
            minInteractionRate: interactionMin,
            maxInteractionRate: interactionMax,
            minSotierLevel: levelRange[0],
            maxSotierLevel: levelRange[1],
            provider,
            filterStartDate,
            filterEndDate,
            sortDirection: sortDirection
        });




        // Burada gerekirse filtreleme veya sıralama işlemleri yapılabilir veya başka bir fetch işlemi tetiklenebilir.
    }, [currentPage, deleteAll, sortChallengesBy, currentPerPage, followerCountMax, levelRange, followerCountMin, viewCountMax, viewCountMin, interactionMax, interactionMin, campaignFilter, provider, filterStartDate, filterEndDate, checkboxStates]);

    
    const onInputChange = (value, setValue, maxValue) => {
        if (maxValue) {
            if (value > 0 && value <= maxValue) {
                setValue(value);
            }
            else if (value == "" || value == 0) {
                setValue('');
            }
        }
        else {
            if (value > 0) {
                setValue(value);
            }
            else if (value == "" || value == 0) {
                setValue('');
            }
        }

    };

   
    return (
        <div className="dashboard-container">
            {loaderState && <Loader />}
            <NavigationBar locationArray={locationArray} />
            <div className="dashboard-controls-container">
                <FilterButton
                    checkboxData={checkboxData}
                    onCheckboxSelection={handleCheckboxSelection}
                    eventType="content"
                    onSocialMediaTypeSelect={handleSocialMediaTypeSelect}
                    followerCountMax={followerCountMax}
                    followerCountMin={followerCountMin}
                    setFollowerCountMax={setFollowerCountMax}
                    setFollowerCountMin={setFollowerCountMin}
                    viewCountMax={viewCountMax}
                    viewCountMin={viewCountMin}
                    setViewCountMax={setViewCountMax}
                    setViewCountMin={setViewCountMin}
                    interactionMax={interactionMax}
                    interactionMin={interactionMin}
                    setInteractionMax={setInteractionMax}
                    setInteractionMin={setInteractionMin}
                    onInputBlur={onInputBlur}
                    levelRange={levelRange}
                    setLevelRange={setLevelRange}
                    onInputChange={(value, setter, maxValue) => { onInputChange(value, setter, maxValue) }}
                    setDeleteAll={setDeleteAll}

                />
                <SortByButton items={sortItems} onItemSelect={handleSortClick} />
                <DatePickerButton onDateChange={handleFilterDatesChanged} />

            </div>

            <div className="challenge-detail-cards-container">
                {dataSets.map((data, index) => (
                    <TotalCards key={index} {...data} />
                ))}
            </div>
            <div className="challenge-detail-buttons-container">

                <ContentLibraryButton onClick={handleContentLibraryButton} />
                <ContentCartButton counter={basketContents?.length || 0} onClick={handleContentBasketButton} />
            </div>
            <div className="content-list-pagination-container">
                <div className="visibility-hidden">
                    <ItemsPerPageButton onSelectItem={(e) => { currentPerPageChange(e, setCurrentPerPage) }} title={t("contentPerPage")} />
                </div>
                <Pagination
                    currentPage={currentPage + 1 || null}
                    totalPages={totalPages}
                    onPageChange={(page) => { setCurrentPage(page - 1) }}
                />
                <ItemsPerPageButton onSelectItem={(e) => { currentPerPageChange(e, setCurrentPerPage) }} title={t("contentPerPage")} />
            </div>

            <div className="content-list-container">
                {!loaderState && contentCards.map((content, index) => (
                    <ContentCard
                        key={index}
                        content={content}
                        provider={content.provider}
                        link={content.link}
                        providerIcon={content.provider === "FACEBOOK" ? facebookIcon : content.provider === "INSTAGRAM" ? instagramIcon : content.provider === "YOUTUBE" ? youtubeIcon : content.provider === "TIKTOK" ? tiktokIcon : twitterIcon}
                        onClick={() => onBasketClick(index)}
                        disabled={disabledCards[index]}
                        profilePicture={content.sotierProfilePhoto === undefined ? userPng : imagesUrl + "/" + content.sotierProfilePhoto}
                        fullName={content.sotierName}
                        id={content.sotierId}
                        interaction={content.interaction}
                        sotyCoin={content.totalEarnedCoins || 0}
                        creationDate={formatDateForCreation(content.published)}
                        bottomType={"default"}

                    />
                ))}
            </div>
        </div>
    );
};

export default ContentList;
