import React from 'react';
import { useTranslation } from "react-i18next";
import RangeSlider from '../utils/rangeSlider';
import AutoScrollToTop from '../utils/autoScrollToTop';

const AreYouSure = ({ questionText, isCondition, cancelText, acceptText, onAccept, isNegative, content, onCancel }) => {
    const { t, i18n } = useTranslation("segmentation");

    AutoScrollToTop();




    return (
        <div className="approval-popup-container">
            <div className="approval-popup-header">
                {questionText}
            </div>

            <div className="approval-popup-content">
                {content && content}
            </div>
            <div className="approval-popup-buttons">
                {isCondition ?
                    <>
                        <button className="approval-popup-button cancel" onClick={() => { onCancel() }}>
                            {cancelText}
                        </button>

                        <button className={`approval-popup-button ${isNegative ? "delete" : ""}`} onClick={() => { onAccept() }}>
                            {acceptText}
                        </button>
                    </>

                    :
                    <>
                        <button className="approval-popup-button cancel" onClick={() => { onCancel() }}>
                        {t("close")}
                    </button>
            </>

                }

        </div>
        </div >
    );
};

export default AreYouSure;
