import React, { useEffect, useState } from 'react';
import SearchIcon from '../../assets/media/SearchIcon.svg';
import checkIcon from '../../assets/media/Checklist.svg';

const FilterOption = ({ isChecked, onClick, title}) => (
  <div className="filter-dialog-row">
    <div
      className={`filter-dialog-checkbox-container ${isChecked ? 'filter-dialog-checkbox-container-active' : ''}`}
      onClick={onClick}
    >
      <img className="filter-title-checkicon" src={checkIcon} alt="Checkbox" />
    </div>
    <div className="filter-title">{title}</div>
  </div>
);

const SearchSelectDialog = ({ listItems, onItemsSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [checkboxStates, setCheckboxStates] = useState(listItems.map(() => false));
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxClick = index => {
    const updatedCheckboxStates = [...checkboxStates];
    updatedCheckboxStates[index] = !updatedCheckboxStates[index];
    setCheckboxStates(updatedCheckboxStates);

    const selectedItem = listItems[index];
    if (updatedCheckboxStates[index]) {
      setSelectedItems(prev => [...prev, selectedItem]);
    } else {
      setSelectedItems(prev => prev.filter(item => item !== selectedItem));
    }
  };

  const filteredListItems = listItems.filter(item =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    onItemsSelect(selectedItems)
  },[selectedItems])

  return (
    <div className="select-dialog-with-search-box">
      <div className="select-dialog-search-container">
        <img className="select-dialog-search-icon" src={SearchIcon} alt="search" />
        <input
          className="select-dialog-search-input"
          type="text"
          placeholder="search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="select-dialog-container">
        {filteredListItems.map((item, index) => (
          <div className="select-dialog-container-selectbox" key={index}>
            <FilterOption
              isChecked={checkboxStates[index]}
              onClick={() => handleCheckboxClick(index)}
              title={item.title}
            />
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default SearchSelectDialog;
