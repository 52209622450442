import React, { useEffect, useState, useMemo } from "react";
import "../../../../../assets/css/BrandAdminRolePopup.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import closeIcon from "../../../../../assets/media/close.png";
import { apiUrl } from "../../../../../config.js";
import { useTranslation } from "react-i18next";
import Loader from "../../../../popup/loader.js";

function Popup(props) {
  const navigate = useNavigate();
  const { roleData: initialRoleData = { roleCategories: [] } } = props;
  const { t } = useTranslation("brandAdminRoleList");
  const [groupName, setGroupName] = useState(initialRoleData.name || "");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [roleData, setRoleData] = useState(initialRoleData);
  const [localRoleData, setLocalRoleData] = useState(props.roleData);
  const [loaderState, setLoaderState] = useState(false);

  useEffect(() => {
    setLocalRoleData(props.roleData);
  }, [props.roleData]);

  useEffect(() => {
    setRoleData(initialRoleData);
  }, [initialRoleData]);

  const originalRoleData = useMemo(() => initialRoleData, [initialRoleData]);

  function isValid(name) {
    return name.trim() !== "";
  }

  const resetToOriginal = () => {
    setGroupName(originalRoleData.name || "");
    setSelectAllChecked(false); // Reset selectAllChecked
    setRoleData(originalRoleData);
    props.setTrigger(false);
  };

  const generateRoleRows = () => {
    if (!roleData.roleCategories) return null;

    return roleData.roleCategories.map((category, categoryIndex) => (
      <div key={categoryIndex} className="popup-role-row">
        <div className="role-title">{category.name}</div>
        <div className="role-inputs-container">
          {category.roles.map((role, roleIndex) => (
            <div key={roleIndex} className="role-input-wrapper">
              <input
                className="role-select"
                type="checkbox"
                key={`${categoryIndex}-${roleIndex}-${selectAllChecked}`}
                defaultChecked={selectAllChecked || role.selected}
                onChange={() =>
                  handleRoleCheckboxChange(categoryIndex, roleIndex)
                }
              />
              <div className="role-select-title">{role.name}</div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);

    const updatedRoleData = { ...localRoleData }; // Use localRoleData here
    updatedRoleData.roleCategories.forEach((category) => {
      category.roles.forEach((role) => {
        role.selected = !selectAllChecked;
      });
    });

    // Update the localRoleData state
    setLocalRoleData(updatedRoleData);
  };

  const handleRoleCheckboxChange = (categoryIndex, roleIndex) => {
    const updatedRoleData = { ...roleData };
    updatedRoleData.roleCategories[categoryIndex].roles[roleIndex].selected =
      !roleData.roleCategories[categoryIndex].roles[roleIndex].selected;

    // Update roleData state
    setRoleData(updatedRoleData);
  };

  const validateRolesSelected = () => {
    return roleData.roleCategories.some((category) =>
      category.roles.some((role) => role.selected)
    );
  };

  const approve = () => {
    if (!validateRolesSelected()) {
      toast.error(t("pleaseSelectAtLeastOneRole"));
      return;
    }

    setLoaderState(true);

    if (props.roleData.name) {
      console.log("put");
      console.log(roleData);

      const formattedRoles = roleData.roleCategories.reduce((acc, category) => {
        category.roles.forEach((role) => {
          if (role.selected) {
            acc.push(role.key);
          }
        });

        return acc;
      }, []);

      const endPoint = `${apiUrl}/api/v1/admin/role/BRAND/group/${props.roleData.name}`;

      const authToken = getCookie("access_token");

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      };

      axios
        .put(endPoint, { roles: formattedRoles }, { headers })
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);
            toast.success("Rol Güncellendi");
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          setLoaderState(false);

          if (error.response.status === 401) {
            navigate("/sign-in-realm");
          } else {
            toast.error("Kaydedilemedi");
            console.error(error);
          }
        });
    } else {
      console.log("post");
      console.log(roleData);
      if (!groupName || groupName === "" || groupName === false) {
        toast.error(t("roleNameCannotBeEmpty"));
        return;
      }
      const formattedRolesForPost = roleData.roleCategories.reduce(
        (acc, category) => {
          category.roles.forEach((role) => {
            if (role.selected) {
              acc.push(role.key);
            }
          });
          return acc;
        },
        []
      );

      const newGroupName = groupName;

      const postApiUrl = `${apiUrl}/api/v1/admin/role/BRAND/group`;

      const authToken = getCookie("access_token");

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      };

      axios
        .post(
          postApiUrl,
          { name: newGroupName, roles: formattedRolesForPost },
          { headers }
        )
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);
            toast.success("Yeni rol grubu oluşturuldu");
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          setLoaderState(false);

          if (error.response.status === 401) {
            navigate("/sign-in-realm");
          } else {
            toast.error("Kaydedilemedi");
            console.error(error);
          }
        });
    }
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  return props.trigger ? (
    <>
      {loaderState && <Loader />}
      <div className="popup">
        <div className="popup-header">
          <div className="popup-title">{props.title}</div>
          <button
            className="popup-close-button"
            onClick={() => props.setTrigger(false)}
          >
            <img className="popup-close-button-icon" src={closeIcon} alt="X" />
          </button>
        </div>
        <hr />
        <div className="popup-role-container">
          <div className="popup-role-container-title">
            {t("roleName")}
            <span>*</span>
          </div>
          <input
            className="popup-role-name-input"
            placeholder={props.roleData?.name || "Enter a role name"}
            type="text"
            readOnly={isValid(props.roleData?.name)}
            onBlur={(e) => setGroupName(e.target.value)}
          />
          <div className="popup-role-container-title">
            {t("rolePermissions")}
          </div>
          <div className="popup-role-row">
            <div className="role-title">{t("adminAccess")}</div>
            <div className="role-inputs-container">
              <div className="role-input-wrapper">
                <input
                  className="role-select"
                  type="checkbox"
                  onChange={handleSelectAllChange}
                  checked={selectAllChecked}
                />
                <div className="role-select-title">{t("selectAll")}</div>
              </div>
            </div>
          </div>
          {generateRoleRows()}
        </div>

        <div className="popup-role-button-container">
          <button className="popup-button-cancel" onClick={resetToOriginal}>
            {t("cancel")}
          </button>
          <button className="popup-button-approve" onClick={approve}>
            {t("save")}
          </button>
        </div>
      </div>
    </>
  ) : (
    ""
  );
}

export default Popup;
