import React, { useState, useEffect } from 'react';
import checkIcon from '../../assets/media/Checklist.svg';
import { useTranslation } from 'react-i18next';
import SocialMediaButtonContainer from '../utils/sotier_list/socialMediaButtonContainer';
import InstagramIcon from '../../assets/media/instagram-flat.png';
import FacebookIcon from '../../assets/media/facebook-flat.png';
import TwitterIcon from '../../assets/media/twitter-flat.png';
import YoutubeIcon from '../../assets/media/youtube-flat.png';
import TiktokIcon from '../../assets/media/tiktok.svg';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const FilterOption = ({ isChecked, onClick, title }) => (
    <div className="filter-dialog-row">
        <div
            className={`filter-dialog-checkbox-container ${isChecked ? 'filter-dialog-checkbox-container-active' : ''}`}
            onClick={onClick}
        >
            <img className="filter-title-checkicon" src={checkIcon} alt="Checkbox" />
        </div>
        <div className="filter-title">{title}</div>
    </div>
);
const FilterDialogContent = ({
    checkboxData,
    onCheckboxSelection,
    onItemsSelect,
    onSocialMediaTypeSelect,
    viewCountMin,
    setViewCountMin,
    viewCountMax,
    setViewCountMax,
    interactionMin,
    setInteractionMin,
    interactionMax,
    setInteractionMax,
    followerCountMin,
    setFollowerCountMin,
    followerCountMax,
    setFollowerCountMax,
    onInputBlur,
    onInputChange,
    levelRange,
    setLevelRange,
    setDeleteAll
}) => {
    const { t } = useTranslation('contentList');
    const [selectedSocialMedia, setSelectedSocialMedia] = useState();
    const [maxLevelRange, setMaxLevelRange] = useState(5);
    // Initial state setup with safety checks
    const initialCheckboxStates = {
        contentTypes: checkboxData ? Array(checkboxData.length).fill(false) : []
    };

    useEffect(() => {
        if (selectedSocialMedia) {
            console.log(selectedSocialMedia, "SELECTEDDDDDDDDDDDDDDDD")
            if (selectedSocialMedia === "FACEBOOK") {
                setMaxLevelRange(3);
                if(levelRange[1] > 3){
                    setLevelRange([1,3]);
                }
            }
            else if (selectedSocialMedia === "INSTAGRAM") {
                setMaxLevelRange(5);
                if(levelRange[0] === 1 && levelRange[1] === 3){
                    setLevelRange([1,5])
                }
                
            }
        }


    }, [selectedSocialMedia])

    const socialMediaArray = [
        { name: 'INSTAGRAM', icon: InstagramIcon },
        { name: 'FACEBOOK', icon: FacebookIcon }
        // { name: 'YOUTUBE', icon: YoutubeIcon },
        // { name: 'TWITTER', icon: TwitterIcon },
        // { name: 'TIKTOK', icon: TiktokIcon }
    ];

    const [checkboxStates, setCheckboxStates] = useState(() => {
        const savedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || initialCheckboxStates;
        return savedCheckboxStates;
    });

    useEffect(() => {
        const storedSocialMedia = localStorage.getItem('selectedSocialMedia');
        if (storedSocialMedia) {
            setSelectedSocialMedia(JSON.parse(storedSocialMedia).name ? JSON.parse(storedSocialMedia).name : "INSTAGRAM")
            onSocialMediaTypeSelect(JSON.parse(storedSocialMedia));

        }
        const savedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || initialCheckboxStates;
        setCheckboxStates(savedCheckboxStates);

    }, []);

    useEffect(() => {
        localStorage.setItem('checkboxStates', JSON.stringify(checkboxStates));
    }, [checkboxStates]);

    const handleCheckboxClick = (category, index) => {
        if (!checkboxData) return;
        const newCheckboxStates = { ...checkboxStates };

        // Ensure that newCheckboxStates[category] is initialized as an array if it's not already
        if (!newCheckboxStates[category]) {
            newCheckboxStates[category] = [];
        }

        newCheckboxStates[category][index] = !newCheckboxStates[category][index];
        setCheckboxStates(newCheckboxStates);

        // Ensure to filter out undefined or null values in selectedContentTypes and selectedStatus
        const selectedContentTypes = newCheckboxStates.contentTypes
            .map((isChecked, idx) => (isChecked ? checkboxData[idx] : null))
            .filter(Boolean);

       
        const selectedCheckboxes = [
            ...selectedContentTypes
        ];

        onCheckboxSelection(selectedCheckboxes);
    };


    const resetCheckboxes = () => {
        setCheckboxStates(initialCheckboxStates);
        setLevelRange([])
        localStorage.removeItem('checkboxStates');
        onCheckboxSelection(null, null);
        onItemsSelect([]);
        setInteractionMin('');
        setInteractionMax('');
        setFollowerCountMin('');
        setFollowerCountMax('');
        setDeleteAll(true);
    };

    const handleSocialMedia = (e) => {
        setCheckboxStates(initialCheckboxStates);
        localStorage.removeItem('checkboxStates');
        onCheckboxSelection(null, null);
        onItemsSelect([]);
        onSocialMediaTypeSelect(e);
        setSelectedSocialMedia(e.name);

        localStorage.setItem('selectedSocialMedia', JSON.stringify(e));
    };




    return (
        <div className="filter-dialog">
            <SocialMediaButtonContainer socialMediaArray={socialMediaArray} InstagramIcon={InstagramIcon} onSelect={handleSocialMedia} />

            <div className="filter-dialog-wrapper">
                <div className="filter-dialog-part filter-dialog-part-type">
                    <div className="filter-dialog-title">{t("contentType")}</div>
                    <div className="filter-dialog-row-container">
                        {checkboxData && checkboxData.map((data, index) => (
                            <FilterOption
                                key={index}
                                isChecked={checkboxStates.contentTypes[index]}
                                onClick={() => handleCheckboxClick('contentTypes', index)}
                                title={data.title}
                            />
                        ))}
                    </div>
                </div>

                <div className='filter-dialog-part-type filter-dialog-part'>
                    <div className="filter-dialog-title">{t("sotierLevels")}</div>
                    <div className='filter-dialog-range-slider-container'>
                        <RangeSlider
                            id={'range-slider-level'}
                            className={"filter-dialog-part-type-range-slider"}
                            min={1}
                            max={maxLevelRange}
                            value={levelRange}
                            onInput={setLevelRange}
                        />
                        <div className='filter-dialog-range-slider-value'>
                            <div>{levelRange ? levelRange[0] : 1}</div>
                            <div>{levelRange && levelRange[1]}</div>

                        </div>

                    </div>


                </div>

                <div className='filter-dialog-part-type filter-dialog-part'>
                    <div className='filter-dialog-interaction-rate-container'>
                        <div className='filter-dialog-interaction-rate-title filter-dialog-title'>
                            {t("interactionRate")}
                        </div>
                        <div className='filter-dialog-interaction-rate-input-container'>
                            <div className='filter-dialog-interaction-rate-input-wrapper'>
                                <label>{t("min")} %</label>
                                <input
                                    type='number'
                                    value={interactionMin}
                                    onChange={(e) => onInputChange(e.target.value, setInteractionMin, 100)}
                                    onBlur={(e) => onInputBlur(e.target.value, setInteractionMin, 100)}
                                    max={100}
                                />
                            </div>
                            
                            <div className='filter-dialog-interaction-rate-input-wrapper'>
                                <label>{t("max")} %</label>
                                <input
                                    type='number'
                                    value={interactionMax}
                                    onChange={(e) => onInputChange(e.target.value, setInteractionMax, 100)}
                                    onBlur={(e) => onInputBlur(e.target.value, setInteractionMax, 100)}
                                    max={100}
                                    
                                />
                                
                            </div>
                        </div>
                    </div>


                </div>
                <div className='filter-dialog-part-type filter-dialog-part'>
                    <div className='filter-dialog-interaction-rate-container'>
                        <div className='filter-dialog-interaction-rate-title filter-dialog-title'>
                            {t("followerCount")}
                        </div>
                        <div className='filter-dialog-interaction-rate-input-container'>
                            <div className='filter-dialog-interaction-rate-input-wrapper'>
                                <label>{t("min")}</label>
                                <input
                                    type='number'
                                    value={followerCountMin}
                                    onChange={(e) => onInputChange(e.target.value, setFollowerCountMin)}
                                    onBlur={(e) => onInputBlur(e.target.value, setFollowerCountMin)}
                                />
                            </div>
                            <div className='filter-dialog-interaction-rate-input-wrapper'>
                                <label>{t("max")}</label>
                                <input
                                    type='number'
                                    value={followerCountMax}
                                    onChange={(e) => onInputChange(e.target.value, setFollowerCountMax)}
                                    onBlur={(e) => onInputBlur(e.target.value, setFollowerCountMax)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='filter-dialog-interaction-rate-container display-none'>
                        <div className='filter-dialog-interaction-rate-title filter-dialog-title'>
                            {t("viewCount")}
                        </div>
                        <div className='filter-dialog-interaction-rate-input-container'>
                            <div className='filter-dialog-interaction-rate-input-wrapper'>
                                <label>{t("min")}</label>
                                <input
                                    type='number'
                                    placeholder='0'
                                    value={viewCountMin}
                                    onChange={(e) => onInputChange(e.target.value, setViewCountMin)}
                                    onBlur={(e) => onInputBlur(e.target.value, setViewCountMin)}
                                />
                            </div>
                            <div className='filter-dialog-interaction-rate-input-wrapper'>
                                <label>{t("max")}</label>
                                <input
                                    type='number'
                                    placeholder='100'
                                    value={viewCountMax}
                                    onChange={(e) => onInputChange(e.target.value, setViewCountMax)}
                                    onBlur={(e) => onInputBlur(e.target.value, setViewCountMax)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="filter-dialog-button-container">
                <div className="filter-dialog-reset-button" onClick={resetCheckboxes}>
                    {t('reset')}
                </div>
            </div>
        </div>
    );
};

export default FilterDialogContent;
