import React from "react";
import tick from "../../../../../assets/media/tickInteraction.svg"; // Doğru yol ile iconu import edin
import infoIconEmpty from "../../../../../assets/media/infoIconEmpty.svg"; // Doğru yol ile iconu import edin

const ParticipationAmount = ({
  singleParticipate,
  setSingleParticipate,
  t,
}) => {
  return (
    <div className="coin-folding-container">
      <div className="coin-folding-input-container">
        <div className="coin-folding-input-wrapper">
          {/* Single Participation Option */}
          <div
            className={`coin-folding-input ${
              singleParticipate && "coin-folding-input-active"
            }`}
            onClick={() => setSingleParticipate(true)}
          >
            {t("single")}
            {singleParticipate && <img src={tick} alt="tick" />}
          </div>

          {/* Multiple Participation Option */}
          <div
            className={`coin-folding-input ${
              !singleParticipate && "coin-folding-input-active"
            }`}
            onClick={() => setSingleParticipate(false)}
          >
            {t("multiple")}
            {!singleParticipate && <img src={tick} alt="tick" />}
          </div>
        </div>

        {/* Info description */}
        <div className="challenge-creation-date-description-row">
          <img
            className="challenge-creation-date-description-icon"
            src={infoIconEmpty}
            alt="icon"
          />
          <div className="challenge-creation-date-description">
            {t("campaignParticipationCountDesc")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipationAmount;
