import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/SideBar.css";
import { getCookie } from "../pages/auth/tokenUtils";
import report from "../../assets/media/Icon material-report-problem.svg";
import SRM from "../../assets/media/Icon ionic-ios-people.svg";
import analysis from "../../assets/media/analysis.svg";
import weChat from "../../assets/media/Icon simple-wechat.svg";
import dashboardIcon from "../../assets/media/dashboard.svg";
import challengeIcon from "../../assets/media/campaign.svg";
import logoutIcon from "../../assets/media/Icon open-account-logout.svg";
import settings from "../../assets/media/Icon ionic-ios-settings.svg";
import { isMyTokenExpired } from "../pages/auth/tokenUtils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FAQ from "../../assets/media/Path 13.svg";
import SidebarRecursive from "./SidebarRecursive";
import CallAPI from "../api/callApi";
import { apiUrl } from "../../config";
import { userRoles } from "../utils/userRoles";
import { isValidLink } from "../utils/RouteUtils";

const SideBarNew = ({ mobile }) => {
  const [userRoleList, setUserRoleList] = useState([]);
  const [userSigned, setUserSigned] = useState(false);
  const [userType, setUserType] = useState(false); // IF USER REALM === FALSE, IF USER BRAND === TRUE
  const [BrandProfileFull, setBrandProfileFull] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const navigate = useNavigate(); // useNavigate hook'unu kullanarak yönlendirme işlemi için bir fonksiyon alın
  const jwtToken = getCookie("access_token");
  const { t } = useTranslation("sideBar");
  const isProfileFull = async () => {
    const response = await CallAPI({
      method: "GET",
      endPoint: apiUrl + "/api/v1/brand-admin/brand",
    });

    if (
      response.data.responseData.contractApproved === undefined ||
      response.data.responseData.contractApproved === false
    ) {
      setBrandProfileFull(false);
      return false;
    } else {
      setBrandProfileFull(true);
      setUserRoleList(userRoles());
      return true;
    }
  };

  useEffect(() => {
    if (!jwtToken) {
      // Handle the case where the token is empty or undefined
      console.log("JWT token is empty or undefined");
      setUserSigned(false);
      return;
    }

    // Decode the JWT token
    const tokenParts = jwtToken.split(".");
    if (tokenParts.length !== 3) {
      // Handle invalid JWT token format
      console.log("Invalid JWT token format");
      return;
    }

    const decodedPayload = JSON.parse(atob(tokenParts[1]));

    if (decodedPayload.azp === "brand-client") {
      const token = getCookie("access_token");
      if (!token) {
        console.error("Access token is missing.");
        navigate("/sign-in-brand");
        return;
      }
      if (isMyTokenExpired(token)) {
        toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
        navigate("/sign-in-brand");
      }
    } else if (decodedPayload.azp === "admin-client") {
      const token = getCookie("access_token");
      if (!token) {
        console.error("Access token is missing.");
        navigate("/sign-in-realm");
        return;
      }
      if (isMyTokenExpired(token)) {
        toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
        navigate("/sign-in-realm");
      }
    }
  }, []);

  useEffect(() => {
    if (!jwtToken) {
      // Handle the case where the token is empty or undefined
      console.log("JWT token is empty or undefined");
      setUserSigned(false);
      return;
    } else if (isMyTokenExpired(jwtToken)) {
      setUserSigned(false);
      return;
    }
    // Decode the JWT token
    const tokenParts = jwtToken.split(".");
    if (tokenParts.length !== 3) {
      // Handle invalid JWT token format
      console.log("Invalid JWT token format");
      return;
    }

    const decodedPayload = JSON.parse(atob(tokenParts[1]));

    // Check the "azp" field
    if (decodedPayload.azp === "brand-client") {
      isProfileFull();
      setUserType(true);
      setUserSigned(true);
    } else if (decodedPayload.azp === "admin-client") {
      setUserType(false);
      setUserSigned(true);
    } else {
      // Handle other cases if needed
      console.log('User has an unknown "azp" value');
    }
  }, [jwtToken]);

  const depth = 0;

  // Helper function to apply isValidLink to menu items recursively
  const applyIsValidLink = (items) => {
    return items.map((item) => ({
      ...item,
      isValidLink: isValidLink(item.value),
      items: item.items ? applyIsValidLink(item.items) : undefined,
    }));
  };

  // Define items for brand users
  const Branditems = applyIsValidLink([
    { value: "/brand/dashboard", label: t("dashboard"), img: dashboardIcon },
    userRoleList?.includes("LIST_BRAND_CHALLENGES") && {
      value: "/brand/challenges",
      label: t("challenges"),
      items: [
        { value: "/brand/challenges/list", label: t("challengeList") },
        {
          value: "/brand/challenges/challenge-creation",
          label: t("createChallenge"),
        },
      ],
      img: challengeIcon,
    },
    {
      value: "/brand/srm",
      label: t("SRM"),
      items: [
        {
          value: "/brand/srm/sotier/list",
          label: t("Sotier"),
        },
        userRoleList?.includes("LIST_CAMPAIGNS") && {
          value: "/brand/srm/campaign",
          label: t("Campaigns"),
          items: [
            { value: "/brand/srm/campaign/create", label: t("createCampaign") },
            { value: "/brand/srm/campaign/list", label: t("campaignList") },
            {
              value: "/brand/srm/campaign/special-campaign/create",
              label: t("CustomCampaignsCreate"),
            },
          ],
        },
        userRoleList?.includes("LIST_CAMPAIGNS") && {
          value: "/brand/srm/corporate-campaign",
          label: t("corporateCampaigns"),
          items: [
            {
              value: "/brand/srm/corporate-campaign/create",
              label: t("corporateCampaignsCreate"),
            },
          ],
        },
        userRoleList?.includes("LIST_SEGMENT") && {
          value: "/brand/srm/segment",
          label: t("Segments"),
          items: [
            { value: "/brand/srm/segment/create", label: t("segmentCreate") },
          ],
        },
        userRoleList?.includes("LIST_POSTS") && {
          value: "/brand/srm/content",
          label: t("Contents"),
          items: [
            { value: "/brand/srm/content/library", label: t("contentLibrary") },
          ],
        },
      ].filter(Boolean), // Filter out any false values
      img: SRM,
    },
    userRoleList?.includes("LIST_BAN") && {
      value: "/brand/reports",
      label: t("REPORT"),
      img: report,
    },
    { value: "/brand/analysis", label: t("ANALYSIS"), img: analysis },
    userRoleList?.includes("LIST_NOTIFICATION") && {
      value: "/brand/inbox/push",
      label: t("INBOX"),
      img: weChat,
    },
    {
      value: "/brand/settings",
      label: t("SETTINGS"),
      img: settings,
      items: [
        userRoleList?.includes("VIEW_BRAND") && {
          value: "/brand/settings/profile",
          label: t("profileInfo"),
        },
        userRoleList?.includes("LIST_BRAND_USERS") && {
          value: "/brand/settings/users",
          label: t("users"),
        },
        userRoleList?.includes("LIST_BRAND_ROLE") && {
          value: "/brand/settings/roles",
          label: t("brandAdminRoleList"),
        },
        { value: "/brand/settings/logs", label: t("logs") },
        { value: "/brand/settings/packages", label: t("packages") },
      ].filter(Boolean),
    },
    { value: "/brand/faq", label: t("F.A.Q"), img: FAQ },
    { value: "/sign-in-brand", label: t("logout"), img: logoutIcon },
  ]);

  // Define items for realm users
  const Realmitems = applyIsValidLink([
    {
      value: "/challenges",
      label: t("challenges"),
      items: [{ value: "/challenges/list", label: t("challengeList") }],
      img: challengeIcon,
    },
    { value: "/brand-application-list", label: t("brandApplicationList") },
    { value: "/brands", label: t("brands") },
    {
      value: "/reports",
      label: t("REPORT"),
      img: report,
    },
    {
      value: "/analysis",
      label: t("ANALYSIS"),
      img: analysis,
    },
    {
      value: "/settings",
      label: t("SETTINGS"),
      img: settings,
      items: [
        {
          value: "/settings/users",
          label: t("users"),
        },
        { value: "/settings/roles", label: t("brandAdminRoleList") },
      ],
    },
    {
      value: "/faq",
      label: t("F.A.Q"),
      img: FAQ,
    },
    {
      value: "/sign-in-realm",
      label: t("logout"),
      img: logoutIcon,
    },
  ]);

  return userSigned ? (
    <div className={`SideBar ${mobile ? "mobile-sidebar" : ""}`}>
      {userType ? (
        <SidebarRecursive
          items={
            BrandProfileFull
              ? Branditems
              : [
                  {
                    value: "/brand/settings",
                    label: t("SETTINGS"),
                    img: settings,
                    items: [
                      {
                        value: "/brand/settings/profile",
                        label: t("profileInfo"),
                      },
                    ],
                  },
                  {
                    value: "/sign-in-brand",
                    label: t("logout"),
                    img: logoutIcon,
                  },
                ]
          }
          setClickedItem={(item) => {
            if (item.isValidLink) {
              navigate(item.value);
            }
          }}
          clickedItem={clickedItem}
          depth={depth}
        />
      ) : (
        <SidebarRecursive
          items={Realmitems}
          setClickedItem={(item) => {
            if (item.isValidLink) {
              navigate(item.value);
            }
          }}
          clickedItem={clickedItem}
          depth={depth}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default SideBarNew;
