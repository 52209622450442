import React, { useEffect, useState } from "react";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import locationIcon from "../../../../../../assets/media/locationIcon.svg";
import mailIcon from "../../../../../../assets/media/mail.svg";
import { apiUrl, imagesUrl } from "../../../../../../config";
import CallAPI from "../../../../../api/callApi";
import InputContainer from "../../../../../utils/inputContainer";
import { toast } from "react-toastify";
import Loader from "../../../../../popup/loader";

const GiftSotyCoin = () => {
  const { t } = useTranslation("sotierList");
  const { id } = useParams();
  const [sotier, setSotier] = useState(null);
  const [loaderState, setLoaderState] = useState(false);
  // State for storing the input values
  const [coinAmount, setCoinAmount] = useState("");
  const [coinDescription, setCoinDescription] = useState("");

  // State for tracking input errors
  const [coinAmountError, setCoinAmountError] = useState(false);
  const [coinDescriptionError, setCoinDescriptionError] = useState(false);

  useEffect(() => {
    getSotier(id); // Fetch sotier data based on id
  }, [id]);

  const getSotier = async (sotierId) => {
    const response = await CallAPI({
      method: "get",
      endPoint: apiUrl + "/api/v1/brand-admin/sotier/" + sotierId + "/profile",
    });
    setSotier(response.data.responseData);
  };

  const locationArray = [
    [t("sotierList"), "/brand/srm/sotier/list"],
    [
      sotier?.userName ? sotier?.userName : t("profile"),
      `/brand/srm/sotier/profile/${id}`,
    ],
    [t("giftSotyCoin"), `/brand/srm/sotier/profile/${id}/gift`],
  ];

  const onSubmit = async () => {
    // Validation for coinAmount
    if (coinAmount < 1 || coinAmount > 100000) {
      setCoinAmountError(true);
      toast.error(t("coinAmountMustBeBetween1And100000"));

      return;
    } else {
      setCoinAmountError(false);
    }

    // Validation for coinDescription
    if (coinDescription.trim().length < 1) {
      setCoinDescriptionError(true);
      toast.error(t("descriptionCannotBeEmpty"));

      return;
    } else {
      setCoinDescriptionError(false);
    }

    setLoaderState(true);
    await CallAPI({
      method: "post",
      endPoint: apiUrl + "/api/v1/brand-admin/sotier/gift/request",
      body: {
        sotiers: [id],
        coin: coinAmount,
        message: coinDescription,
      },
      successMessage: t("coinSent"),
      errorMessage: t("anErrorAcurredWhileSendingCoin"),
    });
    setCoinDescription("");
    setCoinAmount("");
    setLoaderState(false);
  };

  return (
    <div className="dashboard-container profile-dashboard-background">
      <NavigationBar locationArray={locationArray} />
      {loaderState && <Loader />}
      <div className="profile-dashboard">
        {/* Sotier Profile Section */}
        <div className="sotier-profile-row-container profile-card">
          <div className="sotier-profile-row-top">
            <div className="sotier-profile-row-left">
              <div className="sotier-profile-credentials-container">
                <img
                  className="sotier-profile-picture"
                  src={
                    sotier?.logo
                      ? imagesUrl + "/" + sotier?.logo
                      : placeholderImg
                  }
                  alt={t("profilePictureAlt")}
                />
                <div className="sotier-profile-credentials">
                  <div className="sotier-profile-name-container">
                    <div>{sotier?.userName}</div>
                    <div>{sotier?.firstName}</div>
                    <div>{sotier?.lastName}</div>
                  </div>
                  <div className="sotier-profile-contact-container">
                    <div>
                      {t("sotierId")}: {sotier?.sotierId}
                    </div>
                    <div>
                      <img src={locationIcon} alt={t("locationAlt")} />
                      {sotier?.city || t("notAvailable")}
                    </div>
                    <div>
                      <img src={mailIcon} alt={t("mailAlt")} />
                      {sotier?.email || ""}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      {t("birthDateTitle")}
                    </div>
                    <div className="sotier-profile-credential">
                      {sotier?.birthDate
                        ? new Date(sotier.birthDate).toLocaleDateString(
                            localStorage.getItem("language"),
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            }
                          )
                        : t("notAvailable")}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      {t("membershipDateTitle")}
                    </div>
                    <div className="sotier-profile-credential">
                      {sotier?.membershipDate
                        ? new Date(sotier.membershipDate).toLocaleDateString(
                            localStorage.getItem("language"),
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            }
                          )
                        : t("notAvailable")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Gift Soty Coin Amount Input */}
        <InputContainer
          title={t("giftSotyCoin")}
          isMandatory={true}
          titleDescription={t("giftSotyCoinSubtitle")}
        >
          <input
            id="coinAmount"
            type="number"
            min="1"
            max="100000"
            value={coinAmount}
            onChange={(e) => {
              setCoinAmount(e.target.value);
            }}
            className={`gift-coin-input-field ${
              coinAmountError ? "error" : ""
            }`}
          />
        </InputContainer>

        {/* Gift Soty Coin Description Textarea */}
        <InputContainer
          title={t("giftSotyCoinMessage")}
          isMandatory={true}
          titleDescription={t("giftSotyCoinMessageDescription")}
        >
          <textarea
            id="coinDescription"
            className={`gift-coin-textarea ${
              coinDescriptionError ? "error" : ""
            }`}
            value={coinDescription}
            onChange={(e) => {
              setCoinDescription(e.target.value);
            }}
            maxLength="511"
          />
        </InputContainer>

        <div className="challenge-creation-submit-button-container">
          <button className="preview" onClick={onSubmit}>
            {t("sendCoin")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GiftSotyCoin;
