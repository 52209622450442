import React from "react";
import tick from "../../../../../assets/media/tickInteraction.svg"; // tick ikonu

const CouponPercentage = ({
  couponOptions,
  percentCoupon,
  setPercentCoupon,
  t,
}) => {
  return (
    <div className="coin-folding-container">
      {/* Popular Choices Section */}
      <div className="coin-folding-input-container campaign-option-select-container">
        <div className="challenge-creation-input-container-header-subtitle">
          {t("popularChoices")}
        </div>
        <div className="coin-folding-input-wrapper">
          {couponOptions.map((option) => (
            <div
              key={option}
              className={`percentage-coupon-input ${
                percentCoupon === option && "percentage-coupon-input-active"
              }`}
              onClick={() => setPercentCoupon(option)}
            >
              %{option}
              {percentCoupon === option && <img src={tick} alt="tick" />}
            </div>
          ))}
        </div>
      </div>

      {/* Other Section */}
      <div className="coin-folding-input-container">
        <div className="challenge-creation-input-container-header-subtitle">
          {t("other")}
        </div>
        <div className="coin-folding-input-wrapper">
          <div className="coin-folding-input-type-wrapper">
            %
            <input
              type="number"
              min={0}
              max={100}
              className="coin-folding-input-type"
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 100) {
                  setPercentCoupon(e.target.value);
                }
              }}
              value={percentCoupon}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponPercentage;
