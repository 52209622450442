import React from "react";
import SotierAnalyticsCard from "../../../../../../cards/sotierAnalyticsCard";

const SotierAnalytics = ({ cardsData }) => {
  return (
    <div className="sotier-analytics">
      {cardsData.map((card, index) => (
        <SotierAnalyticsCard
          key={index}
          topTitle={card.topTitle}
          title={card.title}
          valueColor={card.valueColor}
          short={card.short}
          value={card.value}
          tableData={card.tableData}
          tableHeaders={card.tableHeaders}
        />
      ))}
    </div>
  );
};

export default SotierAnalytics;
