import React from "react";
import DatePicker from "react-datepicker"; // Ensure you have this library installed
import "react-datepicker/dist/react-datepicker.css"; // Ensure you have this CSS
import equalsOrLessImg from "../../assets/media/equalsOrLess.png";
import { useTranslation } from "react-i18next";
import SegmentDateRangeButton from "../button/SegmentDateRangeButton";

const SegmentAddDateFilterSelect = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  categoryTitle,
}) => {
  const { t } = useTranslation("segmentation");
  console.log(categoryTitle);
  return (
    <div className="segment-add-filter-select">
      <div className="segment-add-filter-select-input-container">
        {categoryTitle === "DATE_FILTER" ? (
          ""
        ) : !endDate ? (
          <>
            <div className="segment-add-filter-input segment-add-filter-date-input">
              <SegmentDateRangeButton
                selectedValue={startDate}
                setSelectedValue={setStartDate}
                onValueChange={setStartDate}
              />
            </div>
            <img
              src={equalsOrLessImg}
              alt="<="
              className="segment-add-filter-equation-icon"
            />
          </>
        ) : (
          ""
        )}
        <div className="segment-add-filter-title">{t(categoryTitle) || ""}</div>
        {!startDate ? (
          <>
            <img
              src={equalsOrLessImg}
              alt="<="
              className="segment-add-filter-equation-icon"
            />
            <div className="segment-add-filter-input segment-add-filter-date-input">
              <SegmentDateRangeButton
                selectedValue={endDate}
                setSelectedValue={setEndDate}
                onValueChange={setEndDate}
              />
            </div>{" "}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SegmentAddDateFilterSelect;
