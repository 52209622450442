import "../../../../assets/css/PopupPage.css";
import noImageToShow from "../../../../assets/media/noImageToShow.jpeg";
import tickIcon from "../../../../assets/media/correct.png";
import failIcon from "../../../../assets/media/failed.png";
import arrow from "../../../../assets/media/arrow.png";
import { useState } from "react";
import { imagesUrl } from "../../../../config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Approved = ({ challengeImg, challengeName, redirectPage }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation("challengeCreation");

    const image = challengeImg ? imagesUrl + '/' + challengeImg : noImageToShow;
    const titleText = challengeName ? (
        <>
            <div>{challengeName}</div>
            <div>{t("success.ChallengeCreation")}</div>
        </>

    ) : (
        <>
            <div>{challengeName}</div>
            <div>{t("error.ChallengeCreation")}</div>
        </>
    );
    const icon = challengeName ? tickIcon : failIcon;

    const handleRedirect = () => {
        try {
            console.log('Redirecting to:', redirectPage);
            navigate(redirectPage);
        } catch (error) {
            console.error('Navigation error:', error);
        }
    };


    return (
        <div className="dashboard-container">
            <div className="popup-page-approved-container">
                <img className="popup-page-approved-logo" src={image} alt={"test"} />
                <img className="popup-page-approved-icon scale-in-center" src={icon} alt="test" />
                <div className="popup-page-approved-title">
                    {titleText}
                </div>
                <button className="popup-page-approved-redirect-button" onClick={handleRedirect}>
                    {t("returnToDashboard")} <img className="popup-page-approved-redirect-button-icon shake-horizontal" src={arrow} alt="->" />
                </button>
            </div>
        </div>
    );
}
export default Approved;









