import React, { useState, useEffect } from "react";
import Pagination from "../tables/Pagination";
import ItemsPerPageButton from "../button/itemsPerPageButton";
import { Tooltip } from "react-tippy";
import tick from "../../assets/media/tickInteraction.svg";
import CallAPI from "../api/callApi";
import { apiUrl } from "../../config";
import Loader from "../popup/loader";
import { useTranslation } from "react-i18next";

const SegmentList = ({
  t,
  handleSegmentPopup,
  selectedSegment = [],
  setSelectedSegment,
  navigate,
  multiple = false,
}) => {
  const [segments, setSegments] = useState([]);
  const [segmentPage, setSegmentPage] = useState(0);
  const [segmentTotalPage, setSegmentTotalPage] = useState(0);
  const [segmentPerPage, setSegmentPerPage] = useState(20);
  const [loaderState, setLoaderState] = useState(false);
  const { t: translate, i18n } = useTranslation("challengeCreation");
    const handleFetchSegments = async () => {
    setLoaderState(true);
    const segmentResponse = await CallAPI({
      method: "GET",
      endPoint: `${apiUrl}/api/v1/brand-admin/brand/segment?page=${segmentPage}&size=${
        segmentPerPage || 20
      }`,
      body: undefined,
      successMessage: undefined,
      errorMessage: t("errorMessage"),
      navigate: navigate,
      t: t,
    });
    console.log(segmentResponse);
    setLoaderState(false);
    setSegmentTotalPage(segmentResponse.data.pageMetadata.totalPage);
    setSegmentPage(segmentResponse.data.pageMetadata.page);
    const updatedSegments = [
      {
        id: 0,
        name: translate("allSotiers"),
        description: translate("segmentForAllUsers"),
        expressionText: `=${translate("allSotiers")}`,
      },
      ...segmentResponse.data.responseData,
    ];

    setSegments(updatedSegments);
  };

  useEffect(() => {
    handleFetchSegments();
  }, [segmentPage, segmentPerPage]);

  const handleItemsPerPageChange = (value) => {
    setSegmentPerPage(value);
    setSegmentPage(0); // Reset to first page when items per page changes
  };

  const toggleSegmentSelection = (id) => {
    setSelectedSegment((prevSelected) =>
      Array.isArray(prevSelected)
        ? prevSelected.includes(id)
          ? prevSelected.filter((segmentId) => segmentId !== id)
          : [...prevSelected, id]
        : [id]
    );
  };

  const isSelected = (id) =>
    Array.isArray(selectedSegment) && selectedSegment.includes(id);

  return (
    <div className="segment-list-container per-page-fixer">
      {loaderState && <Loader />}
      <div className="segment-list-inner">
        {segments.map((segment, index) => (
          <div key={index} className="challenge-creation-segment-row">
            <div
              className={
                isSelected(segment.id) ? "qr-checkbox checked" : "qr-checkbox"
              }
              onClick={() => toggleSegmentSelection(segment.id)}
            >
              {isSelected(segment.id) && <img src={tick} alt="Tick" />}
            </div>
            <div className="challenge-creation-segment-content-container">
              <Tooltip
                title={t("showDetails")}
                position="bottom-start"
                theme="light"
                size="small"
                delay="1000"
              >
                <div
                  className="challenge-creation-segment-title segment-list-segment-title outline"
                  onClick={() => handleSegmentPopup(segment)}
                >
                  {segment.name}
                </div>
              </Tooltip>
              <div className="challenge-creation-segment-description">
                {segment.description}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="segment-list-footer">
        <Pagination
          currentPage={segmentPage + 1 || null}
          totalPages={segmentTotalPage}
          onPageChange={(page) => setSegmentPage(page - 1)}
        />
        <ItemsPerPageButton
          onSelectItem={handleItemsPerPageChange}
          title={translate("segmentPerPage")}
        />
      </div>
    </div>
  );
};

export default SegmentList;
