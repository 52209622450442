import React, { useState, useEffect, useRef } from "react";
import "../../../../../../../assets/css/TextTypeSurvey.css";
import { toast } from "react-toastify";
import orderImg from "../../../../../../../assets/media/change-order.png";
import ChallengeImageDropzone from "../../../../../../dropzone/ChallengeImageDropzone";
import axios from "axios";
import { apiUrl, imagesUrl } from "../../../../../../../config";
import {
  getCookie,
  getUserRolesFromToken,
} from "../../../../../auth/tokenUtils";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../../popup/loader";



const LikertTypeSurvey = ({
  question,
  onIsSavedChange,
  onQuestionsDataReady,
  infoIconEmpty,
  returnedQuestions,
  currentQuestionImageResponseString,
  onCurrentQuestionImageChange,
  likertType,
  onResetQuestionImage,
  setClearImage,
  clearImage,
}) => {
  const [loaderState, setLoaderState] = useState(false);
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [tempImageResponseString, setTempImageResponseString] = useState();
  const { t, i18n } = useTranslation("challengeCreation");
  const [currentQuestion, setCurrentQuestion] = useState(question);
  const [isPopupsOpen, setIsPopupsOpen] = useState(
    Array(savedQuestions.length).fill(false)
  );
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1);
  const [
    selectedQuestionImageResponseString,
    setSelectedQuestionImageResponseString,
  ] = useState();
  const [popupContent, setPopupContent] = useState({
    question: "",
    answers: [],
  });


  const staticLikertAnswers = {

    0: [
      t("stronglyDisagree"),
      t("disagree"),
      t("neutral"),
      t("agree"),
      t("stronglyAgree"),
    ],
    1: [t("never"), t("rarely"), t("occasionally"), t("frequently"), t("always")],
    2: [
      t("insignificant"),
      t("slightlyImportant"),
      t("moderatelyImportant"),
      t("veryImportant"),
      t("extremelyImportant"),
    ],
    3: [
      t("notInterested"),
      t("slightlyInterested"),
      t("moderatelyInterested"),
      t("veryInterested"),
      t("extremelyInterested"),
    ],
  };

  const deleteQuestionImage = (index) => {
    const updatedQuestion = savedQuestions[index];
    setTempImageResponseString(null);
    setSelectedQuestionImageResponseString(null);
    updatedQuestion.image = null;

    const updatedQuestions = [...savedQuestions];
    updatedQuestions[index] = updatedQuestion;
    setSavedQuestions(updatedQuestions);
  };

  const handleImageUpload = async (file, isChallengeImage) => {
    try {
      setLoaderState(true);
      const authToken = getCookie("access_token");
      const formData = new FormData();
      const response = await fetch(file);
      const blobData = await response.blob();
      formData.append("file", blobData);
      const uploadUrl = isChallengeImage
        ? apiUrl + "/api/v1/brand-admin/brand/challenge/logo"
        : apiUrl + "/api/v1/brand-admin/brand/challenge/logo";
      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (isChallengeImage) {
        // Challenge image
      } else {
        const updatedQuestions = [...savedQuestions];
        setSelectedQuestionImageResponseString(
          uploadResponse.data.responseData
        );
        setSavedQuestions(updatedQuestions);
      }
      setLoaderState(false);
      toast.success(
        isChallengeImage
          ? t("challengeImageUploaded")
          : t("questionImageUploaded")
      );

    } catch (error) {
      setLoaderState(false);
      console.error("Dosya yüklenirken hata oluştu:", error);
      toast.error("Dosya yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  useEffect(() => {
    if (!hasEffectRun.current) {

      fetchData();
      hasEffectRun.current = true;
    }
  }, []);

  const hasEffectRun = useRef(false);


  const fetchData = () => {
    if (returnedQuestions) {
      const updatedSavedQuestions = returnedQuestions.map((question) => ({
        question: question.text,
        answers: question.surveyChoices.map((choice) => choice.text),
        image: question.image,
      }));
      console.log("likertType a gelen sorular : ", updatedSavedQuestions);

      // Set the questions to the state

      // Do additional asynchronous operations here if needed
      // ...
      // Call handleSave for each updatedSavedQuestion

      for (const updatedSavedQuestion of updatedSavedQuestions) {
        console.log("foreach", updatedSavedQuestion);
        handleSave(updatedSavedQuestion);
        console.log("savedQuestions : ", savedQuestions);
      }
    }
  };

  const createQuestionsDataAndSendToParent = () => {
    const questionsData = savedQuestions.map((savedQuestion, index) => {
      console.log(savedQuestions);
      return {
        order: index,
        text: savedQuestion.question,
        image: savedQuestion.image,
        choices: staticLikertAnswers[likertType].map((answer, answerIndex) => {
          return {
            order: answerIndex,
            text: answer,
            image: "string",
            score: 10,
          };
        }),
      };
    });

    onQuestionsDataReady(questionsData);
  };

  const moveQuestionUp = (index) => {
    if (index > 0) {
      const updatedQuestions = [...savedQuestions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index - 1];
      updatedQuestions[index - 1] = temp;
      setSavedQuestions(updatedQuestions);
    }
  };

  const moveQuestionDown = (index) => {
    if (index < savedQuestions.length - 1) {
      const updatedQuestions = [...savedQuestions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index + 1];
      updatedQuestions[index + 1] = temp;
      if (
        index < savedQuestions.length - 1 &&
        updatedQuestions[index].question &&
        updatedQuestions[index].answers.length > 0 &&
        updatedQuestions[index + 1].question &&
        updatedQuestions[index + 1].answers.length > 0
      ) {
        setSavedQuestions(updatedQuestions);
      }
    }
  };

  const openPopup = (question, answers, index, image) => {
    setPopupContent({ question, answers });
    const updatedPopupsOpen = [...isPopupsOpen];
    updatedPopupsOpen[index] = true;
    setTempImageResponseString(image);
    setIsPopupsOpen(updatedPopupsOpen);
  };

  const closePopup = (index) => {
    const updatedPopupsOpen = [...isPopupsOpen];
    updatedPopupsOpen[index] = false;
    setIsPopupsOpen(updatedPopupsOpen);
    createQuestionsDataAndSendToParent();
  };

  const deleteQuestion = (index) => {
    closePopup(index);
    const updatedQuestions = [...savedQuestions];
    const updatedPopupsOpen = [...isPopupsOpen];
    updatedPopupsOpen[index] = false;
    updatedQuestions.splice(index, 1);
    setSavedQuestions(updatedQuestions);
  };

  const editQuestion = (index) => {
    closePopup(index);
    const updatedQuestion = savedQuestions[index];
    const updatedPopupsOpen = [...isPopupsOpen];
    if (!selectedQuestionImageResponseString)
      setSelectedQuestionImageResponseString(tempImageResponseString);
    console.log("temp: " + tempImageResponseString);
    const updatedQuestionImageString =
      selectedQuestionImageResponseString || tempImageResponseString;
    const updatedPopupQuestion = popupContent.question;
    const updatedPopupAnswers = popupContent.answers;
    updatedQuestion.question = updatedPopupQuestion;
    updatedQuestion.answers = updatedPopupAnswers;
    updatedQuestion.image = updatedQuestionImageString;
    const updatedQuestions = [...savedQuestions];
    updatedQuestions[index] = updatedQuestion;
    setSavedQuestions(updatedQuestions);
    updatedPopupsOpen[index] = false;
    setIsPopupsOpen(updatedPopupsOpen);
    createQuestionsDataAndSendToParent();
    setSelectedQuestionImageResponseString(undefined);
  };

  const questionControl = () => {
    if (currentQuestion === "") {
      toast.error("Lütfen bir soru yazınız.");
      return;
    }
    if (currentQuestion.length < 3) {
      toast.error("Soru en az 3 karakter uzunluğunda olmalıdır.");
      return;
    }
    let imageToUse =
      selectedQuestionImageResponseString || currentQuestionImageResponseString;
    const savedQuestion = {
      question: currentQuestion,
      answers: staticLikertAnswers[likertType],
      image: imageToUse,
    };

    if (!savedQuestion.answers.every((answer) => answer.length >= 3)) {
      toast.error("Cevap en az 3 karakter uzunluğunda olmalıdır.");
      return;
    }
    if (savedQuestion.answers.length < 2) {
      toast.error("Cevap sayısı en az 2 olabilir.");
      return;
    }
    if (new Set(savedQuestion.answers).size !== savedQuestion.answers.length) {
      toast.error("Aynı cevap birden fazla kez yazılamaz.");
      return;
    }

    // Her cevabın minimum 3 karakter uzunluğunda olup olmadığını kontrol ediyoruz.
    else {
      handleSave(savedQuestion);
    }
  };
  const handleSave = (savedQuestion) => {
    // Check if the question is already present in savedQuestions
    const isQuestionAlreadySaved = savedQuestions.some(
      (question) => question.question === savedQuestion.question
    );

    if (!isQuestionAlreadySaved) {
      // If the question is not already present, add it to savedQuestions
      setSavedQuestions((prevSavedQuestions) => [...prevSavedQuestions, savedQuestion]);
    } else {
      // If the question is already present, remove the duplicate
      const updatedSavedQuestions = savedQuestions.filter(
        (question) => question.question !== savedQuestion.question
      );
      // Add the updated question list to savedQuestions
      setSavedQuestions(updatedSavedQuestions);
    }

    // Clear the current question-related state
    setCurrentQuestion("");

    // Trigger necessary callbacks and resets
    onIsSavedChange(true);
    onCurrentQuestionImageChange("");
    setClearImage((prevClearImage) => (prevClearImage + 1) % 2);
    onResetQuestionImage();

    // Clear the input field
    const questionInput = document.querySelector(".challenge-creation-survey-question-input");
    if (questionInput) {
      questionInput.value = "";
    }

    // Update the parent component with the latest questions data
    createQuestionsDataAndSendToParent();
  };

  useEffect(() => {
    setCurrentQuestion(question);
  }, [question]);

  useEffect(() => {
    if (!returnedQuestions) setSavedQuestions([]);
  }, [likertType]);

  useEffect(() => {
    // question prop'u değiştiğinde yeni bir soru geldiğini varsayalım
    // createQuestionsDataAndSendToParent'ı çağırarak soruları güncelleyin
    createQuestionsDataAndSendToParent();
    console.log(savedQuestions);
  }, [savedQuestions]);

  return (
    <div className="text-type-survey-answer-container">
      {loaderState && <Loader />}

      <div className="text-type-survey-answer-container-title">
        <div className="text-type-survey-answer-container-title-text">
          {t("answers")}<span style={{ color: "red" }}>*</span>
        </div>
      </div>
      {staticLikertAnswers[likertType].map((answer, index) => (
        <div className="text-type-survey-answer-row" key={index}>
          <input
            type="text"
            className="text-type-survey-answer-input"
            value={answer}
            readOnly
          />
        </div>
      ))}
      <button
        className="text-type-survey-question-add-save-button"
        onClick={() => {
          questionControl();
        }}
      >
        {t("save")}
      </button>
      <hr />
      {savedQuestions.map((savedQuestion, index) => (
        <div key={index}>
          <div
            className={`text-type-survey-question-demo-container ${!isPopupsOpen[index]
                ? "text-type-survey-question-demo-container-clickable"
                : ""
              }`}
            onClick={() => {
              if (!isPopupsOpen[index]) {
                openPopup(
                  savedQuestion.question,
                  savedQuestion.answers,
                  index,
                  savedQuestion.image
                );
              }
            }}
          >
            {!isPopupsOpen[index] && (
              <div className="text-type-survey-question-demo-container">
                {savedQuestion.image && (
                  <img
                    src={`${imagesUrl}/${savedQuestion.image}`}
                    alt={`Soru Resmi ${index + 1}`}
                    className="text-type-survey-question-demo-image"
                  />
                )}
                <div className="text-type-survey-question-demo">
                  <div className="text-type-survey-question-demo-title">
                    {index + 1}
                  </div>
                  <div className="text-type-survey-question-demo-question">
                    {savedQuestion.question}
                  </div>
                </div>
                <div className="text-type-survey-question-answer-demo">
                  {savedQuestion.answers.map((answer, answerIndex) => (
                    <div
                      key={answerIndex}
                      className={`text-type-survey-question-answer-demo-answer-${answerIndex} text-type-survey-question-answer-demo-answer`}
                    >
                      <div className="text-type-survey-question-answer-demo-check-area"></div>

                      <span>{answer}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {isPopupsOpen[index] && (
              <div className="text-type-survey-popup-container">
                <div className="text-type-survey-popup">
                  <div className="text-type-survey-popup-content">
                    <div className="challenge-creation-survey-question-img-container">
                      <div className="challenge-creation-survey-question-img-title">
                        {t("questionImage")}
                      </div>
                      {savedQuestion.image && (
                        <>


                          <ChallengeImageDropzone
                            onImageUpload={(file) =>
                              handleImageUpload(file, false)
                            }
                            currentImg={savedQuestion.image}
                          />
                          <button className="challenge-creation-image-question-image-delete-button" onClick={() => deleteQuestionImage(index)}>
                            {t("deleteImage")}
                          </button>
                        </>
                      )}
                      {!savedQuestion.image && (
                        <ChallengeImageDropzone
                          onImageUpload={(file) =>
                            handleImageUpload(file, false)
                          }
                          currentImg={savedQuestion.image}
                        />
                      )}
                      <label>{t("allowedFileTypes")} png, jpg</label>
                    </div>
                    <h3>{t("question")}</h3>
                    <input
                      type="text"
                      maxLength={140}
                      minLength={3}
                      value={popupContent.question}
                      onChange={(e) =>
                        setPopupContent({
                          ...popupContent,
                          question: e.target.value,
                        })
                      }
                    />
                    <h3>{t("answers")}</h3>
                    <div className="text-type-survey-popup-answers-container">
                      {popupContent.answers.map((answer, answerIndex) => (
                        <div
                          key={answerIndex}
                          className="text-type-survey-popup-answer-row"
                        >
                          <input
                            type="text"
                            minLength={3}
                            maxLength={60}
                            value={answer}
                            readOnly={true}
                            onChange={(e) => {
                              const updatedAnswers = [...popupContent.answers];
                              updatedAnswers[answerIndex] = e.target.value;
                              setPopupContent({
                                ...popupContent,
                                answers: updatedAnswers,
                              });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="text-type-survey-popup-button-container">
                      <button
                        onClick={() => closePopup(index)}
                        className="text-type-survey-popup-button"
                      >
                        {t("cancel")}
                      </button>
                      <button
                        onClick={() => deleteQuestion(index)}
                        className="text-type-survey-popup-button delete"
                      >
                        {t("delete")}
                      </button>
                      <button
                        onClick={() => editQuestion(index)}
                        className="text-type-survey-popup-button save"
                      >
                        {t("save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <hr />
          {index !== savedQuestions.length - 1 && (
            <div>
              <button
                className="text-type-survey-move-button-down"
                onClick={() => moveQuestionDown(index)}
              >
                <img src={orderImg} alt="Sirayi Degistir" />
              </button>
              <hr />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default LikertTypeSurvey;
