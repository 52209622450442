import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import downArrow from "../../../../../assets/media/down-arrow.png";
import "../../../../../assets/css/BrandAdminRoleList.css";
import Popup from "../brand_admin_role_popup/BrandAdminRolePopup";
import "../../../../../assets/css/BrandAdminRolePopup.css";
import { apiUrl } from "../../../../../config.js";
import { useTranslation } from "react-i18next";
import Loader from "../../../../popup/loader.js";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar.js";

const BrandAdminRoleList = () => {
  const [responseData, setResponseData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [roleTemplate, setRoleTemplate] = useState([]);
  const [popupTitle, setPopupTitle] = useState();
  const [popupRoleData, setPopupRoleData] = useState();
  const axiosInstance = axios.create();
  const { t, i18n } = useTranslation("brandAdminRoleList");
  const [loaderState, setLoaderState] = useState(false);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const navigate = useNavigate();

  const [buttonPopup, setButtonPopup] = useState(false);

  const fetchData = async () => {
    setLoaderState(true);

    try {
      const token = getCookie("access_token");
      if (!token) {
        console.error("Access token is missing.");
        setLoaderState(false);

        navigate("/sign-in-realm");
        return;
      }

      const response = await axios.get(
        apiUrl + "/api/v1/admin/role/BRAND/group",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data) {
        setLoaderState(false);

        setResponseData(response.data);
      }
      if (!response.data) {
        setLoaderState(false);

        console.error("Error fetching data.");
        return;
      }
      setLoaderState(false);

      setRoleTemplate(response.data.responseData.roleTemplate);
      setRoleData(response.data.responseData.roleList);

      console.log(response);
    } catch (error) {
      setLoaderState(false);

      if (error.response && error.response.status === 401) {
        navigate("/sign-in-realm");
      }
      console.error("HATA:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonPopup]); // Add buttonPopup as a dependency

  const getRoleCategoryNames = () => {
    const roleCategoryNames = [];

    roleData.forEach((adminData) => {
      adminData.roleCategories.forEach((category) => {
        roleCategoryNames.push(category.name);
      });
    });

    return roleCategoryNames;
  };

  const openPopup = (title, data) => {
    setButtonPopup(true);
    setPopupTitle(title);
    setPopupRoleData(data);
  };


  const locationArray = [
    [t("roles"), "/settings/roles"]
  ];
  return (
    <div className="brand-admin-role-list-dashboard-container">
      {loaderState && <Loader />}

      
      <NavigationBar locationArray={locationArray} />

      <div className="brand-admin-roles-container">
        <div className="grid-container">
          {roleData.map((adminData) => {
            const selectedCategories = adminData.roleCategories.filter(
              (category) => category.roles.some((role) => role.selected)
            );

            return selectedCategories.length > 0 ? (
              <div key={adminData.name} className="grid-item">
                <div className="grid-item-top">
                  <div className="grid-item-title">{adminData.name}</div>
                  <div className="grid-item-subtitle display-none">
                    {t("totalUsersWithRole")} 5
                  </div>
                  <ul className="grid-item-list">
                    {selectedCategories.slice(0, 5).map((category, index) => (
                      <li key={index}>{category.name}</li>
                    ))}
                    {selectedCategories.length > 5 && (
                      <li>{`${selectedCategories.length - 5} ${t("more")}`}</li>
                    )}
                  </ul>
                </div>
                <div className="grid-item-bottom">
                  <button
                    className="grid-item-edit-button"
                    onClick={() => {
                      openPopup("View Role", adminData);
                    }}
                  >
                    {t("viewRole")}
                  </button>
                  <br />
                </div>
              </div>
            ) : null;
          })}
          <button
            onClick={() => {
              setButtonPopup(true);
              setPopupTitle(t("addRole"));
              setPopupRoleData(roleTemplate);
            }}
            className="grid-item grid-item-button grid-item-add-button"
          >
            +<div className="grid-item-button-title">{t("addNewRole")}</div>
          </button>
          {buttonPopup && (
            <Popup
              trigger={buttonPopup}
              setTrigger={setButtonPopup}
              title={popupTitle}
              roleData={popupRoleData}
              setRoleData={setRoleData}
              responseData={responseData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandAdminRoleList;
