import React, { useState, useEffect, useRef } from "react";
import DownArrow from '../../assets/media/down-arrow.png';
import '../../assets/css/SortFilterSearch.css';
import DateRangePicker from "../dialogs/dateRangePicker";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const DatePickerButton = ({ highlightedDates, onDateChange, LastMonth }) => {
    const [currentDate, setCurrentDate] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isPickerVisible, setPickerVisibility] = useState(false);
    const { t } = useTranslation("challengeDashboard");
    const datePickerRef = useRef(null);
    const [resetButton, setResetButton] = useState(false);
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const [startDateParam, setStartDateParam] = useState(params.get('start_date'))
    const [endDateParam, setEndDateParam] = useState(params.get('end_date'));


    useEffect(() => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        setCurrentDate(`${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setPickerVisibility(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [datePickerRef]);

    useEffect(() => {
        const interval = setInterval(() => {
            const parameters = new URLSearchParams(window.location.search);
            const startDateParam = parameters.get('start_date');
            const endDateParam = parameters.get('end_date');
            if (startDateParam && endDateParam) {
                setStartDate(new Date(startDateParam));
                setEndDate(new Date(endDateParam));
            }
        }, 1000); // Her saniye kontrol et

        return () => clearInterval(interval);
    }, []);

    const handleDateChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        onDateChange({ selectedStartDate: startDate, selectedEndDate: endDate });
    };

    const togglePickerVisibility = () => {
        setPickerVisibility(!isPickerVisible);
    };

    const handleResetClick = (state) => {
        setResetButton(state);
    }

    return (
        <div className="date-picker-button-container" ref={datePickerRef}>
            <div className="date-picker-button-clickable" onClick={togglePickerVisibility}>
                <div className="date-picker-button-left">
                    <div className="date-picker-button-title">
                        {t("date")}
                    </div>
                    <div className="date-picker-button-date">
                        {startDate && endDate ? `${startDate.toLocaleDateString('tr-TR')} - ${endDate.toLocaleDateString('tr-TR')}` : ''}
                    </div>
                </div>
                <div className="date-picker-button-right">
                    <img src={DownArrow} alt="V" />
                </div>
            </div>
            {isPickerVisible && <DateRangePicker onDateChange={handleDateChange} highlightedDates={highlightedDates} defaultEndDate={endDate} defaultStartDate={startDate} onResetClick={handleResetClick} />}
        </div>
    );
};

export default DatePickerButton;
