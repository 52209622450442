import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import DownArrow from '../../assets/media/down-arrow.png';
import EditableCalendar from '../../assets/media/editable-calendar.svg';
import '../../assets/css/DateRangePicker.css'; // CSS özelleştirmeleri için ek dosya
import { useTranslation } from "react-i18next";
import { t } from "i18next";
const DateRangePicker = ({ onDateChange, highlightedDates, defaultStartDate, defaultEndDate, onResetClick }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, setStartDate] = useState(defaultStartDate? defaultStartDate : new Date());
    const [endDate, setEndDate] = useState(defaultEndDate? defaultEndDate : null);
    const [selectedRadio, setSelectedRadio] = useState('0'); // Default to 'Gün'
    const { t, i18n } = useTranslation("challengeDashboard");
    const [localHighlightedDates, setLocalHighlightedDates] = useState(highlightedDates);


    const resetDateRange = () => {
        setSelectedDate(null);
        setDateRange([null, null]);
        setStartDate(new Date());
        setEndDate(null);
        setSelectedRadio('0');
        onDateChange({ startDate: false, endDate: false });
        onResetClick(true)
    };
    setDefaultLocale(i18n.resolvedLanguage);

    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const months = [
        'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'
    ];

    const locale = {
        localize: {
            day: n => t(`${days[n]}Short`), // Gün isimlerini çevir
            month: n => t(months[n]) // Ay isimlerini çevir
        },
        formatLong: {
            date: () => 'mm/dd/yyyy'
        }
    };

    const getHighlightedDateRange = () => {
        return highlightedDates?.map(item => new Date(item.date));
    };

    const customDayClassName = date => {
        const highlightedDate = localHighlightedDates.find(item => {
          return (
            item.dates.some(highlightedDate => (
              date.getDate() === highlightedDate.getDate() &&
              date.getMonth() === highlightedDate.getMonth() &&
              date.getFullYear() === highlightedDate.getFullYear()
            ))
          );
        });
    
        if (highlightedDate) {
          const socialProvider = highlightedDate.socialProvider;
    
          // Apply specific classes based on socialProvider
          if (socialProvider === 'INSTAGRAM') {
            return 'react-datepicker__day--highlighted-custom-1';
          } else if (socialProvider === 'FACEBOOK') {
            return 'react-datepicker__day--highlighted-custom-2';
          }
        }
    
        return '';
      };


      useEffect(() => {
        // Update local state when highlightedDates prop changes
        setLocalHighlightedDates(highlightedDates);
      }, [highlightedDates]);

    // Rastgele renkli nokta eklemek için tarih özelleştirme


    // Tarih değiştiğinde çağrılan fonksiyon
    const onChange = (dates) => {
        if (Array.isArray(dates)) {
            // Range selection (day or week)
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
            // Call the onDateChange prop with the selected dates
            onDateChange({ startDate: start, endDate: end });
        } else {
            // Single date selection (month, year, or day in non-range mode)
            setStartDate(dates);
            setEndDate(null);
            // Call the onDateChange prop with the selected date
            onDateChange({ startDate: dates, endDate: null });
        }
    };



    const handleNextDay = () => {
        const prevStartDay = new Date(startDate);
        const prevEndDay = new Date(endDate);
        prevStartDay.setDate(prevStartDay.getDate() + 1);
        prevEndDay.setDate(prevEndDay.getDate() + 1);
        setStartDate(prevStartDay);
        setEndDate(prevEndDay);
        onDateChange({ startDate: prevStartDay, endDate: prevEndDay });
    };

    const handlePrevDay = () => {
        const prevStartDay = new Date(startDate);
        const prevEndDay = new Date(endDate);
        prevStartDay.setDate(prevStartDay.getDate() - 1);
        prevEndDay.setDate(prevEndDay.getDate() - 1);
        setStartDate(prevStartDay);
        setEndDate(prevEndDay);
        onDateChange({ startDate: prevStartDay, endDate: prevEndDay });
    };

    


    const renderDayContents = (day, date) => {
        const tooltipText = `Tooltip for date: ${date}`;
        
        const highlightedDate = localHighlightedDates?.highlightedDates.find(item => (
            item.dates?.some(highlightedDate => (
                new Date(date.toDateString()) === new Date(highlightedDate.date)
            ))
        ));
    
        if (highlightedDate) {
            tooltipText += `\nID: ${highlightedDate.id}\nName: ${highlightedDate.name}`;
        }
    
        return <span title={tooltipText}>{day}</span>;
    };
    
    
    
    

    return (
        <div className='date-range-picker-dialog'>
            <div className='date-range-picker-header'>
                <div className='date-range-picker-header-title'>
                    {t("date")}
                </div>
                <div className='date-range-picker-header-date-row'>
                    <div className='date-range-picker-button' onClick={handlePrevDay}>
                        <img className='dec' src={DownArrow} alt='<' />
                    </div>
                    <div className='date-ranger-picker-header-row-center'>
                        <div className='date-range-picker-header-calendar-img-container'>
                            <div className='data-range-picker-header-calendar-img-content-wrapper'>
                                <div className='date-range-picker-calendar-img-date-title'>{startDate && startDate.toLocaleDateString('en-US', { weekday: 'short' })}</div>
                                <div className='date-range-picker-calendar-img-date'>{startDate && startDate.getDate()}</div>
                            </div>
                        </div>

                        <div className='date-range-picker-header-date'>
                            {startDate && (

                                <div>{startDate.toLocaleDateString('tr-TR')}</div>

                            )}
                            {endDate && startDate.getTime() !== endDate.getTime() && (
                                <div>{endDate.toLocaleDateString('tr-TR')}</div>
                            )}

                        </div>
                        <div className='date-range-picker-header-calendar-img-container'>
                            <div className='data-range-picker-header-calendar-img-content-wrapper'>
                                <div className='date-range-picker-calendar-img-date-title'>{endDate && endDate.toLocaleDateString('en-US', { weekday: 'short' })}</div>
                                <div className='date-range-picker-calendar-img-date'>{endDate && endDate.getDate()}</div>
                            </div>
                        </div>
                    </div>



                    <div className='date-range-picker-button' onClick={handleNextDay}>
                        <img className='inc' src={DownArrow} alt='<' />
                    </div>

                </div>
                <div className="date-range-picker-radio-input">
                    <label>
                        <input
                            value="0"
                            name="value-radio"
                            id="0"
                            type="radio"
                            checked={selectedRadio === '0'}
                            onChange={() => setSelectedRadio('0')}
                        />
                        <span>{t("day")}</span>
                    </label>
                    <label>
                        <input
                            value="1"
                            name="value-radio"
                            id="1"
                            type="radio"
                            checked={selectedRadio === '1'}
                            onChange={() => setSelectedRadio('1')}
                        />
                        <span>{t("week")}</span>
                    </label>
                    <label>
                        <input
                            value="2"
                            name="value-radio"
                            id="2"
                            type="radio"
                            checked={selectedRadio === '2'}
                            onChange={() => setSelectedRadio('2')}
                        />
                        <span>{t("month")}</span>
                    </label>
                    <label>
                        <input
                            value="3"
                            name="value-radio"
                            id="3"
                            type="radio"
                            checked={selectedRadio === '3'}
                            onChange={() => setSelectedRadio('3')}
                        />
                        <span>{t("year")}</span>
                    </label>
                    <span className="selection"></span>
                </div>
            </div>
            <div className='date-range-picker-separator' />
            <div className='date-range-picker'>
                <DatePicker
                    locale={locale}
                    selected={startDate}
                    onChange={onChange}
                    placeholderText="Select a date"
                    customDayClassName={customDayClassName}
                    isClearable={true}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange={true}
                    showDisabledMonthNavigation
                    inline
                    showWeekPicker={selectedRadio === '1'}
                    showMonthYearPicker={selectedRadio === '2'} // Only show for 'month' mode
                    showYearPicker={selectedRadio === '3'}
                    highlightDates={getHighlightedDateRange()}
                    disabledKeyboardNavigation
                   /* renderDayContents={renderDayContents}*/


                />
            </div>

            <div className='filter-dialog-button-container'>
                <div className='filter-dialog-reset-button' onClick={() => {resetDateRange()}}>
                    {t("reset")}
                </div>

            </div>

        </div>
    );
};

export default DateRangePicker;
