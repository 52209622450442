import React from "react";
import "../../assets/css/ViewInSotyAppButton.css";
import cartIcon from "../../assets/media/Icon ionic-ios-cart.svg";
import { useTranslation } from "react-i18next";
const ContentCartButton = ({ onClick, counter }) => {
  const { t, i18n } = useTranslation("challengeCreation");
  return (
    <button
      className="static-button-container content-cart-button"
      onClick={onClick}
    >
      <img src={cartIcon} alt="icn" />
      {t("contentCart")}
      <div style={{ marginLeft: "auto" }}>({counter})</div>
    </button>
  );
};

export default ContentCartButton;
