// BrandLoginForm.js
import React, { useState, useEffect } from "react";
import "../../../assets/css/login.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showSuccessToast, showErrorToast } from "../../toast/toast";
import { getCookie, isMyTokenExpired } from "./tokenUtils";
import privacyPolicyText from "../../text_content/Privacy.json";
import { apiUrl, authUrl } from "../../../config";
import sotyIcon from "../../../assets/media/sotylogo.svg";
import "../../../assets/i18n/i18n";
import TextPopup from "../../popup/textPopup";
import Loader from "../../popup/loader";
import CallAPI from "../../api/callApi";
import VerificationCodeInput from "./2fa/VerificationCodeInput";
const BrandLoginForm = () => {
  const { t, i18n } = useTranslation("brandLoginForm");
  const navigate = useNavigate();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [brandEmail, setBrandEmail] = useState("");
  const [brandPassword, setBrandPassword] = useState("");
  const [popupContent, setPopupContent] = useState("");
  const [popupTitle, setPopupTitle] = useState();
  const [UUID, setUUID] = useState();
  const [loaderState, setLoaderState] = useState(false);

  useEffect(() => {
    const token = getCookie("access_token");

    if (!token) {
      return;
    } else if (isMyTokenExpired(token)) {
      return;
    } else {
      //buraya kodla
      //loginMethod();
    }
    console.log("kullanici giris yapmis");
  }, []);

  const loginMethod = async () => {
    const response = await CallAPI({
      method: "GET",
      endPoint: apiUrl + "/api/v1/brand-admin/brand",
    });
    console.log(response);
    if (response.data.responseData.contractApproved) {
      navigate("/brand/dashboard");
    } else {
      navigate("/brand/settings/profile");
    }
  };
  
  const brandHandleSubmit = async (e) => {
    e.preventDefault();
    setLoaderState(true);
    try {
      const response = await CallAPI({
        method: "PUT",
        endPoint: apiUrl + "/api/v1/brand-admin/brand/otp",
        body: { email: brandEmail },
        noAuth: true
      });
      
     
      
      console.log(response);
      setUUID(response.data.responseData.uuid);
      setLoaderState(false);
    } catch {
      setLoaderState(false);
    }
    return;
  };
  const handlePopupClose = () => {
    setPopupVisibility(false);
  };

  const handlePopupOpen = (content) => {
    console.log(content);
    setPopupContent(content);
    setPopupTitle("soty");
    setPopupVisibility(true);
  };

  const brandAuthorization = async (code) => {
    console.log(code);
    setLoaderState(true);
    try {
      const brandUrlencoded = new URLSearchParams();
      brandUrlencoded.append("client_id", "brand-client");
      brandUrlencoded.append("username", brandEmail);
      brandUrlencoded.append("password", code);
      brandUrlencoded.append("grant_type", "password");
      brandUrlencoded.append(
        "client_secret",
        "WWM6AhMUIUUyqOSET6jXAOSDGxGUq01W"
      );

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: brandUrlencoded,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          authUrl + "/realms/brand/protocol/openid-connect/token",
          requestOptions
        );
        const result = await response.text();
        console.log(response);

        const jsonResponse = JSON.parse(result);
        const token = jsonResponse.access_token;

        if (token) {
          document.cookie = `access_token=${token}; path=/; expires=${new Date(
            new Date().getTime() + 7 * 24 * 60 * 60 * 1000
          ).toUTCString()}`;
          const unnecessary = await CallAPI({
            method: "PUT",
            endPoint: apiUrl + "/api/v1/brand-admin/brand/config",
            dontShowError: true
         
          });
          console.log("unnecessary",unnecessary)
          setLoaderState(false);

          showSuccessToast(t("successMessages.loginSuccessful"));
          loginMethod();
        } else {
          setLoaderState(false);

          showErrorToast(t("errorMessages.invalidCredentials"));
        }
      } catch (error) {
        setLoaderState(false);

        console.error(error);
        if (error.message.includes("ERR_CERT_DATE_INVALID")) {
          showErrorToast("SSL certificate is invalid or expired.");
        } else {
          showErrorToast(t("errorMessages.genericError"));
        }
      }
    } catch (error) {
      console.log(error);
      setLoaderState(false);
    }
  };
  return (
    <div className="login-container">
      {loaderState && <Loader />}

      <div className="login-wrapper">
        <img src={sotyIcon} alt="soty" />
        {UUID ? (
          <form>
            <div className="go-back-button" onClick={() => setUUID(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                viewBox="0 -960 960 960"
                width="16px"
                fill="#e8eaed"
              >
                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
              </svg>
              {t("goBack")}
            </div>
            <VerificationCodeInput
              onComplete={(code) => brandAuthorization(code)}
            />
          </form>
        ) : (
          <form
            onSubmit={brandHandleSubmit}
            className={`${UUID ? "hide-login" : ""}`}
          >
            <label>{t("form.label")}</label>
            <input
              className="login-input"
              placeholder={t("form.placeholders.email")}
              value={brandEmail}
              onChange={(e) => setBrandEmail(e.target.value)}
            />

            <input
              className="login-input display-none"
              type="password"
              placeholder={t("form.placeholders.password")}
              value={brandPassword}
              onChange={(e) => setBrandPassword(e.target.value)}
            />
            <button type="submit">{t("form.buttonText")}</button>
          </form>
        )}
        <label
          className="login-form-policy-label"
          onClick={() => {
            handlePopupOpen(privacyPolicyText.privacy_policy);
          }}
        >
          {t("readOurPrivacyPolicy")}
        </label>
      </div>
      {isPopupVisible && (
        <TextPopup
          title={"soty"}
          content={popupContent}
          onClose={handlePopupClose}
        />
      )}
    </div>
  );
};

export default BrandLoginForm;
