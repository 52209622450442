import React, { useState, useEffect } from 'react';
import checkIcon from '../../assets/media/Checklist.svg';
import { useTranslation } from 'react-i18next';
import SearchSelectDialog from './searchSelectDialog';

const FilterOption = ({ isChecked, onClick, title }) => (
    <div className="filter-dialog-row">
        <div
            className={`filter-dialog-checkbox-container ${isChecked ? 'filter-dialog-checkbox-container-active' : ''}`}
            onClick={onClick}
        >
            <img className="filter-title-checkicon" src={checkIcon} alt="Checkbox" />
        </div>
        <div className="filter-title">{title}</div>
    </div>
);

const FilterDialogCampaign = ({ checkboxData, onCheckboxSelection, eventType, checkboxData2, listCheckbox, onItemsSelect }) => {
    const { t } = useTranslation('challengeDashboard');
    const initialCheckboxStates = {
        contentTypes: Array(checkboxData.length).fill(false),
        status: checkboxData2 ? Array(checkboxData2.length).fill(false) : []
    };

    const [checkboxStates, setCheckboxStates] = useState(() => {
        const savedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || initialCheckboxStates;
        return savedCheckboxStates;
    });

    useEffect(() => {
        const savedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || initialCheckboxStates;
        setCheckboxStates(savedCheckboxStates);
    }, []);

    useEffect(() => {
        localStorage.setItem('checkboxStates', JSON.stringify(checkboxStates));
    }, [checkboxStates]);

    const handleCheckboxClick = (category, index) => {
        const newCheckboxStates = { ...checkboxStates };
        newCheckboxStates[category][index] = !newCheckboxStates[category][index];
        setCheckboxStates(newCheckboxStates);
    
        const selectedContentTypes = newCheckboxStates.contentTypes.map((isChecked, idx) =>
            isChecked ? checkboxData[idx] : null
        ).filter(Boolean);
    
        const selectedStatus = newCheckboxStates.status.map((isChecked, idx) =>
            isChecked ? checkboxData2[idx] : null
        ).filter(Boolean);
    
        const selectedCheckboxes = [
            ...selectedContentTypes,
            ...selectedStatus
        ];
    
        onCheckboxSelection(selectedCheckboxes);
    };

    const resetCheckboxes = () => {
        setCheckboxStates(initialCheckboxStates);
        localStorage.removeItem('checkboxStates');
        onCheckboxSelection(null, null);

        // Sıfırlama işlemi
        onItemsSelect([]);
    };

    return (
        <div className="filter-dialog">
            
            <div className="filter-dialog-wrapper">
                <div className="filter-dialog-part filter-dialog-part-type">
                    <div className="filter-dialog-title">{eventType === "campaign" ? t("campaignType") : t('challengeType')}</div>
                    <div className="filter-dialog-row-container">
                        {checkboxData.map((data, index) => (
                            <FilterOption
                                key={index}
                                isChecked={checkboxStates.contentTypes[index]}
                                onClick={() => handleCheckboxClick('contentTypes', index)}
                                title={data.title}
                            />
                        ))}
                    </div>
                </div>
                {checkboxData2 && (
                    <div className="filter-dialog-part filter-dialog-part-type">
                        <div className="filter-dialog-title">{t("status")}</div>
                        <div className="filter-dialog-row-container">
                            {checkboxData2.map((data, index) => (
                                <FilterOption
                                    key={index}
                                    isChecked={checkboxStates.status[index]}
                                    onClick={() => handleCheckboxClick('status', index)}
                                    title={data.title}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {listCheckbox && (
                    <div className="filter-dialog-part filter-dialog-part-type">
                        <div className="filter-dialog-title">{t("corporationName")}</div>
                        <SearchSelectDialog listItems={listCheckbox} onItemsSelect={onItemsSelect} />
                    </div>
                )}
            </div>
            <div className="filter-dialog-button-container">
                <div className="filter-dialog-reset-button" onClick={resetCheckboxes}>
                    {t('reset')}
                </div>
            </div>
        </div>
    );
};

export default FilterDialogCampaign;
