import React, { useState, useEffect } from "react";
import "../../assets/css/QuadrupleToggleInputWrapper.css";
import tick from "../../assets/media/tickInteraction.svg";

const InputContainer = ({
  title,
  isMandatory,
  titleDescription,
  error = false,
  warning = false,
  children,
}) => {
  return (
    <div
      className={`challenge-creation-input-container ${
        error ? "error" : warning ? "warning" : ""
      }`}
    >
      <div
        className={`challenge-creation-input-container-header ${
          error ? "error" : warning ? "warning" : ""
        }`}
      >
        <div className="challenge-creation-input-container-header-title">
          {title && title}
          {isMandatory && <span>*</span>}
          {title && ":"}
        </div>
        <div className="challenge-creation-input-container-header-subtitle">
          {titleDescription && titleDescription}
        </div>
      </div>
      {children && (
        <>
          <hr className={`${error ? "error" : warning ? "warning" : ""}`} />
          <div className="challenge-creation-input-container-content-wrapper">
            {children && children}
          </div>
        </>
      )}
    </div>
  );
};

export default InputContainer;
