import React from "react";
import infoIconEmpty from "../../assets/media/infoIconEmpty.svg";

const DateRangeInput = ({ dateRangeTitle, dateRangeTitleDescription, startDateTitle, startDateTitleDescription, endDateTitle, endDateDescription, startDateValue, dateInfo, endDateValue, isDateMandatory, startDatePlaceholder, endDatePlaceholder, startDateMaxLength, endDateMaxLength, startDateIsValid = true, endDateIsValid = true, onStartDateChange, onEndDateChange, onStartDateBlur, onEndDateBlur }) => {
    return (
        <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                    {dateRangeTitle && dateRangeTitle}{isDateMandatory && <span>*</span>}{dateRangeTitle && ":"}
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                    {dateRangeTitleDescription && dateRangeTitleDescription}
                </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
                <div className="challenge-creation-date-column-container">
                    <div className="challenge-creation-date-column">
                        <div className="challenge-creation-date-title">
                            {startDateTitle && startDateTitle}{isDateMandatory && <span>*</span>}{startDateTitle && ":"}
                        </div>
                        <input
                            type="datetime-local"
                            className="challenge-creation-date-picker"
                            placeholder={startDatePlaceholder && startDatePlaceholder}
                            defaultValue={startDateValue}
                            value={startDateValue}
                            onBlur={(e) => onStartDateBlur && onStartDateBlur(e)}
                            onChange={(e) => onStartDateChange && onStartDateChange(e)}
                            maxLength={startDateMaxLength}
                            style={{ border: startDateIsValid ? "" : "2px solid red" }}
                        />
                    </div>

                    <div className="challenge-creation-date-column">
                        <div className="challenge-creation-date-title">
                            {endDateTitle && endDateTitle}{isDateMandatory && <span>*</span>}{endDateTitle && ":"}
                        </div>
                        <input
                            type="datetime-local"
                            className="challenge-creation-date-picker"
                            placeholder={endDatePlaceholder && endDatePlaceholder}
                            defaultValue={endDateValue}
                            value={endDateValue}
                            onBlur={(e) => onEndDateBlur && onEndDateBlur(e)}
                            onChange={(e) => onEndDateChange && onEndDateChange(e)}
                            maxLength={endDateMaxLength}
                            style={{ border: endDateIsValid ? "" : "2px solid red" }}
                        />
                    </div>
                </div>
                {dateInfo &&
                    <div className="challenge-creation-date-description-row">
                        <img
                            className="challenge-creation-date-description-icon"
                            src={infoIconEmpty}
                            alt="icon"
                        />
                        <div className="challenge-creation-date-description">
                            {dateInfo}
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default DateRangeInput;
