import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../assets/css/DynamicTable.css";
import downArrow from '../../assets/media/down-arrow.png';
import FilterButton from '../button/filterButton';
import CreateChallengeButton from "../button/createChallengeButton";
import Pagination from "../tables/Pagination";
import ItemsPerPageButton from "../button/itemsPerPageButton";
import hamburgerIcon from '../../assets/media/hamburger.svg';
import closeIcon from '../../assets/media/close.png';

const SotierListTable = ({ columns, data, socialProvider, tableName, rowClassName, isRowClickable, onRowClick, corporateCampaign, segmentPage, segmentTotalPage, setSegmentPage, handleInputChange, segmentPerPageTitle, setSegmentPerPage }) => {
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });
    const [sotyCoinColumnsActive, setSotyCoinColumnsActive] = useState(false);

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const parseDate = (dateString) => {
        if (!dateString) return 0;

        if (dateString.props && dateString.props.children) {
            const children = dateString.props.children;
            let date = '';
            let time = '';

            for (const child of children) {
                if (typeof child === 'string' && child.trim() !== '') {
                    if (child.includes('.')) {
                        date = child.trim().split('.').reverse().join('-');
                    } else if (child.includes(':')) {
                        time = child.trim();
                    }
                }
            }

            const dateTimeString = date + (time ? ' ' + time : '');
            return Date.parse(dateTimeString) || 0;
        } else if (typeof dateString === 'string') {
            const dateParts = dateString.split(' ');
            const date = dateParts[0].split('.').map(Number);
            const time = dateParts[1] ? dateParts[1].split(':').map(Number) : [0, 0, 0];
            return new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]).getTime();
        } else {
            return 0;
        }
    };

    const parseCorporateDate = (dateString) => {
        if (!dateString) return 0;

        const [datePart, timePart] = dateString.split(', ');
        const [day, month, year] = datePart.split('.').map(Number);
        const [hour, minute, second] = timePart.split(':').map(Number);

        return new Date(year, month - 1, day, hour, minute, second).getTime();
    };



    // Function to strip <span> elements and get the text content
    const stripSpan = (value) => {
        if (!value) return value;
        if (typeof value === 'object' && value.props && value.props.children) {
            return stripSpan(value.props.children); // Recursively strip children
        }
        return value;
    };




    const sortedData = [...data].sort((a, b) => {
        if (sortConfig.key === "creationDate" || sortConfig.key === "startDate" || sortConfig.key === "endDate") {
            let dateA;
            let dateB;
            if (corporateCampaign) {
                dateA = parseCorporateDate(a[sortConfig.key]);
                dateB = parseCorporateDate(b[sortConfig.key]);
            } else {
                dateA = parseDate(a[sortConfig.key]);
                dateB = parseDate(b[sortConfig.key]);
            }
            return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
        } else if (sortConfig.key === "id") {
            const valueA = parseInt(stripSpan(a[sortConfig.key]));
            const valueB = parseInt(stripSpan(b[sortConfig.key]));
            return sortConfig.direction === "asc" ? valueA - valueB : valueB - valueA;
        } else {
            const valueA = stripSpan(a[sortConfig.key]);
            const valueB = stripSpan(b[sortConfig.key]);
            // Check if both values are integers
            if (!isNaN(valueA) && !isNaN(valueB)) {
                return sortConfig.direction === "asc" ? valueA - valueB : valueB - valueA;
            } else {
                // If not integers, compare them as strings
                return sortConfig.direction === "asc" ? String(valueA).localeCompare(String(valueB)) : String(valueB).localeCompare(String(valueA));
            }
        }
    });






    return (
        <div className="dynamic-table-container">
            <table className="dynamic-table margin-top-none">
                <thead>
                    <tr className="complaint-row">
                        {columns.map((column, index) => (
                            index === 2 ? (
                                <th key={index} className={`dynamic-th ${column.className} social-media-list-title`} colSpan="3">
                                    <div className="social-media-list-title-img-container">
                                        <img src={socialProvider.icon} alt="socialProvider" />
                                        {socialProvider.name}
                                    </div>
                                </th>
                            ) : (index === 1 || index === 3) ? null : (
                                <th key={index} className={`dynamic-th ${column.className} hidden`}>
                                    <div className="dynamic-th-wrapper">
                                        {column.title}
                                        {column.toggle && (
                                            <button onClick={column.onToggleClick}>
                                                {sotyCoinColumnsActive ? 'Hide Coins' : 'Show Coins'}
                                            </button>
                                        )}
                                        {!(index === 0 || column.field === "unscrollable") && (
                                            <div className="sort-buttons">
                                                <button onClick={() => handleSort(column.field)}>
                                                    <img className="dyanmic-sort-asc" src={downArrow} alt="Sort ascending" />
                                                </button>
                                                <button onClick={() => handleSort(column.field)}>
                                                    <img className="dyanmic-sort-desc" src={downArrow} alt="Sort descending" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </th>
                            )
                        ))}
                    </tr>
                </thead>
                <thead>
                    <tr className="complaint-row">
                        {columns.map((column, index) => (
                            <th key={index} className={`dynamic-th ${column.className}`}>
                                <div className="dynamic-th-wrapper">
                                    {column.title}
                                    
                                    {!(index === 0 || column.field === "unscrollable") && (
                                        <div className="sort-buttons">
                                            <button onClick={() => handleSort(column.field)}>
                                                <img className="dyanmic-sort-asc" src={downArrow} alt="Sort ascending" />
                                            </button>
                                            <button onClick={() => handleSort(column.field)}>
                                                <img className="dyanmic-sort-desc" src={downArrow} alt="Sort descending" />
                                            </button>
                                        </div>
                                    )}
                                    {column.toggle && (
                                        <img src={sotyCoinColumnsActive ?  closeIcon : hamburgerIcon } alt="show/hide" onClick={() => { column.onToggleClick(); setSotyCoinColumnsActive(!sotyCoinColumnsActive) }} className="sotier-list-hamburger-icon dynamic-sort-asc" />
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {sortedData.map((item, index) => (
                        <React.Fragment key={index}>
                            <tr
                                className={`dynamic-table-row ${rowClassName ? rowClassName : ""} ${typeof isRowClickable === "function" && isRowClickable(item) ? "clickable-row" : ""}`}
                            >
                                {columns.map((column, index) => (
                                    <td key={index} className={`dynamic-td ${column.className}`}>
                                        {column.render ? column.render(item) : item[column.field]}
                                    </td>
                                ))}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

SotierListTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    tableName: PropTypes.string.isRequired,
    isRowClickable: PropTypes.func,
    onRowClick: PropTypes.func,
};

export default SotierListTable;
