import React, { useState } from "react";
import arrow from '../../assets/media/down-arrow.png';
import '../../assets/css/RecursiveMenu.css';

const RecursiveMenu = ({ items, collapse, setClickedItem, clickedItem }) => {
    const [collapseStates, setCollapseStates] = useState({});

    const handleToggleCollapse = (value) => {
        setCollapseStates((prev) => ({
            ...prev,
            [value]: !prev[value],
        }));
        setClickedItem(value);
    };

    const handleLinkClick = (item) => {
        setClickedItem(item);
    };

    const isAncestorClicked = (item) => {
        if (!item.items) return false;

        return item.items.some((child) => {
            if (child.value === clickedItem) return true;
            return isAncestorClicked(child);
        });
    };

    const urlParams = new URLSearchParams(window.location.search);
    const parameterValue = urlParams.get('parameter');

    return (
        <div className={`recursive-menu ${collapse ? 'recursive-menu-collapsed' : ''}`}>
            {items.map((item) => (
                <div key={item.value} className="recursive-menu-item">
                    {item.items ? (
                        <div
                            className={`recursive-menu-title-row ${parameterValue === item.value ? 'recursive-menu-clicked' : ''}`}
                            onClick={() => {
                                handleToggleCollapse.bind(null, item.value);
                                handleLinkClick(item);
                            }}
                        >
                            <div className='recursive-menu-title'>{item.label}</div>
                            <img className={`recursive-menu-img ${collapseStates[item.value] ? 'deg180' : ''}`} src={arrow} alt='V' />
                        </div>
                    ) : (
                        <div
                            className={`recursive-menu-item-link ${parameterValue === item.value ? 'recursive-menu-clicked' : ''}`}
                            onClick={() => {
                                handleLinkClick(item);
                            }}
                        >
                            {item.label}
                        </div>
                    )}
                    {item.items && (
                        <RecursiveMenu
                            items={item.items}
                            collapse={collapseStates[item.value] || false}
                            setClickedItem={setClickedItem}
                            clickedItem={clickedItem}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default RecursiveMenu;
