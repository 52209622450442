import React from "react";
import tick from "../../../../../assets/media/tickInteraction.svg"; // Icon yolunu kontrol edin

const NotificationPreferences = ({
  t,
  sendInApp,
  sendSms,
  sendMail,
  handleInputChange,
  setSendInApp,
  setSendSms,
  setSendMail,
}) => {
  return (
    <div className="challenge-creation-input-container-content-wrapper">
      {/* In-App Notification */}
      <div className="challenge-creation-segment-row">
        <div
          className={sendInApp ? "qr-checkbox checked" : "qr-checkbox"}
          onClick={() => {
            handleInputChange(!sendInApp, setSendInApp);
          }}
        >
          <img src={tick} alt="Tick" />
        </div>
        <div className="challenge-creation-segment-content-container">
          <div className="challenge-creation-segment-title">
            {t("inAppNotification")}
          </div>
        </div>
      </div>

      {/* SMS Notification */}
      <div className="challenge-creation-segment-row">
        <div
          className={sendSms ? "qr-checkbox checked" : "qr-checkbox"}
          onClick={() => {
            handleInputChange(!sendSms, setSendSms);
          }}
        >
          <img src={tick} alt="Tick" />
        </div>
        <div className="challenge-creation-segment-content-container">
          <div className="challenge-creation-segment-title">{t("sms")}</div>
        </div>
      </div>

      {/* Email Notification */}
      <div className="challenge-creation-segment-row">
        <div
          className={sendMail ? "qr-checkbox checked" : "qr-checkbox"}
          onClick={() => {
            handleInputChange(!sendMail, setSendMail);
          }}
        >
          <img src={tick} alt="Tick" />
        </div>
        <div className="challenge-creation-segment-content-container">
          <div className="challenge-creation-segment-title">{t("mail")}</div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPreferences;
