import React from "react";
import infoIconEmpty from "../../assets/media/infoIconEmpty.svg";

const SingleNumberInput = ({
  title,
  titleDescription,
  isMandatory,
  inputPlaceholder,
  maxLength,
  minLength,
  readonly = false,
  infoText,
  value,
  isValid = true,
  onBlur,
  onChange,
  infoTextFooter,
}) => {
  return (
    <div className="challenge-creation-input-container">
      <div className="challenge-creation-input-container-header">
        <div className="challenge-creation-input-container-header-title">
          {title && title}
          {isMandatory && <span>*</span>}
          {title && ":"}
        </div>
        <div className="challenge-creation-input-container-header-subtitle">
          {titleDescription && titleDescription}
        </div>
      </div>
      <hr />
      <div className="challenge-creation-input-container-content-wrapper">
        <div className="challenge-title-container">
          <input
            className="challenge-title"
            type="number"
            readOnly={readonly}
            placeholder={inputPlaceholder && inputPlaceholder}
            minLength={minLength && minLength}
            maxLength={maxLength && maxLength}
            value={value}
            onBlur={(e) => {
              onBlur(e);
            }}
            onChange={(e) => {
              onChange(e);
            }}
            style={{ border: isValid ? "" : "2px solid red" }}
          />
          {infoText &&
            infoText.map((text, index) => (
              <div key={index} className="challenge-title-info-container">
                <img
                  className="challenge-title-info-icon"
                  src={infoIconEmpty}
                  alt="info:"
                />
                <div className="challenge-title-info-text">{text}</div>
              </div>
            ))}
        </div>
        {infoTextFooter && (
          <div className="single-input-footer">
            {infoTextFooter.map((text, index) => (
              <div key={index} className="challenge-title-info-container">
                <img
                  className="challenge-title-info-icon"
                  src={infoIconEmpty}
                  alt="info:"
                />
                <div className="challenge-creation-date-description">
                  {text}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default SingleNumberInput;
