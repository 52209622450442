import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import "../../../../../../assets/css/ChallengeDetail.css";
import "../../../../../../assets/css/ContentLibrary.css";
import facebookIcon from "../../../../../../assets/media/facebook.svg";
import instagramIcon from "../../../../../../assets/media/instagram.svg";
import twitterIcon from "../../../../../../assets/media/x-twitter.svg";
import youtubeIcon from "../../../../../../assets/media/youtube.svg";
import tiktokIcon from "../../../../../../assets/media/tiktok.svg";
import userPng from '../../../../../../assets/media/user.png';
import { apiUrl, imagesUrl } from "../../../../../../config";
import ContentCard from "../../../../../cards/ContentCard";
import SearchInput from "../../../../../search/search";
import CallAPI from "../../../../../api/callApi";
import ItemsPerPageButton from "../../../../../button/itemsPerPageButton";
import Pagination from "../../../../../tables/Pagination";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import Loader from "../../../../../popup/loader";


const ContentLibrary = () => {

    const { t, i18n } = useTranslation("contentList");
    const location = useLocation();
    const [contentCards, setContentCards] = useState([]);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [currentPerPage, setCurrentPerPage] = useState(20);
    const [loaderState, setLoaderState] = useState(false);
    const [theChallenge, setTheChallenge] = useState();
    const [offerStatus, setOfferStatus] = useState("ACCEPTED");
    let returnedChallenge = location.state?.returnedChallenge;

    const locationArray = [
        [t("contents"), "/brand/srm/content"],
        [t("contentLibrary"), "/brand/srm/content/library"],
    ];


    const fetchPosts = async () => {
        setLoaderState(true);
        setContentCards([]);

        try {
            
            const response = await CallAPI({
                method: "GET",
                endPoint: `${apiUrl}/api/v1/brand-admin/posts?page=${currentPage || 0}&size=${currentPerPage || 20}&offerStatus=${offerStatus}`
            });
            console.log("RESPONSE =", response)
            setContentCards(response.data.responseData);

            setTotalPages(response.data.pageMetadata.totalPage)
            console.log(response.data)
            setLoaderState(false);
        } catch (error) {
            console.log(error);
            setLoaderState(false);
        }



    };

   

    useEffect(() => {
        setContentCards([]);
        fetchPosts();
    }, [currentPage, currentPerPage, offerStatus])


    const handleChange = (event) => {
        setOfferStatus(event.target.value);
    };

    const currentPerPageChange = (e, setter) => {
        setCurrentPage(0)
        setter(e);


    }


    const formatDateForCreation = (dateString) => {
        const now = new Date();
        const date = new Date(dateString);
        const diffMs = now - date;
        const diffMins = Math.floor(diffMs / (1000 * 60));
        const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

        if (diffMins < 60) {
            return `${diffMins} ${t("minutesAgo")}`;
        } else if (diffHours < 24) {
            return `${diffHours} ${t("hoursAgo")}`;
        } else {
            return date.toLocaleString("tr-TR", {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        }
    };
    const formatDateStringLocal = (dateString) => {
        // UTC tarihini al
        const utcDate = new Date(dateString);

        // UTC tarihine manuel olarak 3 saat ekle
        utcDate.setHours(utcDate.getHours() + 3);

        // Tarih ve saat bilgilerini istediğiniz formatta al
        const formattedDate = utcDate.toLocaleDateString("tr-TR");
        const formattedTime = utcDate.toLocaleTimeString("tr-TR");
        const formattedDateTime = formattedDate + " " + formattedTime;
        return formattedDateTime;
    };

    return (
        <div className="dashboard-container">
            <NavigationBar
                locationArray={locationArray}
            />
         {loaderState && <Loader />}
            <div className="content-library-radio-container">
                <div className="content-library-radio">
                    <input
                        type="radio"
                        id="approved"
                        name="content-type"
                        value="ACCEPTED"
                        checked={offerStatus === 'ACCEPTED'}
                        onChange={handleChange}
                    />
                    <label
                        htmlFor="approved"
                        className={offerStatus === 'ACCEPTED' ? 'content-library-selected-content-type' : ''}
                    >
                        {t("approved")}
                    </label>
                    <div className={offerStatus === 'ACCEPTED' ? 'indicator-selected' : 'indicator'} />
                </div>
                <div className="content-library-radio">
                    <input
                        type="radio"
                        id="pending"
                        name="content-type"
                        value="OFFERED"
                        checked={offerStatus === 'OFFERED'}
                        onChange={handleChange}
                    />
                    <label
                        htmlFor="pending"
                        className={offerStatus === 'OFFERED' ? 'content-library-selected-content-type' : ''}
                    >
                        {t("pending")}
                    </label>
                    <div className={offerStatus === 'OFFERED' ? 'indicator-selected' : 'indicator'} />
                </div>
                <div className="content-library-radio">
                    <input
                        type="radio"
                        id="rejected"
                        name="content-type"
                        value="DECLINED"
                        checked={offerStatus === 'DECLINED'}
                        onChange={handleChange}
                    />
                    <label
                        htmlFor="rejected"
                        className={offerStatus === 'DECLINED' ? 'content-library-selected-content-type' : ''}
                    >
                        {t("rejected")}
                    </label>
                    <div className={offerStatus === 'DECLINED' ? 'indicator-selected' : 'indicator'} />
                </div>
            </div>
           
           

            <div className="content-list-container">
                {contentCards.map((content, index) => (
                    <ContentCard
                        key={index}
                        content={content}
                        provider={content.provider}
                        link={content.link}
                        providerIcon={content.provider === "FACEBOOK" ? facebookIcon : content.provider === "INSTAGRAM" ? instagramIcon : content.provider === "YOUTUBE" ? youtubeIcon : content.provider === "TIKTOK" ? tiktokIcon : twitterIcon}
                        offerDeclineDate={formatDateStringLocal(content.offerDeclineDate)}
                        profilePicture={content.sotierProfilePhoto === undefined ? userPng : imagesUrl + "/" + content.sotierProfilePhoto}
                        fullName={content.sotierName}
                        id={content.sotierId}
                        interaction={content.interaction}
                        sotyCoin={content.totalEarnedCoins || 0}
                        creationDate={formatDateForCreation(content.published)}
                        bottomType={offerStatus}
                        offerCoin={content.offerCoin}
                        offerDate={formatDateStringLocal(content.offerDate)}
                        offerAcceptDate={formatDateStringLocal(content.offerAcceptDate)}
                        offerEndDate={formatDateStringLocal(content.offerEndDate)}
                        formatDateStringLocal={formatDateStringLocal}

                    />
                ))}
            </div>
            <div className="content-list-pagination-container">
                <div className="visibility-hidden">
                    <ItemsPerPageButton onSelectItem={(e) => { currentPerPageChange(e, setCurrentPerPage) }} title={t("contentPerPage")} />
                </div>
                <Pagination
                    currentPage={currentPage + 1 || null}
                    totalPages={totalPages}
                    onPageChange={(page) => { setCurrentPage(page - 1) }}
                />
                <ItemsPerPageButton onSelectItem={(e) => { currentPerPageChange(e, setCurrentPerPage) }} title={t("contentPerPage")} />
            </div>
        </div>
    )
}
export default ContentLibrary;