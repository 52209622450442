import React, { useState, useEffect } from "react";
import Loader from "../../../../../popup/loader";
import DynamicTable from "../../../../../tables/DynamicTable";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { apiUrl } from "../../../../../../config";
import { toast } from "react-toastify";
import { getCookie } from "../../../../auth/tokenUtils";
import AnalysisTable from "../../../../../tables/analysisTable";
import ItemsPerPageButton from "../../../../../button/itemsPerPageButton";
import Pagination from "../../../../../tables/Pagination";

const SrmSotier = ({ startDate, endDate, onSotierClick }) => {
    const { t, i18n } = useTranslation("analysis");
    const [loaderState, setLoaderState] = useState(false);
    const [sotierList, setSotierList] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "asc",
    });
    const [modifiedData, setModifiedData] = useState([]);
    const [endpoint, setEndpoint] = useState();
    const [sortParameter, setSortParameter] = useState();
    const [itemPerPage, setItemPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [prevStartDate, setPrevStartDate] = useState();
    const [prevEndDate, setPrevEndDate] = useState();
    const [prevCurrentPage, setPrevCurrentPage] = useState(1);

    const fetchData = async () => {
        try {
            if (!endpoint) {
                return;
            }

            console.log(endpoint);
            setLoaderState(true);

            const token = getCookie("access_token");
            const response = await axios.get(
                apiUrl + endpoint,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const newData = response.data.responseData.map((item) => {
                const { sotierId, firstName, lastName, ...rest } = item;
                return {
                    sotierId: sotierId.id,
                    fullName: `${firstName} ${lastName}`, // Combine "name" and "surname" into a new field
                    ...rest,
                };
            });

            console.log(response);
            setTotalPage(response.data.pageMetadata.totalPage);
            setModifiedData(newData);
            setSotierList(newData);

            const sotierColumns = [
                { title: "Sotier ID", field: "sotierId", className: "analysis-table-clickable-id" },
                { title: `${t("name")} ${t("surname")}`, field: "fullName", className: "", sort: true, asc: "NAME_ASC", desc: "NAME_DESC" },
                { title: t("totalEarnedSotyCoin"), field: "totalEarnedCoin", className: "table-green-text", sort: true, desc: "LOW_TOTAL_EARNED_COIN", asc: "HIGH_TOTAL_EARNED_COIN" },
                { title: t("totalSpentSotyCoin"), field: "totalSpentCoin", className: "table-red-text", sort: true, desc: "LOW_TOTAL_SPENT_COIN", asc: "HIGH_TOTAL_SPENT_COIN" },
                { title: t("existingSotyCoin"), field: "existingCoin", className: "table-blue-text", sort: true, desc: "LOW_EXISTING_COIN", asc: "HIGH_EXISTING_COIN" },
            ];

            setSotierList(newData);
            setTableColumns(sotierColumns);

            setLoaderState(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error(error.response.data.metadata.message);
            // Handle error, display a message, etc.
            setLoaderState(false);
        }
    };



    useEffect(() => {
        if (!(prevEndDate === endDate && prevStartDate === startDate)) {
            setCurrentPage(1)
        }
        let endPoint = '/api/v1/analytics/sotier?';
        if (startDate && endDate) {
            let formattedStartDate;
            let formattedEndDate;
            if (startDate instanceof Date) {
                formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
            }
            else {
                formattedStartDate = startDate;
            }
            if (endDate instanceof Date) {
                formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
            }
            else {
                formattedEndDate = endDate;
            }





            const endpointStartDate = `${formattedStartDate}T00:00:00`;
            const endpointEndDate = `${formattedEndDate}T23:59:59`;
            endPoint += `from=${endpointStartDate}&to=${endpointEndDate}&`
        }
        else{
            return;
        }




        if (sortParameter) {
            endPoint += `sotierSortBy=${sortParameter}&`
        }
        endPoint += `page=${currentPage - 1}&size=${itemPerPage}`;


        setEndpoint(endPoint);
        setPrevCurrentPage(currentPage);
        setPrevStartDate(startDate);
        setPrevEndDate(endDate);

    }, [startDate, endDate, itemPerPage, currentPage, sortParameter]);



    useEffect(() => {
        setCurrentPage(1)
    }, [startDate, endDate, itemPerPage, sortParameter])

    useEffect(() => {
        if (endpoint) {

            fetchData();
        }

    }, [endpoint])

    const isRowClickable = () => {
        return true;
    }
    const handleRowClick = (e) => {
        console.log(e)
        onSotierClick(e)
    }
    const handleSortClick = (sortText) => {
        setSortParameter(sortText);
    }
    return (
        <div className="analysis-sotier-container">
            {loaderState && <Loader />}

            <AnalysisTable
                columns={tableColumns}
                data={sotierList}
                tableName={t('sotierList')}
                isRowClickable={isRowClickable} // Example: Make rows clickable if the status is "Inactive"
                onRowClick={handleRowClick}
                onSortClick={handleSortClick}
                sortConfig={sortConfig}
            />
            <div className="table-bottom-control-container" style={{ marginBottom: '300px' }}>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPage}
                    onPageChange={(e) => { setCurrentPage(e) }}
                />
                <ItemsPerPageButton
                    onSelectItem={(e) => { setCurrentPage(1); setItemPerPage(e); }}
                    title={t("sotierPerPage")} />
            </div>


        </div>
    )
}
export default SrmSotier;