import { useState, useEffect } from 'react';

const loadFacebookSDK = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById("facebook-jssdk");

    if (existingScript) {
      resolve();
    } else {
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";

      script.onload = () => {
        window.FB.init({
          appId: "your-app-id", // Burada appId'yi dynamic olarak handleFacebookLogin'de set edelim.
          autoLogAppEvents: true,
          xfbml: true,
          version: "v12.0",
        });
        resolve();
      };
      script.onerror = reject;

      document.head.appendChild(script);
    }
  });
};

const fetchUserPages = async (accessToken) => {
  try {
    const response = await fetch(`https://graph.facebook.com/v18.0/me/accounts?access_token=${accessToken}`);
    if (!response.ok) {
      throw new Error(`Error fetching user pages: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user pages:", error);
    throw error;
  }
};

const fetchUserProfile = async (accessToken) => {
  try {
    const response = await fetch(`https://graph.facebook.com/v18.0/me?access_token=${accessToken}`);
    if (!response.ok) {
      throw new Error(`Error fetching user profile: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

const handleFacebookLogin = (apiUrl) => {
  let accessToken;
  let appId;
  if (apiUrl === "https://api.dev.soty.io") appId = "869308934820516";
  else if(apiUrl === "https://api.qa.soty.io") appId = "3741010559462533";
  else appId = "3741010559462533";
  console.log(appId);

  return loadFacebookSDK()
    .then(() => {
      window.FB.init({
        appId: appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v12.0",
      });

      return new Promise((resolve, reject) => {
        window.FB.login((response) => {
          if (response.authResponse) {
            resolve(response);
          } else {
            reject("User cancelled login or did not fully authorize.");
          }
        });
      });
    })
    .then((response) => {
      console.log(response);
      console.log(response.authResponse.accessToken);
      accessToken = response.authResponse.accessToken;
      return Promise.all([
        fetchUserProfile(response.authResponse.accessToken),
        fetchUserPages(response.authResponse.accessToken),
      ]);
    })
    .then(([userProfile, userPages]) => {
      // Use userProfile and userPages data as needed
      console.log("User profile:", userProfile);
      console.log("User pages:", userPages);
      console.log("User ID:", userProfile.id);

      const result = {
        accessToken: accessToken,
        userId: userProfile.id,
      };

      // Return the access token, user ID, and profile link to the calling code
      return result;
    })
    .catch((error) => {
      console.error("Error during Facebook login:", error);
      throw error;
    });
};

export { handleFacebookLogin };
