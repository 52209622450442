import React from "react";
import close from "../../assets/media/close.png";
import '../../assets/css/ViewInSotyAppButton.css';
import { useTranslation } from "react-i18next";
const EndChallengeButton = ({ onClick, campaign }) => {
    const { t, i18n } = useTranslation("challengeCreation");
    return (
        campaign ?
            <button className="static-button-container end-campaign-button" onClick={onClick}>
                <img src={close} alt="icn" />
                {t("endCampaign")}
            </button>

            :
            <button className="static-button-container end-challenge-button" onClick={onClick}>
                <img src={close} alt="icn" />
                {t("endChallenge")}
            </button>

    );
}
export default EndChallengeButton;