import React from "react";

const CustomTabButton = ({
  onClick,
  title,
  borderBottomColor = "transparent",
}) => {
  return <div className="custom-tab-button button" style={{borderBottomColor: borderBottomColor}} onClick={onClick}>{title}</div>;
};

export default CustomTabButton;
