import React, { useState, useEffect } from 'react';
import checkIcon from '../../assets/media/Checklist.svg';
import youtubeIcon from '../../assets/media/youtube.svg';
import twitterIcon from '../../assets/media/x-twitter.svg';
import facebookIcon from '../../assets/media/facebook.svg';
import tiktokIcon from '../../assets/media/tiktok.svg';
import instagramIcon from '../../assets/media/instagram.svg';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const FilterDialog = ({ checkboxData, checkboxSocialProviderData, onCheckboxSelection, eventType }) => {
    let initialCheckboxStates = {
        contentTypes: [false, false, false],
        socialMediaTypes: [false, false, false, false, false],
    };
    const { t, i18n } = useTranslation('challengeDashboard');

    const [checkboxStates, setCheckboxStates] = useState(() => {
        const savedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || {
            contentTypes: [false, false, false],
            socialMediaTypes: [false, false, false, false, false],
        };
        return savedCheckboxStates;
    });

    useEffect(() => {
        const savedCheckboxStates = JSON.parse(localStorage.getItem('checkboxStates')) || initialCheckboxStates;
        if(!savedCheckboxStates){
            initialCheckboxStates = {
                contentTypes: [false, false, false],
                socialMediaTypes: [false, false, false, false, false],
            };
        }
        
        
        setCheckboxStates(savedCheckboxStates);
    }, []);

    // Save checkbox states to localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('checkboxStates', JSON.stringify(checkboxStates));
    }, [checkboxStates]);

    const handleCheckboxClick = (category, index) => {
        const newCheckboxStates = { ...checkboxStates };

        if (category === 'contentTypes') {
            if (index === 2) {
                // If the clicked checkbox is in "contentTypes" and is "SURVEY"
                // Uncheck checkboxes 0 and 1
                newCheckboxStates.contentTypes[0] = false;
                newCheckboxStates.contentTypes[1] = false;
            } else if (newCheckboxStates.contentTypes[2]) {
                // If "SURVEY" is checked and any other checkbox in "contentTypes" is clicked
                // Uncheck the "SURVEY" checkbox
                newCheckboxStates.contentTypes[2] = false;
            }
        }

        // Toggle the state of the clicked checkbox
        newCheckboxStates[category][index] = !newCheckboxStates[category][index];

        setCheckboxStates(newCheckboxStates);

        // Call onCheckboxSelection with the selected checkboxes
        onCheckboxSelection(
            newCheckboxStates.contentTypes
                .map((isChecked, idx) => (isChecked ? checkboxData[idx] : null))
                .filter(Boolean),
            newCheckboxStates.socialMediaTypes
                .map((isChecked, idx) => (isChecked ? checkboxSocialProviderData[idx] : null))
                .filter(Boolean)
        );
    };

    const resetCheckboxes = () => {
        setCheckboxStates({
            contentTypes: [false, false, false],
            socialMediaTypes: [false, false, false, false, false],
        });
        localStorage.removeItem('checkboxStates');
        onCheckboxSelection(null, null);
    };

    const isAnyContentTypeChecked = checkboxStates.contentTypes[0] || checkboxStates.contentTypes[1];

    return (
        <div className="filter-dialog">
            <div className="filter-dialog-part filter-dialog-part-type">
                <div className="filter-dialog-title">{eventType === "campaign" ? t("campaignType") : t('challengeType')}</div>
                <div className="filter-dialog-row-container">
                    {checkboxData &&
                        checkboxData.map((data, index) => (
                            <div key={index} className="filter-dialog-row">
                                <div
                                    className={`filter-dialog-checkbox-container ${
                                        checkboxStates.contentTypes[index]
                                            ? 'filter-dialog-checkbox-container-active'
                                            : ''
                                    }`}
                                    onClick={() => handleCheckboxClick('contentTypes', index)}
                                >
                                    <img className="filter-title-checkicon" src={checkIcon} alt="Checkbox" />
                                </div>
                                <div className="filter-title">{data.title}</div>
                            </div>
                        ))}
                </div>
            </div>
            <div
                className={`filter-dialog-separator ${isAnyContentTypeChecked ? '' : 'height-0'}`}
            />
            <div
                className={`filter-dialog-part filter-dialog-part-social-media-part ${
                    isAnyContentTypeChecked ? '' : 'height-0'
                }`}
            >
                <div className="filter-dialog-title">{t('socialMediaPlatform')}</div>
                <div className="filter-dialog-row-container">
                    {checkboxSocialProviderData &&
                        checkboxSocialProviderData.map((data, index) => (
                            <div key={index} className="filter-dialog-row">
                                <div
                                    className={`filter-dialog-checkbox-container ${
                                        checkboxStates.socialMediaTypes[index]
                                            ? 'filter-dialog-checkbox-container-active'
                                            : ''
                                    }`}
                                    onClick={() => handleCheckboxClick('socialMediaTypes', index)}
                                >
                                    <img className="filter-title-checkicon" src={checkIcon} alt="Checkbox" />
                                </div>
                                <img className="filter-title-img" src={data.icon} alt="scl" />
                                <div className="filter-title">{data.title}</div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="filter-dialog-button-container">
                <div className="filter-dialog-reset-button" onClick={resetCheckboxes}>
                    {t('reset')}
                </div>
            </div>
        </div>
    );
};

export default FilterDialog;
