import React from "react";
import checkIcon from '../../../assets/media/Checklist.svg';
import infoIcon from '../../../assets/media/infoIconEmpty.svg';
import timeIcon from '../../../assets/media/time.svg';
import rejectedIcon from '../../../assets/media/rejected.png';
import shoppingCardIcon from '../../../assets/media/Icon ionic-ios-cart.svg';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
const ContentCardBottomRejected = ({ offerDeclineDate, offerCoin, content, offerEndDate }) => {
    const { t, i18n } = useTranslation("contentList");
    const navigate = useNavigate();
    const handleContentBasketButton = () => {
        // contentCards içeriğini localStorage'dan al
        const savedContentCards = JSON.parse(localStorage.getItem('contentCards')) || [];

        // Eğer içerik zaten mevcut değilse ekle
        const isContentExists = savedContentCards.some(item => item.id === content.id);
        if (!isContentExists) {
            savedContentCards.push(content);
            localStorage.setItem('contentCards', JSON.stringify(savedContentCards));
        }

        // Sepet sayfasına yönlendir
        navigate('/brand/srm/content/basket', {state: {content}});
    };

    return (
        <div className="content-card-bottom-rejected-container">
            <div className="content-card-bottom-rejected-row-container">
                <div className="content-card-bottom-rejected-row">
                    <div className="content-card-bottom-rejected-row-left">
                        <div className="content-card-bottom-rejected-row-left-indicator" />
                        <label>{content.offerDeclineDate ?t("declined") : t("timeout")}</label>
                    </div>
                    <div className="content-card-bottom-rejected-row-right-img-container">
                        <img src={rejectedIcon} alt="tick" />
                    </div>
                </div>

                <div className="content-card-bottom-rejected-row">
                    <div className="content-card-bottom-rejected-row-left">
                        <div className="content-card-bottom-rejected-row-left-indicator" />
                        <label>{content.offerDeclineDate ? t("declineDate") : t("offerEndDate")}:</label>
                        <span>{content.offerDeclineDate ? offerDeclineDate : offerEndDate}</span>
                    </div>
                </div>

                <div className="content-card-bottom-rejected-row">
                    <div className="content-card-bottom-rejected-row-left">
                        <div className="content-card-bottom-rejected-row-left-indicator" />
                        <label>{t("offerCoin")}:</label>
                    </div>
                    <div className="content-card-bottom-rejected-row-right">
                        {offerCoin}
                    </div>
                </div>


            </div>

            <div className="content-card-bottom-default-card-button-new-offer" onClick={() => {handleContentBasketButton()}}>
                <img className="content-card-default-card--button-img" src={shoppingCardIcon} alt="icn" />
                <div className="content-card-bottom-default-card-button-title">
                    {t("makeANewOffer")}
                </div>
            </div>

        </div>
    )
}
export default ContentCardBottomRejected;