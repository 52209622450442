// Import necessary dependencies
import React, { useEffect, useState } from 'react';
import arrow from '../../../../../assets/media/arrow.png';
import downArrow from '../../../../../assets/media/down-arrow.png';
import analysisIcon from '../../../../../assets/media/analysis.svg';
import '../../../../../assets/css/Analysis.css';
import { useNavigate, useLocation } from 'react-router-dom';
import SrmSotier from './srm/sotier';
import { useTranslation } from "react-i18next";
import DatePickerButton from '../../../../button/datePickerButton';
import RecursiveMenu from '../../../../collapsible/recursiveMenu';
import StaticDateRangeButton from '../../../../button/staticDateRangeButton';
import { format } from 'date-fns';
import trLocale from 'date-fns/locale/tr'; // Türkçe dil dosyasını ekleyin
import { enUS } from 'date-fns/locale'; // Türkçe dil dosyasını ekleyin
import DetailIcon from '../../../../../assets/media/Icon feather-arrow-right-circle.svg';

const Analysis = () => {
    const [clickedItem, setClickedItem] = useState(null);
    const { t, i18n } = useTranslation("analysis");
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedChannel, setSelectedChannel] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLabel, setPageLabel] = useState(localStorage.getItem('pageLabel') || undefined);
    const [totalPage, setTotalPage] = useState();
    const [itemPerPage, setItemPerPage] = useState(20);

    // State'leri ayarlayın
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [staticSelectedDateRange, setStaticSelectedDateRange] = useState();
    const [selectedOption, setSelectedOption] = useState(null);


    const [graphsWeekly, setGraphsWeekly] = useState(false);


    const [language, setLanguage] = useState();





    const renderChildComponentIfParameterMatches = (parameterValue, childComponent) => {
        const urlParams = new URLSearchParams(location.search);

        return (urlParams.get('parameter') === parameterValue || urlParams.has(parameterValue)) && childComponent;
    };


    const onSotierClick = (sotier) => {
        const searchParams = new URLSearchParams();
        searchParams.set('sotier', sotier.sotierId);
        window.location.search = searchParams.toString();
    };

    useEffect(() => {
        // URL'deki parametreleri alın

        if (localStorage.getItem('language') === 'tr-TR') {
            setLanguage(trLocale);
        }
        else if (localStorage.getItem('language') === 'en-US') {
            setLanguage(enUS);
        }


        const searchParams = new URLSearchParams(window.location.search);
        const startDateParam = searchParams.get('start_date');
        const endDateParam = searchParams.get('end_date');
        // URL'deki parametreleri kontrol edin ve varsa state'leri ayarlayın
        if (startDateParam && endDateParam) {
            setStartDate(startDateParam instanceof Date ?
                `${startDateParam.getFullYear()}-${(startDateParam.getMonth() + 1).toString().padStart(2, '0')}-${startDateParam.getDate().toString().padStart(2, '0')}` :
                startDateParam
            );
            setEndDate(endDateParam instanceof Date ?
                `${endDateParam.getFullYear()}-${(endDateParam.getMonth() + 1).toString().padStart(2, '0')}-${endDateParam.getDate().toString().padStart(2, '0')}` :
                endDateParam
            );
        } else {
            // URL'de parametreler yoksa, varsayılan tarih aralığını kullanın
            // Bu kısmı dilediğiniz gibi ayarlayabilirsiniz
            // Örneğin, mevcut tarihten başlayarak 7 gün sonrasını endDate olarak ayarlayabilirsiniz
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

            setEndDate(currentDate);
            setStartDate(firstDayOfMonth); // formatDate fonksiyonu gerekirse kullanılabilir
        }

        staticDateTitleChanger();



    }, []);

    const staticDateTitleChanger = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const startDateParam = searchParams.get('start_date');
        const endDateParam = searchParams.get('end_date');

        const today = new Date();
        const start = new Date(startDateParam);
        const end = new Date(endDateParam);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (end.getDate() === today.getDate() && end.getMonth() === today.getMonth() && end.getFullYear() === today.getFullYear()) {
            if (start.getFullYear() === today.getFullYear() - 5 && start.getMonth() === 0 && start.getDate() === 1) {


                setStaticSelectedDateRange({ value: "last5year", label: t("last5year") });


            } else if (start.getFullYear() === today.getFullYear() && start.getMonth() === 0 && start.getDate() === 1) {


                setStaticSelectedDateRange({ value: "year", label: t("thisYear") });

            } else if ((start.getMonth() === today.getMonth() && start.getDate() === today.getDate() && start.getFullYear === today.getFullYear)) {

                setStaticSelectedDateRange({ value: "today", label: t("today") });
            } else if (start.getMonth() === today.getMonth() && start.getDate() === 1 && start.getFullYear === today.getFullYear) {

                setStaticSelectedDateRange({ value: "month", label: t("thisMonth") });
            } else if (start.getDay() === 1 && start.getMonth === today.getMonth && start.getFullYear === today.getFullYear) {

                setStaticSelectedDateRange({ value: "week", label: t("thisWeek") });
            }
            else {
                setStaticSelectedDateRange();
            }
        }
        else {

        }
    }


    function DateDifferenceChecker(date1, date2) {
        const date1Obj = new Date(date1);
        const date2Obj = new Date(date2);

        const diffInMilliseconds = Math.abs(date2Obj - date1Obj);
        const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;

        console.log(formatDate(date1Obj), formatDate(date2Obj), " difference in days = ", diffInDays);
        return diffInDays > 59;

    }







    useEffect(() => {

        console.log("START DATE = ", startDate);
        DateDifferenceChecker('2019-01-01', '2024-02-12');
        if (startDate && endDate) {
            const searchParams = new URLSearchParams(window.location.search);

            searchParams.set('start_date', startDate instanceof Date ? formatDate(startDate) : startDate);
            searchParams.set('end_date', endDate instanceof Date ? formatDate(endDate) : endDate);
            const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);


            let formattedStartDate;
            let formattedEndDate;
            if (startDate instanceof Date) formattedStartDate = formatDate(startDate);
            else {
                formattedStartDate = startDate;
            }
            if (endDate instanceof Date) formattedEndDate = formatDate(endDate);
            else {
                formattedEndDate = endDate;
            }
            setGraphsWeekly(DateDifferenceChecker(formattedStartDate, formattedEndDate));


        }


        staticDateTitleChanger();

    }, [startDate, endDate]);







    useEffect(() => {
        if (selectedChannel.value) {
            const searchParams = new URLSearchParams();
            searchParams.set('parameter', selectedChannel.value);
            window.location.search = searchParams.toString();
        }



    }, [selectedChannel])


    const staticDateOptions = [
        { value: "today", label: t("today") },
        { value: "week", label: t("thisWeek") },
        { value: "month", label: t("thisMonth") },
        { value: "year", label: t("thisYear") },
        { value: "last5year", label: t("last5year") }
    ]

    const srmOptions = [
        { value: "/general", label: t("general") },
        {
            value: "/challenge", label: t("challenge"), items: [
                { value: "/challenge/content", label: t("contentCreation") },
                { value: "/challenge/interaction", label: t("interaction") }
            ]
        },/*
        {
            value: "/survey", label: t("survey"), items: [
                { value: "/survey/surveySingle", label: t("singleChoice") },
                { value: "/survey/surveyMultiple", label: t("multipleChoice") },
                {
                    value: "/survey/ratingScale", label: t("ratingScale"), items: [
                        { value: "/survey/ratingScale/linearNumeric", label: t("linearScale") },
                        {
                            value: "/survey/ratingScale/likertScale", label: t("likertScale"), items: [
                                { value: "/survey/ratingScale/likertScale/agreement", label: t("agreement") },
                                { value: "/survey/ratingScale/likertScale/frequency", label: t("frequency") },
                                { value: "/survey/ratingScale/likertScale/importance", label: t("importance") },
                                { value: "/survey/ratingScale/likertScale/interest", label: t("interest") },

                            ]
                        },
                    ]
                },
            ]
        },*/
        {
            value: "/srm", label: t("srm"), items: [/*
                {
                    value: "/srm/campaigns", label: t("campaigns"), items: [
                        { value: "/srm/campaigns/sotyCoefficient", label: t("sotyCoefficient") },
                        { value: "/srm/campaigns/percentage", label: t("percentage") },
                        { value: "/srm/campaigns/xShopping", label: t("xShopping") },
                        { value: "/srm/campaigns/freeShipping", label: t("freeShipping") },
                        { value: "/srm/campaigns/xProduct", label: t("xProduct") },
                        { value: "/srm/campaigns/BuyxPayy", label: t("buyxPayy") }
                    ]
                },*/
                { value: "/srm/sotier", label: t("sotier") },/*
                { value: "/srm/customDay", label: t("customDay") },
                { value: "/srm/corporate", label: t("corporate") },
                { value: "/srm/segment", label: t("segment") },
                { value: "/srm/contents", label: t("contents") },*/
            ]
        }
    ];



    const handleDateChange = ({ selectedStartDate, selectedEndDate }) => {
        if (selectedEndDate) {
            setStartDate(selectedStartDate);
            setEndDate(selectedEndDate)
            setStaticSelectedDateRange();

        }


    };


    const goBack = () => {
        localStorage.removeItem('pageLabel');
        setSelectedChannel();
        setPageLabel();
        navigate('/brand/analysis');
    }

    function formatDate(date) {
        if (date instanceof Date) {
            const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
            return formattedDate;
        }


    }



    const MetabaseFrame = ({ question, parameterName, detail, detailDestination }) => {

        const urlParams = new URLSearchParams(location.search);
        const sotierParamValue = urlParams.get(parameterName);
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;
        if (startDate instanceof Date) {
            formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
        }

        if (endDate instanceof Date) {
            formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
        }
        let iframeSrc;



        if (urlParams.has('sotier')) {
            // Mevcut URL'de 'sotier' parametresi varsa, iframe'in src'sine bu parametreyi ekleyin
            iframeSrc = `https://metabase.dev.soty.io/public/question/${question}?sotier=${sotierParamValue}&start_date=${formattedStartDate && formattedStartDate}&end_date=${formattedEndDate && formattedEndDate + 'T23:59:59'}#hide_parameters=sotier,start_date,end_date`;
        }
        else {
            iframeSrc = `https://metabase.dev.soty.io/public/question/${question}?&start_date=${formattedStartDate && formattedStartDate}&end_date=${formattedEndDate && formattedEndDate + 'T23:59:59'}#hide_parameters=sotier,start_date,end_date`;


        }
        return (
            <div className='metabase-frame-wrapper'>
                <iframe
                    id="myIframe"
                    src={iframeSrc}
                    frameborder="0"
                    width="100%"
                    height="600px"
                ></iframe>
                {detail &&
                    <div className='metabase-frame-details-button' onClick={() => { setSelectedChannel(detailDestination) }}>
                        {t("detail")}
                        <img className='metabase-frame-details-button-img' src={DetailIcon} alt={">"} />
                    </div>
                }

            </div>

        )

    }

    const handleStaticDateRangleSelected = (selectedOption) => {
        setStaticSelectedDateRange(selectedOption);
        const today = new Date();
    
        if (selectedOption.value === "today") {
            const originalStartDate = new Date();
            const formattedStartDate = `${originalStartDate.getFullYear()}-${(originalStartDate.getMonth() + 1).toString().padStart(2, '0')}-${originalStartDate.getDate().toString().padStart(2, '0')}`;
            setStartDate(formattedStartDate);
        } else if (selectedOption.value === "week") {
            // Get the first day of the current week starting from Monday
            const firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));
            const formattedStartDate = `${firstDayOfWeek.getFullYear()}-${(firstDayOfWeek.getMonth() + 1).toString().padStart(2, '0')}-${firstDayOfWeek.getDate().toString().padStart(2, '0')}`;
            setStartDate(formattedStartDate);
        } else if (selectedOption.value === "month") {
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const formattedStartDate = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${firstDayOfMonth.getDate().toString().padStart(2, '0')}`;
            setStartDate(formattedStartDate);
        } else if (selectedOption.value === "year") {
            const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
            const formattedStartDate = `${firstDayOfYear.getFullYear()}-01-01`;
            setStartDate(formattedStartDate);
        } else if (selectedOption.value === "last5year") {
            const firstDayOfLast5Year = new Date(today.getFullYear() - 5, 0, 1);
            const formattedStartDate = `${firstDayOfLast5Year.getFullYear()}-01-01`;
            setStartDate(formattedStartDate);
        }
    
        setEndDate(`${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`);
    }



    return (
      <div className="dashboard-container challenge-analysis-margin">
        <div className="content-location-container analysis-location-container">
          <a
            className="content-location-container-href"
            onClick={() => {
              goBack();
            }}
            href="/brand/analysis"
          >
            <img src={arrow} alt="arrow" />
          </a>
          <a
            className="content-location-container-link-text"
            href="/brand/analysis"
          >
            <div className="older">{t("analysis")}</div>
          </a>
          {pageLabel && (
            <>
              <img src={downArrow} className="backArrow" alt="down-arrow" />
              <div className="current">{pageLabel}</div>
            </>
          )}
        </div>
        <div className="analysis-top-divider" />
        <div className="challenge-analysis-top-controls-container">
          <div className="challenge-analysis-top-buttons-container">
            <DatePickerButton onDateChange={handleDateChange} />
          </div>
          <div className="challenge-analysis-title-container">
            <div className="challenge-analysis-title">
              {t("#analysisDashboard")}
            </div>
            <div className="challenge-analysis-description">
              {t("analysisDashboardDescription")}
            </div>
            <div className="challenge-analysis-description-logo-wrapper">
              <img
                className="challenge-analysis-description-logo"
                src={analysisIcon}
                alt="anlys"
              />
            </div>
          </div>
        </div>
        <div className="challenge-analysis-top-hr-container">
          <hr />
          {staticSelectedDateRange
            ? staticSelectedDateRange.label
            : startDate &&
              format(startDate, "d MMMM yyyy EEEE", { locale: language }) +
                "  -  " +
                format(endDate, "d MMMM yyyy EEEE", { locale: language })}

          <hr />
          <StaticDateRangeButton
            options={staticDateOptions}
            defaultSelected={
              staticSelectedDateRange ? staticSelectedDateRange : undefined
            }
            onSelect={(e) => {
              handleStaticDateRangleSelected(e);
            }}
          />
        </div>
        <div className="challenge-analysis-container">
          <div className="challenge-analysis-channels-container">
            <div className="challenge-analysis-channels-title">
              {t("analysisChannels")}
            </div>
            <RecursiveMenu
              items={srmOptions}
              setClickedItem={(item) => {
                setSelectedChannel(item);
              }}
              clickedItem={clickedItem || "/general"}
            />
          </div>
          <div className="analysis-container-analysis-wrapper">
            {renderChildComponentIfParameterMatches(
              "/srm/sotier",
              <SrmSotier
                startDate={startDate}
                endDate={endDate}
                onSotierClick={(sotier) => {
                  onSotierClick(sotier);
                }}
              />
            )}
            {renderChildComponentIfParameterMatches(
              "sotier",
              <>
                {graphsWeekly ? (
                  <>
                    <MetabaseFrame
                      question={"a8b7268e-6cb9-4892-8c7f-f26175072494"}
                      parameterName={"sotier"}
                    />
                    <MetabaseFrame
                      question={"d7c22bda-0835-4979-bebc-3bce36f569a2"}
                      parameterName={"sotier"}
                    />
                  </>
                ) : (
                  <>
                    <MetabaseFrame
                      question={"af00b51e-a7af-43cc-a6cd-9b208ada9d76"}
                      parameterName={"sotier"}
                    />
                    {/* earning */}
                    <MetabaseFrame
                      question={"63b144b9-de3d-436d-9ae3-9e710840c6c3"}
                      parameterName={"sotier"}
                    />
                    {/* spending */}
                  </>
                )}
                {/* WEEKLY */}
              </>
            )}
            {renderChildComponentIfParameterMatches(
              "/general",
              <>
                {graphsWeekly ? (
                  <>
                    <MetabaseFrame
                      question={"4663b248-81ee-4627-a4f8-a548f76636a6"}
                      detail={true}
                      detailDestination={{
                        value: "/totalEarningDetails",
                        label: t("totalEarningDetails"),
                      }}
                    />
                    <MetabaseFrame
                      question={"23b99877-dc81-4f8e-b582-fdf46abc2128"}
                    />
                  </>
                ) : (
                  <>
                    <MetabaseFrame
                      question={"55daac61-2e9a-441c-b923-56eadb9347ab"}
                      detail={true}
                      detailDestination={{
                        value: "/totalEarningDetails",
                        label: t("totalEarningDetails"),
                      }}
                    />
                    <MetabaseFrame
                      question={"e1b9d9b7-b221-49d3-90b8-5598edbc7736"}
                    />
                  </>
                )}
              </>
            )}
            {renderChildComponentIfParameterMatches(
              "/totalEarningDetails",
              <>
                {graphsWeekly ? (
                  <>
                    <MetabaseFrame
                      question={"4663b248-81ee-4627-a4f8-a548f76636a6"}
                    />
                    <MetabaseFrame
                      question={"ca48bbd1-0f57-4374-90da-e8556a67b092"}
                    />
                  </>
                ) : (
                  <>
                    <MetabaseFrame
                      question={"55daac61-2e9a-441c-b923-56eadb9347ab"}
                    />
                    <MetabaseFrame
                      question={"ca48bbd1-0f57-4374-90da-e8556a67b092"}
                    />
                  </>
                )}
              </>
            )}
            {renderChildComponentIfParameterMatches(
              "/challenge",
              <>
                {graphsWeekly ? (
                  <>
                    <MetabaseFrame
                      question={"86e59fcf-5a21-4946-89e2-8ca2dd3ec497"}
                    />
                    <MetabaseFrame
                      question={"e763ebd8-8c8c-4f2d-88cf-20507435a96c"}
                    />
                  </>
                ) : (
                  <>
                    <MetabaseFrame
                      question={"b2db17e7-3e12-4295-8960-6d1d4b6766df"}
                    />
                    <MetabaseFrame
                      question={"e763ebd8-8c8c-4f2d-88cf-20507435a96c"}
                    />
                  </>
                )}
              </>
            )}
            {renderChildComponentIfParameterMatches(
              "/challenge/content",
              <>
                {graphsWeekly ? (
                  <>
                    <div className="analysis-graph-container-row">
                      <div className="analysis-graph-flex-3">
                        <MetabaseFrame
                          question={"3a2b8c20-a691-4b0f-bbae-1a6042450a92"}
                        />
                      </div>
                      <div className="analysis-graph-flex-1">
                        <MetabaseFrame
                          question={"f3aeccfc-b0e4-4ffd-98f1-a5981eb1bb08"}
                        />{" "}
                        {/* social */}
                      </div>
                    </div>
                    <div className="analysis-graph-container-row">
                      <div className="analysis-graph-flex-1">
                        <MetabaseFrame
                          question={"3b5674e0-9a38-45dd-ac0a-a1bfddb81a5b"}
                        />{" "}
                        {/* Tag */}
                      </div>
                      <div className="analysis-graph-flex-1">
                        <MetabaseFrame
                          question={"94d5788c-9e81-41d0-b52d-75e3e54b0e5b"}
                        />{" "}
                        {/* Hashtag */}
                      </div>
                    </div>
                    <MetabaseFrame
                      question={"e763ebd8-8c8c-4f2d-88cf-20507435a96c"}
                    />
                  </>
                ) : (
                  <>
                    <div className="analysis-graph-container-row">
                      <div className="analysis-graph-flex-3">
                        <MetabaseFrame
                          question={"a8c259c0-0b47-4987-829d-8b630e60f064"}
                        />
                      </div>
                      <div className="analysis-graph-flex-1">
                        <MetabaseFrame
                          question={"f3aeccfc-b0e4-4ffd-98f1-a5981eb1bb08"}
                        />{" "}
                        {/* social */}
                      </div>
                    </div>
                    <div className="analysis-graph-container-row">
                      <div className="analysis-graph-flex-1">
                        <MetabaseFrame
                          question={"3b5674e0-9a38-45dd-ac0a-a1bfddb81a5b"}
                        />{" "}
                        {/* Tag */}
                      </div>
                      <div className="analysis-graph-flex-1">
                        <MetabaseFrame
                          question={"94d5788c-9e81-41d0-b52d-75e3e54b0e5b"}
                        />{" "}
                        {/* Hashtag */}
                      </div>
                    </div>
                    <MetabaseFrame
                      question={"e763ebd8-8c8c-4f2d-88cf-20507435a96c"}
                    />
                  </>
                )}
              </>
            )}

            {/* If none of the above conditions are met, render "Hello World" */}
            {renderChildComponentIfParameterMatches(
              null,
              <>
                {graphsWeekly ? (
                  <>
                    <MetabaseFrame
                      question={"4663b248-81ee-4627-a4f8-a548f76636a6"}
                      detail={true}
                      detailDestination={{
                        value: "/totalEarningDetails",
                        label: t("totalEarningDetails"),
                      }}
                    />
                    <MetabaseFrame
                      question={"23b99877-dc81-4f8e-b582-fdf46abc2128"}
                    />
                  </>
                ) : (
                  <>
                    <MetabaseFrame
                      question={"55daac61-2e9a-441c-b923-56eadb9347ab"}
                      detail={true}
                      detailDestination={{
                        value: "/totalEarningDetails",
                        label: t("totalEarningDetails"),
                      }}
                    />
                    <MetabaseFrame
                      question={"e1b9d9b7-b221-49d3-90b8-5598edbc7736"}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
};

export default Analysis;
