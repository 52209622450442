import React from "react";

const CustomBackgroundButton = ({backgroundColor, onClick, title, disabled}) => {
  return(
    <div className="custom-background-button button" style={{backgroundColor: backgroundColor}} onClick={onClick}>
        {title}
    </div>
  );
};

export default CustomBackgroundButton;
