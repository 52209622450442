import React, { useState, useEffect } from "react";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import InputContainer from "../../../../utils/inputContainer";
import CustomSelect from "../../../../collapsible/customSelect";
import "../../../../../assets/css/inbox.css";
import { useNavigate } from "react-router-dom";
import SingleTextInput from "../../../../utils/singleTextInput";
import DynamicInputList from "../../../../utils/dynamicInputList";
import DateRangeInput from "../../../../utils/dateRangeInput";
import SingleNumberInput from "../../../../utils/singleNumberInput";
import PhoneViewNotification from "../../../../phone/phoneViewNotification";
import Pagination from "../../../../tables/Pagination";
import ItemsPerPageButton from "../../../../button/itemsPerPageButton";
import { toast } from "react-toastify";
import CallAPI from "../../../../api/callApi";
import { apiUrl } from "../../../../../config";
import SingleTextTextarea from "../../../../utils/singleTextTextarea";
import Loader from "../../../../popup/loader";
import DynamicTableWithInnerElements from "../../../../tables/DynamicTableWithInnerElements";
import DynamicTableWithCustomInners from "../../../../tables/DynamicTableWithCustomInners";
import DatePickerButton from "../../../../button/datePickerButton";
import { set } from "date-fns";

const PushNotificationList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("inbox");
  const [loaderState, setLoaderState] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
  };

  const [filterStartDate, setFilterStartDate] = useState(
    formatDate(oneMonthAgo)
  );
  const [filterEndDate, setFilterEndDate] = useState(formatDate(today));

  const locationArray = [
    [t("inbox"), "/brand/inbox"],
    [t("pushNotification"), "/brand/inbox/push"],
  ];

  useEffect(() => {
    fetchNotifications();
  }, [filterStartDate, filterEndDate, itemPerPage, page]); // Add dependencies to re-fetch data when dates change

  const fetchNotifications = async () => {
    setLoaderState(true);
    const url = `${apiUrl}/api/v1/brand-admin/brand/notification?from=${encodeURIComponent(
      filterStartDate
    )}&to=${encodeURIComponent(
      filterEndDate
    )}&page=${page}&size=${itemPerPage}`;
    const response = await CallAPI({
      method: "GET",
      endPoint: url,
      t: t,
      errorMessage: t("anErrorAcurredWhenFetchingNotifications"),
    });
    console.log(response);
    setTotalPage(response.data.pageMetadata.totalPage);
    // Format 'updated' and 'created'  fields
    const formattedData = response.data.responseData.map((notification) => ({
      ...notification,
      updated: formatDateString(notification.updated),
      created:
        notification.status === "SENT" &&
        formatDateString(notification.created),
      status: t(notification.status),
      segmentList: notification.segmentList.join(", "),
      userCount: notification.userList.length,
      successRate: notification.successRate.toFixed(0),
      preview: (
        <div
          className="notification-list-preview-button"
          onClick={() =>
            navigate(`/brand/inbox/push/create`, { state: { notification } })
          }
        >
          {t("preview")}
        </div>
      ),
    }));

    setData(formattedData);
    setLoaderState(false);
  };

  function formatDateString(dateString) {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 3);
    const formattedDate = date.toLocaleString("tr-TR", {
      hour12: false,
      timeZone: "Europe/Istanbul", // Türkiye saati için zaman dilimini ayarlayın
    });
    return formattedDate;
  }

  const columns = [
    { title: t("sentDate"), field: "created", className: "column-name" },
    { title: t("ID"), field: "id", className: "column-id" },

    { title: t("segments"), field: "segmentList", className: "column-id" },
    { title: t("userCount"), field: "userCount", className: "column-id" },
    { title: t("successRate"), field: "successRate", className: "column-date" },
    { title: t("status"), field: "status", className: "column-status" },
    { title: t("preview"), field: "preview", className: "column-button" },
  ];

  const handleInputChange = (data, setter) => {
    setter(data);
  };

  const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {
    if (selectedEndDate) {
      const originalStartDate = new Date(selectedStartDate);
      const originalEndDate = new Date(selectedEndDate);

      originalStartDate.setHours(0, 0, 0);
      originalEndDate.setHours(23, 59, 59);

      const formattedStartDate = formatDate(originalStartDate);
      const formattedEndDate = formatDate(originalEndDate);

      setFilterStartDate(formattedStartDate);
      setFilterEndDate(formattedEndDate);
    } else {
      setFilterStartDate(formatDate(oneMonthAgo));
      setFilterEndDate(formatDate(today));
    }
  };

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} />
      <DynamicTableWithCustomInners
        columns={columns}
        data={data}
        itemTotalPage={totalPage}
        itemPerPageTitle={t("notificationPerPage")}
        setItemPerPage={setItemPerPage}
        setItemPage={setPage}
        handleInputChange={handleInputChange}
        customButtonText={t("createPushNotification")}
        itemPage={page}
      >
        <DatePickerButton onDateChange={handleFilterDatesChanged} />
      </DynamicTableWithCustomInners>
    </div>
  );
};

export default PushNotificationList;
