import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../../../../assets/css/BrandAppRequest.css";
import axios from "axios";
import arrow from "../../../../../assets/media/arrow.png";
import downArrow from "../../../../../assets/media/down-arrow.png";
import defaultSotyLogo from "../../../../../assets/media/sotylogo.svg";
import validator from "validator";
import { showErrorToast } from "../../../../toast/toast.js";
import tick from "../../../../../assets/media/tick.svg";
import Collapsible from "../../../../collapsible/collapsible";
import instagramIcon from "../../../../../assets/media/instagram.svg";
import xIcon from "../../../../../assets/media/x-twitter.svg";
import { toast } from "react-toastify";
import youtubeIcon from "../../../../../assets/media/youtube.svg";
import facebookIcon from "../../../../../assets/media/facebook.svg";
import tiktokIcon from "../../../../../assets/media/tiktok.svg";
import contractTexts from "../../../brand_admin/contractTexts";
import checkImage from "../../../../../assets/media/check.svg";
import "../../../../../assets/css/BrandAdminProfile.css";
import calendar from "../../../../../assets/media/calendar.svg";
import { apiUrl } from "../../../../../config.js";
import { useTranslation } from "react-i18next";
import Loader from "../../../../popup/loader.js";
import failedIcon from "../../../../../assets/media/failed.png";
import InputContainer from "../../../../utils/inputContainer.js";

const BrandAppRequest = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [created, setCreated] = useState("");
  const [id, setId] = useState(0);
  const [status, setStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [brandName, setBrandName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taxOffice, setTaxOffice] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [giro, setGiro] = useState("");
  const [storeCount, setStoreCount] = useState("");
  const [checked, setIsChecked] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [formattedCreated, setFormattedCreated] = useState("");
  const [isForm1Filled, setIsForm1Filled] = useState(false);
  const [isForm2Filled, setIsForm2Filled] = useState(false);
  const [isForm3Filled, setIsForm3Filled] = useState(false);
  const [isForm4Filled, setIsForm4Filled] = useState(false);
  const { t, i18n } = useTranslation("brandApplicationList");
  const { ID } = useParams();
  const [loaderState, setLoaderState] = useState(false);
  const [brandCategories, setBrandCategories] = useState([]);

  useEffect(() => {
    const allFields1Filled =
      firstName !== "" && lastName !== "" && phoneNumber !== "" && email !== "";
    setIsForm1Filled(allFields1Filled);
    const allFields2Filled = url !== "" && brandName !== "";
    setIsForm2Filled(allFields2Filled);
    const allFields3Filled =
      companyName !== "" && taxOffice !== "" && taxNumber !== "";
    setIsForm3Filled(allFields3Filled);
    const allFileds4Filled = giro !== "" && storeCount !== "";
    setIsForm4Filled(allFileds4Filled);
  });
  function formatGiro(giro) {
    const rangePart = giro.replace("RANGE_", "");

    const formattedRange = rangePart.replace("_", " - ");

    const formattedGiro = formattedRange + " TL";

    return formattedGiro;
  }



  const storeCountData = [];
  for (let i = 0; i <= 2500; i += 500) {
    const rangeStart = i;
    const rangeEnd = i + 500;
    const storeCountKey = `RANGE_${rangeStart}_${rangeEnd}`;
    const rangeLabel = `${rangeStart}-${rangeEnd}`;

    storeCountData.push({ storeCount: storeCountKey, range: rangeLabel });
  }

  function convertStoreCountLabel(storeCount) {
    // Aralık değerini "_" karakterinden bölelim
    const [title, rangeStart, rangeEnd] = storeCount.split("_");

    // Aralık değerlerini birleştirip istediğimiz formatta döndürelim
    return `${rangeStart}-${rangeEnd}`;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based, so add 1
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };
  const fetchData = async () => {
    setLoaderState(true);
    const token = getCookie("access_token");

    if (!token) {
      console.error("Access token is missing.");
      setLoaderState(false);

      navigate("/sign-in-realm");
      return;
    }

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const endPoint = `${apiUrl}/api/v1/admin/application/${ID}`;

    try {
      const response = await fetch(endPoint, options);

      if (response.status !== 200) {
        console.error("Error fetching data. Status: ", response.status);
        setLoaderState(false);

        navigate("/sign-in-realm");
        return;
      }

      const responseData = await response.json();
      setBrandCategories(responseData.responseData.brandCategories);
      // Set formatted created date
      const formattedCreatedDate = formatDate(
        responseData.responseData.created
      );
      setFormattedCreated(formattedCreatedDate);
      const { requestId } = responseData.metadata;

      setRequestId(requestId);

      const {
        id,
        status,
        firstName,
        lastName,
        phoneNumber,
        email,
        url,
        brandName,
        companyName,
        taxOffice,
        taxNumber,
        giro,
        storeCount,
        created,
      } = responseData.responseData;

      setId(id);
      setStatus(status);
      setFirstName(firstName || "");
      setLastName(lastName || "");
      setPhoneNumber(phoneNumber || "");
      setEmail(email || "");
      setUrl(url || "");
      setBrandName(brandName || "");
      setCompanyName(companyName || "");
      setTaxOffice(taxOffice || "");
      setTaxNumber(taxNumber || "");
      setGiro(giro || "");
      setStoreCount(storeCount || "");
      setCreated(created || "");
      setIsChecked(checked || false);
    
      setLoaderState(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoaderState(false);

      navigate("/sign-in-realm");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const handleApproveSubmit = () => {
    setLoaderState(true);

    const endPoint = `${apiUrl}/api/v1/admin/application/${ID}/approve?autoSubscribe=${checkboxChecked}`;
    // Get the access token from the cookie
    const authToken = getCookie("access_token");

    // Define the headers separately
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include your authentication token
    };

    axios
      .put(endPoint, {}, { headers }) // Pass the headers as the third argument
      .then((response) => {
        if (response.status === 200) {
          setLoaderState(false);

          toast.success("Marka On Başvurusu Onaylandi"); // Show success toast
          navigate("/brand-application-list");
        }
        // You can add code here to handle success or redirect the user
      })
      .catch((error) => {
        setLoaderState(false);

        toast.error("Form iletilirken bir hata oluştu. Lütfen tekrar deneyin.");
        // Handle errors (e.g., show an error message to the user)
      });
  };
  const handleRejectSubmit = () => {
    const endPoint = `${apiUrl}/api/v1/admin/application/${ID}/reject`;
    // Get the access token from the cookie
    const authToken = getCookie("access_token");

    // Define the headers separately
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include your authentication token
    };

    axios
      .put(endPoint, {}, { headers }) // Pass the headers as the third argument
      .then((response) => {
        if (response.status === 200) {
          setLoaderState(false);

          toast.success("Marka Başvurusu Reddedildi"); // Show success toast
          navigate("/brand-application-list");
        }
        // You can add code here to handle success or redirect the user
      })
      .catch((error) => {
        setLoaderState(false);

        toast.error("Form iletilirken bir hata oluştu. Lütfen tekrar deneyin.");
        // Handle errors (e.g., show an error message to the user)
      });
  };

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}

      <div className="content-location-container">
        <a className="content-location-container-href" href="#">
          <img src={arrow} />
        </a>
        <a className="content-location-container-link-text" href="#">
          <div className="older">{t("brandApplications")}</div>
        </a>
        <img src={downArrow} className="backArrow" alt="arrow" />
        <Link
          to="../brand-application-list"
          className="content-location-container-link-text"
        >
          <div className="older">{t("brandApplicationsList")}</div>
        </Link>
        <img src={downArrow} className="backArrow" alt="arrow" />
        <div className="current">{t("newRequest")}</div>
      </div>
      <hr />

      <div className="request-content-container">
        <div className="request-content-column-left">
          <div className="request-form">
            <div className="request-form-area">
              <div className="request-form-area-title">
                {t("personalInformation")}
              </div>
              <hr className="request-hr" />
              <div className="request-form-input-container">
                <div className="request-form-input-row">
                  <div className="request-column">
                    <div className="request-title">{t("name")}:</div>
                    <div className="request-input-area">
                      <input className="request-input" value={firstName} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>

                  <div className="request-column">
                    <div className="request-title">{t("surname")}:</div>
                    <div className="request-input-area">
                      <input className="request-input" value={lastName} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="request-form-input-row">
                  <div className="request-column">
                    <div className="request-title">{t("phoneNumber")}:</div>
                    <div className="request-input-area">
                      <input className="request-input" value={phoneNumber} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>

                  <div className="request-column">
                    <div className="request-title">{t("email")}:</div>
                    <div className="request-input-area">
                      <input className="request-input" value={email} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="request-form-area">
              <div className="request-form-area-title">{t("brandInfo")}</div>
              <hr className="request-hr" />
              <div className="request-form-input-container">
                <div className="request-form-input-row">
                  <div className="request-column">
                    <div className="request-title">{t("domainName")}:</div>
                    <div className="request-input-area">
                      <input className="request-input" value={url} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>

                  <div className="request-column">
                    <div className="request-title">{t("brandName")}:</div>
                    <div className="request-input-area">
                      <input className="request-input" value={brandName} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="request-form-area">
              <div className="request-form-area-title">{t("companyInfo")}</div>
              <hr className="request-hr" />
              <div className="request-form-input-container">
                <div className="request-form-input-row">
                  <div className="request-column">
                    <div className="request-title">{t("companyName")}:</div>
                    <div className="request-input-area">
                      <input className="request-input" value={companyName} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>

                  <div className="request-column">
                    <div className="request-title">{t("taxOffice")}:</div>
                    <div className="request-input-area">
                      <input className="request-input" value={taxOffice} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>

                <div className="request-form-input-row">
                  <div className="request-column">
                    <div div className="request-title">
                      {t("taxNumber")}:
                    </div>
                    <div className="request-input-area">
                      <input className="request-input" value={taxNumber} />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="request-form-area">
              <div className="request-form-area-title">
                {t("additionalCompanyInfo")}
              </div>
              <hr className="request-hr" />
              <div className="request-form-input-container">
                <div className="request-form-input-row">
                  <div className="request-column">
                    <div className="request-title">{t("revenue")}:</div>
                    <div className="request-input-area">
                      <input
                        className="request-input"
                        value={formatGiro(giro)}
                      />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>

                  <div className="request-column">
                    <div className="request-title">{t("storeCount")}:</div>
                    <div className="request-input-area">
                      <input
                        className="request-input"
                        value={convertStoreCountLabel(storeCount)}
                      />
                      <img
                        className="request-input-icon"
                        src={tick}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="request-form-area">
              <div className="request-form-area-title">
                {t("brandCategories")}
              </div>
              <hr className="request-hr" />
              <div className="request-form-input-container">
                <div className="request-form-input-row">
                  {brandCategories.map((category) => (
                    <div className="request-column">
                      <div className="request-title">{t("category")}:</div>
                      <div className="request-input-area">
                        <input
                          className="request-input"
                          value={
                            navigator.language.startsWith("en")
                              ? category.categoryEn
                              : category.category
                          }
                        />
                        <img
                          className="request-input-icon"
                          src={tick}
                          alt="img"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <InputContainer
              title={t("autoSubscribe")}
              isMandatory={true}
              titleDescription={t("autoSubscribeDesc")}
            >
              <div className="qr-checkbox-container">
                <div
                  className={checkboxChecked ? "qr-checkbox checked" : "qr-checkbox"}
                  onClick={() => setCheckboxChecked(!checkboxChecked)}
                >
                  {checkboxChecked && <img src={tick} alt="Tick" />}
                </div>
                <div>{t("autoSubscribeTitle")}</div>
              </div>
            </InputContainer>

            <div className="button-container">
              <button
                className="request-button-accept"
                onClick={handleApproveSubmit}
              >
                {t("approveFirstApplication")}
              </button>
              <button
                className="request-button-reject"
                onClick={handleRejectSubmit}
              >
                {t("reject")}
              </button>
            </div>
          </div>
        </div>

        <div className="request-content-column-right">
          <div className="request-content-column-right-id">
            {t("requestId")}: {requestId}
          </div>
          <div className="request-content-column-right-wrapper">
            <div className="request-content-column-right-brand-application-time-container">
              <img
                className="request-content-column-right-brand-application-time-icon"
                src={calendar}
                alt="ico"
              />
              <div className="request-content-column-right-brand-application-time-title">
                {t("applicationDate")}:
              </div>
              <div className="request-content-column-right-brand-application-time">
                {formatDate(created)}
              </div>
            </div>

            <Collapsible
              title={t("personalInformation")}
              checkImage={checkImage}
              showCheckIcon={isForm1Filled}
            >
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">{t("name")}:</div>
                <div id="ad2" className="collapsible-content-data">
                  {firstName}
                </div>
              </div>
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">{t("surname")}:</div>
                <div id="soyad2" className="collapsible-content-data">
                  {lastName}
                </div>
              </div>
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">
                  {t("phoneNumber")}:
                </div>
                <div id="tel2" className="collapsible-content-data">
                  {phoneNumber}
                </div>
              </div>
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">{t("email")}:</div>
                <div id="mail2" className="collapsible-content-data">
                  {email}
                </div>
              </div>
            </Collapsible>
            <Collapsible
              title={t("brandInfo")}
              checkImage={checkImage}
              showCheckIcon={isForm2Filled}
            >
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">
                  {t("domainName")}
                </div>
                <div id="alanAdi2" className="collapsible-content-data">
                  {url}
                </div>
              </div>
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">
                  {t("brandName")}:
                </div>
                <div id="markaAdi2" className="collapsible-content-data">
                  {brandName}
                </div>
              </div>
            </Collapsible>
            <Collapsible
              title={t("companyInfo")}
              checkImage={checkImage}
              showCheckIcon={isForm3Filled}
            >
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">
                  {t("companyName")}:
                </div>
                <div id="sirketAdi2" className="collapsible-content-data">
                  {companyName}
                </div>
              </div>
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">
                  {t("taxOffice")}:
                </div>
                <div id="vergiDairesi2" className="collapsible-content-data">
                  {taxOffice}
                </div>
              </div>
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">
                  {t("taxNumber")}:
                </div>
                <div id="vergiNumarasi2" className="collapsible-content-data">
                  {taxNumber}
                </div>
              </div>
            </Collapsible>
            <Collapsible
              title={t("additionalCompanyInfo")}
              checkImage={checkImage}
              showCheckIcon={isForm4Filled}
            >
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">{t("revenue")}:</div>
                <div id="eTicaretCiro2" className="collapsible-content-data">
                  {formatGiro(giro)}
                </div>
              </div>
              <div className="collapsible-content-row">
                <div className="collapsible-content-title">
                  {t("storeCount")}
                </div>
                <div id="magazaSayisi2" className="collapsible-content-data">
                  {convertStoreCountLabel(storeCount)}
                </div>
              </div>
            </Collapsible>
            <Collapsible
              title={t("brandCategories")}
              checkImage={brandCategories.length > 0 ? checkImage : failedIcon}
              showCheckIcon={isForm4Filled}
            >
              {brandCategories.map((category) => (
                <div className="collapsible-content-row">
                  <div className="collapsible-content-title">
                    {t("category")}:
                  </div>
                  <div id="eTicaretCiro2" className="collapsible-content-data">
                    {navigator.language.startsWith("en")
                      ? category.categoryEn
                      : category.category}
                  </div>
                </div>
              ))}
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandAppRequest;
