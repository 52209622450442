import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';



const PhoneViewNotification = ({ }) => {
    const { t, i18n } = useTranslation("challengeCreation");




    return (
        <div className="phone-view notification-phone-view">
            <div className='phone-view-interaction-container'>

                <div className='notification-row'>
                    Push ID:
                </div>

            </div>
        </div>
    );
}

export default PhoneViewNotification;
