import React from "react";
import downArrow from "../../assets/media/down-arrow.png";

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptionList: false,
      selectedOption: this.props.defaultText,
    };
  }

  handleListDisplay = () => {
    this.setState((prevState) => ({
      showOptionList: !prevState.showOptionList,
    }));
  };

  handleOptionClick = (option, index) => {
    this.setState({
      selectedOption: option,
      showOptionList: false,
    });
  
    // Call the onChange callback with the selected option index
    this.props.onChange(index);
  };

  render() {
    const { optionsList } = this.props;
    const { showOptionList, selectedOption } = this.state;

    const iconStyle = {
      transform: showOptionList ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.3s ease",
    };

    return (
      <div className="custom-select-container">
        <div
          className={`selected-text ${showOptionList ? "active" : ""}`}
          onClick={this.handleListDisplay}
        >
          {selectedOption}
          <img className="arrow" style={iconStyle} src={downArrow} alt="" />
        </div>
        {showOptionList && (
          <ul className="select-options">
            {optionsList.map((option, index) => (
              <li
                className="custom-select-option"
                key={option}
                onClick={() => this.handleOptionClick(option, index)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default CustomSelect;
