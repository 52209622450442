import React, { useState, useEffect } from 'react';

const SocialMediaButtonContainer = ({ socialMediaArray, InstagramIcon, onSelect }) => {
    // Function to get initial selected social media from localStorage or default to "INSTAGRAM"
    const getInitialSelectedSocialMedia = () => {
        const storedSocialMedia = localStorage.getItem('selectedSocialMedia');
        return storedSocialMedia ? JSON.parse(storedSocialMedia).name : "INSTAGRAM";
    };

    const [selected, setSelected] = useState(getInitialSelectedSocialMedia());

    // Function to handle social media selection
    const handleSelect = (socialMedia) => {
        setSelected(socialMedia.name);
        onSelect(socialMedia);
        localStorage.setItem('selectedSocialMedia', JSON.stringify(socialMedia));
    };

    return (
        <div className='social-media-radio-button-container'>
            {socialMediaArray.map((socialMedia, index) => (
                <div
                    key={index}
                    className={`social-media-radio-button-wrapper ${selected === socialMedia.name ? 'social-media-radio-button-wrapper-active' : ''}`}
                    onClick={() => handleSelect(socialMedia)}
                >
                    <div className='social-media-radio-button'>
                        <img className='social-media-radio-button-img' src={socialMedia.icon} alt='icon' />
                        <div className='social-media-radio-button-text'>{socialMedia.name}</div>
                    </div>
                    <div className='social-media-radio-button-bottom-indicator' />
                </div>
            ))}
        </div>
    );
};

export default SocialMediaButtonContainer;
