import React, { useState, useEffect, useRef } from "react";
import threeDots from "../../assets/media/threeDots.svg";
import placeholderImg from "../../assets/media/placeholder.png";
import paperIcon from "../../assets/media/paper.svg";
import instagramIcon from "../../assets/media/instagram.svg";
import facebookIcon from "../../assets/media/facebook.svg";
import twitterIcon from "../../assets/media/x-twitter.svg";
import youtubeIcon from "../../assets/media/youtube.svg";
import tiktokIcon from "../../assets/media/tiktok.svg";
import { imagesUrl } from "../../config";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ChallengeCardDialog from "../dialogs/challengeCardDialog";
const ChallengeCard = ({ challengeType, challengeData }) => {
  const { id, title, coinPrize, startDate, endDate, successRate } =
    challengeData;

  const { t, i18n } = useTranslation("challengeDashboard");

  const [isDialogVisible, setDialogVisibility] = useState(false);
  const dialogRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setDialogVisibility(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dialogRef]);

  const toggleDialogVisibility = () => {
    setDialogVisibility(!isDialogVisible);
  };

  function formatDateStringLocal(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}.${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}.${date.getFullYear()}`;
      return formattedDate;
    } else {
      return "";
    }
  }

  const getBackgroundColor = (successRate) => {
    if (isNaN(successRate)) {
      return "#EF4838"; // NaN durumu
    } else if (successRate >= 0 && successRate < 25) {
      return "#EF4838";
    } else if (successRate >= 25 && successRate < 50) {
      return "#F99C33";
    } else if (successRate >= 50 && successRate < 75) {
      return "#FED233";
    } else if (successRate >= 75 && successRate <= 100) {
      return "#5ABB6A";
    } else {
      return "#fff"; // Varsayılan durum
    }
  };

  const getIcon = (socialProvider) => {
    switch (socialProvider) {
      case "INSTAGRAM":
        return instagramIcon;
      case "TWITTER":
        return twitterIcon;
      case "YOUTUBE":
        return youtubeIcon;
      case "FACEBOOK":
        return facebookIcon;
      case "TIKTOK":
        return tiktokIcon;
      default:
        return paperIcon;
    }
  };

  return (
    <div className="challenge-card-container" ref={dialogRef}>
      <div className="challenge-card-header">
        <div className="challenge-card-left">
          <span>{t("id")}:</span>
          {challengeData.challenge.id}
        </div>
        <div className="challenge-card-right">
          <img
            className="challenge-card-right-img "
            src={threeDots}
            alt="..."
            onClick={() => {
              toggleDialogVisibility();
            }}
          />

          {isDialogVisible && (
            <ChallengeCardDialog challenge={challengeData.challenge} />
          )}
        </div>
      </div>
      <img
        className="challenge-card-img"
        src={
          challengeData.challenge.logo
            ? imagesUrl + "/" + challengeData.challenge.logo
            : placeholderImg
        }
        alt={title}
      />
      <div className="challenge-details-container">
        <div className="challenge-details-left">
          <div className="challenge-details-title">
            {challengeData.challenge.name}
          </div>
          <div className="challenge-details-Prize">
            {t("coinPrize")}:{" "}
            <span>
              {challengeData.challenge.prize +
                " " +
                t("equalsTo") +
                " " +
                challengeData.challenge.prize / 10 +
                t("TL")}
            </span>
          </div>
          <div className="challenge-details-start-date display-none">
            {t("segment")}:{" "}
            <span>
              {formatDateStringLocal(challengeData.challenge.segment)}
            </span>
          </div>
          <div className="challenge-details-start-date">
            {t("startDate")}:{" "}
            <span>
              {formatDateStringLocal(challengeData.challenge.startDate)}
            </span>
          </div>
          <div className="challenge-details-end-date">
            {t("endDate")}:{" "}
            <span>
              {formatDateStringLocal(challengeData.challenge.endDate)}
            </span>
          </div>
          <div className="challenge-details-success-rate">
            {t("successRate")}:
            <span
              style={{
                backgroundColor: getBackgroundColor(
                  challengeData.successRate * 100
                ),
              }}
            >
              %
              {challengeData.successRate
                ? Math.floor(parseFloat(challengeData.successRate) * 100)
                : "0"}
            </span>
          </div>
        </div>
        <img
          className="challenge-details-img"
          src={getIcon(challengeData.challenge.socialProvider)}
          alt="icon"
        />
      </div>
    </div>
  );
};

export default ChallengeCard;
