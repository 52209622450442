// DynamicTable.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../assets/css/AnalysisTable.css";
import downArrow from '../../assets/media/down-arrow.png';
import sortIcon from '../../assets/media/Icon awesome-sort-analysis.svg';

const AnalysisTable = ({ columns, data, tableName, isRowClickable, onRowClick, onSortClick, sortConfig }) => {
  const [sortCounts, setSortCounts] = useState({});

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    // Month is 0-indexed, so subtract 1 from the month
    return new Date(year, month - 1, day);
  };



  return (
    <div className="analysis-table-container">
      <table className="analysis-table">
        <thead>
          <tr className="analysis-table-row">
            {columns.map((column) => (
              <th key={column.field} className={`analysis-table-th ${column.className}`}>
                <div className="analysis-table-th-wrapper">
                  {column.title}
                  {column.sort && (
                    <div className="sort-buttons">
                    <button onClick={() => onSortClick(column.asc)}>
                      <img className="dyanmic-sort-asc" src={downArrow} alt="Sort ascending" />
                    </button>
                    <button onClick={() => onSortClick(column.desc)}>
                      <img className="dyanmic-sort-desc" src={downArrow} alt="Sort descending" />
                    </button>
                  </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index} className={`analysis-table-row ${typeof isRowClickable === "function" && isRowClickable(item) ? "clickable-row" : ""}`}
              onClick={() => {onRowClick(item)}}
            >
              {columns.map((column, index) => (
                <td key={index} className={`analysis-table-td ${column.className}`}>
                  {column.render ? column.render(item) : item[column.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

AnalysisTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
  isRowClickable: PropTypes.func,
  onRowClick: PropTypes.func,
  onSortClick: PropTypes.func, // Assuming you pass a function to handle sorting
  sortConfig: PropTypes.object, // Assuming you pass a sortConfig object
};

export default AnalysisTable;
