import React, { useState, useEffect, useRef } from "react";
import sortIcon from '../../assets/media/Icon awesome-sort.svg';
import '../../assets/css/SortFilterSearch.css'
import SortByDialog from "../dialogs/sortByDialog";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
const SortByButton = ({items, onItemSelect}) => {
    const { t, i18n } = useTranslation("challengeDashboard");
    
  

    const [isDialogVisible, setDialogVisibility] = useState(false);
    const [clickedItem, setClickedItem] = useState(items[0]); // Set a default text
    

    const dialogRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Kullanıcı DateRangePicker dışına tıklarsa, picker'ı gizle
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setDialogVisibility(false);
            }
        };

        // Event listener'ı ekleyin
        document.addEventListener("mousedown", handleClickOutside);

        // Component unmount edildiğinde event listener'ı kaldırın
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dialogRef]);

    const toggleDialogVisibility = () => {
        setDialogVisibility(!isDialogVisible);
    };

    const handleItemClick = (itemId, itemText, itemString) => {
        setClickedItem({ id: itemId, text: itemText });
        onItemSelect(itemId, itemText, itemString);
    };

    


    return (
        <div className="sort-by-button-container" ref={dialogRef}>
            <div className="sort-by-button-clickable" onClick={toggleDialogVisibility}>
                <img className="sort-by-button-img" src={sortIcon} alt="sort" />
                <div className="sort-by-button-title">
                    {t("sortBy")} : {clickedItem && clickedItem.text}
                </div>
            </div>

            {isDialogVisible && <SortByDialog items={items} onItemClick={handleItemClick} selectedItem={clickedItem} />}
        </div>
    )
}
export default SortByButton;