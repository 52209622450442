import React, { useState, useEffect, useRef } from "react";
import FilterIcon from "../../assets/media/filter.svg";
import { useTranslation } from "react-i18next";
import FilterDialogCustom from "../dialogs/filterDialogCustom";

const FilterButtonV2 = ({ filterItems, dialogTitle, onFilteredItemsChange, checkedItems }) => {
  const [isDialogVisible, setDialogVisibility] = useState(false);
  const dialogRef = useRef(null);
  const buttonRef = useRef(null); // Ref for the filter button
  const { t } = useTranslation("challengeDashboard");

  const toggleDialogVisibility = () => {
    setDialogVisibility((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside the dialog and button
    if (
      dialogRef.current &&
      !dialogRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDialogVisibility(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="filter-button-container" ref={buttonRef}>
      <div className="filter-button-clickable" onClick={toggleDialogVisibility}>
        <img className="filter-button-img" src={FilterIcon} alt="filter" />
        <div className="filter-button-title">{t("filter")}</div>
      </div>
      {isDialogVisible && (
        <FilterDialogCustom
          ref={dialogRef}
          filterItems={filterItems}
          title={dialogTitle}
          onFilteredItemsChange={onFilteredItemsChange}
          checkedItems={checkedItems}
        />
      )}
    </div>
  );
};

export default FilterButtonV2;
