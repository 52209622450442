import React, { useState, useEffect, useRef } from "react";
import '../../assets/css/SortFilterSearch.css';
import downArrow from '../../assets/media/down-arrow.png';
import { useTranslation } from "react-i18next";
import ItemsPerPageDialog from "../dialogs/itemsPerPageDialog";

const ItemsPerPageButton = ({ onSelectItem, title }) => {
    const [isDialogVisible, setDialogVisibility] = useState(false);
    const dialogRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const { t, i18n } = useTranslation("challengeDashboard");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setDialogVisibility(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dialogRef]);

    const toggleDialogVisibility = () => {
        setDialogVisibility(!isDialogVisible);
    };

    const handleSelectItem = (item) => {
        setSelectedItem(item);
        setDialogVisibility(false);
        onSelectItem(item); // Seçilen öğeyi parent bileşene iletmek için callback'i çağır
    };

    return (
        <div className="items-per-page-button-container" ref={dialogRef}>
            
            <div className={`items-per-page-button-wrapper ${isDialogVisible ? 'items-per-page-dialog-parent' : ''}`}>
                <div className="items-per-page-button" onClick={() => {toggleDialogVisibility()}}>
                {title ? title : t("challengePerPage")}: {selectedItem || 20}
                    <img className="items-per-page-button-img" src={downArrow} alt=">" />
                </div>
                {isDialogVisible && <ItemsPerPageDialog onSelectItem={handleSelectItem} />}
            </div>
        </div>
    );
}

export default ItemsPerPageButton;
