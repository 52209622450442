import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import closeIcon from "../../../../../../assets/media/close.png";
import { apiUrl } from "../../../../../../config.js";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../popup/loader.js";
import CallAPI from "../../../../../api/callApi.js";
import AutoScrollToTop from "../../../../../utils/autoScrollToTop.js";

function BrandRolePopup(props) {
  const navigate = useNavigate();
  const { roleData: initialRoleData = { roleCategories: [] } } = props;
  const { t, i18n } = useTranslation("brandAdminRoleList");
  const [groupName, setGroupName] = useState(initialRoleData.name || "");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [roleData, setRoleData] = useState(initialRoleData);
  const [localRoleData, setLocalRoleData] = useState(props.roleData);
  const [loaderState, setLoaderState] = useState(false);

  AutoScrollToTop();

  useEffect(() => {
    setLocalRoleData(props.roleData);
  }, [props.roleData]);

  useEffect(() => {
    setRoleData(initialRoleData);
  }, [initialRoleData]);

  const originalRoleData = useMemo(() => initialRoleData, [initialRoleData]);

  function isValid(name) {
    try {
      return name.trim() !== "";
    } catch (err) {
      return false;
    }
  }

  const resetToOriginal = () => {
    setGroupName(originalRoleData.name || "");
    setSelectAllChecked(false); // Reset selectAllChecked
    setRoleData(originalRoleData);
    props.setTrigger(false);
  };

  const generateRoleRows = () => {
    if (!roleData.roleCategories) return null;

    return roleData.roleCategories.map((category, categoryIndex) => (
      <div key={categoryIndex} className="popup-role-row">
        <div className="role-title">{category.name}</div>
        <div className="role-inputs-container">
          {category.roles.map((role, roleIndex) => (
            <div key={roleIndex} className="role-input-wrapper">
              <input
                className="role-select"
                type="checkbox"
                key={`${categoryIndex}-${roleIndex}-${selectAllChecked}`}
                defaultChecked={selectAllChecked || role.selected}
                onChange={() =>
                  handleRoleCheckboxChange(categoryIndex, roleIndex)
                }
              />
              <div className="role-select-title">{role.name}</div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);

    const updatedRoleData = { ...localRoleData }; // Use localRoleData here
    updatedRoleData.roleCategories.forEach((category) => {
      category.roles.forEach((role) => {
        role.selected = !selectAllChecked;
      });
    });

    // Update the localRoleData state
    setLocalRoleData(updatedRoleData);
  };

  const handleRoleCheckboxChange = (categoryIndex, roleIndex) => {
    const updatedRoleData = { ...roleData };
    updatedRoleData.roleCategories[categoryIndex].roles[roleIndex].selected =
      !roleData.roleCategories[categoryIndex].roles[roleIndex].selected;

    const allCategoryRoles =
      updatedRoleData.roleCategories[categoryIndex].roles;
    const categoryIsSelected = allCategoryRoles.every((role) => role.selected);
  };

  const validateRolesSelected = () => {
    return roleData.roleCategories.some((category) =>
      category.roles.some((role) => role.selected)
    );
  };

  const approve = async () => {
    if (!validateRolesSelected()) {
      toast.error(t("pleaseSelectAtLeastOneRole"));
      return;
    }

    setLoaderState(true);

    try {
      const formattedRoles = roleData.roleCategories.reduce((acc, category) => {
        category.roles.forEach((role) => {
          if (role.selected) {
            acc.push(role.key);
          }
        });
        return acc;
      }, []);

      let request;
      if (props.roleData.name) {
        request = await CallAPI({
          method: "PUT",
          endPoint: `${apiUrl}/api/v1/brand-admin/role/group/${props.roleData.name}`,
          body: {
            roles: formattedRoles,
          },
          successMessage: t("roleUpdatedSuccessfully"),
          errorMessage: t("anErrorAcurredWhileUpdatingRole"),
        });
      } else {
        if (!groupName || groupName === "" || groupName === false) {
          toast.error(t("roleNameCannotBeEmpty"));
          return;
        }
        request = await CallAPI({
          method: "POST",
          endPoint: `${apiUrl}/api/v1/brand-admin/role/group`,
          body: {
            name: groupName,
            roles: formattedRoles,
          },
          successMessage: t("roleCreatedSuccessfully"),
          errorMessage: t("anErrorAcurredWhileCreatingRole"),
        });
      }
      console.log(request);
      props.setTrigger(false);
    } catch (error) {
      toast.error(t("anErrorOccurred"));
      console.error(error);
    } finally {
      setLoaderState(false);
    }
  };

  return props.trigger ? (
    <>
      {loaderState && <Loader />}
      <div className="popup">
        <div className="popup-header">
          <div className="popup-title">{props.title}</div>
          <button
            className="popup-close-button"
            onClick={() => props.setTrigger(false)}
          >
            <img className="popup-close-button-icon" src={closeIcon} alt="X" />
          </button>
        </div>
        <hr />
        <div className="popup-role-container">
          <div className="popup-role-container-title">
            {t("roleName")}
            <span>*</span>
          </div>
          <input
            className="popup-role-name-input"
            placeholder={props.roleData?.name || "Enter a role name"}
            type="text"
            readOnly={isValid(props.roleData?.name)}
            onBlur={(e) => setGroupName(e.target.value)}
          />
          <div className="popup-role-container-title">
            {t("rolePermissions")}
          </div>
          <div className="popup-role-row">
            <div className="role-title">{t("adminAccess")}</div>
            <div className="role-inputs-container">
              <div className="role-input-wrapper">
                <input
                  className="role-select"
                  type="checkbox"
                  onChange={handleSelectAllChange}
                  checked={selectAllChecked}
                />
                <div className="role-select-title">{t("selectAll")}</div>
              </div>
            </div>
          </div>
          {generateRoleRows()}
        </div>

        <div className="popup-role-button-container">
          <button className="popup-button-cancel" onClick={resetToOriginal}>
            {t("cancel")}
          </button>
          <button className="popup-button-approve" onClick={approve}>
            {t("save")}
          </button>
        </div>
      </div>
    </>
  ) : (
    ""
  );
}

export default BrandRolePopup;
