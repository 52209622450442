import React, { useEffect, useState } from "react";

const SortByDialog = ({ items, onItemClick, selectedItem }) => {

    const [activeItemId, setActiveItemId] = useState(null);

    useEffect(() => {
        // Update activeItemId when selectedItem changes
        if (selectedItem) {
            setActiveItemId(selectedItem.id);
        }

    }, [selectedItem]);

    const handleItemClick = (itemId, itemText, itemString) => {
        setActiveItemId(itemId);
        onItemClick(itemId, itemText, itemString);
    };

    return (
        <div className="sort-by-dialog">
            {items && items.map((item) => (
                <div
                    key={item.id}
                    className={`sort-by-dialog-item ${activeItemId === item.id ? 'sort-by-dialog-item-active' : ''}`}
                    onClick={() => handleItemClick(item.id, item.text, item.string)}
                >
                    {item.text}
                </div>
            ))}
        </div>
    );
};

export default SortByDialog;
