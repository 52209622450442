import Logic from "./Logic";
import React, { useEffect, useState } from "react";
import SegmentCondition from "./SegmentCondition";
import { useTranslation } from "react-i18next";

const SegmentRuleRowContainer = ({
  subRules,
  addNewSubRule,
  deleteSubRule,
  returnedSegment,
  items,
  setSubRules,
  returnedSubRules,
  selectedLogic, // Get this from parent
  setSelectedLogic, // Get this from parent
}) => {
  const { t } = useTranslation("segmentation");
  useEffect(() => {
    if (returnedSegment) {
      if (returnedSubRules) setSubRules(returnedSubRules);
    }
  }, [returnedSegment]);

  const handleSetSubRule = (localSubRule, subRuleId) => {
    const updatedRules = subRules.map((rule) =>
      rule.id === subRuleId
        ? { ...rule, ...localSubRule, expressions: [...rule.expressions] }
        : rule
    );

    if (!updatedRules.some((rule) => rule.id === subRuleId)) {
      setSubRules([...subRules, localSubRule]);
    } else {
      setSubRules(updatedRules);
    }
  };

  return (
    <div className="segment-rule-row-container">
      <div className="flex flex-row align-center">
        <Logic
          selectedLogic={selectedLogic}
          setSelectedLogic={setSelectedLogic}
          hideTopLine
          returnedSegment={returnedSegment}
        />
        <div className="segment-info-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#c33e5d"
          >
            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
          </svg>
          {t(
            "AND seçeneği tüm kriterlerin karşılanmasını, OR seçeneği ise herhangi bir kriterin karşılanmasını bekler."
          )}
        </div>
      </div>
      {subRules &&
        Array.isArray(subRules) &&
        subRules.map((subRule, index) => (
          <React.Fragment key={subRule.id}>
            <SegmentCondition
              index={index + 1}
              items={items}
              subRule={subRule}
              returnedSegment={returnedSegment}
              handleSetSubRule={handleSetSubRule}
              deleteRow={deleteSubRule}
            />
            <Logic readOnly={true} selectedLogic={selectedLogic} />
          </React.Fragment>
        ))}
      <div className="segment-rule-row">
        <div className="add-new-rule" onClick={addNewSubRule}>
          {t("addNewRule")}
        </div>
      </div>
    </div>
  );
};

export default SegmentRuleRowContainer;
