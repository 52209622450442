// CrispChat.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CrispChat = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.$crisp) {
            if (location.pathname.startsWith('/brand/')) {
                window.$crisp.push(["do", "chat:show"]);
            } else {
                window.$crisp.push(["do", "chat:hide"]);
            }
        }
    }, [location]);

    return null;
};

export default CrispChat;
