import React, { useEffect, useState } from "react";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import locationIcon from "../../../../../../assets/media/locationIcon.svg";
import mailIcon from "../../../../../../assets/media/mail.svg";
import { apiUrl, imagesUrl } from "../../../../../../config";
import CustomBackgroundButton from "../../../../../button/customBackgroundButton";
import CustomTabButton from "../../../../../button/customTabButton";
import SotierAnalytics from "./analytics/sotierAnalytics";
import CallAPI from "../../../../../api/callApi";
import DynamicTable from "../../../../../tables/DynamicTable";

const SotierProfile = () => {
  const { t } = useTranslation("sotierList");
  const { id } = useParams();
  const navigate = useNavigate();
  const [sotier, setSotier] = useState(null);
  const [activeTab, setActiveTab] = useState("Analytics"); // State for active tab
  const [segments, setSegments] = useState([]);
  useEffect(() => {
    getSotier(id); // URL parametresinden id'yi kullan
  }, [id]);

  useEffect(() => {
    console.log(sotier);
  }, [sotier]);
  const getSotier = async (sotierId) => {
    const response = await CallAPI({
      method: "get",
      endPoint: apiUrl + "/api/v1/brand-admin/sotier/" + sotierId + "/profile",
    });
    setSotier(response.data.responseData);
    const formattedSegments = response.data.responseData.segments.map(
      (segment) => ({
        ...segment,
        created: new Date(segment.created).toLocaleDateString(
          localStorage.getItem("language"),
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }
        ),
        updated: new Date(segment.updated).toLocaleDateString(
          localStorage.getItem("language"),
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }
        ),
      })
    );
    setSegments(formattedSegments);
  };

  // Destructure sotier to use individual properties
  const segmentColumns = [
    { title: t("id"), field: "id" },
    { title: t("name"), field: "name" },
    { title: t("created"), field: "created" },
    { title: t("updated"), field: "updated" },
  ];
  const locationArray = [
    [t("sotierList"), "/brand/srm/sotier/list"],
    [
      sotier?.userName ? sotier?.userName : t("profile"),
      `/brand/srm/sotier/profile/${id}`,
    ],
  ];

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    console.log(`Selected Tab: ${tab}`);
  };

  const tabData = [
    { key: "Analytics", label: "Analitik" },
    {
      key: "Segments",
      label: t("segments"),
    },
  ];

  const cardsData = [];

  return (
    <div className="dashboard-container profile-dashboard-background">
      <NavigationBar locationArray={locationArray} />

      <div className="profile-dashboard">
        <div className="sotier-profile-row-container profile-card">
          <div className="sotier-profile-row-top">
            <div className="sotier-profile-row-left">
              <div className="sotier-profile-credentials-container">
                <img
                  className="sotier-profile-picture"
                  src={
                    sotier?.logo
                      ? imagesUrl + "/" + sotier?.logo
                      : placeholderImg
                  }
                  alt={t("profilePictureAlt")} // Localization for alt text
                />
                <div className="sotier-profile-credentials">
                  <div className="sotier-profile-name-container">
                    <div>{sotier?.userName}</div>
                    <div>{sotier?.firstName}</div>
                    <div>{sotier?.lastName}</div>
                  </div>
                  <div className="sotier-profile-contact-container">
                    <div>
                      {t("sotierId")}: {sotier?.sotierId}
                    </div>
                    <div>
                      <img src={locationIcon} alt={t("locationAlt")} />
                      {sotier?.city || t("notAvailable")}
                    </div>
                    <div>
                      <img src={mailIcon} alt={t("mailAlt")} />
                      {sotier?.email || ""}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      {t("birthDateTitle")}
                    </div>
                    <div className="sotier-profile-credential">
                      {sotier?.birthDate
                        ? new Date(sotier.birthDate).toLocaleDateString(
                            localStorage.getItem("language"),
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            }
                          )
                        : t("notAvailable")}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      {t("membershipDateTitle")}
                    </div>
                    <div className="sotier-profile-credential">
                      {sotier?.membershipDate
                        ? new Date(sotier.membershipDate).toLocaleDateString(
                            localStorage.getItem("language"),
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            }
                          )
                        : t("notAvailable")}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <CustomBackgroundButton
                      title={t("giftSotyCoin")}
                      backgroundColor={"#45C7D1"}
                      onClick={() => {
                        navigate(`/brand/srm/sotier/profile/${id}/gift`);
                      }}
                    />
                    <CustomBackgroundButton
                      title={t("penaltyManagement")}
                      onClick={() => {
                        navigate(`/brand/reports/detail/${sotier.sotierId}`);
                      }}
                      backgroundColor={"#DC6767"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="vertical-line sotier-profile-vertical-line-padding" />
            <div className="sotier-profile-row-right">
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  {t("loyaltyLevel")}
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  {sotier?.loyaltyLevel}
                </div>
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
              </div>
              <div className="sotier-profile-row-right-row display-none-important">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  {t("segments")}
                </div>
                {sotier?.segments.slice(0, 4).map((segment, index) => (
                  <div
                    className="sotier-profile-row-right-row-cell"
                    key={segment.id}
                  >
                    <div className="sotier-profile-row-right-segment-wrapper">
                      ID : {segment.id}
                    </div>
                  </div>
                ))}

                {/* Boş div'leri ekleyelim */}
                {Array.from({
                  length: 5 - Math.min(sotier?.segments.length, 4),
                }).map((_, index) => (
                  <div
                    className="sotier-profile-row-right-row-cell"
                    key={`empty-${index}`}
                  />
                ))}
              </div>
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  {t("subscriptionBrand")}
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <div className="sotier-profile-row-right-subscribe-wrapper">
                    {sotier?.subscriptionCount || t("notAvailable")}
                  </div>
                </div>
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
              </div>
            </div>
          </div>
          <div className="sotier-profile-row-bottom">
            {tabData.map(({ key, label }) => (
              <CustomTabButton
                key={key}
                borderBottomColor={
                  activeTab === key ? "#04C8C8" : "transparent"
                }
                onClick={() => handleTabChange(key)}
                title={label}
              />
            ))}
          </div>
        </div>

        {(activeTab == "Analytics" || activeTab == "") && (
          <SotierAnalytics cardsData={cardsData} />
        )}
        {activeTab == "Segments" && (
          <DynamicTable columns={segmentColumns} data={segments} />
        )}
      </div>
    </div>
  );
};

export default SotierProfile;
