import React, { useState, useEffect, useRef } from "react";
import SegmentDateRangeSelect from "../collapsible/SegmentDateRangeSelect";
import { useTranslation } from "react-i18next";
const SegmentDateRangeButton = ({
  selectedValue,
  setSelectedValue,
  onValueChange,
}) => {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const { t } = useTranslation("segmentation");

  const buttonRef = useRef(null);
  const selectorRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        selectorRef.current &&
        !selectorRef.current.contains(event.target)
      ) {
        setSelectorVisible(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (value) => {
    setSelectedValue(value);
    setSelectorVisible(false); // Close the selector on selection
    if (onValueChange) {
      onValueChange(value); // Notify parent of the selection
    }
  };

  return (
    <div className="segment-date-range-button-container">
      <div
        className={`segment-add-filter-button button  ${
          selectedValue ? "filled" : ""
        }`}
        ref={buttonRef}
        onClick={() => setSelectorVisible(!selectorVisible)}
      >
        <div className="segment-add-filter-button-title">
          {t("dateRange")} : {t(selectedValue) || t("not")}
        </div>
      </div>
      {selectorVisible && (
        <div ref={selectorRef}>
          <SegmentDateRangeSelect
            selectedValue={selectedValue}
            setSelectedValue={handleSelect}
          />
        </div>
      )}
    </div>
  );
};

export default SegmentDateRangeButton;
