import react from "react";
import { isExpired, decodeToken } from "react-jwt";

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
export function isMyTokenExpired(token) {
  if (isExpired(token)) return true;
  else {
    return false;
  }

}
export function getUserRolesFromToken(token) {
  try {
    const tokenParts = token.split('.');
    const payload = JSON.parse(atob(tokenParts[1]));

    const realmAccess = payload.realm_access;

    if (realmAccess && realmAccess.roles) {
      return realmAccess.roles;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Token çözme hatası:', error);
    return [];
  }
}

export const deleteCookie = (name) => {
  document.cookie = name + '=; Max-Age=0; path=/;';
}
export function getUserTypeFromToken(token) {
  try {
    const tokenParts = token.split('.');
    const payload = JSON.parse(atob(tokenParts[1]));
    const userType = payload.azp;
    if (userType === "brand-client") {
      return true;
    } else if (userType === "admin-client") {
      return false;
    }
    else {
      return false;
    }
  } catch (error) {
    console.error('Token çözme hatası:', error);
    return;
  }
}

