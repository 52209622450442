import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useNavigate } from "react-router";
import axios from "axios";
import {
  getCookie,
  isMyTokenExpired,
  getUserTypeFromToken,
} from "../pages/auth/tokenUtils";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import Loader from "../popup/loader";
const ChallengeCardDialog = (challenge) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("challengeDashboard", "challengeList");
  const challengeStatus = challenge.challenge.status;
  const challengeID = challenge.challenge.id;
  const [loaderState, setLoaderState] = useState(false);

  console.log("popupchallgenede", challenge);

  const showEditClicked = () => {
    fetchSelectedChallenge(challengeID, challenge.challenge);
  };
  const showEditCorporateClicked = () => {
    fetchSelectedCorpCampaign(
      challenge.challenge.campaign_id,
      challenge.challenge
    );
  };
  const showDetailClicked = () => {
    fetchSelectedChallengeToDetailPage(challengeID, challenge.challenge);
  };
  const showCorpDetailClicked = () => {
    navigate("/brand/srm/corporate-campaign/detail", {
      state: { id: challenge.challenge.campaign_id },
    });
  };

  const fetchSelectedCorpCampaign = async (challengeId, challenge) => {
    const token = getCookie("access_token");
    if (!token) {
      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }
    const endpoint =
      apiUrl + `/api/v1/brand-admin/brand/campaign/${challengeId}`;
    try {
      setLoaderState(true);
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      console.log(data);

      setLoaderState(false);
      navigate("/brand/srm/corporate-campaign/create", {
        state: { returnedCampaign: data },
      });

      // Do something with the data
    } catch (error) {
      setLoaderState(false);
      toast.error(t("error.fetchingData") + " (" + error.message + ")");
      console.error("Error fetching data:", error);
    }
  };
  const fetchSelectedChallenge = async (challengeId, challenge) => {
    const token = getCookie("access_token");
    if (!token) {
      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }

    let endpoint;
    if (getUserTypeFromToken(getCookie("access_token"))) {
      if (challenge.challengeType === "SURVEY") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/survey/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/content/${challengeId}/detail`;
      }
    } else {
      if (challenge.challengeType === "SURVEY") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/survey/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/social/content/${challengeId}/detail`;
      }
    }

    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      if (data.responseData.socialChallengeType === "CONTENT_CREATION") {
        navigate("/brand/challenges/content-creation", {
          state: { returnedChallenge: data },
        });
      } else if (data.responseData.socialChallengeType === "INTERACTION") {
        navigate("/brand/challenges/interaction-creation", {
          state: { returnedChallenge: data },
        });
      } else if (data.responseData.challengeType === "SURVEY") {
        navigate("/brand/challenges/survey-creation", {
          state: { returnedChallenge: data },
        });
      }
      // Do something with the data
    } catch (error) {
      toast.error(t("error.fetchingData") + " (" + error.message + ")");
      console.error("Error fetching data:", error);
    }
  };

  const fetchSelectedChallengeToDetailPage = async (challengeId, challenge) => {
    const token = getCookie("access_token");
    if (!token) {
      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");

      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }

    let endpoint;
    if (getUserTypeFromToken(token)) {
      if (challenge.challengeType === "SURVEY") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/survey/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/content/${challengeId}/detail`;
      }
    } else {
      if (challenge.challengeType === "SURVEY") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/survey/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/social/content/${challengeId}/detail`;
      }
    }

    if (getUserTypeFromToken(token)) {
      try {
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;

        navigate("/brand/challenges/challenge-detail", {
          state: { returnedChallenge: data.responseData },
        });
        // Do something with the data
      } catch (error) {
        console.log(error)
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;

        navigate("/challenges/detail", {
          state: { returnedChallenge: data.responseData },
        });
        // Do something with the data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  console.log(challenge.challenge.publish_status);
  status: challenge.publish_status === "RELEASED" // If campaign.publishStatus is "RELEASED"
    ? challenge.isactive === "true" // Check if campaign.isactive is "false"
      ? t("ACTIVE") // If true, set status to "nonActive"
      : t("UNACTIVE") // If false, set status to "ACTIVE"
    : t("DRAFTED"); // If campaign.publishStatus is not "RELEASED", set status to "DRAFTED"

  return !getUserTypeFromToken(getCookie("access_token")) &&
    challengeStatus === "DRAFTED" ? (
    <></>
  ) : (
    <div className="challenge-card-dialog">
      {loaderState && <Loader />}
      {getUserTypeFromToken(getCookie("access_token")) &&
        (challenge.challenge?.publish_status === "DRAFTED" ||
          challenge.challenge?.isactive === "false") && (
          <div className="challenge-card-dialog-row">
            <div
              className="challenge-card-dialog-button"
              onClick={showEditCorporateClicked}
            >
              {t("edit")}
            </div>
          </div>
        )}

      {getUserTypeFromToken(getCookie("access_token")) &&
        challengeStatus === "RELEASED" && (
          <div className="challenge-card-dialog-row">
            <div
              className="challenge-card-dialog-button"
              onClick={showDetailClicked}
            >
              {t("showDetails")}
            </div>
          </div>
        )}

      {getUserTypeFromToken(getCookie("access_token")) &&
        challengeStatus === "DRAFTED" && (
          <div className="challenge-card-dialog-row">
            <div
              className="challenge-card-dialog-button"
              onClick={showEditClicked}
            >
              {t("edit")}
            </div>
          </div>
        )}

      {!getUserTypeFromToken(getCookie("access_token")) &&
        challengeStatus === "RELEASED" && (
          <div className="challenge-card-dialog-row">
            <div
              className="challenge-card-dialog-button"
              onClick={showDetailClicked}
            >
              {t("showDetails")}
            </div>
          </div>
        )}
      {challenge.challenge.isactive === "true" && (
        <div className="challenge-card-dialog-row">
          <div
            className="challenge-card-dialog-button"
            onClick={showCorpDetailClicked}
          >
            {t("showDetails")}
          </div>
        </div>
      )}
    </div>
  );
};
export default ChallengeCardDialog;
