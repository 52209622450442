import React from "react";
import { useTranslation } from "react-i18next";
import arrowImg from '../../assets/media/arrow.png';
const SotierAnalyticsCard = ({
  topTitle,
  title,
  valueColor = "#000",
  short,
  value,
  tableHeaders = [],
  tableData = [],
  onClick
}) => {
  const { t } = useTranslation("sotierList");

  return (
    <div className="profile-card sotier-analytics-card">
      <div className="profile-card-total-container">
        <div className="profile-card-total-title">
          {topTitle ? topTitle : t("total")}
        </div>
        <div className="profile-card-total-data">{title}</div>
        <div className="profile-card-total-data-short">({short})</div>
        <div
          className="profile-card-total-data-value"
          style={{ color: valueColor }}
        >
          {value}
        </div>
      </div>
      <table className="profile-card-table">
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="analysis-button button">{t("viewOnAnalysis")}<img src={arrowImg}/></div>
    </div>
  );
};

export default SotierAnalyticsCard;
