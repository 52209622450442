import React from "react";
import infoIconEmpty from "../../assets/media/infoIconEmpty.svg";

const SingleTextTextarea = ({ title, titleDescription, isMandatory, inputPlaceholder, maxLength, minLength, infoText, value, isValid = true, onBlur, onChange }) => {

    return (
        <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                    {title && title}{isMandatory && <span>*</span>}{title && ":"}
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                    {titleDescription && titleDescription}
                </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
                <div className="challenge-title-container">
                    <textarea
                        className="challenge-title"
                        placeholder={inputPlaceholder && inputPlaceholder}
                        minLength={minLength && minLength}
                        maxLength={maxLength && maxLength}
                        value={value}
                        onBlur={(e) => { onBlur(e) }}
                        onChange={(e) => { onChange(e) }}
                        style={{ border: isValid ? "" : "2px solid red" }}
                    />
                    <div className="single-text-input-info-container">
                        {
                            infoText &&
                            infoText.map((text, index) => (
                                <div key={index} className="challenge-title-info-container">
                                    <img
                                        className="challenge-title-info-icon"
                                        src={infoIconEmpty}
                                        alt="info:"
                                    />
                                    <div className="challenge-title-info-text">
                                        {text}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SingleTextTextarea;
