import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link, createSearchParams } from "react-router-dom";
import "../../../../../assets/css/BrandApplicationList.css";
import arrow from "../../../../../assets/media/arrow.png";
import { showErrorToast } from "../../../../toast/toast.js";
import downArrow from "../../../../../assets/media/down-arrow.png";
import hourglass from "../../../../../assets/media/sand-clock.png";
import tick from "../../../../../assets/media/tickInteraction.svg";
import ListTable from "../../../../list/ListTable";
import { apiUrl } from "../../../../../config.js";
import { toast } from "react-toastify";
import { isMyTokenExpired } from "../../../auth/tokenUtils";
import { useTranslation } from "react-i18next";

import Loader from "../../../../popup/loader.js";

const BrandApplicationList = ({ }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t, i18n } = useTranslation("brandApplicationList");
  const [loaderState, setLoaderState] = useState(false);

  const countListItems = (list) => {
    return list.length;
  };

  // Create an Axios instance
  const axiosInstance = axios.create();

  // Add an interceptor to handle 401 errors
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        showErrorToast(
          "Oturmunuzun süresi doldu. Lütfen tekrar giriş yapınız."
        );
        navigate("/sign-in-realm");
      }
      return Promise.reject(error);
    }
  );

  let requestGroupTitleText = t("newRequest");
  let pTextContent = "11";
  let requestLinkTextContent = t("newRequestReadyToApprove");
  let requestIconSrcValue = hourglass;

  const navigate = useNavigate();
  const ToDetails = (value) => {
    if (value === 0) {
      requestGroupTitleText = t("newRequest");
      pTextContent = "11";
      requestLinkTextContent = t("newRequestReadyToApprove");
      requestIconSrcValue = hourglass;
    } else if (value === 1) {
      requestGroupTitleText = t("pending");
      pTextContent = "5";
      requestLinkTextContent = t("pendingList");
      requestIconSrcValue = hourglass;
    } else if (value === 2) {
      requestGroupTitleText = t("approved");
      pTextContent = "1";
      requestLinkTextContent = t("approvedList");
      requestIconSrcValue = tick;
    }
    navigate("/brand-application-detail", {
      state: {
        requestGroupTitleText,
        pTextContent,
        requestLinkTextContent,
        requestIconSrcValue,
        currentPage, // currentPage'i props olarak iletebilirsiniz
        totalPages, // totalPages'i props olarak iletebilirsiniz
      },
    });
  };

  const [newListA, setNewListA] = useState([]);
  const [newListB, setNewListB] = useState([]);
  const [newListC, setNewListC] = useState([]);

  const onItemClick = (ID) => {
    // Redirect to 'brand-app-request' with the selected row's id
    navigate(`/brand-app-request/${ID}`);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const fetchData = async () => {
    try {
      setLoaderState(true);

      const token = getCookie("access_token");
      if (!token) {
        console.error("Access token is missing.");
        setLoaderState(false);

        navigate("/sign-in-realm");
        return;
      }
      if (isMyTokenExpired(token)) {
        toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
        setLoaderState(false);

        navigate("/sign-in-realm");
      }

      const response = await axiosInstance.get(
        `${apiUrl}/api/v1/admin/application?page=${currentPage - 1}&size=100`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        console.error("Error fetching data. Status: ", response.status);
        return;
      }

      const responseData = response.data; // Use response.data directly
      setLoaderState(false);

      setTotalPages(responseData.pageMetadata.totalPage);
      // Check if responseData.responseData is an array
      if (!Array.isArray(responseData.responseData)) {
        
        console.error("Response data is not an array.");
        return;
      }

      // Initialize the lists
      const newListA = [];
      const newListB = [];
      const newListC = [
        {
          id: 1,
          companyName: "Company1",
          brandName: "Brand1",
          url: "https://example1.com",
          created: "11-09-2023",
        },
        {
          id: 2,
          companyName: "Company2",
          brandName: "Brand2",
          url: "https://example2.com",
          created: "11-09-2023",
        },
        {
          id: 3,
          companyName: "Company3",
          brandName: "Brand3",
          url: "https://example3.com",
          created: "11-09-2023",
        },
      ];
      // Loop through the responseData and add to the appropriate list
      responseData.responseData.forEach((item) => {
        if (item.status === "PENDING") {
          newListA.push({
            id: item.id,
            companyName: item.companyName,
            brandName: item.brandName,
            url: item.url,
            created: item.created,
            status: item.status,
          });
        } else if (item.status === "APPROVED") {
          newListB.push({
            id: item.id,
            companyName: item.companyName,
            brandName: item.brandName,
            url: item.url,
            created: item.created,
            status: item.status,
          });
        }
      });

      // Update the state with the lists
      setNewListA(newListA);
      setNewListB(newListB);
      setNewListC(newListC);
      setLoaderState(false);

    } catch (error) {
      console.error("HATA:", error);
      setLoaderState(false);

    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  return (
    <div className="dashboard-container">
      {loaderState && <Loader/>} 

      <div className="content-location-container">
        <a className="content-location-container-href" href="#">
          <img src={arrow} />
        </a>
        <a className="content-location-container-link-text" href="#">
          <div className="older">{t("brandApplications")}</div>
        </a>
        <img src={downArrow} className="backArrow" alt="arrow" />
        <div className="current">{t("dashboard")}</div>
      </div>
      <hr />
      <div className="column-container">
        <div className="column column-left">
          <div className="main-container">
            <div className="main-container-header">
              <div className="main-container-header-column">
                <div className="request-group">
                  <div className="request-group-title">
                    {requestGroupTitleText}
                  </div>
                  <p>{countListItems(newListA)}</p>
                </div>
                <div onClick={() => ToDetails(0)} className="request-link">
                  {requestLinkTextContent}
                </div>
              </div>
              <div className="request-icon">
                <img
                  src={requestIconSrcValue}
                  className="request-icon-img"
                  alt="Hourglass Icon"
                />
              </div>
            </div>
            <hr />
            {/*
            <div className="tables">
              <div className="table-border">
                <ListTable List={newListA} onItemClick={onItemClick} />
              </div>
            </div>
            */}
            <ListTable List={newListA} onItemClick={onItemClick} />

          </div>
        </div>
        <div className="column column-center">
          <div className="main-container">
            <div className="main-container-header">
              <div className="main-container-header-column">
                <div className="request-group">
                  <div className="request-group-title ">{t("pending")}</div>
                  <p>{countListItems(newListC)}</p>
                </div>
                <div className="request-link" onClick={() => ToDetails(1)}>
                {t("pendingList")}
                </div>
              </div>
              <div className="request-icon">
                <img
                  src={requestIconSrcValue}
                  className="request-icon-img"
                  alt="Hourglass Icon"
                />
              </div>
            </div>
            <hr />
            <ListTable List={newListC}/>
          </div>
        </div>
        <div className="column column-right">
          <div className="main-container">
            <div className="main-container-header">
              <div className="main-container-header-column">
                <div className="request-group">
                  <div className="request-group-title">{t("approved")}</div>
                  <p>{countListItems(newListB)}</p>
                </div>
                <div className="request-link" onClick={() => ToDetails(2)}>
                {t("approvedList")}
                </div>
              </div>
              <div className="request-icon">
                <img src={tick} className="request-icon-img"/>
              </div>
            </div>
            <hr />
            <ListTable List={newListB} />
          </div>
        </div>
      </div>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img className="pagination-prev-icon" src={downArrow} alt="prev" />
        </button>
        <span>{t("page")} {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img className="pagination-next-icon" src={downArrow} alt="next" />
        </button>
      </div>
    </div>
  );
};

export default BrandApplicationList;
