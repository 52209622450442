import React, { useState, useEffect } from "react";
import checkIcon from '../../../assets/media/Checklist.svg';
import infoIcon from '../../../assets/media/infoIconEmpty.svg';
import timeIcon from '../../../assets/media/time.svg';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
const ContentCardBottomPending = ({ offerDate, offerCoin, offerEndDate, formatDateStringLocal }) => {
    const { t, i18n } = useTranslation("contentList");
    const navigate = useNavigate();
    const [dayDifference, setDayDifference] = useState(0);
    const [hourDifference, setHourDifference] = useState(0);
    const [minuteDifference, setMinuteDifference] = useState(0);
    const [secondDifference, setSecondDifference] = useState(0);



    const convertToDate = (dateTimeStr) => {
        // '07.06.2024 16:44:00' formatındaki stringi ISO 8601 formatına çeviriyoruz
        const [datePart, timePart] = dateTimeStr.split(' ');
        const [day, month, year] = datePart.split('.');
        const isoFormatDateTime = `${year}-${month}-${day}T${timePart}`;

        return new Date(isoFormatDateTime);
    };




    const calculateDateDifference = (offerEndDate) => {
        const now = Date.now(); 
        const initialDate = convertToDate(offerEndDate).getTime();
        // Calculate time difference in milliseconds
        const timeDifference = initialDate - now;

        if (timeDifference > 0) {
            const seconds = Math.floor(timeDifference / 1000) % 60;
            const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
            const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

            setDayDifference(days);
            setHourDifference(hours);
            setMinuteDifference(minutes);
            setSecondDifference(seconds);
        } else {
            setDayDifference(0);
            setHourDifference(0);
            setMinuteDifference(0);
            setSecondDifference(0);
        }
    };

    useEffect(() => {
        if (offerEndDate) {
            calculateDateDifference(offerEndDate);
            const intervalId = setInterval(() => {
                calculateDateDifference(offerEndDate);
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, []);


    return (
        <div className="content-card-bottom-pending-container">
            <div className="content-card-bottom-pending-row-container">

                <div className="content-card-bottom-pending-row">
                    <div className="content-card-bottom-pending-row-left">
                        <div className="content-card-bottom-pending-row-left-indicator" />
                        <label>{t("offerCoin")}:</label>
                    </div>
                    <div className="content-card-bottom-pending-row-right">
                        {offerCoin}
                    </div>
                </div>

                <div className="content-card-bottom-pending-row">
                    <div className="content-card-bottom-pending-row-left">
                        <div className="content-card-bottom-pending-row-left-indicator" />
                        <label>{t("offerDate")}:</label>
                        <span>{offerDate}</span>
                    </div>
                </div>
                <div className="content-card-bottom-pending-row">
                    <div className="content-card-bottom-pending-row-left">
                        <div className="content-card-bottom-pending-row-left-indicator" />
                        <label>{t("offerEndDate")}:</label>
                        <span>{offerEndDate}</span>
                    </div>
                </div>



            </div>


            <div className="content-card-bottom-date-counter-container">
                <img src={timeIcon} alt="time" />
                <div className="content-card-bottom-date-counter-wrapper">
                    <div className="content-card-bottom-date-counter-data">
                        {dayDifference}
                        <div className="content-card-bottom-date-counter-title">
                            {t("days")}
                        </div>
                    </div>
                    <div className="content-card-bottom-date-counter-data">
                        {hourDifference}
                        <div className="content-card-bottom-date-counter-title">
                            {t("hours")}
                        </div>
                    </div>
                    <div className="content-card-bottom-date-counter-data">
                        {minuteDifference}
                        <div className="content-card-bottom-date-counter-title">
                            {t("minutes")}
                        </div>
                    </div>
                    <div className="content-card-bottom-date-counter-data">
                        {secondDifference}
                        <div className="content-card-bottom-date-counter-title">
                            {t("seconds")}
                        </div>
                    </div>
                </div>

                <div className="content-card-bottom-pending-container-info-container">
                    <div className="content-card-bottom-pending-container-info-row">
                        <img src={infoIcon} alt="info" />
                        <label>
                            {t("offerExpiresMessage")}
                        </label>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default ContentCardBottomPending;