import React, { useState, useEffect } from "react";
import "../../assets/css/SideBar.css";
import arrow from "../../assets/media/sidebarArrow.png";
import DotCircleIcon from "../../assets/media/dot-circle.svg";

const SidebarRecursive = ({
  items,
  collapse,
  clickedItem,
  setClickedItem,
  depth,
}) => {
  const [collapseStates, setCollapseStates] = useState({});

  useEffect(() => {
    const savedStates = localStorage.getItem("collapseStates");
    if (savedStates) {
      setCollapseStates(JSON.parse(savedStates));
    } else {
      // Varsayılan olarak tüm öğeleri collapse yapın
      const defaultCollapseStates = {};
      const setDefaultCollapse = (items) => {
        items.forEach((item) => {
          if (item.items) {
            defaultCollapseStates[item.value] = true; // Kapalı (collapse) durumunu ayarla
            setDefaultCollapse(item.items); // Alt öğeleri de kontrol et
          }
        });
      };
      setDefaultCollapse(items);
      setCollapseStates(defaultCollapseStates);
    }
  }, [items]);

  const handleToggleCollapse = (event, value) => {
    event.stopPropagation(); // Stop the event from propagating to parent elements
    setCollapseStates((prev) => {
      const newState = {
        ...prev,
        [value]: !prev[value],
      };
      localStorage.setItem("collapseStates", JSON.stringify(newState)); // LocalStorage'a kaydet
      return newState;
    });
    setClickedItem(value);
  };

  const handleLinkClick = (item) => {
    if (item.value === "/sign-in-brand" || item.value === "/sign-in-realm") {
      deleteAccessTokenCookie();
    }
    setClickedItem(item);
  };

  function deleteAccessTokenCookie() {
    document.cookie =
      "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  const isAncestorClicked = (item) => {
    if (!item.items) return false;

    return item.items.some((child) => {
      if (child.value === clickedItem) return true;
      return isAncestorClicked(child);
    });
  };

  const path = window.location.pathname;

  return (
    <div
      className={`recursive-sidebar ${
        collapse ? "recursive-sidebar-collapsed" : ""
      }`}
    >
      {items.map((item, index) => (
        <div key={index} className="recursive-sidebar-item">
          {item.items ? (
            <div
              onClick={(event) => {handleLinkClick(item);  handleToggleCollapse(event, item.value)}}
              className={`recursive-sidebar-title-row ${
                depth === 0 ? "first-depth-sidebar" : "second"
              } ${
                path.includes(item.value) ? "recursive-sidebar-clicked" : ""
              } ${window.location.pathname === item.value ? "active" : ""}`}
            >
              <img
                className={`${
                  item.img
                    ? "recursive-sidebar-icon"
                    : "recursive-sidebar-dotcircle"
                }`}
                src={item.img || DotCircleIcon}
                alt="."
              />
              <div className="recursive-sidebar-collapsibe-row-header">
                <div className="recursive-sidebar-title">{item.label}</div>
                <img
                  className={`recursive-sidebar-img ${
                    collapseStates[item.value] ? "deg270" : ""
                  }`}
                  src={arrow}
                  alt="V"
                  onClick={(event) => handleToggleCollapse(event, item.value)}
                />
              </div>
            </div>
          ) : (
            <div
              className={`recursive-sidebar-item-link ${
                depth === 0 ? "first-depth-sidebar" : "second"
              } ${
                path.includes(item.value) ? "recursive-sidebar-clicked" : ""
              } ${window.location.pathname === item.value ? "active" : ""}`}
              onClick={() => {
                handleLinkClick(item);
              }}
            >
              <img
                className={`${
                  item.img
                    ? "recursive-sidebar-icon"
                    : "recursive-sidebar-dotcircle"
                }`}
                src={item.img || DotCircleIcon}
                alt="."
              />
              {item.label}
            </div>
          )}
          {item.items && (
            <SidebarRecursive
              items={item.items}
              collapse={collapseStates[item.value] || false}
              setClickedItem={setClickedItem}
              clickedItem={clickedItem}
              depth={depth + 1}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SidebarRecursive;
