import React, { useState, useRef } from "react";
import downArrow from "../../assets/media/down-arrow.png";
import defaultImage from "../../assets/media/sotylogo.svg";

const Collapsible = ({ title, children, checkImage, showCheckIcon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  const checkStyle = {
    height: "20px",
    width: "20px",
    visibility: showCheckIcon ? "visible" : "hidden",
  };
  


  const iconStyle = {
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  };

  const contentStyle = {
    maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0",
    transition: "max-height 0.3s ease",
    overflow: "hidden",
    padding: isOpen ? "1rem" : "1rem 1rem 0 1rem",
  };


  const collapsibleTitle = {};

  return (
    <div className="collapsible">
      <div className="collapsible-header" onClick={toggleCollapsible}>
        <div className="collapsible-left">
          {showCheckIcon && (
            <img src={checkImage} alt={defaultImage} style={checkStyle} />
          )}
          
          <span style={collapsibleTitle}>{title}</span>
        </div>
        <div className="collapsible-right">
          <img src={downArrow} alt="Toggle" style={iconStyle} />
        </div>
      </div>
      <div className="collapsible-content" style={contentStyle} ref={contentRef}>
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
