import React from "react";

const Logic = ({
  selectedLogic,
  setSelectedLogic,
  readOnly = false,
  hideTopLine = false,
  hideBottomLine = false,
}) => {
  const handleSelect = (option) => {
    if (!readOnly) {
      setSelectedLogic(option);
    }
  };

  return (
    <div className="and-container">
      {!hideTopLine && (
        <div className="and-container-line and-container-line-top" />
      )}
      {!hideBottomLine && (
        <div className="and-container-line and-container-line-bottom" />
      )}

      <div className="and-border">
        {readOnly ? (
          <>
            <span className={`and-border-select`} />
            <div className="and selected">{selectedLogic}</div>
          </>
        ) : (
          <>
            <span
              className={`and-border-select ${
                selectedLogic === "OR" ? "or-selected" : ""
              }`}
            />
            <div
              className={`and ${selectedLogic === "AND" ? "selected" : ""}`}
              onClick={() => handleSelect("AND")}
            >
              and
            </div>
            <div
              className={`and ${selectedLogic === "OR" ? "selected" : ""}`}
              onClick={() => handleSelect("OR")}
            >
              or
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Logic;
