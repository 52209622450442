import React from "react";
import { useTranslation } from "react-i18next";
const FaqItem = ({ faq, onEdit, onDelete }) => {
  const { t } = useTranslation("segmentation");
  return (
    <div className="faq-item">
      <div className="faq-row">
        {false && faq.questionEn && (
          <div className="faq-language">
            <div className="faq-item__question-en">
              {faq.questionEn === "null" ? "" : faq.questionEn}
            </div>

            <div className="faq-answer faq-item__answer-en">
              {faq.answerEn === "null" ? "" : faq.answerEn}
            </div>
          </div>
        )}
        {faq.questionTr && (
          <div className="faq-language">
            <div className="faq-item__question-tr">
              {faq.questionTr === "null" ? "" : faq.questionTr}
            </div>
            <div className="faq-answer faq-item__answer-tr">
              {faq.answerTr === "null" ? "" : faq.answerTr}
            </div>
          </div>
        )}
        {faq.question && (
          <div className="faq-language">
            <div className="faq-item__question-tr">
              {faq.question === "null" ? "" : faq.question}
            </div>
            <div className="faq-answer faq-item__answer-tr">
              {faq.answer === "null" ? "" : faq.answer}
            </div>
          </div>
        )}
      </div>
      {!faq.question && (
        <div className="faq-item__actions">
          <button className="faq-item__edit-button" onClick={onEdit}>
            {t("edit")}
          </button>
          <button className="faq-item__delete-button" onClick={onDelete}>
            {t("delete")}
          </button>
        </div>
      )}
    </div>
  );
};

export default FaqItem;
