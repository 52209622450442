import React, { useState } from "react";
import searchImg from "../../assets/media/SearchIcon.svg";
import downArrow from "../../assets/media/down-arrow.png";
import CategoryDetails from "./CategoryDetails";
import { useTranslation } from "react-i18next";
const SegmentRoleTypeSelect = ({
  onCategorySelect,
  items,
  updatedCategoriesForSavedCheckeds,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const { t } = useTranslation("segmentation");
  const handleItemClick = (item) => {
    if (item.details) {
      setSelectedItem(item);
      setSelectedCategory(null);

      setSelectedItems([]); // Clear selected items
    } else {
      onCategorySelect(item);
      setSelectedItem(item);
      setSelectedCategory(null);

      setSelectedItems([]); // Clear selected items
    }
  };

  const handleCategoryClick = (category) => {
    if (category.details) {
      setSelectedCategory(category);
    } else {
      onCategorySelect(category);
    }
  };

  return (
    <div className="segment-role-type-select-container">
      {/*
        <div className="segment-role-type-search-container">
        <img src={searchImg} alt="search" />
        <input type="text" placeholder="Search" />
      </div>
       */}

      <div className="segment-role-type-select-wrapper">
        <div className="segment-role-type-select-sidebar">
          {items.map((item, index) => {
            // Kontrollü bir şekilde `updatedCategoriesForSavedCheckeds` içinde `item.detail` ile örtüşen bir eleman olup olmadığını kontrol edin

            const isMatching = item.details?.some(
              (detail) =>
                updatedCategoriesForSavedCheckeds &&
                Array.isArray(updatedCategoriesForSavedCheckeds) &&
                updatedCategoriesForSavedCheckeds?.some(
                  (cat) =>
                    cat.title === detail.title &&
                    cat.valueType === detail.valueType &&
                    cat.requiresDateRange === detail.requiresDateRange &&
                    cat.multiselect === detail.multiselect
                )
            );

            return (
              <div
                key={index}
                className={`segment-role-type-select-sidebar-item ${
                  selectedItem === item || isMatching
                    ? "segment-role-type-select-sidebar-item-selected"
                    : ""
                }`}
                onClick={() => handleItemClick(item)}
              >
                <div className="segment-role-type-select-sidebar-item-title">
                  {t(item.title)}
                </div>
                <img src={downArrow} alt="down arrow" className="rotate-270" />
              </div>
            );
          })}
        </div>
        <div className="segment-role-type-select-columns-container">
          <div className="segment-role-type-select-category-column">
            <div className="segment-role-type-select-category-title">
              {selectedItem ? t(selectedItem.title) : t("selectCategory")}
            </div>
            {selectedItem && (
              <CategoryDetails
                details={selectedItem.details}
                onCategoryClick={handleCategoryClick}
                selectedItem={selectedItem}
                setSelectedCategory={setSelectedCategory}
                updatedCategoriesForSavedCheckeds={
                  updatedCategoriesForSavedCheckeds
                }
                multiSelect={selectedItem.multiselect} // Handle multi-select
                setSelectedItems={setSelectedItems} // Update selected items
              />
            )}
          </div>
          {selectedCategory && selectedCategory.details && (
            <div className="segment-role-type-select-category-column">
              {selectedCategory && (
                <div className="segment-role-type-select-subcategory-container">
                  <div className="segment-role-type-select-category-title">
                    {t(selectedCategory.title)}
                  </div>
                  <CategoryDetails
                    details={selectedCategory.details}
                    onCategoryClick={handleCategoryClick}
                    setSelectedCategory={setSelectedCategory}
                    updatedCategoriesForSavedCheckeds={
                      updatedCategoriesForSavedCheckeds
                    }
                    multiSelect={selectedItem.multiselect} // Handle multi-select
                    selectedItems={selectedItems} // Selected items
                    selectedItem={selectedItem}
                    setSelectedItems={setSelectedItems} // Update selected items
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SegmentRoleTypeSelect;
