import React, { useState, useRef, useEffect } from 'react';
import downArrow from "../../assets/media/down-arrow.png";
import axios from 'axios';
import { toast } from "react-toastify";
import leftArrow from "../../assets/media/Left.svg";
import notificationIcon from "../../assets/media/Notifications.png";
import MedalIcon from "../../assets/media/Medal.svg";
import InstagramIcon from "../../assets/media/instagram.svg";
import FacebookIcon from "../../assets/media/facebook.svg";
import TwitterIcon from "../../assets/media/x-twitter.svg";
import YoutubeIcon from "../../assets/media/youtube.svg";
import TiktokIcon from "../../assets/media/tiktok.svg";
import InfoIcon from "../../assets/media/Info.svg";
import arrow from "../../assets/media/arrow.png";
import placeholderImg from "../../assets/media/noImageToShow.jpeg";
import "../../assets/css/ChallengePreview.css";
import "../../assets/css/PhoneView.css";
import SurveyType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/SurveyType.js';
import InteractionType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/InteractionType.js';
import tagIcon from "../../assets/media/tagIcon.svg";
import hashtagIcon from "../../assets/media/hashtagIcon.svg";
import ContentType from '../pages/brand_admin/challenges/challenge_preview/challenge_types/ContentType.js';
import Approved from '../pages/brand_admin/popup_page/PopupPage.js';
import Slider from 'react-slick';
import { useLocation, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { imagesUrl } from '../../config.js';
import { getCookie } from '../pages/auth/tokenUtils.js';
import { isMyTokenExpired } from '../pages/auth/tokenUtils.js';
import { apiUrl } from "../../config.js";
import paperIcon from "../../assets/media/paper.svg";


const PhoneViewProgressBar = ({ currentSlide, totalSlides }) => {
    const progress = (currentSlide / totalSlides) * 100;

    return (
        <div className="phone-view-progress-bar">
            <div
                className="phone-view-progress-bar-inner"
                style={{ width: `${progress}%` }}
            />
        </div>
    );
};


const PhoneViewSurvey = (returnedApiData) => {

    const ReturnedApiData = returnedApiData.returnedApiData;
    useEffect(() => {
        console.log("returnedApiData ", ReturnedApiData);
        console.log("responseData ", ReturnedApiData.responseData);

    }, [ReturnedApiData])
    const sliderRef = useRef(null);
    const [selectedButton, setSelectedButton] = useState(1);
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = ReturnedApiData.responseData.questions.length;

    const handleButtonClick = (buttonNumber) => {
        setSelectedButton(buttonNumber);
    };
    const handleNextSlide = () => {
        if (sliderRef.current) {
            const newSlide = currentSlide + 1;
            if (newSlide >= ReturnedApiData.responseData.questions.length) {
                setCurrentSlide(ReturnedApiData.responseData.questions.length - 1);
            } else {
                sliderRef.current.slickNext();
            }
        }
    };

    const handleAfterChange = (currentIndex) => {
        setCurrentSlide(currentIndex);
    };

    const handleBeforeChange = (oldIndex, newIndex) => {
        setCurrentSlide(newIndex);
    };


    const settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: handleAfterChange,
        beforeChange: handleBeforeChange,
    };

    return (
        (
            <>
                <div className='phone-view-header'>
                    <img src={leftArrow} alt='<' />

                    {ReturnedApiData.responseData.name}

                    <img src={notificationIcon} alt='notify' style={{ visibility: "hidden" }} />
                </div>
                <div className='phone-view-slider-container'>


                    <Slider ref={sliderRef} {...settings}>
                        {ReturnedApiData.responseData.questions.map((question, index) => (
                            <div key={index}>
                                <div className='phone-view-slide'>

                                    {question.image && (
                                        <img className='phone-view-question-img' src={imagesUrl + '/' + question.image} alt="Question Image" />
                                    )}
                                    <div className='phone-view-question'>
                                        {index + "- " + question.text}
                                    </div>

                                    {question.surveyChoices && (
                                        <div className='phone-view-answers-container'>
                                            {question.surveyChoices.map((choice, choiceIndex) => (

                                                <div key={choiceIndex + 1000} className='phone-view-answers-row'>
                                                    <input
                                                        key={choiceIndex + 100}
                                                        type='radio'
                                                        name={`choice-${index}`}  // Set a unique name for each set of radio buttons
                                                        value={choiceIndex}
                                                        onChange={(e) => {
                                                            handleNextSlide(e);
                                                            // Handle the radio button selection here
                                                            // e.target.value will give you the selected choice index
                                                        }}
                                                    />
                                                    <div
                                                        key={choiceIndex}
                                                        className={`phone-view-answer`}

                                                    >
                                                        {choice.text}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}


                                </div>
                            </div>
                        ))}
                    </Slider>
                    <div className='phone-view-next-button-progress-container'>
                        <div className='phone-view-progress-bar-container'>
                            <div className='phone-view-progress-bar-title'>
                                Soru {currentSlide + 1} / {totalSlides}
                            </div>
                            <div className='phone-view-progress-bar'>
                                <PhoneViewProgressBar currentSlide={currentSlide + 1} totalSlides={totalSlides} />

                            </div>
                        </div>

                        <div className='phone-view-next-button-wrapper'>

                            <button
                                className={` ${currentSlide+1 === totalSlides ? 'phone-view-complete-button' : 'phone-view-next-button'}`}
                                onClick={handleNextSlide}
                            >
                                {currentSlide+1 === totalSlides ? 'Tamamla' : 'İlerle'}
                            </button>

                        </div>

                    </div>
                </div>
            </>)

    );
}
export default PhoneViewSurvey;