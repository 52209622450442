import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../../../assets/css/reports.css";
import placeholderImg from "../../../../../assets/media/user.png";
import blockIcon from "../../../../../assets/media/block.png";
import { apiUrl, imagesUrl } from "../../../../../config";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";
import DynamicTable from "../../../../tables/DynamicTable";
import InputContainer from "../../../../utils/inputContainer";
import arrow from "../../../../../assets/media/arrow.png";
import ApprovalPopup from "../../../../popup/approvalPopup";
import AreYouSure from "../../../../popup/areYouSure";

import cancelIcon from "../../../../../assets/media/clear.png";
import CallAPI from "../../../../api/callApi";
import ReportOperationsCard from "../../../../cards/ReportOperationsCard";
const ReportsDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("reports");
  const { callApiT } = useTranslation("challengeCreation");
  const [textareaVisible, setTextAreaVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [questionText, setQuestionText] = useState("");
  const [blockUser, setBlockUser] = useState(false);
  const report = location.state?.report;
  const locationArray = [
    [t("reports"), "/reports/"]
    [t("reportsDetail"), "/reports/detail"],
  ];

  const formatDateStringLocal = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);
    const formattedDate = utcDate.toLocaleDateString("tr-TR");
    const formattedTime = utcDate.toLocaleTimeString("tr-TR");
    return `${formattedDate} ${formattedTime}`;
  };

  const columns = [
    { title: t("reported"), field: "reported", className: "sotier" },
    { title: t("reporter"), field: "reporter", className: "sotier" },
    { title: t("creationDate"), field: "creationDate" },
    { title: t("reason"), field: "reason" },
    { title: t("status"), field: "status" },
  ];

  const data = [
    {
      reported: (
        <div className="sotier-list-sotier-container">
          <img
            className="sotier-list-sotier-image"
            src={
              report.reported.logo
                ? `${imagesUrl}/${report.reported.logo}`
                : placeholderImg
            }
            alt="logo"
          />
          <div className="sotier-list-sotier-details-container">
            <div className="sotier-list-sotier-details-full-name">
              {report.reported.firstName} {report.reported.lastName}
            </div>
            <div className="sotier-list-sotier-details-id">
              ID: {report.reported.userId}
            </div>
            <div className="sotier-list-sotier-details-report">
              {t("reportedReportCount")}: {report.reportCountToReported}
            </div>
          </div>
        </div>
      ),
      reporter: report.reporter && (
        <div className="sotier-list-sotier-container">
          <img
            className="sotier-list-sotier-image"
            src={
              report.reporter.logo
                ? `${imagesUrl}/${report.reporter.logo}`
                : placeholderImg
            }
            alt="logo"
          />
          <div className="sotier-list-sotier-details-container">
            <div className="sotier-list-sotier-details-full-name">
              {report.reporter.firstName} {report.reporter.lastName}
            </div>
            <div className="sotier-list-sotier-details-id">
              ID: {report.reporter.userId}
            </div>
            <div className="sotier-list-sotier-details-report">
              {t("reporterReportCount")}: {report.reportCountFromReporter}
            </div>
          </div>
        </div>
      ),
      creationDate: formatDateStringLocal(report.created),
      reason: t(report.type),
      status: t(report.status),
    },
  ];

  const handleTextArea = () => {
    setTextAreaVisible(true);
  };

  const returnToSelection = () => {
    setTextAreaVisible(false);
  };

  const handleOperation = (block) => {
    setBlockUser(block);
    if (block) {
      setQuestionText(t("areYouSureYouWantToBlockThisUser"));
    } else {
      setQuestionText(t("areYouSureYouWantToDoNothingToThisUser"));
    }
    console.log(message);
    setPopup(true);
  };
  const body = {
    accepted: blockUser,
  };
  console.log(body);

  const doSomethingToUser = async () => {
    setPopup(false);
    try {
      const response = await CallAPI({
        method: "PUT",
        endPoint: `${apiUrl}/api/v1/admin/report/${report.id}`,
        body: JSON.stringify(body), // Ensure the body is stringified
        successMessage: blockUser
          ? t("userBlockedSuccessfully")
          : t("didNothingToUser"),
        errorMessage: blockUser
          ? t("errorBlockingUser")
          : t("failedToTakeActionOnUser"),
        t: callApiT,
      });

      if (response.status === 200) {
        navigate("/reports");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dashboard-container">
      <NavigationBar locationArray={locationArray} />

      <DynamicTable columns={columns} data={data} unsortable={true} />
      {popup && (
        <AreYouSure
          questionText={questionText}
          onCancel={() => {
            setPopup(false);
          }}
          onAccept={() => {
            doSomethingToUser();
          }}
          isCondition={true}
          cancelText={t("cancel")}
          acceptText={t("yes")}
          content={
            <div className="sotier-list-sotier-container">
              <img
                className="sotier-list-sotier-image"
                src={
                  report.reported.logo
                    ? `${imagesUrl}/${report.reported.logo}`
                    : placeholderImg
                }
                alt="logo"
              />
              <div className="sotier-list-sotier-details-container">
                <div className="sotier-list-sotier-details-full-name">
                  {report.reported.firstName} {report.reported.lastName}
                </div>
                <div className="sotier-list-sotier-details-id">
                  ID: {report.reported.userId}
                </div>
              </div>
            </div>
          }
          isNegative={true}
        />
      )}

      {textareaVisible ? (
        <div className="challenge-creation-content-container">
          <InputContainer
            title={t("operation")}
            titleDescription={
              "Şikayet değerlendirme neticesinde halinde aşağıdaki seçeneklerden birini seçebilirsiniz."
            }
            children={
              <>
                <div
                  className="report-operation-return-button"
                  onClick={() => {
                    returnToSelection();
                  }}
                >
                  <img src={arrow} alt="<" />
                  {t("returnToChoice")}
                </div>
                <div className="report-operation-message-container">
                  <div className="report-operation-message-top">
                    <div className="report-operation-message-top-left">
                      <div className="sotier-list-sotier-container">
                        <img
                          className="sotier-list-sotier-image"
                          src={
                            report.reported.logo
                              ? `${imagesUrl}/${report.reported.logo}`
                              : placeholderImg
                          }
                          alt="logo"
                        />
                        <div className="sotier-list-sotier-details-container">
                          <div className="sotier-list-sotier-details-full-name">
                            {report.reported.firstName}{" "}
                            {report.reported.lastName}
                          </div>
                          <div className="sotier-list-sotier-details-id">
                            ID: {report.reported.userId}
                          </div>
                          <div className="sotier-list-sotier-details-report">
                            {t("reportedReportCount")}:{" "}
                            {report.reportCountToReported}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="report-operation-message-top-right">
                      {formatDateStringLocal(report.created)}
                    </div>
                  </div>
                  <hr />
                  <div className="report-operation-message-header">
                    <div className="report-operation-message-header-title">
                      {t("decision")}:
                    </div>
                    <div className="report-operation-message-header-data">
                      <div className="report-operations-card-button-disabled">
                        {t("blockUser")}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="report-operation-message-body">
                    <textarea
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                  <hr />
                  <div className="report-operation-message-footer">
                    <div
                      className="report-operations-card-button fit-content"
                      onClick={() => {
                        handleOperation();
                      }}
                    >
                      {t("send")}
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      ) : (
        <div className="challenge-creation-content-container">
          <InputContainer
            title={t("operation")}
            titleDescription={t("operationDescription")}
            children={
              <div className="report-operations-wrapper">
                <ReportOperationsCard
                  Title={t("doNothing")}
                  Description={t("doNothingDescription")}
                  buttonText={t("doNothing")}
                  cardImg={cancelIcon}
                  onClick={() => {
                    handleOperation(false);
                  }}
                />
                <ReportOperationsCard
                  Title={t("block")}
                  Description={t("blockUserDescription")}
                  buttonText={t("blockUser")}
                  cardImg={blockIcon}
                  onClick={() => {
                    handleOperation(true);
                  }}
                />
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default ReportsDetail;
