import React, { useEffect, useState } from 'react';
import '../../assets/css/ScrollToTop.css';
import downArrow from '../../assets/media/down-arrow.png';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const triggerScroll = 200;

            setIsVisible(scrollY > triggerScroll);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div
            className={`scroll-to-top ${isVisible ? 'visible' : ''}`}
            onClick={scrollToTop}
        >
            <label>
                <img src={downArrow} alt='img' />
            </label>

        </div>
    );
};

export default ScrollToTopButton;
