import React, { useState, useEffect } from "react";
import "../../assets/css/ListTable.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../popup/loader";
const DetailListTable = ({ List }) => {
  const navigate = useNavigate();
  const [created, setCreated] = useState('');
  const [elapsed, setElapsed] = useState('');
  const [showRequestedIds, setShowRequestedIds] = useState([]);
  const { t, i18n } = useTranslation("brandApplicationList");
  const [loaderState, setLoaderState] = useState(false);

  const handleRowClick = (event, app) => {
    // You can perform navigation or other actions here
    console.log("Row clicked:", app.id);
    if(app.status === "PENDING"){
      navigate(`/brand-app-request/${app.id}`);
    }
  };

  const toggleRequestedId = (appId) => {
    setShowRequestedIds((prevIds) =>
      prevIds.includes(appId)
        ? prevIds.filter((id) => id !== appId)
        : [...prevIds, appId]
    );
  };

  useEffect(() => {
    // API'den veriyi al
    // Örnek: apiData = { "created": "2023-09-04T12:31:19.837Z" }

    // API'den gelen "created" verisini state'e ayarla
    setLoaderState(true);

    setCreated(List.map((app) => app.created));

    // "created" tarihini hesapla ve elapsed state'ini güncelle
    setElapsed(List.map((app) => calculateElapsedTime(app.created)));
    setLoaderState(false);

  }, []);

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay indeksi 0 ile başlar, bu yüzden 1 ekliyoruz.
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');
    
    return `${day}-${month}-${year}`;
  }

  const calculateElapsedTime = (createdTime) => {
    const currentTime = new Date();
    const timeStamp = new Date(createdTime);
  
    const elapsedMilliseconds = currentTime - timeStamp;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);
  
    const seconds = elapsedSeconds % 60;
    const minutes = elapsedMinutes % 60;
    const hours = elapsedHours % 24;
  
    const elapsedString = `${elapsedDays} days`;
    return elapsedString;
  };
  

  
  

  

  return (
    <table id="kt_datatable_zero_configuration" className="table">
      {loaderState && <Loader/>} 

      <thead>
        <tr className="list-table-row">
          <th className="id">{t("id")}</th>
          <th>{t("companyName")}</th>
          <th>{t("brandName")}</th>
          <th>{t("applicationDate")}</th>
          <th>{t("daysSinceApplication")}</th>
          <th>{t("phoneNumber")}</th>
          <th>{t("process")}</th>
          <th>{t("progress")}</th>
        </tr>
      </thead>
      <tbody>
        {List.map((app) => (
          <tr className="list-table-row" key={app.id} onClick={(event) => handleRowClick(event, app)}>
            <td className="id">{app.id}</td>
            <td className="company-name-list-area">
              <div className="company-fake">{app.url}</div>
              <div className="company-name">{app.companyName}</div>
              <a className="company-name-link" href={"https://" + app.url}>{app.url}</a>
            </td>
            <td>
              <div>
                <span>{app.brandName}</span>
                <br />
                <a
                  href={app.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {app.websiteLink}
                </a>
              </div>
            </td>
            <td>{formatDateTime(app.created)}</td>
            <td>{calculateElapsedTime(app.created)}</td>
            <td>
              {app.phoneNumber}
            </td>
            <td>{app.status}</td>
            <td>
              <div className="list-table-progress-bar">
                <div
                  className={`progress-fill ${
                    app.status === "PENDING" ? "progress-25" : ""
                  }${app.status === "In Progress" ? "progress-50" : ""}${
                    app.status === "REJECTED" ? "progress-100 red-progress" : ""
                  }${app.status === "APPROVED" ? "progress-100" : ""}`}
                ></div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DetailListTable;
