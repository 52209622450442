import React, { useState } from "react";

const ItemsPerPageDialog = ({ onSelectItem }) => {
    const items = [5, 10, 20, 50, 100, 250, 500];
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        onSelectItem(item); // Seçilen öğeyi ana bileşene iletmek için callback'i çağır
    };

    return (
        <div className="items-per-page-dialog">
            {items.map((item, index) => (
                <div
                    key={index}
                    className={`items-per-page-item ${item === selectedItem ? 'selected' : ''}`}
                    onClick={() => handleItemClick(item)}
                >
                    {item}
                </div>
            ))}
        </div>
    );
}

export default ItemsPerPageDialog;
