import React, { useState, useEffect, useRef } from "react";
import threeDots from "../../assets/media/threeDots.svg";
import placeholderImg from "../../assets/media/placeholder.png";
import paperIcon from "../../assets/media/paper.svg";
import instagramIcon from "../../assets/media/instagram.svg";
import facebookIcon from "../../assets/media/facebook.svg";
import twitterIcon from "../../assets/media/x-twitter.svg";
import youtubeIcon from "../../assets/media/youtube.svg";
import tiktokIcon from "../../assets/media/tiktok.svg";
import { imagesUrl } from "../../config";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ChallengeCardDialog from "../dialogs/challengeCardDialog";
const CampaignCard = ({ challengeType, campaignData }) => {
  const {
    id,
    campaignName,
    campaignImg,
    campaignType,
    Status,
    paymentAmount,
    refundAmount,
    sotierCount,
    numberOfDaysAfterShopping,
    creationDate,
  } = campaignData;

  const { t, i18n } = useTranslation("challengeDashboard");

  const [isDialogVisible, setDialogVisibility] = useState(false);
  const dialogRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setDialogVisibility(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dialogRef]);

  const toggleDialogVisibility = () => {
    setDialogVisibility(!isDialogVisible);
  };

  const getBackgroundColor = (Status, corpcampaignstatus) => {
    if (corpcampaignstatus) {
      if (corpcampaignstatus === "DRAFTED") {
        return "#c33e5d";
      }
      if (Status === true || Status === "false") {
        return "#888"; // NaN durumu
      } else if (Status === false || Status === "true") {
        return "#5ABB6A"; // NaN durumu
      } else {
        return "#5ABB6A";
      }
    } else {
      if (Status === true || Status === "false") {
        return "#888"; // NaN durumu
      } else if (Status === false || Status === "true") {
        return "#5ABB6A"; // NaN durumu
      } else {
        return "#5ABB6A";
      }
    }
  };

  let today;
  let campaignEndDate;
  if (campaignData.endDate) {
    today = new Date();
    const [day, month, year] = campaignData.endDate.split(".").map(Number);
    campaignEndDate = new Date(year, month - 1, day);
  }

  const campaignEndStatus = campaignEndDate < today;

  return (
    <div className="challenge-card-container" ref={dialogRef}>
      <div className="challenge-card-header">
        {campaignData.id && (
          <div className="challenge-card-left">
            <span>{t("id")}:</span>
            {campaignData.id}
          </div>
        )}
        {campaignData.campaign_id && !campaignData.sotier_id && (
          <div className="challenge-card-left">
            <span>{t("id")}:</span>
            {campaignData.campaign_id}
          </div>
        )}
        {campaignData.sotier_id && (
          <div className="challenge-card-left">
            <span>{t("id")}:</span>
            {campaignData.sotier_id}
          </div>
        )}

        <div className="challenge-card-right">
          <img
            className="challenge-card-right-img "
            src={threeDots}
            alt="..."
            onClick={() => {
              toggleDialogVisibility();
            }}
          />

          {isDialogVisible && <ChallengeCardDialog challenge={campaignData} />}
        </div>
      </div>
      {(campaignData.image || campaignData.logo) && (
        <img
          className="challenge-card-img"
          src={
            campaignData.image && campaignData.image.startsWith("https")
              ? campaignData.image
              : campaignData.logo
              ? campaignData.logo
              : imagesUrl + "/" + campaignData.image
          }
          alt={
            campaignData.image ? "Kampanya Resmi" : "Kampanya Resmi Bulunamadı"
          }
        />
      )}

      <div className="challenge-details-container">
        <div className="challenge-details-left">
          {campaignData.campaignName && (
            <div className="challenge-details-title">
              {campaignData.campaignName}
            </div>
          )}
          {campaignData.name && (
            <div className="challenge-details-title">{campaignData.name}</div>
          )}
          {campaignData.first_name && campaignData.last_name && (
            <div className="challenge-details-title">
              {campaignData.first_name} {campaignData.last_name}
            </div>
          )}
          {campaignData.campaign_name && (
            <div className="challenge-details-title">
              {campaignData.campaign_name}
            </div>
          )}
          {campaignData.campaignType && (
            <div className="challenge-details-Prize">
              {t("campaignType")}: <span>{t(campaignData.campaignType)}</span>
            </div>
          )}

          {campaignData.campaign_type && (
            <div className="challenge-details-Prize">
              {t("campaignType")}: <span>{t(campaignData.campaign_type)}</span>
            </div>
          )}

          {(campaignData.sotierCount || campaignData.sotiercount) && (
            <div className="challenge-details-Prize">
              {t("sotierCount")}:{" "}
              <span>
                {campaignData.sotierCount || campaignData.sotiercount}
              </span>
            </div>
          )}
          {campaignData.participation_count && (
            <div className="challenge-details-Prize">
              {t("participation_count")}:{" "}
              <span>{campaignData.participation_count}</span>
            </div>
          )}
          {campaignData.corporate_name && (
            <div className="challenge-details-Prize">
              {t("corporateName")}: <span>{campaignData.corporate_name}</span>
            </div>
          )}
          {campaignData.corporate_domain && (
            <div className="challenge-details-Prize">
              {t("corporationExtension")}:{" "}
              <span>
                {" "}
                <a href={"https://" + campaignData.corporate_domain}>
                  {"(" + campaignData.corporate_domain + ")"}
                </a>
              </span>
            </div>
          )}

          {(campaignData.payment_amount || campaignData.sotier_id) && (
            <div className="challenge-details-Prize">
              {t("paymentAmount")}:{" "}
              <span>{t(campaignData.payment_amount) || 0}</span>
            </div>
          )}
          {campaignData.sotier_refund_amount && (
            <div className="challenge-details-Prize">
              {t("refundAmount")}:{" "}
              <span>{t(campaignData.sotier_refund_amount) || 0}</span>
            </div>
          )}
          {campaignData.sotier_refund_coin_amount && (
            <div className="challenge-details-Prize">
              {t("sotyCoinRefundAmount")}:{" "}
              <span>{t(campaignData.sotier_refund_coin_amount) || 0}</span>
            </div>
          )}

          {campaignData.giro && (
            <div className="challenge-details-Prize">
              {t("income")}: <span>{campaignData.giro}</span>
            </div>
          )}

          {campaignData.paymentAmount && (
            <div className="challenge-details-Prize">
              {t("paymentAmount")}: <span>{campaignData.paymentAmount}</span>
            </div>
          )}
          {campaignData.refundAmount && (
            <div className="challenge-details-start-date">
              {t("refundAmount")}: <span>{campaignData.refundAmount}</span>
            </div>
          )}
          {campaignData.refund_amount && (
            <div className="challenge-details-start-date">
              {t("refundAmount")}: <span>{campaignData.refund_amount}</span>
            </div>
          )}
          {campaignData.created && (
            <div className="challenge-details-start-date">
              {t("creationDate")}: <span>{campaignData.created}</span>
            </div>
          )}
          {campaignData.creationDate && (
            <div className="challenge-details-start-date">
              {t("creationDate")}: <span>{campaignData.creationDate}</span>
            </div>
          )}

          {numberOfDaysAfterShopping && (
            <div className="challenge-details-start-date">
              {t("numberOfDaysAfterShopping")}:{" "}
              <span>{numberOfDaysAfterShopping}</span>
            </div>
          )}
          {campaignData.participation_status && (
            <div className="challenge-details-start-date">
              {t("participationStatus")}:{" "}
              <span>{t(campaignData.participation_status)}</span>
            </div>
          )}
          {campaignData.usage_date && (
            <div className="challenge-details-start-date">
              {t("usageDate")}: <span>{campaignData.usage_date}</span>
            </div>
          )}
          {campaignData.campaignEndStatus && (
            <div className="challenge-details-success-rate margin-0">
              <span
                className="margin-0"
                style={{
                  backgroundColor: getBackgroundColor(campaignEndStatus),
                }}
              >
                {campaignEndStatus ? t("COMPLETED") : t(campaignData.status)}
              </span>
            </div>
          )}

          {campaignData.isactive && (
            <div className="challenge-details-success-rate stick-to-bottom">
              <span
                className="stick-to-bottom"
                style={{
                  backgroundColor: getBackgroundColor(
                    campaignData.isactive,
                    campaignData.publish_status
                  ),
                }}
              >
                {campaignData.publish_status === "RELEASED"
                  ? campaignData.isactive === "true"
                    ? t("ACTIVE")
                    : t("UNACTIVE")
                  : t("DRAFTED")}
              </span>
            </div>
          )}
          {campaignData.status && (
            <div className="challenge-details-success-rate stick-to-bottom">
              <span
                className="stick-to-bottom"
                style={{
                  backgroundColor: getBackgroundColor(
                    campaignData.status,
                    campaignData.status
                  ),
                }}
              >
                {campaignData.status}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
