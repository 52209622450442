import React from "react";
import tick from "../../../../../assets/media/tickInteraction.svg"; // Icon yolunu kontrol edin

const CoinFolding = ({
  t,
  coinFoldingOptions,
  selectedCoinFolding,
  setSelectedCoinFolding,
}) => {
  return (
    <div className="coin-folding-container">
      {/* Popular Choices Section */}
      <div className="coin-folding-input-container">
        <div className="challenge-creation-input-container-header-subtitle">
          {t("popularChoices")}
        </div>
        <div className="coin-folding-input-wrapper">
          {coinFoldingOptions.map((multiplier) => (
            <div
              key={multiplier}
              className={`coin-folding-input ${
                selectedCoinFolding === multiplier &&
                "coin-folding-input-active"
              }`}
              onClick={() => setSelectedCoinFolding(multiplier)}
            >
              X{multiplier}
              {selectedCoinFolding === multiplier && (
                <img src={tick} alt="tick" />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Other Section (for custom input) */}
      <div className="coin-folding-input-container">
        <div className="challenge-creation-input-container-header-subtitle">
          {t("other")}
        </div>
        <div className="coin-folding-input-wrapper">
          <div className="coin-folding-input-type-wrapper">
            x
            <input
              type="number"
              className="coin-folding-input-type"
              onChange={(e) => setSelectedCoinFolding(e.target.value)}
              value={selectedCoinFolding}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinFolding;
